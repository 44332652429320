import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import Estimate from "./Estimate";
import { axiosInstance } from "../../../axiosSetup";
import { CardHeader, Card as CardMui, Divider } from "@mui/material";

const NewOrder = (props) => {
  const [loaded, setLoaded] = useState(false); //for loading spinner
  const { handleNewOrder } = props; //callback to update Order Table
  const [showEstimate, setShowEstimate] = useState(false); // to show generated estimate
  const [newSchedule, setNewSchedule] = useState({}); //to store schedule coming from backend
  const [showDeadlineForm, setShowDeadlineForm] = useState(false); //make visible taking "weeks" input
  const [weeksInvalid, setWeeksInvalid] = useState(false); //wether user gave less number of weeks for deadline
  const initialFormData = {
    sku: "P-0361",
    so: "",
    customer: "",
    model_name: "2BY",
  }; //initial skeleton  for form data
  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    setShowEstimate(false);
    // console.log(e.target.name, e.target.value);
    if (e.target.name === "priority" && e.target.value === "Deadline") {
      setShowDeadlineForm(true); //make visible deadline weeks input
    } else if (e.target.name === "priority" && e.target.value !== "Deadline") {
      setShowDeadlineForm(false); //disable deadline weeks input
    }
    if (e.target.name === "deadlineWeeks" && e.target.value < 9) {
      setWeeksInvalid(true); //shows warning for wrong input
    } else if (e.target.name === "deadlineWeeks" && e.target.value >= 9) {
      setWeeksInvalid(false); //removes warning for wrong input
    }
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!weeksInvalid || formData.priority === "Automated") {
      //no warning in case of deadline option or if automated option choosen
      setLoaded(true);
      axiosInstance
        .get("ajs/estimate/", {})
        .then((res) => {
          const data = res.data;
          setNewSchedule(data);
          setLoaded(false);
        })
        .catch((error) => {
          console.log(error.response);
        });
      setShowEstimate(true);
    }
  };
  return (
    <>
     <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title="Add New Order"
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </CardMui>
      <Card border="light" className="shadow-sm">
        {/* <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Add New Order</h5>
            </Col>
          </Row>
        </Card.Header> */}
      </Card>

      <Row>
        <Col xl={8}>
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    SKU
                  </Form.Label>
                  <Col sm="10">
                    <Form.Select required name="sku" onChange={handleChange}>
                      <option value="P-0361">P-0361</option>
                      <option value="C-0240">C-0240</option>
                      <option value="SKU-1">SKU-1</option>
                      <option value="SKU-2">SKU-2</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    SO
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      required
                      name="so"
                      placeholder="Project SO"
                      onChange={handleChange}
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Customer
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      required
                      name="customer"
                      placeholder="Customer Name"
                      onChange={handleChange}
                    ></Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Model
                  </Form.Label>
                  <Col sm="10">
                    <Form.Select
                      required
                      name="model_name"
                      onChange={handleChange}
                    >
                      <option value="2BY">2BY</option>
                      <option value="CT">CT</option>
                      <option value="SW">SW</option>
                      <option value="CU">CU</option>
                      <option value="3CY">3CY</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}>
                    Priority
                  </Form.Label>
                  <Col sm="10">
                    <Form.Select name="priority" onChange={handleChange}>
                      <option value="Automated">Automated</option>
                      <option value="Deadline">Defined Deadline</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                {showDeadlineForm && (
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Deadline
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control
                        required
                        name="deadlineWeeks"
                        type="number"
                        isInvalid={weeksInvalid}
                        placeholder="(in Weeks)"
                        onChange={handleChange}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Must be greater than 8 weeks.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group className="d-flex justify-content-center">
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: "#050092",
                      color: "#fff",
                    }}
                    type="submit"
                    size="md"
                    // className="w-100 mb-2"
                  >
                    Generate Estimate
                  </Button>
                </Form.Group>
              </Form>

              <Row>
                <Col md={{ span: 3, offset: 5 }}>
                  {loaded && (
                    <>
                      <Spinner animation="grow" variant="info" />
                      <Spinner animation="grow" variant="success" />
                      <Spinner animation="grow" variant="warning" />
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <Card bg="light" text="dark" className="mb-2">
            <Card.Header>Do you know?</Card.Header>
            <Card.Body>
              <Card.Title>Facts</Card.Title>

              <ol>
                <li>
                  Our estimate has been <b>96%</b> accurate to predict{" "}
                  <b>ETA</b>.
                </li>
                <li>
                  Our estimate has been <b>92%</b> accurate to predict{" "}
                  <b>Start Date</b>.
                </li>
                <li>
                  We have <b>10%</b> increase in estimate <b>order locks</b>{" "}
                  from previous month.
                </li>
              </ol>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {showEstimate && !loaded && (
          <Estimate
            data={formData}
            handleNewOrder={handleNewOrder}
            newSchedule={newSchedule}
          />
        )}
      </Row>
    </>
  );
};

export default NewOrder;
