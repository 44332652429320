import { Button, MenuItem, Select, TextField } from "@mui/material";
import {
  GridRowModes,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  addPMRow,
  addRow,
  updateCompletePMRow,
  updatePMRow,
  updateRow,
} from "../../../slices/calculatorSlice";

export const NumericEditComponentRM = (props) => {
  const { id, value, field, row } = props;
  const [valueState, setValueState] = useState(value);
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();

  const onChange = (event) => {
    const newValue = +event.target.value;
    setValueState(newValue);
    dispatch(
      updateRow({
        primaryKey: row.primary_key,
        primaryKeyValue: row[row.primary_key],
        changedColumnKey: field,
        changedColumnValue: newValue,
      })
    );
    // console.log(newValue);
  };
  return (
    <TextField
      type="number"
      name={field}
      value={valueState}
      onChange={onChange} // Call handleChange with row object
      size="small"
      fullWidth
      variant="outlined"
      key={`${id}-${field}`}
    />
  );
};
export const NumericEditComponentPM = (props) => {
  const { id, value, field, row, handleChange } = props;
  const [valueState, setValueState] = useState(value);
  const dispatch = useDispatch();
  const apiRef = useGridApiContext();

  const onChange = (event) => {
    const newValue = +event.target.value;
    setValueState(newValue);
    // const updatedRow = {
    //   ...row,
    //   [field]: newValue,
    // };
    dispatch(
      updatePMRow({
        primaryKey: "id",
        primaryKeyValue: id,
        changedColumnKey: field,
        changedColumnValue: newValue,
      })
    );
  };
  const onBlur = (event) => {
    handleChange(id);
  };
  return (
    <TextField
      type="number"
      name={field}
      value={valueState}
      onChange={onChange}
      size="small"
      fullWidth
      variant="outlined"
      key={`${id}-${field}`}
      onBlur={onBlur}
    />
  );
};

export const TextEditComponent = (props) => {
  const { id, value, field, row } = props;
  const [valueState, setValueState] = useState(value);
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();

  const onChange = (event) => {
    const newValue = event.target.value;
    setValueState(newValue);

    // Check if the row already exists using the ref

    const updatedRow = {
      primaryKey: row.primary_key,
      primaryKeyValue: row[row.primary_key],
      changedColumnKey: field,
      changedColumnValue: newValue,
    };
    console.log({
      primaryKey: row.primary_key,
      primaryKeyValue: row[row.primary_key],
      changedColumnKey: field,
      changedColumnValue: newValue,
    });
    dispatch(updateRow(updatedRow));
  };
  return (
    <TextField
      labelid="demo-simple-select-label"
      id="demo-simple-select"
      value={valueState}
      label={field}
      onChange={onChange}
      key={`${id}-${field}`}
      size="small"
      fullWidth
      variant="outlined"
      // onBlur={onBlur}
    />
  );
};
export const TextEditComponentPM = (props) => {
  const { id, value, field, row, pmItemsInfo, handleChange } = props;
  const [valueState, setValueState] = useState(value);
  const apiRef = useGridApiContext();
  const dispatch = useDispatch();

  const onChange = (event) => {
    setValueState(event.target.value);
  };
  const onBlur = (event) => {
    const updatedRow = {
      ...row,
      [field]: valueState,
    };
    // console.log(updatedRow);
    dispatch(
      updatePMRow({
        primaryKey: "id",
        primaryKeyValue: id,
        changedColumnKey: field,
        changedColumnValue: valueState,
      })
    );

    handleChange(id);
  };
  return (
    <TextField
      labelid="demo-simple-select-label"
      id="demo-simple-select"
      value={valueState}
      // label={field}
      onChange={onChange}
      key={`${id}-${field}`}
      size="small"
      fullWidth
      variant="outlined"
      onBlur={onBlur}
    />
  );
};

export const SelectEditComponent = (props) => {
  const { id, value, field, row, options, pmItemsInfo, handleChange } = props;
  const [valueState, setValueState] = useState(value);
  const [type, setType] = useState(
    options.includes(value) || value === "" ? "dropdown" : "text"
  );
  const apiRef = useGridApiContext();
  const { pmRows } = useSelector((state) => state.calculatorData);
  const dispatch = useDispatch();

  const onChange = (event) => {
    const newValue = event.target.value;
    setValueState(newValue);
    if (newValue && pmItemsInfo && newValue !== "custom") {
      const selectedItem = pmItemsInfo.find((item) => item[field] === newValue);
      if (selectedItem) {
        // Check if the row already exists using the ref
        const existingRow = pmRows.find((row) => row.id === id);
        if (existingRow) {
          const updatedRow = {
            ...row,
            ...selectedItem,
            [field]: newValue,
            ["pm_source"]: "Connected Account",
          };
          // console.log(updatedRow);
          dispatch(updateCompletePMRow({ id, updatedRow, field }));
        }
      }
      handleChange(id, "view");
    } else if (newValue === "custom") {
      setValueState("");
      setType("text");
    }
  };
  const handleCustomOptionChange = (event) => {
    // Update the state on every keystroke
    setValueState(event.target.value);
  };
  const handleCustomOptionBlur = () => {
    // Handle the change only when the input field is blurred (focus is lost)
    const updatedRow = {
      ...row,
      [field]: valueState,
      ["pm_source"]: "Manual",
    };
    dispatch(updateCompletePMRow({ id, updatedRow, field }));
    handleChange(id);
  };
  return (
    <React.Fragment>
      {type === "dropdown" && (
        <Select
          labelid="demo-simple-select-label"
          id="demo-simple-select"
          aria-label="Select Option"
          value={valueState}
          label={field}
          onChange={onChange}
          key={`${id}-${field}`}
          size="small"
          fullWidth
          variant="outlined"
          // onBlur={onBlur}
        >
          {options.map((option, index) => {
            return (
              <MenuItem value={option} key={`pm_name_${index}`}>
                {option}
              </MenuItem>
            );
          })}
          <MenuItem value="custom" style={{ color: "blue" }}>
            +Add New Entry
          </MenuItem>
        </Select>
      )}
      {type === "text" && (
        <TextField
          label="Enter Custom Option"
          value={valueState}
          onChange={handleCustomOptionChange}
          onBlur={handleCustomOptionBlur} // Call handleCustomOptionBlur when the input field is blurred
        />
      )}
    </React.Fragment>
  );
};

export function EditToolbar(props) {
  const { columns, rows, handleColumnChange, setRowModesModel } = props;
  const { number_of_packs } = useSelector(
    (state) => state.calculatorData.details.all
  );
  const dispatch = useDispatch();
  const handleClick = () => {
    // const id = randomId();
    let latestId = 0;

    // Find the latest id in the existing rows
    rows.forEach((row) => {
      if (row.id > latestId) {
        latestId = row.id;
      }
    });

    // Increment the latest id by 1 or set it to 1 if rows are empty
    const id = rows.length === 0 ? 1 : latestId + 1;
    // Create a new row based on each field in the JSON data
    const newRow = { id };
    columns.forEach((column) => {
      if (column.type === "string" || column.type === "singleSelect") {
        newRow[column.field] = "";
      } else if (column.type === "number" && column.field !== "pm_quantity") {
        newRow[column.field] = 0;
      } else if (column.type === "number" && column.field === "pm_quantity") {
        newRow[column.field] = number_of_packs;
      }
    });
    // Set editable to true for pm_rate and pm_quantity only for the new row
    const updatedColumns = columns.map((column) => {
      if (column.field === "pm_rate" || column.field === "pm_quantity") {
        return {
          ...column,
          editable: false,
        };
      }
      return column;
    });
    dispatch(addPMRow(newRow));

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "id" },
    }));
    // setColumns(updatedColumns);
    handleColumnChange(updatedColumns); // Update the columns to make pm_rate and pm_quantity editable
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export function EditToolbarRM(props) {
  const { columns, rows, handleColumnChange } = props;
  const dispatch = useDispatch();
  const handleClick = () => {
    // const id = randomId();
    let latestId = 0;

    // Find the latest id in the existing rows
    rows.forEach((row) => {
      if (row.id > latestId) {
        latestId = row.id;
      }
    });

    // Increment the latest id by 1 or set it to 1 if rows are empty
    const id = rows.length === 0 ? 1 : latestId + 1;
    // Create a new row based on each field in the JSON data
    const newRow = { id };
    const primary_key = rows.length > 0 ? rows[0]["primary_key"] : "item_code";
    columns.forEach((column) => {
      if (column.type === "string" || column.type === "singleSelect") {
        newRow[column.field] = "";
      } else if (column.type === "number") {
        newRow[column.field] = 0;
      }
    });
    newRow["primary_key"] = primary_key;
    newRow["editable"] = true;
    newRow["source"] = "Manual";
    newRow[primary_key] = `RM ${id}`;
    dispatch(addRow(newRow));
  };
  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add RM
      </Button>
    </GridToolbarContainer>
  );
}
