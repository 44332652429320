import {
  Alert,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import DetailTable from "./components/DetailTable";
import SummaryComponent from "./components/SummaryComponent";
import { useSelector, useDispatch } from "react-redux";
import ItemSkeleton from "./components/Skeleton";
import ItemGrid from "./components/ItemGrid";
import {
  clearExportState,
  setCalculationName,
  setDataFromImport,
  setExportState,
} from "../../slices/calculatorSlice";
import { postNewCalculatorData, updateCalculatorData } from "./api/erpItems";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import SyncIcon from "@mui/icons-material/Sync";
import bomMetaData from "./data/bom_details.json";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchPanel from "./components/SearchPanel";
import PMTable from "./components/PMTable";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import RetryCA from "../../components/RetryCA";

const PriceCalculator = () => {
  const dispatch = useDispatch();
  const { selectedItem, selectedSecondaryItem, rows, cost_calculation_name } =
    useSelector((state) => state.calculatorData);

  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Calculator")
      ? connectedApps["Calculator"]
      : null
    : null;
  const [syncButtonClicked, setSyncButtonClicked] = useState(false);
  const [exportButtonClicked, setExportButtonClicked] = useState(false);
  const exportState = useSelector((state) => state.calculatorData.exportState);
  const [snakbarST, setSnakbarST] = useState({
    openSnakbar: false,
    snackbarMessage: "",
    severity: "info",
  });
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");

  const [sm9Height, setSm9Height] = useState(0);

  useEffect(() => {
    if (sm9Height === 0) {
      const sm9Element = document.querySelector(".sm9-section");
      if (sm9Element) {
        const height = sm9Element.clientHeight;
        setSm9Height(height);
      }
    }
  }, [rows]);

  const buttonStyleSync = {
    marginRight: "8px",
    backgroundColor: "#050092",
    color: "#fff", // Set the text color to white for better visibility
  };

  const buttonStyleExport = {
    marginRight: "8px",
    borderColor: "#050092",
    color: "#050092", // Set the text color to white for better visibility
  };

  const buttonStyleImport = {
    marginRight: "8px",
    borderColor: "#050092", // Blue color from Material-UI palette
    color: "#050092", // Set the text color to white for better visibility
  };

  const handleCloseSnakbar = () => {
    setSnakbarST({
      ...snakbarST,
      openSnakbar: false,
    });
  };

  const exportDataviaAPI = async () => {
    try {
      if (!cost_calculation_name) {
        console.log("new entry");
        const res = await postNewCalculatorData(
          exportState,
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret
        );
        if (res.error) {
          console.log(res.error);
          setSnakbarST({
            ...snakbarST,
            openSnakbar: true,
            snackbarMessage: `${res.error.response.data.exception}`,
            severity: "error",
          });
          console.log(res.error.response.data.exception);
        } else {
          // console.log(res.data);

          dispatch(setCalculationName(res.data.data.cost_calculation_name));
          setSnakbarST({
            ...snakbarST,
            openSnakbar: true,
            snackbarMessage: `New record created successfully!`,
            severity: "success",
          });
        }
      } else if (cost_calculation_name) {
        console.log("updating data");
        const res = await updateCalculatorData(
          exportState,
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret
        );

        if (res.error) {
          console.log(res.error);
          setSnakbarST({
            ...snakbarST,
            openSnakbar: true,
            snackbarMessage: `${res.error.response.data.exception}`,
            severity: "error",
          });
          console.log(res.error.response.data.exception);
        } else {
          console.log("success");
          setSnakbarST({
            ...snakbarST,
            openSnakbar: true,
            snackbarMessage: `${res.data.data.Doctype}`,
            severity: "success",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (syncButtonClicked) {
      exportDataviaAPI();
      setSyncButtonClicked(false);
      dispatch(clearExportState());
    }
  }, [syncButtonClicked]);

  useEffect(() => {
    if (exportButtonClicked) {
      setExportButtonClicked(false);
      dispatch(clearExportState());
      const jsonData = JSON.stringify(exportState, null, 2);

      // Create a Blob containing the JSON data
      const blob = new Blob([jsonData], { type: "application/json" });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "data.json"; // Specify the filename for the downloaded file

      // Simulate a click event on the <a> element to trigger the download
      a.click();

      // Clean up by revoking the URL
      URL.revokeObjectURL(url);
      setSnakbarST({
        ...snakbarST,
        openSnakbar: true,
        snackbarMessage: `Json Data is being downloaded!`,
        severity: "success",
      });
    }
  }, [exportButtonClicked]);

  const handleSync = (e) => {
    dispatch(setExportState());
    setSyncButtonClicked(true);
  };
  const handleExport = (e) => {
    dispatch(setExportState());
    setExportButtonClicked(true);
  };
  const handleImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Read the contents of the uploaded file as text
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        try {
          // Parse the JSON data from the file
          const jsonData = JSON.parse(fileContents);
          let bomData = bomMetaData;
          bomData["details"] = jsonData.calculatorData.details;
          bomData["items"] = jsonData.calculatorData.rows;
          bomData["pm_items"] = jsonData.calculatorData.pmRows;
          bomData["summary"] = jsonData.calculatorData.summary;
          bomData["cost_calculation_name"] = jsonData.cost_calculation_name;
          bomData["selectedItem"] = jsonData.calculatorData.selectedItem;
          bomData["selectedSecondaryItem"] =
            jsonData.calculatorData.selectedSecondaryItem;
          if (jsonData.calculatorData.reference) {
            bomData["reference"] = jsonData.calculatorData.reference;
          }
          if (jsonData.calculatorData.selected_reference_type) {
            bomData["selected_reference_type"] =
              jsonData.calculatorData.selected_reference_type;
          }
          if (jsonData.calculatorData.prospect) {
            bomData["prospect"] = jsonData.calculatorData.prospect;
          }
          if (jsonData.calculatorData.prospect_id) {
            bomData["prospect_id"] = jsonData.calculatorData.prospect_id;
          }
          if (jsonData.calculatorData.details?.costing_based_on) {
            bomData["costing_based_on"] =
              jsonData.calculatorData.details.costing_based_on;
          } else {
            bomData["costing_based_on"] = "total";
          }
          dispatch(setDataFromImport(bomData));
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="page-container">
      {connectingCredentials.credential_available == "NO" && (
        <RetryCA
          data={{ ...connectingCredentials, productName: "Calculator" }}
        />
      )}
      {connectingCredentials.credential_available == "YES" && (
        <Card
          variant="outlined"
          sx={{ border: "1px solid lightgray" }}
          className="shadow-sm"
        >
          <CardHeader
            title="Calculator"
            sx={{ margin: "15px" }}
            action={
              <div
                style={{
                  display: "flex",
                  flexDirection: isExtraSmallScreen
                    ? "column"
                    : isMediumScreen
                    ? "row"
                    : "row",
                }}
              >
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    ...buttonStyleImport,
                    marginBottom: isExtraSmallScreen ? "8px" : "0",
                  }}
                  size={isMediumScreen ? "small" : "medium"}
                >
                  <input
                    type="file"
                    accept=".json"
                    onChange={handleImport}
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label htmlFor="fileInput">
                    <CloudUploadIcon />
                  </label>
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    ...buttonStyleExport,
                    marginBottom: isExtraSmallScreen ? "8px" : "0",
                  }}
                  onClick={handleExport}
                  size={isMediumScreen ? "small" : "medium"}
                >
                  <GetAppIcon />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    ...buttonStyleSync,
                    marginBottom: isExtraSmallScreen ? "8px" : "0",
                  }}
                  onClick={handleSync}
                  startIcon={<SyncIcon />}
                  size={isMediumScreen ? "small" : "medium"}
                >
                  Sync
                </Button>
              </div>
            }
          />
          <Divider sx={{ backgroundColor: "darkgray" }} />
          <CardContent style={{ backgroundColor: "#F5F8FB" }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9} className="sm9-section">
                  <Grid item lg={10} md={12}>
                    <SearchPanel />
                  </Grid>
                  <Grid item lg={10} md={12}>
                    <Box my={2}>{<DetailTable />}</Box>
                  </Grid>
                  <Grid container spacing={1}>
                    {rows?.all !== null && (
                      <Box sx={{ minWidth: 200, width: "100%" }}>
                        <Divider light />
                        <Box my={4}>
                          <ItemGrid />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid container spacing={1}>
                    {rows?.all !== null && (
                      <Box sx={{ minWidth: 200, width: "100%" }}>
                        <Divider light />
                        <Box my={4}>
                          <PMTable />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                {selectedSecondaryItem && (
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <div>
                      <SummaryComponent minHeight={sm9Height} />
                    </div>
                  </Grid>
                )}
              </Grid>

              {(!selectedItem || !selectedSecondaryItem) &&
                rows?.all === null && <ItemSkeleton />}
            </Box>
          </CardContent>
        </Card>
      )}
      <Snackbar
        open={snakbarST.openSnakbar}
        autoHideDuration={2000}
        onClose={handleCloseSnakbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleCloseSnakbar} severity={snakbarST.severity}>
          {snakbarST.snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PriceCalculator;
