import { Add } from "@mui/icons-material";
import { Button, ButtonGroup, CardHeader, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const Header1 = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <CardHeader
      title="Timesheet"
      action={
        <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
          {/* <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              style={{
                borderColor: "#050092",
                color: "#050092",
              }}
            >
              Day
            </Button>
            <Button
              style={{
                borderColor: "#050092",
                color: "#050092",
              }}
            >
              Week
            </Button>
          </ButtonGroup> */}
          <Button
            // onClick={handleOpenNewNote}
            variant="contained"
            style={{
              marginLeft: "8px",
              marginRight: "8px",
            }}
          >
            <Add /> New
          </Button>
        </div>
      }
      sx={{
        margin: "15px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isMobileOrTablet ? "column" : "row",
      }}
    />
  );
};

export default Header1;
