import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../axiosSetup";
import { useLocation, useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import { useDispatch } from "react-redux";
import { clearCalculatorState } from "../../slices/calculatorSlice";
import { clearVCState } from "../../slices/visitingCardSlice";
import { clearConnectedData } from "../../slices/connectedAccountSlice";

const LogOut = (props) => {
  const location = useLocation();
  const { state } = location;
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // const response = axiosInstance.post("user/logout/blacklist/", {
    //   refresh_token: localStorage.getItem("refresh_token"),
    // });
    dispatch(clearCalculatorState());
    dispatch(clearVCState());
    dispatch(clearConnectedData());
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("refresh_token");
    localStorage.removeItem("api_secret");
    localStorage.removeItem("api_key");
    localStorage.removeItem("mobile_no");
    localStorage.removeItem("connected_apps_erpFormat");
    localStorage.removeItem("connected_apps_erpFormat2");
    localStorage.removeItem("sid");
    localStorage.removeItem("base_url");
    localStorage.removeItem("user_email");
    localStorage.removeItem("roles");
    localStorage.removeItem("logo");
    // localStorage.removeItem("group");
    localStorage.removeItem("company");
    localStorage.removeItem("company_logo");
    localStorage.removeItem("username");
    localStorage.removeItem("metadata");
    localStorage.removeItem("subscribed_products");
    localStorage.removeItem("redux_localstorage_simple");
    localStorage.removeItem("show_multiple_documents_tag");
    localStorage.removeItem("document_types");
    localStorage.removeItem("pagination_in_sds");
    localStorage.removeItem("show_sync_button");
    localStorage.removeItem("connected_accounts_details_list");
    // localStorage.removeItem("group");
    // localStorage.removeItem("manifest");
    // localStorage.removeItem("subscribed_products");
    // localStorage.removeItem("table_data_manifest");
    axiosInstance.defaults.headers["Authorization"] = null;
    navigate(URLs.Signin.path, { state: { from: "/logout", msg: null } });
  });
  return (
    // <Redirect
    //   to={{ pathname: URLs.Signin.path, state: { from: props.location } }}
    // />
    <div></div>
  );
};

export default LogOut;
