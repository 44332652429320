import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FormatDate } from "../../../components/dateformat";

const WorkcenterService = (props) => {
  const { workcenter, handleServiceChange } = props;
  const [showModal, setshowModal] = useState(false); //modal to take inputs for machine down

  //styling
  const now = new Date();
  const serviceDate = FormatDate(workcenter.due_service_date);
  const serviceDateClass =
    now > new Date(workcenter.due_service_date) ? "my-yellow" : "my-secondary";
  //state and form for handling changes

  const [serviceForm, updateServiceForm] = useState({});

  const handleClose = () => setshowModal(false); //modal on close

  const handleChange = (e) => {
    //handle changes inside modal for service change inputs
    let tempForm = serviceForm;
    if (e.target.name === "days") {
      tempForm["expected_downtime"] = `${e.target.value} 00:00:00`;
    } else {
      tempForm[e.target.name] = e.target.value;
    }
    updateServiceForm(tempForm);
  };

  const handleSubmit = (e) => {
    //handles service changes submit in modal
    e.preventDefault();
    handleServiceChange(serviceForm);
    setshowModal(false);
  };
  const handleServiceScheduleClicked = (e) => {
    e.preventDefault();
    setshowModal(true);
  };
  return (
    <>
      <a onClick={handleServiceScheduleClicked}>
        <FontAwesomeIcon icon={faCogs} className="me-1" />
        <small className={`${serviceDateClass}  mx-1`}>{serviceDate}</small>
      </a>
      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="h6">
              Workcenter Service Schedule : {workcenter.name}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Reason
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="note"
                  placeholder="Write Reason"
                  onChange={handleChange}
                ></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Down From
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="down_from"
                  type="datetime-local"
                  onChange={handleChange}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Approx Time
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  required
                  name="days"
                  type="number"
                  placeholder="Days"
                  onChange={handleChange}
                ></Form.Control>
              </Col>
              <Col sm="3">
                <Form.Control
                  name="hour"
                  type="number"
                  placeholder="Hours"
                ></Form.Control>
              </Col>
              <Col sm="3">
                <Form.Control
                  name="minute"
                  type="number"
                  placeholder="Minutes"
                ></Form.Control>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" className="ms-auto" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default WorkcenterService;
