export const VCActionList = [
  "Update Status",
  "Update Contact Type",
  // "Export as CSV",
];
export const contactTypesList = [
  "-",
  "Customer",
  "Channel Partner",
  "Consultant",
  "CMO",
  "Vendor",
  "Supplier",
];

export const documentTypesList = [
  "Invoice",
  "Receipt",
  "VisitingCard",
  "DeliveryChallan",
  "CustomerOrder",
];

export const contactStatusList = ["Contact", "Lead", "Prospect", "Opportunity"];

export const SDSActionsList = [];

export const sdsStatusList = [
  "Uploaded",
  "Processed",
  "Synced",
  "Needs Attention",
];
