import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  ListGroupItem,
  ListGroup,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight, faCheck } from "@fortawesome/free-solid-svg-icons";

import { getCurrentTask, getNextTasks } from "./tasks";
import Report from "./Report";
import WorkcenterStatus from "./Status";
import WorkcenterService from "./Service";

const WorkCenter = (props) => {
  const { workcenter, handleChangeinWorkcenter } = props;
  // console.log(workcenter);

  //styling
  const [headingVariant, setHeadingVariant] = useState(
    workcenter.status === "Up" ? "success" : "danger"
  );

  //tasks and their styles
  let currentTask = getCurrentTask(workcenter.partmanufacture);
  let nextTasks = getNextTasks(workcenter.partmanufacture);
  let currentTaskDisplay = currentTask.part_name
    ? `(${currentTask.mcsr_no}) ${currentTask.part_name}`.slice(0, 23)
    : "None";
  let nextTaskDisplay = `${nextTasks.length} tasks can begin`;

  //state and form for handling changes
  const initialWorkcenterForm = {
    id: workcenter.id,
    task: {},
    workcenter: {},
    service: {},
  };
  const [workcenterForm, updateWorkcenterForm] = useState(
    initialWorkcenterForm
  );
  const [statusChanged, setStatusChanged] = useState(false);
  const [taskChanged, setTaskChanged] = useState(false);
  const [serviceChanged, setServiceChanged] = useState(false);
  const [promptSubmit, setPromptSubmit] = useState(false);
  //only show submit button when one or both of tasks/status changes

  useEffect(() => {
    if (statusChanged || taskChanged || serviceChanged) {
      if (
        workcenterForm.workcenter?.status !== workcenter.status ||
        taskChanged ||
        serviceChanged
      ) {
        // console.log("service changed");
        setPromptSubmit(true);
        setStatusChanged(false);
      } else if (workcenterForm.workcenter.status === workcenter.status) {
        setPromptSubmit(false);
        setStatusChanged(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChanged, taskChanged, serviceChanged]);

  const callbackChangeinWorkcenter = () => {
    //called after submiting changes, resets forms & states, calls method to refresh all workcenter data
    setPromptSubmit(false);
    setStatusChanged(false);
    setTaskChanged(false);
    updateWorkcenterForm(initialWorkcenterForm);
    handleChangeinWorkcenter();
  };

  const handleTaskChange = (data) => {
    // console.log(data);
    setTaskChanged(true);
    let tempForm = workcenterForm;
    tempForm.task = data;
    updateWorkcenterForm(tempForm);
    // console.log(tempForm);
  };

  const handleStatusChange = (data) => {
    // console.log(data);
    if (workcenter.status === data.status) {
      let tempForm = workcenterForm;
      tempForm.workcenter = data;
      updateWorkcenterForm(tempForm);
      // console.log(tempForm);
    } else if (workcenter.status !== data.status) {
      let tempForm = workcenterForm;
      tempForm.workcenter = data;
      updateWorkcenterForm(tempForm);
      // console.log(tempForm);
    }
    setStatusChanged(true);
  };

  const handleServiceChange = (data) => {
    // console.log(data);
    setServiceChanged(true);
    let tempForm = workcenterForm;
    tempForm.service = data;
    updateWorkcenterForm(tempForm);
    // console.log(tempForm);
  };

  return (
    <Col xl={2} lg={4} md={6} sm={6} className="my-1 px-1">
      <Card border={headingVariant}>
        <Card.Header className="border-bottom border-light  workcenter-header">
          <div className="d-flex justify-content-between m-0">
            <WorkcenterService
              workcenter={workcenter}
              handleServiceChange={handleServiceChange}
            />
            <WorkcenterStatus
              workcenter={workcenter}
              setHeadingVariant={setHeadingVariant}
              handleStatusChange={handleStatusChange}
            />
          </div>

          <div className="d-flex justify-content-center my-0">
            <h5 className={`mb-0 text-${headingVariant}`}>{workcenter.name}</h5>
            {workcenter.status === "Down" && (
              <small>{` (${workcenter.note})`}</small>
            )}
          </div>

          {/* <Alert variant="info">submit?</Alert> */}
        </Card.Header>

        <Card.Body className="workcenter-body">
          <ListGroup className="list-group-flush">
            <ListGroupItem>
              <b>
                <FontAwesomeIcon icon={faCheck} className="me-1" />
              </b>
              <small className={`mx-1`}>{currentTaskDisplay}</small>
            </ListGroupItem>
            <ListGroupItem>
              <b>
                <FontAwesomeIcon icon={faAngleDoubleRight} className="me-1" />
              </b>
              <small className={`mx-1`}>{nextTaskDisplay}</small>
            </ListGroupItem>
          </ListGroup>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-center py-2">
          <Report
            currentTask={currentTask}
            nextTasks={nextTasks}
            workcenter={workcenter}
            handleTaskChange={handleTaskChange}
            promptSubmit={promptSubmit}
            workcenterForm={workcenterForm}
            callbackChangeinWorkcenter={callbackChangeinWorkcenter}
          />
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default WorkCenter;
