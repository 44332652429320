import axios from "axios";
import { axiosInstance } from "../axiosSetup";

// export const GetProductsListAPI = async (Details) => {
//     const res = await axiosInstance.get(`sds/products/`, {});
//     return res;
//   };
export const GetPlansProductsListAPI = async (Details) => {
  const res = await axiosInstance.get(`sds/plans/`, {});
  return res;
};
export const SubscribePlansProductsAPI = async (Details) => {
  const res = await axiosInstance.post(`auth/subscribe/`, Details);
  return res;
};

export const GetSubscribedPlans = async (Details) => {
  const res = await axiosInstance.get(`auth/subscribed-plans/`, {});
  return res;
};

export const getSubscribedPlansAPIerp = async () => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");
  // console.log(url);
  const res = await axios.get(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.return_all_subscribed_plans`,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  return res;
};

export const getPlansListAPIerp = async () => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  try {
    const res = await axios.get(
      `${localStorage.getItem(
        "base_url"
      )}/api/method/drs_backend.api.return_all_plans`,
      {
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getProductsListAPIerp = async () => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const res = await axios.get(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.return_all_products`,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  return res;
};

export const postBuyPlanAPIerp = async (buyData, planNames) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const formData = new FormData();
  formData.append("plan_list", JSON.stringify(buyData.buy_now));
  formData.append("plan_names", planNames);
  
  try {
    const res = await axios.post(
      `${localStorage.getItem(
        "base_url"
      )}/api/method/drs_backend.api.subscribe_plan`,
      formData,
      {
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
