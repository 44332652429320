import React from "react";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import { URLs } from "../routes";
import { useLocation } from "react-router-dom";

const AdminLayout = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();
  let isAuthenticated = false;
  let isVerified = false;
  let isSubscribtionValid = false;
  const location = useLocation();
  try {
    isAuthenticated =
      localStorage.getItem("api_key") && localStorage.getItem("api_secret")
        ? true
        : false;

    isVerified =
      localStorage.getItem("company") && localStorage.getItem("metadata")
        ? true
        : false;
    const subscribedProducts = JSON.parse(
      localStorage.getItem("subscribed_products")
    );
    isSubscribtionValid = subscribedProducts.length > 0 ? true : false;
  } catch {
    navigate(URLs.Signin.path);
  }

  return (
    <>
      {isAuthenticated ? (
        isVerified && isSubscribtionValid ? (
          <>
            <Sidebar />

            <main className="content" style={{ marginTop: "20px" }}>
              {/* <Navbar /> */}
              <Component />

              <Footer />
            </main>
          </>
        ) : (
          <Navigate
            to={URLs.Signin.path}
            state={{
              from: location.pathname,
              msg: "not-validated",
            }}
          />
        )
      ) : (
        <Navigate
          to={URLs.Signin.path}
          state={{
            from: location.pathname,
          }}
        />
      )}
    </>
  );
};

export default AdminLayout;
