import React, { useEffect, useState } from "react";

import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  getQuestionBankDataAPIV2,
  getQuestionBankNameListAPI,
  postSubmitFormData,
} from "../../../api/FormsAPI.js";
import {
  AddCircleOutline,
  ArrowBack,
  CheckBox,
  CheckBoxOutlineBlank,
  DeleteOutline,
  LibraryAdd,
  ListAlt,
  PlayArrow,
  Send,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  useMediaQuery,
  Pagination,
  Grid,
  Stepper,
  Typography,
  Step,
  Box,
  StepLabel,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Snackbar,
  Alert,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "../../../components/Footer.js";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
const DigitalFormsV2 = () => {
  // const isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const formIDbyURL = queryParams.get("form_UID");
  const formNamebyURL = queryParams.get("name");
  const timeStampbyURL = queryParams.get("time_stamp");
  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("DigitalForms")
      ? connectedApps["DigitalForms"]
      : null
    : null;

  // const [newbtnClicked, setNewbtnClicked] = useState(false);
  const [newFormData, setNewFormData] = useState(null);
  const [newFormData3, setNewFormData3] = useState(null);
  const [questionList, setQuestionList] = useState(null);
  const [questionListDependentGroups, setQuestionListDependentGroups] =
    useState(null);
  const [qBNameList, setQBNameList] = useState(null);
  const [qBName, setQBName] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isDataSyncing, setIsDataSyncing] = useState(false);
  const [snakbarST, setSnakbarST] = useState({
    openSnakbar: false,
    snackbarMessage: "",
    severity: "info",
  });
  const [pdfDoc, setPdfDoc] = useState(null);

  // useEffect(() => {
  //   getQBNameList();
  // }, []);

  useEffect(() => {
    if (qBName !== null) {
      getQBData(qBName);
    }
  }, [qBName]);

  const getQBNameList = async () => {
    try {
      const res = await getQuestionBankNameListAPI(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      setQBNameList(res.data.result);
      setQuestionList(null);
      setQuestionListDependentGroups(null);
      setNewFormData(null);

      // console.log(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const getQBData = async (name) => {
    try {
      const res = await getQuestionBankDataAPIV2(
        name,
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );

      let tempInDependentGroups = res.data.result.filter(
        (gru) => gru.dependent_group === false
      );
      let tempDependentGroups = res.data.result.filter(
        (gru) => gru.dependent_group === true
      );
      // console.log(tempInDependentGroups);
      // console.log(tempDependentGroups);
      // console.log(res.data.result);

      handleCheckbox4FirstTimeChangeOnly2(tempInDependentGroups);
      // these 2 are set in handleCheckbox4FirstTimeChangeOnly2
      // setQuestionList(tempInDependentGroups);
      // setNewFormData3(tempInDependentGroups);
      handleCheckbox4FirstTimeChangeOnly3(tempDependentGroups);
      // setQuestionListDependentGroups(tempDependentGroups);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStartForm = () => {
    // console.log(formNamebyURL);
    setQBName(formNamebyURL);
    setActiveStep(0);
    setQuestionList(null);
    setQuestionListDependentGroups(null);
    setNewFormData(null);
    setNewFormData3(null);
  };
  const handleChangeFormName = (qName) => {
    // console.log(qName);

    setQBName(qName);
    setActiveStep(0);
    setQuestionList(null);
    setQuestionListDependentGroups(null);
    setNewFormData(null);
    setNewFormData3(null);
  };

  const handleChangeData = (event, quest, tableGroup) => {
    const { name, value } = event.target;
    setNewFormData({ ...newFormData, [name]: value });

    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            // console.log("updated", { ...questionSub, answer: value });

            return { ...questionSub, answer: value };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });

    setNewFormData3(updatedQuestionList);

    // console.log(updatedQuestionList);
  };
  const handleChangeMultiData = (event, quest, q, tableGroup) => {
    const { name, value } = event.target;
    setNewFormData({ ...newFormData, [name]: value });

    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            let tempAnswer = questionSub.answer
              ? { ...questionSub.answer, [name]: value }
              : { [name]: value };

            // console.log("updated", {
            //   ...questionSub,
            //   answer: tempAnswer,
            // });

            return { ...questionSub, answer: tempAnswer };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });

    setNewFormData3(updatedQuestionList);

    // console.log(updatedQuestionList);
  };

  const handleDependentGroups4Select = (nameMain, valueOptionD, questD) => {
    // console.log(nameMain);
    // console.log(valueOptionD);
    // console.log(questD);
    // console.log(newFormData3);
    if (questD.depend_on?.length > 0) {
      let depGruList4mQuestD = questD.depend_on.filter(
        (dep) => dep.selected_options === valueOptionD
      );

      if (depGruList4mQuestD.length > 0) {
        // console.log(depGruList4mQuestD[0].dependent_group_name);

        const temp3add = questionListDependentGroups.filter((item) =>
          depGruList4mQuestD[0].dependent_group_name.includes(item.group)
        );
        const temp4remove = questionListDependentGroups.filter(
          (item) =>
            !depGruList4mQuestD[0].dependent_group_name.includes(item.group)
        );
        // console.log("temp3add", temp3add);
        // console.log("temp4remove", temp4remove);

        setQuestionList((prevQuestionList) => [
          ...prevQuestionList,
          ...temp3add,
        ]);
        setNewFormData3((prevQuestionList) => [
          ...prevQuestionList,
          ...temp3add,
        ]);

        setQuestionList((prevData) =>
          prevData.filter(
            (item) =>
              !temp4remove.find((removeItem) => removeItem.group === item.group)
          )
        );
        setNewFormData3((prevData) =>
          prevData.filter(
            (item) =>
              !temp4remove.find((removeItem) => removeItem.group === item.group)
          )
        );

        // remove questions from newFormData
        let quetemp5 = temp4remove.map((q1) => {
          return q1.question;
        });
        let quetemp6 = quetemp5.flat();
        let quetemp7 = quetemp6.map((q1) => {
          return q1.question;
        });

        // console.log("quetemp7", quetemp7);
        // console.log(newFormData);

        let tempObj = newFormData;
        const newtempObject = {};

        for (const question in tempObj) {
          if (!quetemp6.some((item) => item.question === question)) {
            newtempObject[question] = tempObj[question];
          }
        }
        // state is running on previos state so deleting and reentering
        delete newtempObject[nameMain];
        // console.log({...newtempObject,[nameMain]:valueOptionD});

        setNewFormData({ ...newtempObject, [nameMain]: valueOptionD });
      }
    }
  };

  const handleChangeSelect = (event, quest, tableGroup) => {
    const { name, value } = event.target;
    setNewFormData({ ...newFormData, [name]: value });

    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            // console.log("updated", { ...questionSub, answer: value });

            return { ...questionSub, answer: value };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });

    setNewFormData3(updatedQuestionList);
    handleDependentGroups4Select(name, value, quest);
    // console.log(updatedQuestionList);
  };
  const handleChangeMultiSelect = (event, quest, tableGroup) => {
    const {
      target: { name, value },
    } = event;

    setNewFormData({
      ...newFormData,
      [quest.question]: typeof value === "string" ? value.split(",") : value,
    });
    // console.log(value);

    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            const joinedString = value.join(", ");

            // console.log("updated", { ...questionSub, answer: value });
            // console.log(joinedString);

            return { ...questionSub, answer: joinedString, answer_list: value };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });

    setNewFormData3(updatedQuestionList);

    // console.log(updatedQuestionList);
  };
  const handleChangeCheckbox = (event, quest, tableGroup) => {
    const { name, checked } = event.target;
    setNewFormData({ ...newFormData, [name]: checked });

    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            // console.log("updated", { ...questionSub, answer: checked });

            return { ...questionSub, answer: checked };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });
    setNewFormData3(updatedQuestionList);

    // console.log(updatedQuestionList);
  };

  const handleCheckbox4FirstTimeChangeOnly = (quest, tableGroup) => {
    let checked = false;
    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            // console.log("updated", { ...questionSub, answer: checked });

            return { ...questionSub, answer: checked };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });
    setNewFormData3(updatedQuestionList);

    // console.log(updatedQuestionList);
  };
  const handleCheckbox4FirstTimeChangeOnly2 = (initialData) => {
    console.log("independent groups", initialData);
    let checked = false;

    const tempList = initialData;
    const groupList = tempList.map((groupItem) => {
      const questionsList = groupItem.question.map((questionSub) => {
        if (questionSub.type === "Check" && questionSub.required === false) {
          // console.log("updated question", { ...questionSub, answer: checked });

          return { ...questionSub, answer: checked };
        } else {
          // console.log("not updated question", questionSub);
          return questionSub;
        }
      });

      // console.log("group both", {
      //   ...groupItem,
      //   question: questionsList,
      // });

      return { ...groupItem, question: questionsList };
    });

    setQuestionList(groupList);
    setNewFormData3(groupList);
    // console.log(groupList);
  };
  const handleCheckbox4FirstTimeChangeOnly3 = (initialData) => {
    // console.log("Dependent Groups", initialData);
    let checked = false;

    const tempList = initialData;
    const groupList = tempList.map((groupItem) => {
      const questionsList = groupItem.question.map((questionSub) => {
        if (questionSub.type === "Check" && questionSub.required === false) {
          // console.log("updated question", { ...questionSub, answer: checked });

          return { ...questionSub, answer: checked };
        } else {
          // console.log("not updated question", questionSub);
          return questionSub;
        }
      });

      // console.log("group both", {
      //   ...groupItem,
      //   question: questionsList,
      // });

      return { ...groupItem, question: questionsList };
    });

    setQuestionListDependentGroups(groupList);

    // console.log(groupList);
  };

  const handleChangeDate = (date, quest, tableGroup) => {
    setNewFormData({
      ...newFormData,
      [quest.question]: dayjs(date).format("YYYY-MM-DD"),
    });

    let value = dayjs(date).format("YYYY-MM-DD");

    // complex recreate json structure logic
    const tempList = newFormData3;
    const updatedQuestionList = tempList.map((groupItem) => {
      if (groupItem.group === tableGroup.group) {
        const updatedQuestions = groupItem.question.map((questionSub) => {
          if (questionSub.question === quest.question) {
            // console.log("updated", { ...questionSub, answer: value });

            return { ...questionSub, answer: value };
          }

          // console.log("not updated", questionSub);

          return questionSub;
        });

        // console.log("group updated", {
        //   ...groupItem,
        //   question: updatedQuestions,
        // });

        return { ...groupItem, question: updatedQuestions };
      }

      // console.log("group not updated", groupItem);

      return groupItem;
    });

    setNewFormData3(updatedQuestionList);

    // console.log(updatedQuestionList);
  };

  const handleValidation = (question) => {
    // console.log(question);
    // console.log(newFormData);
    if (
      newFormData !== null &&
      newFormData !== undefined &&
      newFormData.hasOwnProperty(question)
    ) {
      // console.log("case 1 ");
      // console.log(newFormData[question]);
      if (
        newFormData[question] !== null &&
        newFormData[question] !== undefined &&
        newFormData[question].trim() !== ""
      ) {
        // console.log("case 1 - nested 1 - false ");
        return false;
      }
      if (
        newFormData[question] === null ||
        newFormData[question] === undefined ||
        newFormData[question].trim() === ""
      ) {
        // console.log("case 1 - nested 2 - true ");
        return true;
      }
    } else if (
      newFormData === null ||
      newFormData === undefined ||
      newFormData.hasOwnProperty(question) === false
    ) {
      // console.log("case2 --- false");
      return false;
    }
  };

  const handleSubmitForm = () => {
    let tempList = newFormData3[activeStep].question.filter((quest) => {
      if (quest.type === "Data") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer.trim() !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Select") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Multi Data") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          // console.log("Multi Data", quest);

          const compareOptionsNotInAnswers = quest.options.map((opt) => {
            if (quest.answer.hasOwnProperty(opt)) {
              if (
                quest.answer[opt] !== null &&
                quest.answer[opt] !== undefined &&
                quest.answer[opt].trim() !== ""
              ) {
                // console.log(opt, quest.answer[opt]);

                return "Answered";
              } else {
                return "Not Answered";
              }
            } else {
              return "Not Answered";
            }
            // return quest.answer.hasOwnProperty(opt);
          });

          const tempNotAnswerd = compareOptionsNotInAnswers.filter(
            (ele) => ele === "Not Answered"
          );

          if (tempNotAnswerd.length === 0) {
            return quest;
          }
        }
      }
      if (quest.type === "Multi Select") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Date") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Check") {
        if (quest.required === false) {
          console.log("not mandatory checkbox");
          if (
            quest.hasOwnProperty("answer") &&
            quest.answer !== null &&
            quest.answer !== undefined &&
            quest.answer !== ""
          ) {
            // console.log("has answer prop", quest);
            return quest;
          } else if (!quest.hasOwnProperty("answer")) {
            handleCheckbox4FirstTimeChangeOnly(quest, newFormData3[activeStep]);

            // console.log("not answer prop -- first case only", quest);

            return quest;
          }
        }
        if (quest.required === true) {
          console.log("mandatory checkbox");
          if (
            quest.hasOwnProperty("answer") &&
            quest.answer !== null &&
            quest.answer !== undefined &&
            quest.answer !== "" &&
            quest.answer === true
          ) {
            // console.log("has answer prop", quest);
            return quest;
          }
        }

        // console.log("checking");
      }
    });

    let incompleteTempList = newFormData3[activeStep].question.filter(
      (quest) => {
        return !quest.hasOwnProperty("answer");
      }
    );
    // console.log(tempList.length);
    // console.log(newFormData3[activeStep].question.length);

    if (tempList.length === newFormData3[activeStep].question.length) {
      // console.log("Form Submitted Successfully", newFormData3);
      setIsDataSyncing(true);
      handlePdfGeneration();

      // setFormSubmitted(true);
      // setSnakbarST({
      //   ...snakbarST,
      //   openSnakbar: true,
      //   snackbarMessage: `Form Submitted Successfully`,
      //   severity: "info",
      // });
    } else {
      setSnakbarST({
        ...snakbarST,
        openSnakbar: true,
        snackbarMessage: `Some input-fields are Incomplete`,
        severity: "error",
      });
    }
  };

  // stepper
  const handleNext = () => {
    // console.log(newFormData3);
    // console.log(activeStep);
    let tempList = newFormData3[activeStep].question.filter((quest) => {
      if (quest.type === "Data") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer.trim() !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Select") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Multi Data") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          // console.log("Multi Data", quest);

          const compareOptionsNotInAnswers = quest.options.map((opt) => {
            if (quest.answer.hasOwnProperty(opt)) {
              if (
                quest.answer[opt] !== null &&
                quest.answer[opt] !== undefined &&
                quest.answer[opt].trim() !== ""
              ) {
                // console.log(opt, quest.answer[opt]);

                return "Answered";
              } else {
                return "Not Answered";
              }
            } else {
              return "Not Answered";
            }
            // return quest.answer.hasOwnProperty(opt);
          });

          const tempNotAnswerd = compareOptionsNotInAnswers.filter(
            (ele) => ele === "Not Answered"
          );

          if (tempNotAnswerd.length === 0) {
            return quest;
          }
        }
      }
      if (quest.type === "Multi Select") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Date") {
        if (
          quest.hasOwnProperty("answer") &&
          quest.answer !== null &&
          quest.answer !== undefined &&
          quest.answer !== ""
        ) {
          return quest;
        }
      }
      if (quest.type === "Check") {
        if (quest.required === false) {
          console.log("not mandatory checkbox");
          if (
            quest.hasOwnProperty("answer") &&
            quest.answer !== null &&
            quest.answer !== undefined &&
            quest.answer !== ""
          ) {
            // console.log("has answer prop", quest);
            return quest;
          } else if (!quest.hasOwnProperty("answer")) {
            handleCheckbox4FirstTimeChangeOnly(quest, newFormData3[activeStep]);

            // console.log("not answer prop -- first case only", quest);

            return quest;
          }
        }
        if (quest.required === true) {
          console.log("mandatory checkbox");
          if (
            quest.hasOwnProperty("answer") &&
            quest.answer !== null &&
            quest.answer !== undefined &&
            quest.answer !== "" &&
            quest.answer === true
          ) {
            // console.log("has answer prop", quest);
            return quest;
          }
        }
        // console.log("checking");
      }
    });

    let incompleteTempList = newFormData3[activeStep].question.filter(
      (quest) => {
        return !quest.hasOwnProperty("answer");
      }
    );
    // console.log(tempList.length);
    // console.log(newFormData3[activeStep].question.length);

    if (tempList.length === newFormData3[activeStep].question.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setSnakbarST({
        ...snakbarST,
        openSnakbar: true,
        snackbarMessage: `Some input-fields are Incomplete`,
        severity: "error",
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleCloseSnakbar = () => {
    setSnakbarST({
      ...snakbarST,
      openSnakbar: false,
      // snackbarMessage: "",
      // severity: "info",
    });
  };
  const handleDiscardForm = () => {
    setActiveStep(0);
    setNewFormData(null);
    setNewFormData3(null);
    setQuestionList(null);
    setQuestionListDependentGroups(null);
    setQBName(null);
    setFormSubmitted(false);
    setSnakbarST({
      ...snakbarST,
      openSnakbar: true,
      snackbarMessage: "From Discarded",
      severity: "info",
    });
  };
  const handleCreateNewForm = () => {
    setActiveStep(0);
    setNewFormData(null);
    setNewFormData3(null);
    setQuestionList(null);
    setQBName(null);

    setFormSubmitted(false);
    setSnakbarST({
      ...snakbarST,
      openSnakbar: true,
      snackbarMessage: "Redirecting to New Form",
      severity: "info",
    });
  };
  const handlePdfGeneration = () => {
    // console.log("handlePdfGeneration");
    // console.log(newFormData3);
    let currentDate = dayjs().format("DD/MM/YYYY HH:mm");

    const doc = new jsPDF();
    doc.setFontSize(12);

    let tempContent = newFormData3.map((grp, index) => {
      let tempParent = [];
      tempParent.push(`\nSection ${index + 1}: ${grp.group}\n`);

      let tempQ1 = grp.question.map((q1, index) => {
        if (
          q1.type === "Data" ||
          q1.type === "Select" ||
          q1.type === "Multi Select" ||
          q1.type === "Date"
        ) {
          return `Q${index + 1}: ${q1.question}\nAns: ${q1.answer}\n`;
        } else if (q1.type === "Check") {
          return `Q${index + 1}: ${q1.question}\nAns: ${
            q1.answer === true ? "Agree" : "Disagree"
          }\n`;
        } else if (q1.type === "Multi Data") {
          let subQ1 = q1.options.map((opt1, index) => {
            return `${index + 1}. ${opt1} - ${q1.answer[opt1]}\n`;
          });
          return `Q${index + 1}. ${q1.question}\nAns: ${subQ1.join(
            "        "
          )}`;
        }
      });

      tempParent.push(tempQ1.join("\n"));
      tempParent.push(
        "________________________________________________________________________________"
      );
      // console.log(tempParent.join("\n"));
      return tempParent.join("\n");
    });

    tempContent.unshift(
      "________________________________________________________________________________"
    );
    tempContent.unshift(`Form submitted on: ${currentDate}`);
    tempContent.unshift(`Form Name: ${formNamebyURL}`);
    tempContent.unshift(
      "________________________________________________________________________________\n"
    );
    const joinedContent = tempContent.join("\n");
    // console.log(joinedContent);

    const margin = 10;
    let startY = margin;

    const allDataLines = doc.splitTextToSize(
      joinedContent,
      doc.internal.pageSize.width - 2 * margin
    );

    // Function to check if we need to start a new page
    const needNewPage = (lineHeight) => {
      if (startY + lineHeight > doc.internal.pageSize.height - margin) {
        doc.addPage();
        startY = margin;
      }
    };

    // Loop through the lines and add them to the PDF
    allDataLines.forEach((oneLineData) => {
      // console.log(oneLineData);
      needNewPage(5); // Adjust the value as needed for spacing
      doc.text(margin, startY, oneLineData);
      startY += 5; // Adjust for line height
    });

    // doc.save(`${formNamebyURL}.pdf`);
    const pdfBase64 = doc.output("datauristring");
    // console.log(pdfBase64);
    setPdfDoc(pdfBase64);
    handleSubmitApiCall(pdfBase64, doc);
  };
  const handleSubmitApiCall = async (pdfBase64, docArg) => {
    try {
      let formData = {
        form_name: formNamebyURL,
        form_id: formIDbyURL,
        data: newFormData3,
        pdf_file: pdfBase64,
      };

      const res = await postSubmitFormData(
        formData,
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      // console.log(res);
      if (res.status === 200) {
        setTimeout(() => {
          console.log("This code was executed after a 3-second delay.");
          setIsDataSyncing(false);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setFormSubmitted(true);
          setSnakbarST({
            ...snakbarST,
            openSnakbar: true,
            snackbarMessage: `Form Submitted Successfully`,
            severity: "info",
          });
          docArg.save(`${formNamebyURL}.pdf`);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // style for multi select
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className="page-container">
      {formNamebyURL && formIDbyURL && timeStampbyURL && (
        <Card
          className="shadow-sm"
          variant="outlined"
          sx={{
            border: "1px solid #050092",
            borderRadius: "5px",
            width: "auto",
            minHeight: "90vh",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <CardContent
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
              border: "1px solid #050092",
            }}
          >
            <h3>{formNamebyURL}</h3>

            {/* <Chip
              sx={{ backgroundColor: "#050092", color: "#fff" }}
              label={`Form ID: ${formIDbyURL}`}
            /> */}
            {qBName && !formSubmitted && (
              <Button
                style={{
                  borderColor: "#050092",
                  color: "#050092",
                }}
                variant="outlined"
                onClick={handleDiscardForm}
              >
                <DeleteOutline />
              </Button>
            )}
          </CardContent>

          <Divider sx={{ backgroundColor: "darkgray" }} />

          {/* {qBNameList && (
            <CardContent
              style={{
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: qBName ? "space-between" : "center",
                alignItems: "center",
                borderRadius: "10px",
                border: "1px solid #050092",
              }}
            >
              {!qBName && (
                <Autocomplete
                  id="selectFormName"
                  options={qBNameList.map((item) => item.name)}
                  sx={{ width: isMobileOrTablet ? "70%" : "30%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={<>{<ListAlt />} Select Form</>}
                    />
                  )}
                  value={qBName}
                  onChange={(event, newValue) => handleChangeFormName(newValue)}
                />
              )}
              {qBName && (
                <>
                  <h5 style={{ margin: "15px" }}>{qBName}</h5>
                  {!formSubmitted && (
                    <Button
                      style={{
                        borderColor: "#050092",
                        color: "#050092",
                      }}
                      variant="outlined"
                      onClick={handleDiscardForm}
                    >
                      <DeleteOutline />
                    </Button>
                  )}
                </>
              )}
            </CardContent>
          )} */}

          {!qBName && (
            <CardContent
              style={{
                backgroundColor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // borderRadius: "10px",
                // border: "1px solid #050092",
                textAlign: "center",
                height: "60vh",
              }}
            >
              <h3 style={{ color: "#050092", fontWeight: "bold" }}>
                Welcome to Digital Forms – Simplifying Form Filling!
              </h3>
              <h6> We're here to streamline the process for you.</h6>
              <h6>
                Whether you're applying for a vendor registration, submitting
                important documents, or completing any type of forms,
              </h6>

              <Button
                style={{
                  borderColor: "#050092",
                  color: "#050092",
                }}
                variant="outlined"
                onClick={handleStartForm}
                startIcon={<PlayArrow />}
              >
                Start
              </Button>
            </CardContent>
          )}

          {questionList && qBName && (
            <>
              {/* <Divider sx={{ backgroundColor: "darkgray" }} /> */}
              <CardContent
                style={{
                  backgroundColor: "#ffffff",
                  // borderRadius: "10px",
                  // border: "1px solid #050092",
                }}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation={isMobileOrTablet ? "vertical" : "horizontal"}
                >
                  {questionList.map((heading, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={heading.group} {...stepProps}>
                        <StepLabel></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === questionList.length ? (
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",

                        marginTop: "10px",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          width: "1000px",
                          height: "100px",
                          margin: "10px",
                          backgroundColor: "#eaf5ee",
                          color: "#36724d",
                        }}
                        startIcon={<CheckBox />}
                      >
                        {" "}
                        Form Submitted Successfully{" "}
                      </Button>
                      {/* <Button
                        variant="contained"
                        style={{
                          width: "200px",
                          margin: "10px",
                          backgroundColor: "#050092",
                          color: "#fff",
                        }}
                        onClick={handleCreateNewForm}
                        startIcon={<LibraryAdd />}
                      >
                        New Form
                      </Button> */}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {questionList.map((item, index) => {
                      if (activeStep === index) {
                        return (
                          <div
                            style={{
                              marginTop: "10px",
                            }}
                            key={item.group}
                          >
                            <Typography
                              sx={{
                                mt: 2,
                                mb: 1,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {item.group}
                            </Typography>
                            <Divider sx={{ backgroundColor: "#050092" }} />
                            {item.question.map((quest, index) => {
                              return (
                                <Grid
                                  key={quest.question}
                                  container
                                  spacing={2}
                                  style={{
                                    borderBottom: "1px solid #050092",
                                    // borderRadius:"5px",
                                    margin: "1px",
                                    width: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    lg={8}
                                    style={{
                                      display: "flex",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    {quest.question}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    lg={4}
                                    style={{
                                      display: "flex",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    {quest.type === "Data" && (
                                      <TextField
                                        type="text"
                                        name={quest.question}
                                        fullWidth
                                        onChange={(event) =>
                                          handleChangeData(event, quest, item)
                                        }
                                        multiline
                                        maxRows={10}
                                        variant="outlined"
                                        label="Enter Text..."
                                        value={
                                          newFormData
                                            ? newFormData[quest.question] || ""
                                            : ""
                                        }
                                        error={handleValidation(quest.question)}
                                      />
                                    )}
                                    {quest.type === "Select" && (
                                      <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                          Select
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={
                                            newFormData
                                              ? newFormData[quest.question] ||
                                                ""
                                              : ""
                                          }
                                          label="Select"
                                          name={quest.question}
                                          onChange={(event) =>
                                            handleChangeSelect(
                                              event,
                                              quest,
                                              item
                                            )
                                          }
                                        >
                                          {quest?.options.map((opt, index) => {
                                            return (
                                              <MenuItem
                                                key={opt + index}
                                                value={opt}
                                              >
                                                {opt}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    )}
                                    {quest.type === "Multi Select" && (
                                      <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="demo-multiple-chip-label">
                                          Multi-Select
                                        </InputLabel>
                                        <Select
                                          name={quest.question}
                                          labelId="demo-multiple-chip-label"
                                          id="demo-multiple-chip"
                                          multiple
                                          value={
                                            newFormData
                                              ? newFormData[quest.question] ||
                                                []
                                              : []
                                          }
                                          onChange={(event) =>
                                            handleChangeMultiSelect(
                                              event,
                                              quest,
                                              item
                                            )
                                          }
                                          input={
                                            <OutlinedInput
                                              id="select-multiple-chip"
                                              label=" Multi-Select"
                                            />
                                          }
                                          renderValue={(selected) => (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 0.5,
                                              }}
                                            >
                                              {selected.map((value) => (
                                                <Chip
                                                  key={value}
                                                  label={value}
                                                />
                                              ))}
                                            </Box>
                                          )}
                                          MenuProps={MenuProps}
                                        >
                                          {quest.options?.map((name) => (
                                            <MenuItem key={name} value={name}>
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    )}
                                    {quest.type === "Check" && (
                                      <FormGroup>
                                        <FormControlLabel
                                          // required
                                          control={
                                            <Checkbox
                                              checked={
                                                newFormData
                                                  ? newFormData[
                                                      quest.question
                                                    ] === true // Check if it's explicitly set to true
                                                  : false // Default to false if the data is not available
                                              }
                                              name={quest.question}
                                              onChange={(event) =>
                                                handleChangeCheckbox(
                                                  event,
                                                  quest,
                                                  item
                                                )
                                              }
                                            />
                                          }
                                          label={
                                            quest?.required === true
                                              ? "Required"
                                              : "Optional"
                                          }
                                        />
                                      </FormGroup>
                                    )}
                                    {quest.type === "Multi Data" && (
                                      <div style={{ marginTop: "2px" }}>
                                        {quest.options?.map((q, index) => {
                                          return (
                                            <TextField
                                              style={{ marginTop: "6px" }}
                                              key={q + index}
                                              label={q}
                                              type="text"
                                              name={q}
                                              variant="outlined"
                                              fullWidth
                                              multiline
                                              maxRows={10}
                                              value={
                                                newFormData
                                                  ? newFormData[q] || ""
                                                  : ""
                                              }
                                              onChange={(event) =>
                                                handleChangeMultiData(
                                                  event,
                                                  quest,
                                                  q,
                                                  item
                                                )
                                              }
                                              error={handleValidation(q)}
                                            />
                                          );
                                        })}
                                      </div>
                                    )}
                                    {quest.type === "Date" && (
                                      <DatePicker
                                        label="Basic date picker"
                                        format="YYYY-MM-DD"
                                        value={
                                          newFormData &&
                                          newFormData[quest.question]
                                            ? dayjs(
                                                newFormData[quest.question]
                                              ) || null
                                            : null
                                        }
                                        onChange={(date) =>
                                          handleChangeDate(date, quest, item)
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </div>
                        );
                      }
                    })}
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        style={{
                          borderColor: "#050092",
                          color: "#050092",
                        }}
                        variant="outlined"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        <ArrowBack />
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStep !== questionList.length - 1 && (
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#050092",
                            color: "#fff",
                          }}
                          onClick={() => {
                            handleNext();
                          }}
                        >
                          {activeStep === questionList.length - 1
                            ? "Finish"
                            : "Next"}
                        </Button>
                      )}
                      {activeStep === questionList.length - 1 && (
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#050092",
                            color: "#fff",
                            marginLeft: isMobileOrTablet ? "50px" : "0px",
                          }}
                          onClick={handleSubmitForm}
                        >
                          {isDataSyncing ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <Send />
                          )}
                          <div style={{ marginLeft: "10px" }}>Submit</div>
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </CardContent>
            </>
          )}
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={snakbarST.openSnakbar}
              autoHideDuration={3000}
              onClose={handleCloseSnakbar}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Alert onClose={handleCloseSnakbar} severity={snakbarST.severity}>
                {snakbarST.snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>
        </Card>
      )}
      {(!formNamebyURL || !formIDbyURL || !timeStampbyURL) && (
        <Card
          className="shadow-sm"
          variant="outlined"
          sx={{
            border: "1px solid #050092",
            borderRadius: "10px",
            width: "auto",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <h4> Incomplete url</h4>
        </Card>
      )}
      <Footer />
    </div>
  );
};

export default DigitalFormsV2;
