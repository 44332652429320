import React, { useState, useEffect } from "react";
import { Card, Col, Pagination } from "@themesberg/react-bootstrap";
import AddEntry from "./AddEntry";
import { FilterData } from "./Filter";
import "./Exhibition.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../axiosSetup";
import {
  CardHeader,
  Card as CardMui,
  Divider,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setActivePage, setFilter } from "../../slices/visitingCardSlice";
import VisitingCardsGrid from "./VisitingCardsGrid";
import { TableActions } from "./TableActions";
import { getVisitingCardsAPIerp } from "../../api/SdsAPI";

const Exhibition = (props) => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  //filters
  const dispatch = useDispatch();
  const { filters, activePage } = useSelector(
    (state) => state.visitingCardData
  );
  const [searchTerms, setSearchTerms] = useState("");
  const [filterRequest, setFilterRequest] = useState(filters);

  //pagination
  const [totalPages, updateTotalPages] = useState(null);
  const { size = "md", withIcons = false, disablePrev = false } = props;

  //actions
  const [whetherRowsSelected, setWhetherRowsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");

  useEffect(() => {
    //Loads data for first page
    submitFilterRequest();
  }, []);

  const lookup = async (url) => {
    setShowSpinner(true);

    try {
      const res = await getVisitingCardsAPIerp(url);
      // console.log(res);

      const resultsList = res.data.results;

      let tempList = resultsList.map((card, index) => {
        let temp = {
          belongs_to: card.company,
          unique_document_id: card.unique_document_id,
          document_type: card.document_type,
          created_at: card.creation,
          id: card.name,
          image: card.image,
          file: card.file,
          status: card.status,
          sync_status: card.sync_status,
          sync_details: card.sync_details,
          misc_data: card.misc_data,
          table_data: card.table_data_metadata,
          comments: card._comments,
        };
        return temp;
      });
      // console.log(tempList);

      setInvoiceData(tempList);
      setShowSpinner(false);
      //new
      updateTotalPages(res.data.lastPage);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        setInvoiceData([]);
      }
      setShowSpinner(false);
    }

    // axiosInstance
    //   .get(`${url}`, {})
    //   .then((res) => {
    //     // console.log(res);
    //     const results = res.data.results;
    //     setInvoiceData(results);
    //     setShowSpinner(false);
    //     //new
    //     updateTotalPages(res.data.lastPage);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //     if (error.response?.status === 404) {
    //       setInvoiceData([]);
    //     }
    //     setShowSpinner(false);
    //   });
  };

  const submitFilterRequest = (formData = filterRequest) => {
    let parameterLink = Object.entries(formData).reduce(
      (parameterLink, [itemKey, itemValue]) => {
        if (itemValue) {
          parameterLink = `${parameterLink}&${itemKey}=${itemValue}`;
        }
        return parameterLink;
      },
      ""
    );
    setSearchTerms(parameterLink);
    // const data = `&contact_type=${e.target.value}`;
    lookup(
      `${localStorage.getItem(
        "base_url"
      )}/api/method/drs_backend.api.return_all_visiting_cards?page=${activePage}${parameterLink}`
    );
  };

  const handleFiltering = (targetName, targetValue) => {
    let tempFormData = { ...filterRequest, [targetName]: targetValue };
    setFilterRequest(tempFormData);
    dispatch(setFilter(tempFormData));
    dispatch(setActivePage(1));
    submitFilterRequest(tempFormData);
  };

  useEffect(() => {
    // console.log(searchTerms);
    submitFilterRequest();
  }, [activePage]);

  //pagination callbacks and functions
  const onPrevItem = () => {
    const prevactivePage = activePage === 1 ? activePage : activePage - 1;
    dispatch(setActivePage(prevactivePage));
  };

  const onNextItem = (totalPages) => {
    const nextactivePage =
      activePage === totalPages ? activePage : activePage + 1;
    dispatch(setActivePage(nextactivePage));
  };

  const items = [];
  let startingPage = 1;
  if ((activePage > 6) & (totalPages > 10)) {
    startingPage = activePage - 5;
  }
  let endPage = totalPages;
  if (totalPages > 10) {
    endPage = 10;
  }
  if (activePage > 5) {
    endPage = activePage + 5;
  }
  if (totalPages - activePage < 5) {
    endPage = totalPages;
  }
  for (let number = startingPage; number <= endPage; number++) {
    const isItemActive = activePage === number;

    const handlePaginationChange = () => {
      dispatch(setActivePage(number));
    };

    items.push(
      <Pagination.Item
        aria-controls={`example-collapse-text-${number}`}
        aria-expanded={activePage}
        active={isItemActive}
        key={number}
        onClick={handlePaginationChange}
      >
        {number}
      </Pagination.Item>
    );
  }

  const onSuccessfulEntry = (response) => {
    //callback function given to AddEntry Component
    // console.log(response);
    
    if (response.status === 201 || response.status === 200) {
      // lookup(`sds/business_cards/?page=${activePage}${searchTerms}`);
      lookup(
        `${localStorage.getItem(
          "base_url"
        )}/api/method/drs_backend.api.return_all_visiting_cards?page=${activePage}${searchTerms}`
      );
    }
  };

  const handleRowsSelection = (selectedRows) => {
    setSelectedRows(selectedRows);
    if (selectedRows.length === 0) {
      setWhetherRowsSelected(false);
    } else {
      setWhetherRowsSelected(true);
    }
  };

  return (
    <div className="page-container">
      <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          // borderRadius: "10px",
          // width: "100%",
          // height: "100px",
        }}
      >
        <CardHeader
          title="Visiting Cards"
          sx={{
            margin: "15px",
          }}
          action={
            <div
              style={{
                display: "flex",
                flexDirection: isExtraSmallScreen
                  ? "column"
                  : isMediumScreen
                  ? "row"
                  : "row",
              }}
            >
              {!whetherRowsSelected && (
                <AddEntry callback={onSuccessfulEntry} />
              )}
              {whetherRowsSelected && (
                <React.Fragment>
                  <TableActions
                    selectedRows={selectedRows}
                    invoiceData={invoiceData}
                    callback={onSuccessfulEntry}
                  />
                </React.Fragment>
              )}
            </div>
          }
        />
      </CardMui>
      <Divider sx={{ backgroundColor: "darkgray" }} />
      <Card border="light" className="shadow-sm">
        {/* <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h4>Scans</h4>
            </Col>
            <Col className="text-end">
              <AddEntry callback={onSuccessfulEntry} />
            </Col>
          </Row>
        </Card.Header> */}

        <FilterData
          handleFiltering={handleFiltering}
          filterRequest={filterRequest}
        />
        {showSpinner && <LinearProgress />}

        {/* <ExhibitionTable data={invoiceData} pageNum={activePage} /> */}
        <VisitingCardsGrid
          rowData={invoiceData}
          pageNum={activePage}
          handleRowsSelection={handleRowsSelection}
        />

        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          {totalPages && (
            <Col xl={12}>
              <Pagination size={size} className="mt-3">
                <Pagination.Prev disabled={disablePrev} onClick={onPrevItem}>
                  {withIcons ? (
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  ) : (
                    "Previous"
                  )}
                </Pagination.Prev>
                {items}
                <Pagination.Next onClick={() => onNextItem(totalPages)}>
                  {withIcons ? (
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                  ) : (
                    "Next"
                  )}
                </Pagination.Next>
              </Pagination>
            </Col>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Exhibition;
