import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
// import gantt_chart from "../../assets/img/pages/gantt_chart.png";
import { FormatDate } from "../../../components/dateformat";
import PlotEstimate from "../plots/PlotEstimate";
import { axiosInstance } from "../../../axiosSetup";

const Estimate = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { data, handleNewOrder, newSchedule } = props;
  const schedule_id = newSchedule.id;
  const eta = newSchedule.data.eta;
  const start_time = newSchedule.data.start_time;
  const [estimatedData, updateEstimatedData] = useState(data);
  const [showAlert, setShowAlert] = useState(false);
  const [formData, updateFormData] = useState(data);
  const [estimateLocked, setEstimateLocked] = useState(false);

  useEffect(() => {
    //want these states to be ready before lock, but cant place them directly inside Estimate Block
    //since they have to be updated only once
    updateEstimatedData({
      ...data,
      start_time,
      eta,
    });
    updateFormData({
      ...data,
      schedule_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLock = (e) => {
    e.preventDefault();
    //console.log(formData);
    if (!estimateLocked) {
      setLoaded(true);
      axiosInstance
        .post("ajs/projects/", formData, {})
        .then((res) => {
          // const data = res.data;
          // console.log(data);
          setLoaded(false);
          setShowAlert(true);
          setEstimateLocked(true);
          // setTimeout(() => setLoaded(false), 2000);
          handleNewOrder(estimatedData);
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };
  return (
    <>
      <Col lg={8} className="mt-4">
        <Card>
          <Card.Body>
            <Card.Title>Estimate</Card.Title>
            <Row>
              <Col lg={9}>
                {/* <Card.Img src={gantt_chart} style={{}} /> */}
                <PlotEstimate schedule={newSchedule} model={data.model_name} />
              </Col>
              <Col lg={3} align="center">
                <Card.Title>Start Date</Card.Title>
                <Card.Text>{FormatDate(start_time)}</Card.Text>
                <Card.Title>ETA</Card.Title>
                <Card.Text>{FormatDate(eta)}</Card.Text>
                {/* <Card.Title>M/c Sr. No.</Card.Title>
                <Card.Text>{mcsr_no}</Card.Text> */}
                <Card.Title>Model : {data.model_name} </Card.Title>
                <Card.Text>6 current orders</Card.Text>
                <Card.Text>0.6 days avg delay in ETA</Card.Text>
              </Col>
            </Row>

            <Form onSubmit={handleLock}>
              <Form.Group className="d-flex justify-content-center my-2 py-2">
                <Button
                  variant="tertiary"
                  type="submit"
                  size="md"
                  // className="w-100 mt-3 mb-3 "
                >
                  Lock Estimate
                </Button>
              </Form.Group>
            </Form>

            <Row>
              <Col md={{ span: 2, offset: 5 }}>
                {loaded && (
                  <>
                    <Spinner animation="grow" variant="info" />
                    <Spinner animation="grow" variant="success" />
                    <Spinner animation="grow" variant="warning" />
                  </>
                )}
              </Col>
            </Row>

            {showAlert && !loaded && (
              <Alert
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                Locked Successfully! Check status at <b>Orders Tab.</b>
              </Alert>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Estimate;
