import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setSelectedProspect,
  setSelectedReference,
} from "../../../slices/calculatorSlice";
import { getItems } from "../api/erpItems";

const ProspectSelect = () => {
  const [options, setOptions] = useState([]);
  const { prospect, prospect_id } = useSelector(
    (state) => state.calculatorData
  );
  const [itemSelected, setItemSelected] = useState(
    prospect !== "" ? prospect : null
  );
  const dispatch = useDispatch();
  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Calculator")
      ? connectedApps["Calculator"]
      : null
    : null;

  const getProspectList = async (url) => {
    try {
      const res = await getItems(
        url,
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      if (res.error) {
        console.log(res.error);
      } else {
        const itemNames = res.data.data.data_list.map((item) => {
          return { label: `${item.id} / ${item.name}`, id: item.id };
        });
        setOptions(itemNames);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProspectList("prospect_list");
  }, []);

  const handleChange = (e, newValue) => {
    // console.log(newValue);
    setItemSelected(newValue);
    dispatch(setSelectedProspect(newValue));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      dispatch(setSelectedProspect(itemSelected));
    }
  };
  return (
    <React.Fragment>
      <Autocomplete
        style={{ background: "white", width: "100%" }}
        disablePortal
        id="combo-box-demo"
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        freeSolo={true}
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Prospect" />}
        value={itemSelected}
      />
    </React.Fragment>
  );
};

export default ProspectSelect;
