import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  UpdateConnectedAccount,
  deleteConnectedAccountAPIerp,
  getConnectedAccountsInfoAPIerp,
  patchConnectedAccountsDetailsAPIerp,
} from "../../api/connectedAccountsAPI";
import AddAccount from "./AddAccount";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setConnectedData } from "../../slices/connectedAccountSlice";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import { Delete, Replay } from "@mui/icons-material";

const ConnectedAccounts = () => {
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width:961px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState(null);

  const dispatch = useDispatch();
  const { connected_accounts: connectedAccounts, connected_apps } = useSelector(
    (state) => state.connectedAccountData
  );
  const [selectedProduct, setSelectedProduct] = useState(
    Object.keys(connectedAccounts).length > 0
      ? Object.keys(connectedAccounts)[0]
      : null
  );
  useEffect(() => {
    getConnectedAccountsInfo();
  }, []);
  const getConnectedAccountsInfo = async () => {
    try {
      const res = await getConnectedAccountsInfoAPIerp();
      // console.log(res);

      if (res.status == 200) {
        dispatch(
          setConnectedData({
            connected_accounts: res.data.account_details,
            connected_apps: connected_apps,
          })
        );

        setSelectedProduct(
          Object.keys(res.data.account_details).length > 0
            ? Object.keys(res.data.account_details)[0]
            : null
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultUsername = connectedAccounts[selectedProduct]?.usr || "";
  const defaultPassword = connectedAccounts[selectedProduct]?.pwd || "";
  const defaultBaseUrl = connectedAccounts[selectedProduct]?.base_url || "";

  const [editedUsername, setEditedUsername] = useState(defaultUsername);
  const [editedPassword, setEditedPassword] = useState(defaultPassword);
  const [editedBaseUrl, setEditedBaseUrl] = useState(defaultBaseUrl);
  const [editView, setEditView] = useState(false);

  useEffect(() => {
    setEditedUsername(defaultUsername);
    setEditedPassword(defaultPassword);
    setEditedBaseUrl(defaultBaseUrl);
  }, [defaultUsername, defaultPassword, defaultBaseUrl, selectedProduct]);

  const handleChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  const handleUsername = (event) => {
    setEditedUsername(event.target.value);
  };

  const handlePassword = (event) => {
    setEditedPassword(event.target.value);
  };

  const handleBaseUrl = (event) => {
    setEditedBaseUrl(event.target.value);
  };
  const handleEdit = () => {
    setEditView(true);
  };
  const handleDeleteAcc = async () => {
    console.log("handleDeleteAcc", selectedProduct);
    setShowSpinner(true);
    try {
      const response = await deleteConnectedAccountAPIerp(selectedProduct);
      console.log(response);

      //start- this logic most important
      let tempFormat = JSON.parse(
        localStorage.getItem("connected_apps_erpFormat")
      );
      const convert2Obj = (connectedApps) => {
        let tempData = {};
        for (let app in connectedApps) {
          if (connectedApps[app] === "DRS") {
            tempData[app] = {
              connect2: connectedApps[app],
              credential_available: "YES",
              product_name: app,
              api_key: localStorage.getItem("api_key"),
              api_secret: localStorage.getItem("api_secret"),
              base_url: localStorage.getItem("base_url"),
              sid: localStorage.getItem("sid"),
            };
          } else {
            tempData[app] = {
              connect2: connectedApps[app],
              credential_available: "NO",
            };
          }
        }
        // console.log(tempData)
        return tempData;
      };
      localStorage.setItem(
        "connected_apps_erpFormat2",
        JSON.stringify(convert2Obj(tempFormat))
      );
      //end- this logic most important

      dispatch(
        setConnectedData({
          connected_accounts: response.data.message,
          connected_apps: convert2Obj(tempFormat),
        })
      );
      setEditView(false);
      showSnackbar("success", "Account Deleted!");
      setTimeout(() => {
        navigate(URLs.ConnectedAccountSetup.path);
      }, 1000);
    } catch (error) {
      console.log(error);
      showSnackbar("error", "Failed!");
    }

    setShowSpinner(false);
  };
  const handleCancel = () => {
    setEditView(false);
  };

  const updateData = async (data) => {
    // console.log(data);
    try {
      const response = await patchConnectedAccountsDetailsAPIerp(data);
      // console.log(response);

      //start- this logic most important
      let tempFormat = JSON.parse(
        localStorage.getItem("connected_apps_erpFormat")
      );
      const convert2Obj = (connectedApps) => {
        let tempData = {};
        for (let app in connectedApps) {
          if (connectedApps[app] === "DRS") {
            tempData[app] = {
              connect2: connectedApps[app],
              credential_available: "YES",
              product_name: app,
              api_key: localStorage.getItem("api_key"),
              api_secret: localStorage.getItem("api_secret"),
              base_url: localStorage.getItem("base_url"),
              sid: localStorage.getItem("sid"),
            };
          } else {
            tempData[app] = {
              connect2: connectedApps[app],
              credential_available: "NO",
            };
          }
        }
        // console.log(tempData)
        return tempData;
      };
      localStorage.setItem(
        "connected_apps_erpFormat2",
        JSON.stringify(convert2Obj(tempFormat))
      );
      //end- this logic most important

      dispatch(
        setConnectedData({
          connected_accounts: response.data.message,
          connected_apps: convert2Obj(tempFormat),
        })
      );
      setEditView(false);
      showSnackbar("success", "Details Updated!");
      setTimeout(() => {
        navigate(URLs.ConnectedAccountSetup.path);
      }, 1000);
    } catch (error) {
      console.log(error);
      showSnackbar("error", "Failed!");
    }

    setShowSpinner(false);
  };

  const handleSave = () => {
    // console.log(selectedProduct);

    const data = { ...connectedAccounts[selectedProduct] };
    delete data.pulled_at;
    data["usr"] = editedUsername;
    data["pwd"] = editedPassword;
    data["base_url"] = editedBaseUrl;
    setShowSpinner(true);
    updateData({ accountName: selectedProduct, accountData: data });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const showSnackbar = (alertVariant, alertMsg) => {
    setShowAlert(true);
    setAlertVariant(alertVariant);
    setAlertMsg(alertMsg);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  return (
    <div className="page-container">
      <Card
        variant="outlined"
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
        }}
        style={{}}
        className="shadow-sm"
      >
        <CardHeader
          title="Connected Accounts"
          action={
            <div
              style={{
                marginTop: isMobileOrTablet ? "5px" : "0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <AddAccount callback={showSnackbar} />
              <Button
                variant="outlined"
                style={{
                  marginTop: "8px",
                  borderColor: "#050092",
                  color: "#050092",
                }}
                onClick={() => navigate(URLs.ConnectedAccountSetup.path)}
                // startIcon={<Replay />}
                size={isMediumScreen ? "small" : "medium"}
              >
                Reconnect Accounts
              </Button>
            </div>
          }
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: isMobileOrTablet ? "center" : "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <Card style={{ backgroundColor: "#F5F8FB" }}>
          {Object.keys(connectedAccounts).length === 0 ? (
            <Typography
              variant="subtitle2"
              sx={{
                color: "rgba(0, 0, 0, 0.5)",
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              No External Integrations configured.
            </Typography>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                flexDirection:
                  isMediumScreen || isExtraSmallScreen ? "column" : "row",
                height: "100%",
              }}
            >
              <Tabs
                orientation={
                  isMediumScreen || isExtraSmallScreen
                    ? "horizontal"
                    : "vertical"
                }
                variant="scrollable"
                value={selectedProduct}
                onChange={handleChange}
                aria-label="Connected Accounts Tabs"
                sx={{
                  borderRight: isMediumScreen || isExtraSmallScreen ? 0 : 1,
                  borderBottom: isMediumScreen || isExtraSmallScreen ? 1 : 0,
                  borderColor: "divider",
                  color: "#050092",
                }}
              >
                {Object.keys(connectedAccounts).map((key) => (
                  <Tab key={key} label={key} value={key} />
                ))}
              </Tabs>
              <Box sx={{ p: 1 }}>
                <Typography variant="h6" style={{ marginLeft: "30px" }}>
                  {selectedProduct}
                </Typography>
                {connectedAccounts[selectedProduct] ? (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent={isLargeScreen ? "center" : "flex-start"}
                    style={{
                      marginTop: "10px",
                      marginLeft: isLargeScreen ? "20px" : "0",
                    }}
                  >
                    <Grid item xl={4} md={12}>
                      <TextField
                        fullWidth
                        label="Username"
                        variant="outlined"
                        value={editedUsername}
                        onChange={handleUsername}
                        disabled={!editView}
                      />
                    </Grid>
                    <Grid item xl={4} md={12}>
                      <TextField
                        fullWidth
                        style={{ maxWidth: isMediumScreen ? "235px" : null }}
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        value={editedPassword}
                        onChange={handlePassword}
                        variant="outlined"
                        disabled={!editView}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xl={4} md={12}>
                      <TextField
                        fullWidth
                        label="Base URL"
                        value={editedBaseUrl}
                        onChange={handleBaseUrl}
                        variant="outlined"
                        disabled={!editView}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      {editView === false && (
                        <>
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={handleEdit}
                            style={{
                              borderColor: "#050092",
                              color: "#050092",
                              marginTop: "10px",
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={handleDeleteAcc}
                            style={{
                              borderColor: "red",
                              color: "red",
                              marginTop: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <Delete />
                          </Button>
                        </>
                      )}
                      {editView === true && (
                        <>
                          {" "}
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={handleSave}
                            style={{
                              borderColor: "#050092",
                              color: "#050092",
                              marginLeft: "10px",
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={handleCancel}
                            style={{
                              borderColor: "red",
                              color: "red",
                              marginLeft: "10px",
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Typography>{selectedProduct}</Typography>
                )}
              </Box>
            </Box>
          )}
          {showSpinner && <LinearProgress />}
        </Card>
      </Card>
      <React.Fragment>
        <Snackbar
          open={showAlert}
          onClose={() => setShowAlert(false)}
          style={{ minWidth: "100px" }}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertVariant}
            sx={{ width: "100%", minHeight: "50px" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </div>
  );
};

export default ConnectedAccounts;
