import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Nav,
  Pagination,
  Tabs,
  Tab,
} from "@themesberg/react-bootstrap";
import OrderTable from "./order/OrderTable";
import { Orders } from "../../data/ajsData";
import NewOrder from "./order/NewOrder";
import "./AJS.css";
import { axiosInstance } from "../../axiosSetup";
import { CardHeader, Card as CardMui, Divider } from "@mui/material";

const AJSHome = () => {
  const [key, setKey] = useState("orders");
  const [AJSOrders, setAJSOrders] = useState(Orders);
  useEffect(() => {
    axiosInstance
      .get("ajs/projects/", {})
      .then((res) => {
        // console.log(res);
        const data = res.data;
        // console.log(data);
        if (data[0] !== undefined) {
          //if there is no project then dont try appending anything to AJSOrders
          let tempNewOrders = [...AJSOrders];
          data.forEach((order) => {
            if (order.parts[0] !== undefined) {
              tempNewOrders.unshift(order);
            }
          });
          setAJSOrders(tempNewOrders);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewOrder = (newOrder) => {
    //Put newly added orders at top of array AJSOrders
    let tempNewOrders = [...AJSOrders];
    tempNewOrders.unshift(newOrder);
    setAJSOrders(tempNewOrders);
  };
  return (
    <div className="page-container">
      <Tabs
        id="controlled-tab-example"
        defaultActiveKey="orders"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="orders" title="Orders">
          <OrdersTab orders={AJSOrders} />
        </Tab>
        <Tab eventKey="newOrder" title="New Order">
          <NewOrder handleNewOrder={handleNewOrder} />
        </Tab>
      </Tabs>
    </div>
  );
};

const OrdersTab = (props) => {
  const { orders } = props;
  const [currentPage, setCurrentPage] = useState("1");
  return (
    <>
     <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title="Current Orders"
         
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </CardMui>
      <Card border="light" className="shadow-sm">
        {/* <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Current Orders</h5>
            </Col>
          </Row>
        </Card.Header> */}
        <OrderTable orderData={orders} pageNum={currentPage} />

        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>Previous</Pagination.Prev>
              <Pagination.Item active={currentPage === "1"}>1</Pagination.Item>
              {/* <Pagination.Item disabled>2</Pagination.Item>
              <Pagination.Item disabled>3</Pagination.Item>
              <Pagination.Item disabled>4</Pagination.Item>
              <Pagination.Item disabled>5</Pagination.Item> */}
              <Pagination.Next>Next</Pagination.Next>
            </Pagination>
          </Nav>
        </Card.Footer>
      </Card>
    </>
  );
};

export default AJSHome;
