import React from "react";
import { multiProject } from "../../../data/ajsData";
import { Card, Col } from "@themesberg/react-bootstrap";
import Plotly from "plotly.js-basic-dist-min";
// import Plot from "react-plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

const PlotMachines = (props) => {
  //   console.log(multiProject);
  const Colors = [
    // "#007fff",
    // "#0072e6",
    // "#0066cc",
    //
    "#0059b3",
    // "#004c99",
    // "#003366",

    //
    "#67af26",
    // "#baec6f",
    // "#2c9826",
    // "#93a05a",
    // "#588711",
    // "#2d6036",

    // "#ffff00",
    // "#bfbf3f",
    // "#fccc04",
    //
    "#fcdc04",
    // "#fbfb04",
    // "#f89034",
  ];
  const workcenters = [];
  const unique_project = [];
  multiProject.forEach((trace) => {
    // console.log(trace);
    let showLegend = false;
    if (unique_project.includes(trace.Project)) {
      showLegend = false;
    } else {
      unique_project.push(trace.Project);
      showLegend = true;
    }

    const workcenter = {
      x: [trace.Start, trace.Finish],
      y: [trace.Resource, trace.Resource],
      mode: "lines",
      name: `Project${trace.Project}`,
      line: {
        width: 9,
        color: Colors[trace.Project - 1],
      },
      showlegend: showLegend,
      legendgroup: trace.Project,
      text: [[trace.Task], [trace.Task]],
      hovertemplate: " <b>%{text}</b> <br>X: %{x}<br>",
    };
    workcenters.push(workcenter);
  });
  //console.log(unique_project);
  const layout = {
    title: "Machine Timeline",
    autosize: true,
    showlegend: true,
    hoverlabel: { bgcolor: "#FFF" },
    xaxis: {
      title: "",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 12,
      },
      showgrid: true,
      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    yaxis: {
      title: "",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 8,
      },
      showgrid: true,
      showline: true,
      showticklabels: true,
      rangemode: "nonnegative",
      autorange: true,

      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    legend: {
      orientation: "h",
      x: 0.5,
      y: -0.2,
      xanchor: "center",
    },
    margin: {
      l: 110,
      b: 80,
      r: 30,
      t: 30,
      pad: 5,
    },
    // barmode: "stack",
  };
  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
          <Col xl={7}>
            <div className="d-block">
              <h6 className="fw-normal text-gray mb-2">Work Centers</h6>
              <h3>17</h3>
            </div>
          </Col>
        </Card.Body>
      </Card>
      <Col xl={12}>
        <Card className="p-2 m-0">
          <Plot data={workcenters} layout={layout} />
        </Card>
      </Col>
    </>
  );
};

export { PlotMachines };
