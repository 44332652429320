import React, { useEffect, useState } from "react";
import { getConnectedAccountsCredentialsAPI } from "../../api/connectedAccountsAPI";
import { URLs } from "../../routes";
import { useNavigate } from "react-router-dom";
import { setConnectedData } from "../../slices/connectedAccountSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import Footer from "../../components/Footer";
import { CheckBox, CheckCircleOutline } from "@mui/icons-material";

const ConnectedAccountSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connected_apps: connectedApps, connected_accounts } = useSelector(
    (state) => state.connectedAccountData
  );
  const [configDone, setConfigDone] = useState(false);
  const [connectedAppsList, setConnectedAppsList] = useState(null);

  useEffect(() => {
    handleInitialSetup();
  }, []);

  const MAX_RETRIES = 2;

  const handleInitialSetup = async () => {
    let connected_apps_erpFormatJson = JSON.parse(
      localStorage.getItem("connected_apps_erpFormat")
    );
    let connected_apps_values = Object.values(connected_apps_erpFormatJson);
    let connected_apps_set = new Set(connected_apps_values);
    let connected_apps_list_raw = [...connected_apps_set];
    const connected_apps_list = connected_apps_list_raw.filter(
      (item) => item !== "DRS"
    );
    // console.log(connected_apps_list);

    let allResponses = [];
    let successResponses = [];
    let failedResponses = [];
    try {
      const apiCalls = connected_apps_list.map(async (connect2) => {
        let retries = 0;
        while (retries < MAX_RETRIES) {
          try {
            const response = await getConnectedAccountsCredentialsAPI(connect2);
            allResponses.push({
              app_name: connect2,
              response: response,
            });
            if (response.data.message.credentials_generated === true) {
              successResponses.push({
                app_name: connect2,
                response: response,
              });
            }
            if (response.data.message.credentials_generated === false) {
              failedResponses.push({
                app_name: connect2,
                response: response,
              });
            }
            break;
          } catch (error) {
            // console.error(`API call failed for ${connect2}:`, error);
            if (retries === MAX_RETRIES - 1) {
              // console.error(`Max retries reached for ${connect2}`);
              break;
            }
            retries++;
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      });
      await Promise.all(apiCalls);
      // console.log("Successful responses");
    } catch (error) {
      // console.error("Error fetching connected accounts credentials:", error);
    }

    // console.log("allResponses", allResponses);
    localStorage.setItem(
      "connected_accounts_details_list",
      JSON.stringify(allResponses)
    );
    // console.log("successResponses", successResponses);

    setConnectedAppsList(
      successResponses.map((temp) => {
        return temp.app_name;
      })
    );
    // console.log("failedResponses", failedResponses);
    // console.log(JSON.parse(localStorage.getItem("connected_apps_erpFormat2")));

    let updated_apps_erpFormat2 = JSON.parse(
      localStorage.getItem("connected_apps_erpFormat2")
    );
    successResponses.map((res) => {
      for (let temp in updated_apps_erpFormat2) {
        // console.log("loop running");

        if (updated_apps_erpFormat2[temp]["connect2"] == res.app_name) {
          updated_apps_erpFormat2[temp] = {
            ...updated_apps_erpFormat2[temp],
            credential_available: "YES",
            product_name: temp,
            ...res.response.data.message,
          };
        }
        if (updated_apps_erpFormat2[temp]["connect2"] == "DRS") {
          updated_apps_erpFormat2[temp] = {
            ...updated_apps_erpFormat2[temp],
            credential_available: "YES",
            product_name: temp,
            api_key: localStorage.getItem("api_key"),
            api_secret: localStorage.getItem("api_secret"),
            base_url: localStorage.getItem("base_url"),
            sid: localStorage.getItem("sid"),
          };
        }
      }
    });
    localStorage.setItem(
      "connected_apps_erpFormat2",
      JSON.stringify(updated_apps_erpFormat2)
    );
    dispatch(
      setConnectedData({
        connected_accounts: connected_accounts,
        connected_apps: updated_apps_erpFormat2,
      })
    );
    setConfigDone(true);
    setTimeout(() => {
      navigate(URLs.Overview.path);
    }, 1000);

    // console.log("updated_apps_erpFormat2",updated_apps_erpFormat2);
  };

  return (
    <div>
      {configDone === false ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <div>
              <CircularProgress size={150} color="inherit" />
            </div>
            <div>
              <Alert icon={false} severity="warning">
                <h3>Configuring Connected Accounts...</h3>
              </Alert>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "100px",
          }}
        >
          <CheckCircleOutline
            style={{ width: "150", height: "150", color: "green" }}
          />
          <div>
            <Alert icon={false} variant="outlined" severity="success">
              <AlertTitle>You are Connected !</AlertTitle>
              {connectedAppsList?.map((ca) => {
                return (
                  <Alert
                    style={{ margin: "4px" }}
                    // variant="outlined"
                    severity="success"
                    key={ca}
                  >
                    {ca}
                  </Alert>
                );
              })}
            </Alert>
          </div>
          <Alert style={{ margin: "10px" }} variant="outlined" severity="info">
            Navigating to Overview...
            <LinearProgress />
          </Alert>
        </div>
      )}
    </div>
  );
};

export default ConnectedAccountSetup;
