import React, { useEffect, useState } from "react";
import { AllData } from "../../assets/devData/AllData";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../components/StrictModeDroppable";
import {
  Alert,
  AppBar,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
// import { Col, Row } from "react-bootstrap";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import SyncIcon from "@mui/icons-material/Sync";

const KitMain = () => {
  // props
  const isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  // clg
  // console.log(AllData);

  // usestate
  const [allItemsList, setAllItemsList] = useState(null);
  const [bucketST, setBucketST] = useState(null);
  const [importData, setImportData] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [isDataSyncing, setIsDataSyncing] = useState(false);
  const [kitsST, setKitsST] = useState(null);
  const [snakbarST, setSnakbarST] = useState({
    openSnakbar: false,
    snackbarMessage: "",
    severity: "info",
  });
  const [multiselectST, setMultiselectST] = useState(null);
  const [multiselectBtnST, setMultiselectBtnST] = useState(false);
  const [kitsName, setKitsName] = React.useState([]);

  // useffect
  useEffect(() => {
    // api call
    setAllItemsList(AllData);
    setBucketST(AllData.items_bucket);
    setKitsST(AllData.kits);
  }, []);
  useEffect(() => {
    // api call
    // console.log(kitsST);
    // console.log(bucketST);
  }, [kitsST, bucketST]);

  //   logics
  const reorder = (list, initialIndex, finalIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(initialIndex, 1);
    result.splice(finalIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // console.log(result);
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;

    // Case 1: Dragging and dropping from bucketList to a kit

    if (
      source.droppableId === "bucketList" &&
      destination.droppableId !== "bucketList" &&
      multiselectBtnST === false
    ) {
      const item = bucketST[source.index];

      // console.log(item);
      // console.log(
      //   kitsST[destination.droppableId].some((obj) => obj.id === item.id)
      // );
      if (kitsST[destination.droppableId].some((obj) => obj.id === item.id)) {
        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `Item already exits`,
          severity: "error",
        });
      } else {
        const updatedBucket = [...bucketST];
        updatedBucket.splice(source.index, 1);

        // creating uuid
        const uuid = `${destination.droppableId}-${item.id}`;
        const updatedItem = { ...item, uuid: uuid };
        // console.log(updatedItem);

        const updatedKits = {
          ...kitsST,
          [destination.droppableId]: [
            ...kitsST[destination.droppableId].slice(0, destination.index),
            updatedItem,
            ...kitsST[destination.droppableId].slice(destination.index),
          ],
        };

        // setBucketST(updatedBucket);
        setKitsST(updatedKits);

        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `Item moved from bucket to ${destination.droppableId}`,
          severity: "success",
        });
      }
    }

    // Case 2: Dragging and dropping between different kits & reorder in same kit
    if (
      source.droppableId !== "bucketList" &&
      destination.droppableId !== "bucketList"
    ) {
      const sourceKit = kitsST[source.droppableId];
      const destinationKit = kitsST[destination.droppableId];
      //  same kit
      if (source.droppableId === destination.droppableId) {
        const updatedItems = reorder(
          sourceKit,
          source.index,
          destination.index
        );
        const updatedKits = {
          ...kitsST,
          [source.droppableId]: updatedItems,
        };
        // console.log(updatedKits)
        setKitsST(updatedKits);
        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `Item successfully reordered in ${destination.droppableId}`,
          severity: "success",
        });
      } else {
        const [draggedItem] = sourceKit.splice(source.index, 1);
        const updatedDraggedItem = {
          ...draggedItem,
          uuid: `${destination.droppableId}-${draggedItem.id}`,
        };

        const uKit1 = [...destinationKit];
        const uKit2 = uKit1.filter(
          (existingItem) => existingItem.id !== updatedDraggedItem.id
        );
        // console.log(uKit2);

        uKit2.splice(destination.index, 0, updatedDraggedItem);
        // destinationKit.splice(destination.index, 0, updatedDraggedItem);

        const updatedKits = {
          ...kitsST,
          [source.droppableId]: sourceKit,
          [destination.droppableId]: uKit2,
        };

        setKitsST(updatedKits);
        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `Item moved from ${source.droppableId} to ${destination.droppableId}`,
          severity: "success",
        });
      }
    }
    // Case 3: Dragging and dropping from a kit to the bucket
    if (
      source.droppableId !== "bucketList" &&
      destination.droppableId === "bucketList"
    ) {
      const sourceKit = kitsST[source.droppableId];
      const item = sourceKit[source.index];
      const updatedKit = [...sourceKit];
      updatedKit.splice(source.index, 1);

      // Remove the "uuid:value" pair from the item
      const { uuid, ...itemWithoutUuidValue } = item;

      const updatedBucket = [...bucketST];
      const updatedBucket2 = updatedBucket.filter(
        (existingItem) => existingItem.id !== item.id
      );
      // console.log(updatedBucket2);
      updatedBucket2.splice(destination.index, 0, itemWithoutUuidValue);

      const updatedKits = {
        ...kitsST,
        [source.droppableId]: updatedKit,
      };

      setBucketST(updatedBucket2);
      setKitsST(updatedKits);
      setSnakbarST({
        ...snakbarST,
        openSnakbar: true,
        snackbarMessage: `Item moved from ${source.droppableId} to ${destination.droppableId}`,
        severity: "success",
      });
    }
    // case 4 reorder in bucket
    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId == "bucketList") {
        const updatedItems = reorder(bucketST, source.index, destination.index);
        setBucketST(updatedItems);
        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `Item successfully reordered in ${destination.droppableId}`,
          severity: "success",
        });
      }
    }
  };

  // handleChange
  const handleChangeSearch = (event, newValue) => {
    // console.log(newValue);
    if (newValue === null) {
      setBucketST(allItemsList.items_bucket);
      console.log("null clg");
    } else {
      const filteredItems = allItemsList.items_bucket.filter((item) => {
        // Filter by category or item name
        if (
          item.category === newValue ||
          item.name.toLowerCase().includes(newValue.toLowerCase())
        ) {
          return item;
        }
      });
      setBucketST(filteredItems);
      // console.log(filteredItems);
    }
  };
  const handleChangeCheckbox = (event, item) => {
    const checked = event.target.checked;

    // Create a copy of the multiselectST state
    const templist = [...(multiselectST || [])];

    if (checked === true) {
      templist.push(item);
    } else if (checked === false) {
      const index = templist.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        templist.splice(index, 1);
      }
    }

    // Update the multiselectST state
    setMultiselectST(templist);

    // console.log(multiselectST);
  };
  const handleChangeMoveTo = (event, newValue) => {
    // console.log(newValue);
    // console.log(multiselectST);

    const dissimilarItems = kitsST[newValue].filter((item) => {
      const foundItem = multiselectST.find(
        (multiselectItem) => multiselectItem.id === item.id
      );
      return foundItem === undefined;
    });
    // console.log(dissimilarItems);

    const updatedItemMultiselectST = multiselectST;
    const updatedItemMultiselectST2 = updatedItemMultiselectST.map(
      (item, index) => {
        const uuid = `${newValue}-${item.id}`;
        const updatedItem = { ...item, uuid: uuid };
        return updatedItem;
      }
    );
    // console.log(updatedItemMultiselectST2);

    const tempBucketList = bucketST.filter(
      (element) => !multiselectST.includes(element)
    );

    // setBucketST(tempBucketList);
    setKitsST((prevKitsST) => ({
      ...prevKitsST,
      [newValue]: [...updatedItemMultiselectST2, ...dissimilarItems],
    }));
    setMultiselectST(null);
    setMultiselectBtnST(false);
    setSnakbarST({
      ...snakbarST,
      openSnakbar: true,
      snackbarMessage: `Items successfully moved to ${newValue}`,
      severity: "success",
    });
  };

  const handleSelectMultiKits = (event) => {
    const {
      target: { value },
    } = event;
    setKitsName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // console.log(value);
    // console.log(kitsName);
  };

  // handleClick
  const handleCloseSnakbar = () => {
    setSnakbarST({
      ...snakbarST,
      openSnakbar: false,
      // snackbarMessage: "",
      // severity: "info",
    });
  };
  const handleRemoveItemFromKit = (item) => {
    // console.log(item);
    const updatedKits = { ...kitsST };
    const kitId = Object.keys(updatedKits).find(
      (kitId) =>
        updatedKits[kitId].findIndex((i) => i.uuid === item.uuid) !== -1
    );

    if (kitId) {
      updatedKits[kitId] = updatedKits[kitId].filter(
        (i) => i.uuid !== item.uuid
      );
      setKitsST(updatedKits);
      setSnakbarST({
        ...snakbarST,
        openSnakbar: true,
        snackbarMessage: `Item removed from ${kitId}`,
        severity: "warning",
      });
    }
  };

  // logic

  const handleExportToJson = () => {
    setExportData({ kits: kitsST, items_bucket: bucketST });

    let tempExportData = { kits: kitsST, items_bucket: bucketST };
    // Convert the allItemsList data to a JSON string
    const jsonData = JSON.stringify(tempExportData);
    // Create a Blob (Binary Large Object) from the JSON data
    const blob = new Blob([jsonData], { type: "application/json" });
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create an anchor element to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "product_bundling.json";
    // Simulate a click on the anchor element to trigger the download
    a.click();
    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
    setSnakbarST({
      openSnakbar: true,
      snackbarMessage: "Download completed",
      severity: "success", // Set the severity to "success" for green color
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContents = e.target.result;
        // console.log(fileContents)
        try {
          const jsonData = JSON.parse(fileContents);

          setImportData(jsonData);
          // console.log(jsonData);
          handleViewImportedData(jsonData);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleViewImportedData = (jsonData) => {
    // console.log(jsonData);
    setAllItemsList({
      items_bucket: jsonData.items_bucket,
      kits: jsonData.kits,
    });
    setBucketST(jsonData.items_bucket);
    setKitsST(jsonData.kits);
    setSnakbarST({
      openSnakbar: true,
      snackbarMessage: "Upload completed",
      severity: "success", // Set the severity to "success" for green color
    });
  };

  const handleDataSync = async () => {
    // console.log(kitsST);
    let tempData = kitsST;
    setIsDataSyncing(true);

    try {
      const res = await new Promise((resolve) =>
        setTimeout(() => {
          resolve("result");
        }, 3000)
      );

      setSnakbarST({
        openSnakbar: true,
        snackbarMessage: "Sync completed",
        severity: "success", 
      });
      setIsDataSyncing(false);
    } catch (error) {
      console.log(error);
      setIsDataSyncing(false);
    }
  };

  //   style

  const buttonStyle = {
    marginRight: "8px",
    borderColor: "#050092",
    color: "#050092",
  };

  const buttonStyle2 = {
    marginRight: "8px",
    borderColor: "#050092",
    color: "#050092", // Set the text color to white for better visibility
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "1px",
    margin: `0 0 ${grid}px 0`,
    width: "250px",
    minHeight: "auto",
    background: isDragging ? "#2490ef" : "#fff",
    borderRadius: 5,
    border: "1px solid #aab0b5",
    borderLeftWidth: "5px",
    ...draggableStyle,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    maxWidth: "300px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#fef4e2" : "#f0f8fe",
    padding: grid,
    width: 300,
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: 550,
    overflowY: "auto",
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px solid #aab0b5",
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className="page-container">
      <style>
        {`
          .item-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        `}
      </style>
      <Card
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <CardHeader
          title="Product Bundling"
          action={
            <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
              <input
                type="file"
                accept=".json"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="fileInput" // Give it an id for labeling
              />
              <label htmlFor="fileInput">
                <Button variant="outlined" component="span" style={buttonStyle}>
                  <CloudUploadIcon />
                </Button>
              </label>
              <Button
                onClick={handleExportToJson}
                variant="outlined"
                style={buttonStyle2}
                // startIcon={<GetAppIcon />}
              >
                <GetAppIcon />
              </Button>{" "}
              <Button
                variant="contained"
                style={{
                  marginRight: "8px",
                  backgroundColor: "#050092",
                  color: "#fff",
                }}
                onClick={handleDataSync}
                startIcon={
                  isDataSyncing ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <SyncIcon />
                  )
                }
              >
                Sync
              </Button>
            </div>
          }
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent style={{ backgroundColor: "#ffffff" }}>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              sx={{
                backgroundColor: "#f0f8fe",
                border: "1px solid #aab0b5",
                borderRadius: "3px",
                boxShadow: "none",
              }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} lg={3}>
                    {allItemsList && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={[
                          ...new Set([
                            ...allItemsList.items_bucket.map(
                              (item) => item.category
                            ),
                            ...allItemsList.items_bucket.map(
                              (item) => item.name
                            ),
                          ]),
                        ]}
                        sx={{
                          width: "100%", // Full width for mobile
                          backgroundColor: "#fff",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Search Items..." />
                        )}
                        onChange={handleChangeSearch}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} lg={4}>
                    {kitsST && (
                      <FormControl
                        sx={{
                          width: "100%",
                          backgroundColor: "#fff",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel>Select Kits...</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={kitsName}
                          onChange={handleSelectMultiKits}
                          input={<OutlinedInput label="Select Kits..." />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {Object.entries(kitsST).map(([key, value], index) => (
                            <MenuItem key={key} value={key}>
                              <Checkbox checked={kitsName.indexOf(key) > -1} />
                              <ListItemText primary={key} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} lg={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        // marginLeft: "10px",
                        // marginRight: "10px",
                        borderColor: "#bcbec0",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderRadius: "3px",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={multiselectBtnST}
                              onChange={(event) => {
                                setMultiselectST(null);
                                setMultiselectBtnST(event.target.checked);
                              }}
                            />
                          }
                          label="Multiselect"
                          sx={{
                            color: "black",
                            padding: "8px",
                          }}
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={3}>
                    {multiselectST?.length > 0 && (
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={Object.keys(kitsST)}
                        sx={{
                          width: "100%",
                          mb: 2,
                          backgroundColor: "#fff",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Move to..." />
                        )}
                        onChange={handleChangeMoveTo}
                      />
                    )}
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <div style={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={3}>
                  <div style={{ marginRight: "16px" }}>
                    <StrictModeDroppable droppableId="bucketList">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h6>Bucket</h6>
                          </div>

                          <div style={{ height: "60vh" }}>
                            {bucketST.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                                isDragDisabled={multiselectBtnST}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Grid item xs={2}>
                                        {" "}
                                        {multiselectBtnST && (
                                          <Checkbox
                                            checked={
                                              multiselectST?.find((i) => {
                                                if (i.id === item.id) {
                                                  return true;
                                                }
                                              })
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              handleChangeCheckbox(event, item)
                                            }
                                            color="primary"
                                            size="small"
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        )}{" "}
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Typography className="item-name">
                                          {item.name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={9}>
                  <div
                    style={{
                      overflowX: "auto",
                      display: "flex",
                    }}
                  >
                    {kitsST &&
                      Object.entries(kitsST)
                        .filter(([key]) => kitsName.includes(key))
                        .map(([key, value], index) => (
                          <div style={{ marginRight: "16px" }} key={index}>
                            <StrictModeDroppable droppableId={key}>
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <h6>{key}</h6>
                                  </div>
                                  <div style={{ height: "60vh" }}>
                                    {value.map((item, index) => (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.uuid}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <Grid
                                              container
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Grid item xs={9}>
                                                <Typography
                                                  className="item-name"
                                                  style={{ paddingLeft: "5px" }}
                                                >
                                                  {item.name}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                {" "}
                                                <IconButton
                                                  onClick={() =>
                                                    handleRemoveItemFromKit(
                                                      item
                                                    )
                                                  }
                                                  aria-label="delete"
                                                  // style={{backgroundColor:"yellow"}}
                                                >
                                                  <Delete
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                    }}
                                                  />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )}
                            </StrictModeDroppable>
                          </div>
                        ))}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Snackbar
              open={snakbarST.openSnakbar}
              autoHideDuration={2000}
              onClose={handleCloseSnakbar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Alert onClose={handleCloseSnakbar} severity={snakbarST.severity}>
                {snakbarST.snackbarMessage}
              </Alert>
            </Snackbar>
          </DragDropContext>{" "}
        </CardContent>
      </Card>
    </div>
  );
};

export default KitMain;
