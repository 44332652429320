import React from "react";
import { useLocation } from "react-router-dom";
import RetryCA from "../../components/RetryCA";

const RedirectingErr = () => {
  const location = useLocation();
//   console.log(location);
  return (
    <>
      <RetryCA data={location.state.data} />
    </>
  );
};

export default RedirectingErr;
