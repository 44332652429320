import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Spinner } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link as RRLink, useParams, useLocation } from "react-router-dom";
import "./Exhibition.css";
import TableRow from "./detail/TableData";
import InfoTable from "./detail/GeneralInfo";
import SyncDetails from "./detail/SyncDetails";
import { URLs } from "../../routes";
import { axiosInstance } from "../../axiosSetup";
import { Accordion } from "react-bootstrap";
import {
  Button as ButtonMui,
  Checkbox,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import CommentSection from "./detail/CommentSection";
import {
  ThumbDownAlt,
  ThumbDownAltOutlined,
  ThumbUpAlt,
  ThumbUpAltOutlined,
} from "@mui/icons-material";
import { getVisitingCardsAPIerp } from "../../api/SdsAPI";

const ExhibitionDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { entryId } = useParams();
  const [detail, setDetail] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const metadata = JSON.parse(localStorage.getItem("metadata"))?.visiting_cards;
  const [generalMetadata, setGeneralMetadata] = useState(null);
  const [tableMetadata, setTableMetadata] = useState(null);
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [checkedFeed, setCheckedFeed] = useState({
    thumbsUp: false,
    thumbsDown: false,
  });

  useEffect(() => {
    if (detail) {
      if (detail.unique_document_id) {
        try {
          setGeneralMetadata(metadata[`${detail.unique_document_id}`].general);
          const generalMetadata =
            metadata[`${detail.unique_document_id}`].general;
          try {
            generalMetadata.sort(function (a, b) {
              return a.order - b.order;
            });
          } catch (err) {
            generalMetadata.sort(function (a, b) {
              if (a.name > b.name) return 1;
              else if (a.name < b.name) return -1;
              return 0;
            });
          }
          setTableMetadata(metadata[`${detail.unique_document_id}`].table);
          const tableMetadata = metadata[`${detail.unique_document_id}`].table;
          tableMetadata.sort(function (a, b) {
            return a.order - b.order;
          });
        } catch (err) {
          // localStorage.removeItem("metadata");
          console.log(err);
        }
      }
    }
  }, [detail]);

  useEffect(() => {
    loadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetails = async () => {
    let url = `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.return_all_visiting_cards?document_id=${entryId}`;

    try {
      const res = await getVisitingCardsAPIerp(url);
      // console.log(res.data.results[0]);

      let tempRes = res.data.results[0];
      let temp2 = {
        belongs_to: tempRes.company,
        unique_document_id: tempRes.unique_document_id,
        document_type: tempRes.document_type,
        created_at: tempRes.creation,
        id: tempRes.name,
        image: tempRes.image,
        file: tempRes.file,
        status: tempRes.status,
        sync_status: tempRes.sync_status,
        sync_details: tempRes.sync_details,
        misc_data: tempRes.misc_data,
        table_data: tempRes.table_data_metadata,
        comments: tempRes._comments,
      };
      // console.log(temp2);
      
      setDetail(temp2);
      setShowSpinner(false);
    } catch (error) {
      console.log(error);
      setShowSpinner(false);
    }
    // axiosInstance
    //   .get(`sds/${entryId}/`, {})
    //   .then((res) => {
    //     const data = res.data;
    //     setDetail(data);
    //     setShowSpinner(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setShowSpinner(false);
    //   });
  };

  const refreshDetails = (data) => {
    setDetail(data);
  };

  const PushToSap = (e) => {
    setShowSpinner(true);
    e.preventDefault();
    // console.log(entryId);

    axiosInstance
      .post(
        "sds/publish/",
        { doc_id: entryId },
        { timeout: 1 * 60 * 1000 },
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        // console.log(response.data);

        setDetail(response.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setShowSpinner(false);
      });
  };

  const handleChangeFeedback = (e, nameFeed) => {
    // console.log(e.target.checked, nameFeed);
    if (nameFeed === "thumbsUp") {
      setCheckedFeed({ thumbsUp: e.target.checked, thumbsDown: false });
    }
    if (nameFeed === "thumbsDown") {
      setCheckedFeed({ thumbsUp: false, thumbsDown: e.target.checked });
    }
  };

  return (
    <div className="page-container">
      <Card border="light" className="bg-white shadow-sm mb-2">
        <Card.Header>
          <div
            className="d-flex"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobileOrTablet ? "column" : "row",
            }}
          >
            {/* <Button to={URLs.SDS.path} size="sm" color="success" as={RRLink}>
              Back
            </Button> */}
            <ButtonMui
              onClick={() => navigate(URLs.Exhibition.path)}
              variant="contained"
              style={{
                marginRight: "8px",
                marginBottom: "8px",
                backgroundColor: "#262b40",
                color: "#fff",
              }}
            >
              back
            </ButtonMui>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: isMobileOrTablet ? "column" : "row",
              }}
            >
              {/* <div
                style={{
                  width: "auto",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid grey",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              >
                <Checkbox
                  icon={<ThumbUpAltOutlined />}
                  checkedIcon={<ThumbUpAlt />}
                  checked={checkedFeed.thumbsUp}
                  onChange={(e) => handleChangeFeedback(e, "thumbsUp")}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span>Feedback</span>
                <Checkbox
                  icon={<ThumbDownAltOutlined />}
                  checkedIcon={<ThumbDownAlt />}
                  checked={checkedFeed.thumbsDown}
                  onChange={(e) => handleChangeFeedback(e, "thumbsDown")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div> */}

              {/* {detail && (
                <>
                  <ButtonMui
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      marginBottom: "8px",
                      marginTop: "8px",
                      backgroundColor:
                        detail.sync_status === "Success"
                          ? "#A3ABBD"
                          : "#050092",
                      color: "#fff",
                    }}
                    disabled={detail.sync_status === "Success"}
                    onClick={PushToSap}
                    startIcon={<SyncIcon />}
                  >
                    Request Sync
                  </ButtonMui>
                </>
              )} */}
              {showSpinner && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="grow" variant="info" size="md" />
                  <Spinner animation="grow" variant="success" />
                  <Spinner animation="grow" variant="warning" />
                </div>
              )}
            </div>
          </div>
        </Card.Header>
      </Card>

      <Row>
        <Col xs={12} xl={5}>
          <Accordion defaultActiveKey="general-info">
            {detail && <SyncDetails detail={detail} />}
            {isMediumScreen && (
              <React.Fragment>
                <Accordion.Item eventKey="Comments" className="no__background">
                  <Accordion.Button
                    variant="link"
                    className="w-100 d-flex justify-content-between product__button"
                    bg="light"
                  >
                    <div className="d-flex justify-content-between">
                      <span className="h6 mb-0 fw-bold">Comments / Notes</span>
                    </div>
                  </Accordion.Button>
                  <Accordion.Body className="no__background bg-white shadow-sm">
                    <React.Fragment>
                      {detail?.comments && (
                        <CommentSection
                          data={detail.comments}
                          document={detail.id}
                        />
                      )}
                    </React.Fragment>
                  </Accordion.Body>
                </Accordion.Item>
              </React.Fragment>
            )}
            <Accordion.Item
              eventKey="general-info"
              className="no__background multi-collapse"
            >
              <Accordion.Button
                variant="link"
                className="w-100 d-flex justify-content-between product__button"
                style={{ backgroundColor: "#eaedf2" }}
                bg="light"
              >
                <div className="d-flex justify-content-between">
                  <span className="h6 mb-0 fw-bold">General information</span>
                </div>
              </Accordion.Button>
              <Accordion.Body className="no__background bg-white shadow-sm">
                <Row>
                  {detail && generalMetadata && (
                    <InfoTable
                      detail={detail}
                      generalMetadata={generalMetadata}
                      refreshDetails={refreshDetails}
                    />
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>

        {!isMediumScreen && (
          <Col xs={12} xl={7}>
            <Row>
              <Accordion defaultActiveKey="Comments">
                <Accordion.Item eventKey="Comments" className="no__background">
                  <Accordion.Button
                    variant="link"
                    className="w-100 d-flex justify-content-between product__button"
                    bg="light"
                  >
                    <div className="d-flex justify-content-between">
                      <span className="h6 mb-0 fw-bold">Comments / Notes</span>
                    </div>
                  </Accordion.Button>
                  <Accordion.Body className="no__background bg-white shadow-sm">
                    <React.Fragment>
                      {detail?.comments && (
                        <CommentSection
                          data={detail.comments}
                          document={detail.id}
                        />
                      )}
                    </React.Fragment>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>
          </Col>
        )}
      </Row>

      <Row></Row>
    </div>
  );
};

export default ExhibitionDetail;
