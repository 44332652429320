import moment from "moment";

export const getDate4Week = (startDate) => {
  const weekDates = [];
  const startOfWeek = moment(startDate).startOf("week");

  for (let i = 0; i < 7; i++) {
    weekDates.push(
      startOfWeek.clone().add(i, "days").format("ddd DD-MMM-YYYY")
    );
  }
  return weekDates;
};

export const getDatePreviousWeek = () => {
  const weekDates = [];
  const startOfPreviousWeek = moment().subtract(1, "weeks").startOf("week");

  for (let i = 0; i < 7; i++) {
    weekDates.push(
      startOfPreviousWeek.clone().add(i, "days").format("ddd DD-MMM-YYYY")
    );
  }
  console.log(weekDates);
  return weekDates;
};
export const getDateNextWeek = () => {
  const weekDates = [];
  const startOfNextWeek = moment().add(1, "weeks").startOf("week");

  for (let i = 0; i < 7; i++) {
    weekDates.push(
      startOfNextWeek.clone().add(i, "days").format("ddd DD-MMM-YYYY")
    );
  }
  console.log(weekDates);
  return weekDates;
};
export const generateColumns = (weekDates) => {
  const columns = [
    {
      field: "name",
      headerName: "Task Name",
      minWidth: 150,
      headerClassName: "header-cell",
      cellClassName: "center-align-1",
    },
    {
      field: "project",
      headerName: "Project",
      minWidth: 150,
      headerClassName: "header-cell",
      cellClassName: "center-align",
    },
  ];

  weekDates.forEach((date) => {
    columns.push({
      field: date,
      headerName: date.slice(0, 6),
      minWidth: 150,
      editable: true,
      type: "number",
      headerClassName: "header-cell",
      cellClassName: "center-align",
    });
  });

  columns.push({
    field: "total",
    headerName: "Total",
    minWidth: 150,
    headerClassName: "header-cell-total",
    cellClassName: "center-align-total",
  });
  return columns;
};

export const transformTaskData = (taskList, weekDates) => {
  return taskList.map((task) => {
    const taskData = {
      id: task.id,
      name: task.name,
      project: task.project,
      total: 0,
    };

    let totalTime = 0;
    weekDates.forEach((date) => {
      const dateEntry = task.dates.find((d) => d.date === date);
      if (dateEntry) {
        const hours = parseInt(dateEntry.time.replace("hrs", ""), 10);
        taskData[date] = hours;
        totalTime += hours;
      } else {
        taskData[date] = 0;
      }
    });

    taskData.total = totalTime;
    return taskData;
  });
};
