import React, { useEffect, useState } from "react";
// import moment from "moment-timezone";
import { Row, Col, Card } from "@themesberg/react-bootstrap";
import packageJson from "../../package.json";

const appVersion = packageJson.version;

const Footer = (props) => {
  return (
    <div style={{ marginRight: "15px" }}>
      <footer className="footer section py-5 main-footer" sx={{ "z-index": 0 }}>
        <Row>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0">
            {/* <p className="mb-0 text-center text-xl-left">
              Powered by DRS v11.1
            </p> */}
          </Col>
          <Col xs={12} lg={6}>
            <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
              <div>Powered by </div>
              <div>
                {" "}
                <a href="https://mannlowe.com" target="_blank">
                  {" "}
                  Mannlowe Information Services
                </a>
              </div>
              <p className="mb-0" style={{ fontSize: "small" }}>
                v{appVersion}
              </p>
            </ul>
          </Col>
        </Row>
      </footer>
    </div>
  );
};

export default Footer;
