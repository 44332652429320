import React, { useState } from "react";
import { Card, Col, Row, Table, Form } from "@themesberg/react-bootstrap";
import Plotly from "plotly.js-basic-dist-min";
// import Plot from "react-plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { FormatDate } from "../../../components/dateformat";
const Plot = createPlotlyComponent(Plotly);

const PlotSchedule = (props) => {
  const { plotData } = props;
  const [project, setProject] = useState(plotData[0]);
  // console.log(plotData);
  const handleChange = (e) => {
    // setLoaded(true);
    setProject(plotData[e.target.value]);
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="m-0">
        {/* <h6 className="fw-normal text-gray mb-2">Project</h6> */}
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}>
              Project
            </Form.Label>
            <Col sm="10">
              <Form.Select
                required
                name="projectNumber"
                onChange={handleChange}
              >
                {plotData.map((project, index) => {
                  return (
                    <option key={index} value={index}>
                      {project.customer} / SO: {project.so} / MCSR:{" "}
                      {project.mcsr_no}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Form.Group>
        </Form>
        {/* <h3>{projectNum}</h3> */}
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={3}>
            <small>Model</small>
            <h4> {project.model_name}</h4>
          </Col>
          <Col
            xs={9}
            className="d-flex justify-content-center align-items-center"
          >
            <Table borderless size="sm">
              <tbody>
                <tr className="p-0">
                  <td>Customer</td>
                  <td>
                    <b>{project.customer}</b>
                  </td>
                </tr>
                <tr>
                  <td>Start Date</td>
                  <td>
                    <b>{FormatDate(project.start_time)}</b>
                  </td>
                </tr>
                <tr>
                  <td>ETA</td>
                  <td>
                    <b>{FormatDate(project.eta)}</b>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>

      <Card.Body className="p-2">
        {/* {loaded && (
          <>
            <Spinner animation="grow" variant="info" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="warning" />
          </>
        )} */}
        {project && <PlotProject project={project} />}
      </Card.Body>
    </Card>
  );
};
const PlotProject = (props) => {
  const { project } = props;
  // console.log(project);

  const getColor = (status) => {
    if (status === "Completed") {
      return "#7ac36a";
    } else if (status === "In Process") {
      return "#f0e341";
    } else if (status === "Not Started") {
      return "#bdc5ca";
    }
  };
  const parts = [];
  const unique_status = [];
  project.parts.forEach((part) =>
    part.partmanufacture.forEach((dt) => {
      let showLegend = false;
      if (unique_status.includes(dt.status)) {
        showLegend = false;
      } else {
        unique_status.push(dt.status);
        showLegend = true;
      }

      const part = {
        x: [dt.start_time, dt.eta],
        y: [dt.part_name, dt.part_name],
        mode: "lines+text",
        name: dt.status,
        line: { width: 18, color: getColor(dt.status) },
        showlegend: showLegend,
        legendgroup: dt.status,
        // text: [[dt.part + "." + dt.part_name], [dt.part + "." + dt.part_name]],
        text: [`${dt.workcenter_name}`],
        textposition: "middle right",
        textfont: {
          // family: "sans serif",
          // size: 18,
          color: "#000",
        },
        // hovertemplate: " <b>%{text}</b> <br>X: %{x}<br>",
      };
      parts.push(part);
    })
  );

  const layout = {
    title: "Project Timeline",
    autosize: true,
    showlegend: true,
    hoverlabel: { bgcolor: "#FFF" },
    xaxis: {
      title: "",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 12,
      },
      showgrid: true,
      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    yaxis: {
      title: "Parts",
      titlefont: {
        size: 20,
      },
      tickfont: {
        size: 12,
      },
      showgrid: false,
      showline: false,
      showticklabels: false,
      rangemode: "nonnegative",
      autorange: true,

      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    legend: {
      orientation: "h",
      x: 0.5,
      y: -0.2,
      xanchor: "center",
    },
    margin: {
      l: 50,
      b: 80,
      r: 30,
      t: 30,
      pad: 5,
    },
    // barmode: "stack",
  };
  return (
    <>
      <Card border="light" className="shadow-sm"></Card>
      <Col xl={12}>
        <Card className="p-2 m-0">
          <Plot data={parts} layout={layout} />
        </Card>
      </Col>
    </>
  );
};

export default PlotSchedule;
