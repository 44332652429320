import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "@themesberg/react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import PlotWorkCenter from "./plots/PlotWorkCenter";
import PlotSchedule from "./plots/PlotSchedule";
import { Chart, DataCard } from "./AssemblyDemo";
import all_projects from "../../assets/img/pages/all_projects.jpg";
import "./AJS.css";
import { axiosInstance } from "../../axiosSetup";
import { CardHeader, Card as CardMui, Divider } from "@mui/material";

const AssemblyStatus = () => {
  const allProjectsData = [
    { id: 1, color: "success", label: "Completed" },
    { id: 2, color: "warning", label: "In Assembly" },
    { id: 3, color: "mysecondary", label: "Not Started" },
  ];
  const [projectsData, setProjectsData] = useState();
  useEffect(() => {
    axiosInstance
      .get("ajs/projects/", {})
      .then((res) => {
        // console.log(res);
        const data = res.data;
        // console.log(data);
        if (data[0] !== undefined) {
          let tempProjects = [];
          data.forEach((project) => {
            if (project.parts[0] !== undefined) {
              tempProjects.unshift(project);
            }
          });
          setProjectsData(tempProjects);
        }
        // if (data[0] !== undefined) {
        //   setProjectSchedule(data);
        // }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);
  return (
    <div className="page-container ">
       <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title="Assembly Status"
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </CardMui>
      {/* <Card border="light" className="shadow-sm mt-2">
        <Card.Header bg="white">
          <Row className="align-items-center">
            <Col>
              <h5>Assembly Status</h5>
            </Col>
          </Row>
        </Card.Header>
      </Card> */}
      <Row className="my-2">
        <Col xl={6}>
          {/* <Row>
            
          </Row> */}

          <Chart
            data={allProjectsData}
            title="Projects"
            count="10"
            image={all_projects}
          />
        </Col>

        <Col xl={6}>
          {projectsData && <PlotWorkCenter plotData={projectsData} />}
        </Col>
      </Row>
      <Row className="my-2">
        <Col xl={9}>
          {projectsData && <PlotSchedule plotData={projectsData} />}
        </Col>
        <Col xl={3}>
          <Row>
            <Col md={12}>
              <DataCard
                category="WC Utilization"
                title="84%"
                period="August 2021"
                percentage={28.4}
                icon={faChartLine}
                iconColor="shape-tertiary"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <DataCard
                category="SO August 2021"
                title="5"
                period="to be completed"
                percentage={15}
                icon={faCalendarCheck}
                iconColor="shape-tertiary"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AssemblyStatus;
