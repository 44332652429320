import React, { useState } from "react";
import { Card, Image, Button, Modal } from "@themesberg/react-bootstrap";

const ViewImage = (props) => {
  const { image } = props;
  const imageName = image?.split("/")[3];
  const [showModal, setshowModal] = useState(false);
  const handleClose = () => setshowModal(false);
  // setImageName(detail.image.split("/", 3));

  return (
    <>
      <Button onClick={() => setshowModal(true)} className="no__background">
        <Image
          src={`${localStorage.getItem("base_url")}${image}`}
          alt="Document Image"
          style={{ height: "25vh" }}
        />
      </Button>
      <Modal
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title className="h6">{imageName}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <Card.Img
              src={`${localStorage.getItem("base_url")}${image}`}
              alt="Document Image"
              className="mx-auto"
            />
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewImage;
