import React from "react";
import { Col, Row, Card, Toast } from "@themesberg/react-bootstrap";
import { Accordion } from "react-bootstrap";
import { isObjectEmpty } from "../../../components/Utilities";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

export const StatusUpdate = ({ status, suffix }) => {
  // console.log(status);
  let statusTxtColor;
  switch (status) {
    case "Not Attempted":
      statusTxtColor = "text-info";
      break;
    case "Success":
      statusTxtColor = "text-success";
      break;
    case "Partially Successful":
      statusTxtColor = "text-warning";
      break;
    case "Failed":
      statusTxtColor = "text-danger";
      break;
    default:
      statusTxtColor = "text-info";
  }

  return status ? (
    <span className={statusTxtColor}>
      <span className="fw-bold ms-1">
        {status}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

const SyncDetails = (props) => {
  const { detail } = props;
  // console.log(detail.sync_details);
  return (
    <Accordion.Item eventKey="sync-detail" className="no__background">
      {
        <Accordion.Button
          variant="link"
          className="w-100 d-flex justify-content-between product__button"
          bg="light"
        >
          <div>
            <span className="h6 mb-0 fw-bold mr-3">Sync Status</span>
            <span className="mx-3">
              {detail.sync_status && (
                <StatusUpdate status={detail.sync_status} />
              )}
            </span>
            {/* <h6 className="">{`${detail.sync_status}`}</h6> */}
          </div>
        </Accordion.Button>
      }
      <Accordion.Body className="no__background">
        <Card.Body className="py-2 px-0 bg-white ">
          <Row>
            {detail.sync_details && !isObjectEmpty(detail.sync_details) && (
              <GetFormattedAsToast
                syncDetails={detail.sync_details}
                syncStatus={detail.sync_status}
              />
            )}
          </Row>

          {/* <Row>
            {detail && (
              <Button
                variant={
                  detail.sync_status === "Successful" ? "dark" : "tertiary"
                }
                size="sm"
                className="ms-auto"
                disabled={detail.sync_status === "Successful"}
                // onClick={PushToSap}
              >
                <FontAwesomeIcon icon={faSyncAlt} className="me-1" />
                Request Sync
              </Button>
            )}
          </Row> */}
        </Card.Body>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const GetFormattedAsToast = (props) => {
  const { syncDetails, syncStatus } = props;
  // console.log(syncDetails);

  return (
    <Col xl={6} sm={12}>
      <Toast className="my-2">
        <Toast.Header
          closeButton={false}
          className="d-flex justify-content-between"
        >
          <StatusUpdate status={syncStatus} />
          <small>GR : {syncDetails.GR} </small>
        </Toast.Header>
        <Toast.Body>
          <small>PO_NUM : {syncDetails.PO_NUM}</small>
        </Toast.Body>
      </Toast>
      <p className="fw-bold">{syncDetails.REMARK}</p>
      {syncDetails.DETAILS &&
        syncDetails.DETAILS.map((item, index) => {
          return (
            <p key={`error-${index}`}>
              {index + 1}.{item.ERROR}
            </p>
          );
        })}
    </Col>
  );
};

export default SyncDetails;
