import axios from "axios";

export const getQuestionBankDataAPIV2 = async (
  name,
  base_url,
  api_key,
  api_secret
) => {
  try {
    const response = await axios.get(
      `${base_url}/api/method/ao_dt_customisations.api.question_bank_with_subquestion?name=${name}`,
      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // Handle any errors or exceptions here
    console.error("Error fetching user list:", error);
    throw error;
  }
};
export const getQuestionBankNameListAPI = async (
  base_url,
  api_key,
  api_secret
) => {
  try {
    const response = await axios.get(
      `${base_url}/api/method/ao_dt_customisations.api.question_bank_name`,
      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // Handle any errors or exceptions here
    console.error("Error fetching user list:", error);
    throw error;
  }
};

export const postSubmitFormData = async (
  dataBody,
  base_url,
  api_key,
  api_secret
) => {
  try {
    const response = await axios.post(
      `${base_url}/api/method/ao_dt_customisations.api.submited_question_form`,
      dataBody,
      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
