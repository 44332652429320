import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getInvoicesAPI } from "../../api/RazorpayXAPI"; // Import the function from your API utility file
import { Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import { useSelector } from "react-redux";

const Payments = () => {
  const navigateTo = useNavigate();

  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Make Payments")
      ? connectedApps["Make Payments"]
      : null
    : null;

  const columns = [
    {
      field: "checkbox",
      headerName: "#",
      flex: 1, // Use flex property to make it flexible
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={false}
          onChange={() => console.log(params.row.id)}
        />
      ),
    },

    { field: "name", headerName: "Name", flex: 1.5, minWidth: 100 }, // Adjust flex and set minWidth

    {
      field: "view",
      headerName: "",
      flex: 1, // Adjust flex
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => handleViewClick(params.row.id)}
          style={{borderColor: "#050092",
          color: "#050092",}}
        >
          View
        </Button>
      ),
    },
  ];

  const handleViewClick = (id) => {
    const selectedInvoice = contactsData.find((item) => item.id === id);
    navigateTo(`/payments/invoiceDetail/${id}`, {
      state: { invoiceData: selectedInvoice },
    });
  };

  const [contactsData, setContactsData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contactsResponse = await getInvoicesAPI(
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret,
          '["name"]', // Pass the required parameters for the API request
          {}
        );
        // console.log(contactsResponse.data.data)
        const tempRows = contactsResponse.data.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setContactsData(tempRows);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, []);

  return (
    <div className="page-container">
      <Card
        variant="outlined"
        sx={{ border: "1px solid lightgray" }}
        className="shadow-sm"
      >
        <CardHeader title="Payment List" sx={{ margin: "15px" }} />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent style={{ backgroundColor: "#F5F8FB" }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={contactsData}
              columns={columns}
              style={{ fontSize: "16px" }}
              pageSize={5}
              onSelectionModelChange={(newSelection) => {
                setSelectedRow(newSelection[0]);
              }}
            />
            {/* <p>Selected Row: {selectedRow}</p> */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Payments;

//

//
