import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  credits: 0,
  total_users: 0,
  validity: null,
  price: 0,
  plan_type: null,
  paymentStatus: "Pending",
  paymentDetails: {},
  selectedPlan: null,
  selectedSubPlan: null,
  selectedSubPlanLabel: null,
  selectedProduct: null,
  sub_plan_validity_by_date: null,
  sub_plan_validity_by_days: null,
  cartCount: 0,
};

const OrderDataSlice = createSlice({
  name: "OrderSummary",
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.credits = action.payload.credits;
      state.selectedPlan = action.payload.selectedPlan;
      state.selectedSubPlan = action.payload.selectedSubPlan;
      state.selectedSubPlanLabel = action.payload.selectedSubPlanLabel;
      state.selectedProduct = action.payload.selectedProduct;
      state.sub_plan_validity_by_date =
        action.payload.sub_plan_validity_by_date;
      state.sub_plan_validity_by_days =
        action.payload.sub_plan_validity_by_days;
      state.price = action.payload.price;
      state.total_users = action.payload.total_users;
      state.validity = action.payload.validity;
      state.plan_type = action.payload.plan_type;
      state.cartCount = action.payload.cartCount;
    },

    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload.paymentStatus;
      state.paymentDetails = action.payload.paymentDetails;
    },

    setPlanType: (state, action) => {
      state.planType = action.payload;
      // console.log(action);
    },

    setAddProduct: (state, action) => {
      const idExists = state.products_list.some(
        (obj) => obj.id === action.payload.id
      );
      if (!idExists) {
        state.products_list.push(action.payload);
      }
      // console.log(action.payload);
    },
    setAddUser: (state, action) => {
      state.total_users += 1;
    },
    setSubtractUser: (state, action) => {
      state.total_users -= 1;
    },
    setAddCredits: (state, action) => {
      state.credits += 100;
    },
    setSubtractCredits: (state, action) => {
      state.credits -= 100;
    },
    setRemoveProduct: (state, action) => {
      const indexToRemove = state.products_list.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (indexToRemove !== -1) {
        state.products_list.splice(indexToRemove, 1);
      }
      // console.log(indexToRemove);
      // console.log(action.payload);
    },
    setPaymentFlag: (state, action) => {
      state.paymentFlag = action.payload;
      // console.log(action.payload);
    },
    clearAllCart: (state, action) => {
      // state.plan_type = "";
      // state.total_users = "";
      // state.products_list = [];
      // state.payment_details = {
      //   status: "",
      //   total_price: "",
      //   payment_date: "",
      // };
      return initialState;
    },
  },
});
export const {
  setSelectedPlan,
  setPaymentStatus,
  clearAllCart,
  setPlanType,
  setAddProduct,
  setRemoveProduct,
  setAddUser,
  setSubtractUser,
  setSubtractCredits,
  setAddCredits,
  setPaymentFlag,
} = OrderDataSlice.actions;
export default OrderDataSlice.reducer;
