import moment from "moment-timezone";

// export function FormatDateTime(date) {
//   if (date) {
//     let current_datetime = new Date(date);
//     // const abbrev = moment.tz
//     //   .zone(moment.tz.guess())
//     //   .abbr(current_datetime.getTimezoneOffset());
//     return (
//       current_datetime.toDateString() +
//       " " +
//       current_datetime.toLocaleTimeString()
//       // +
//       // abbrev
//     );
//   } else {
//     return null;
//   }
// }
export function FormatDateTime(date, selectedTimezone = "Asia/Kolkata") {
  if (date) {
    return moment
      .tz(date, selectedTimezone)
      .format("ddd, MMM Do YYYY, h:mm:ss a");
  } else {
    return null;
  }
}

export function FormatDateTimeInTwoLines(date) {
  if (date) {
    let current_datetime = new Date(date);
    // const abbrev = moment.tz
    //   .zone(moment.tz.guess())
    //   .abbr(current_datetime.getTimezoneOffset());
    return {
      date: current_datetime.toDateString(),
      time: current_datetime.toLocaleTimeString(),
      //  + abbrev
    };
  } else {
    return {
      date: null,
      time: null,
    };
  }
}

export function FormatDateTimeWithoutAbbrev(date) {
  let current_datetime = new Date(date);
  return (
    current_datetime.toDateString() +
    " " +
    current_datetime.toLocaleTimeString()
  );
}

export function FormatDate(date) {
  //Sun May 01 2022
  if (date) {
    let current_datetime = new Date(date);
    if (current_datetime == "Invalid Date") {
      return date;
    }
    return current_datetime.toDateString();
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function FormatSimpleDate(date) {
  // 15/05/2022
  if (date) {
    let current_datetime = new Date(date);
    if (current_datetime == "Invalid Date") {
      return date;
    }
    return [
      padTo2Digits(current_datetime.getDate()),
      padTo2Digits(current_datetime.getMonth() + 1),
      padTo2Digits(current_datetime.getFullYear()),
    ].join("/");
  }
}
export function FormatSimpleDateUS(date) {
  // 05/15/2022
  //required bcoz yyyy-mm-dd dates not supported by safari engine
  // and dd/mm/yyyy is going as mm/dd/yyyy
  if (date) {
    let current_datetime = new Date(date);
    if (current_datetime == "Invalid Date") {
      return date;
    }
    return [
      padTo2Digits(current_datetime.getMonth() + 1),
      padTo2Digits(current_datetime.getDate()),
      padTo2Digits(current_datetime.getFullYear()),
    ].join("/");
  }
}
export function FormatStringDate(date) {
  // for string format "yyyy-mm-dd"
  try {
    let returnDate = new Date(date);
    const offset = returnDate.getTimezoneOffset();
    returnDate = new Date(returnDate.getTime() - offset * 60 * 1000);
    returnDate = returnDate.toISOString().split("T")[0];
    return returnDate;
  } catch {
    return null;
  }
}

export function FormatSimpleHourMin(date) {
  if (date) {
    let r_datetime = new Date(date);
    if (r_datetime == "Invalid Date") {
      return date;
    }
    return [
      padTo2Digits(r_datetime.getHours()),
      padTo2Digits(r_datetime.getMinutes()),
    ].join(":");
  }
}

export function hasDST(date = new Date()) {
  const today = moment(date);
  // const januaryDate = moment(new Date(date.getFullYear(), 0, 1));
  const julyDate = moment(new Date(date.getFullYear(), 6, 1));
  // januaryDate.tz("Europe/London");
  julyDate.tz("Europe/London");
  today.tz("Europe/London");
  return julyDate._offset === today._offset;
}

export const defaultTimezone = hasDST(new Date())
  ? {
      value: "Europe/London",
      label: "(GMT+1:00) Edinburgh, London",
      offset: 1,
      abbrev: "BST",
      altName: "British Summer Time",
    }
  : {
      value: "Etc/GMT",
      label: "(GMT+0:00) UTC",
      offset: 0,
      abbrev: "GMT",
      altName: "ETC/GMT",
    };

export const getTimeToShowOnPickup = () => {
  const leadHours = 2;
  const date = new Date();
  let m2 = moment(
    new Date(date.setTime(date.getTime() + leadHours * 60 * 60 * 1000))
  );
  // set the time zone of the new object
  m2.tz("Europe/London");
  return m2.format("HH:mm");
};

export const setMinTimeOnPickup = (dt) => {
  const todayDate = moment(new Date()).format("YYYY-MM-DD");

  if (dt === todayDate) {
    return getTimeToShowOnPickup();
  } else {
    return "05:00";
  }
};
