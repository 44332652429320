// import React from 'react'
// import { Box} from '@mui/system';
// import arrowRight from '../../assets/images/arrowRight.png'

// const Information = () => {
//     const divStyling = {
//         marginBottom: '-20px',
//         marginLeft: '40px',
//         color: '#050092',
//     }
//     return (
//         <div>
//             <Box
//                 sx={{
//                     width: '87%',
//                     height: 550,
//                     bgcolor:'white',
//                     fontSize: '36px',
//                     marginLeft: '100px',
//                     borderRadius: '10px',
//                     marginTop: '30px',
//                     display: 'flex', // Make the main box a flex container
//                 }}
//             >
//                 <Box
//                     sx={{
//                         flex: 1, // Take up 1/3 of the available space
//                         padding: '16px',
//                         borderRight: '1px solid #ccc', // Add a border to separate columns
//                     }}
//                 >
//                     <div style={{ ...divStyling, marginTop: '20px' }}>
//                         <span style={{ fontSize: '40px' }}>What can we do to make </span>
//                     </div>
//                     <div style={divStyling}>
//                         <span style={{ fontSize: '40px' }}>your work easier ?</span>
//                     </div>
//                      <img
//                         src={arrowRight}
//                         style={{ width: '50px', marginTop: '100px', marginLeft: '20px' }}
//                     />
//                     <p style={{
//                         fontSize: '22px', marginTop: '50px', marginLeft: '40px', color: 'GrayText',
//                         fontFamily: 'initial'
//                     }}>
                        
//                         Lorem ipsum dolor sit amet, consectetur adipiscing elit,
//                         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//                     </p> 
//                 </Box> 
//                 <Box
//                     sx={{
//                         flex: 1, // Take up 1/3 of the available space
//                         padding: '16px',
//                         // borderRight: '1px solid #ccc', // Add a border to separate columns
//                     }}
//                 >

//                 </Box>
//             </Box>
//         </div>
//     )
// }

// export default Information

import React from 'react';
import { Box } from '@mui/system';
import arrowRight from '../../assets/images/arrowRight.png';

const Information = () => {
    const divStyling = {
        marginBottom: '-20px',
        marginLeft: '40px',
        color: '#050092',
    };

    const columnStyling = {
        flex: 1, // Take up 1/2 of the available space (adjust as needed)
        padding: '16px',
    };

    const contentRowStyling = {
        display: 'flex',
        alignItems: 'center',
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center', // Vertically center the content within the flex container
                }}
            >
               <Box
                    sx={{
                        flex: 1, // Take up 1/3 of the available space
                        padding: '16px',
                        borderRight: '1px solid #ccc', // Add a border to separate columns
                    }}
                >
                    <div style={{ ...divStyling, marginTop: '20px' }}>
                        <span style={{ fontSize: '40px' }}>What can we do to make </span>
                    </div>
                    <div style={divStyling}>
                        <span style={{ fontSize: '40px' }}>your work easier ?</span>
                    </div>
                <Box sx={columnStyling}>
                    <div style={contentRowStyling}>
                        <img
                            src={arrowRight}
                            style={{ width: '50px', marginTop: '0', marginLeft: '20px' }}
                        />
                        <p style={{ fontSize: '22px', marginTop: '0', marginLeft: '20px', color: 'GrayText', fontFamily: 'initial' }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                    </div>
                </Box>
                <Box
                    sx={{
                        flex: 1, // Take up 1/3 of the available space
                        padding: '16px',
                        // borderRight: '1px solid #ccc', // Add a border to separate columns
                    }}
                >

                </Box>
            </Box>
            </Box> 
        </div>
    );
};

export default Information;

