import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  setCostingBasedOn,
  updateDetail,
  updateSummary,
} from "../../../slices/calculatorSlice";
import { useDispatch } from "react-redux";

const SummaryOption = () => {
  const { costing_based_on, details } = useSelector(
    (state) => state.calculatorData
  );
  const dispatch = useDispatch();
  const handleChange = (event) => {
    let costing_based_dividing_factor = 1;
    if (event.target.value === "per_pack") {
      const packs = details?.all?.number_of_packs || 1;
      costing_based_dividing_factor = packs;
    } else {
      costing_based_dividing_factor = 1;
    }
    dispatch(setCostingBasedOn(event.target.value));
    dispatch(
      updateDetail({
        changedColumnKey: "costing_based_dividing_factor",
        changedColumnValue: costing_based_dividing_factor,
      })
    );
  };

  return (
    <React.Fragment>
      {costing_based_on && (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Costing Based On
            </InputLabel>
            <Select
              style={{ marginTop: "8px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={costing_based_on}
              // label="Age"
              onChange={handleChange}
              size="small"
            >
              <MenuItem value={"per_pack"}>Per Pack</MenuItem>
              <MenuItem value={"total"}>Total</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}
    </React.Fragment>
  );
};

const SummaryComponent = (props) => {
  const { minHeight } = props;
  const { summary } = useSelector((state) => state.calculatorData);
  const [groupedSummary, setGroupedSummary] = useState(null);

  useEffect(() => {
    // Initialize the groupedSummary object with empty arrays for levels 1 to 2
    let newGroupedSummary = {};
    if (summary) {
      for (let i = 1; i <= 2; i++) {
        newGroupedSummary[i] = [];
      }

      // Populate the groupedSummary object based on summary_level
      summary.forEach((item) => {
        const level = item.summary_level || 1; // Use 1 as the default level
        newGroupedSummary[level].push(item);
      });
      setGroupedSummary(newGroupedSummary);
    }
  }, [summary]);

  const isLargeScreen = useMediaQuery("(max-width:1200px)");

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Card
        style={{
          minHeight: isLargeScreen ? null : `${minHeight - 25}px`,
          display: "flex",
          flexDirection: "column",
        }}
        sx={
          !isLargeScreen
            ? {
                position: "fixed",
                // top: "50px",
                maxHeight: "80vh",
                width: "20%",
                overflowY: "auto",
                zIndex: 2,
              }
            : undefined
        }
      >
        {summary && (
          <CardContent>
            <Grid container spacing={2} sx={{ margin: "2px" }}>
              <Grid item xs={12}>
                <Typography  variant="h4" component="div">
                  Summary
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SummaryOption />
              </Grid>
            </Grid>

            {/* Render cards for each level */}
            {Array.from({ length: 2 }, (_, index) => (
              <Card
                key={`level-card-${index}`}
                style={{ marginBottom: "10px", marginTop: "5px" }}
              >
                <CardContent>
                  <TableContainer>
                    <Table size="small">
                      <TableBody>
                        {groupedSummary &&
                          groupedSummary[index + 1].map((item, itemIndex) => (
                            <RenderTableRow
                              item={item}
                              key={`summary_item_${itemIndex}`}
                              itemIndex={itemIndex}
                            />
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        )}
      </Card>
    </Grid>
  );
};

const RenderTableRow = (props) => {
  const { item, itemIndex } = props;
  const [itemValue, setItemValue] = useState(item.value);
  const [lastValue, setLastValue] = useState(item.value);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setItemValue(item.value);
  }, [item]);
  // Define a function to handle editing of the cell's value
  const handleCellValueChange = (event) => {
    const newValue = event.target.value;
    setItemValue(newValue);
    setLastValue(itemValue);
    if (newValue !== "") {
      dispatch(
        updateSummary({
          changedColumnKey: item.name,
          changedColumnValue: newValue,
        })
      );
    }
    // You can update the state or dispatch an action to update the value in the Redux store here
    // For example, dispatch an action to update the summary item's value.
    // This depends on your Redux setup.
  };

  // Define a function to handle onBlur event
  const handleCellBlur = () => {
    setIsEditing(false);
    if (itemValue === "") {
      setItemValue(lastValue);
    }
  };

  // Define a function to handle onClick event (when clicking the cell)
  const handleCellClick = () => {
    if (item.editable) {
      setIsEditing(true);
    }
  };

  return (
    <TableRow
      key={`summary-item-${itemIndex}`}
      sx={{
        backgroundColor: item.to_highlight ? "#E4FA97" : "white",
      }}
    >
      <TableCell
        sx={{
          fontWeight: item.to_highlight ? "bold" : null,
        }}
      >
        {item.label}{" "}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: item.to_highlight ? "bold" : null,
          // marginRight: !item.editable ? "20px" : null,
        }}
        onClick={handleCellClick}
        align="right"
      >
        {item.editable && isEditing ? (
          // Render a TextField for editable cells with onBlur event handler
          <TextField
            value={itemValue}
            onChange={(event) => handleCellValueChange(event)}
            type={item.type}
            onBlur={handleCellBlur} // Add onBlur event handler
            autoFocus // Auto-focus on the input when editing starts
            sx={{
              width: "100px", // Adjust the width to your desired size
            }}
          />
        ) : (
          // Render a non-editable cell
          <div>
            {itemValue}
            {item.editable && !isEditing && (
              <IconButton sx={{ fontSize: "10px" }}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            )}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default SummaryComponent;
