import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spinner } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCalendarCheck,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
// import { ProjectData } from "../../data/ajsData";
// import workcenter from "../../assets/img/pages/workcenter.png";
import all_projects from "../../assets/img/pages/all_projects.jpg";
import { PlotMachines } from "./plots/PlotProjects";
import PlotSchedule from "./plots/PlotSchedule";
import "./AJS.css";
import { axiosInstance } from "../../axiosSetup";

const AssemblyDemo = () => {
  const allProjectsData = [
    { id: 1, color: "success", label: "Completed" },
    { id: 2, color: "warning", label: "In Assembly" },
    { id: 3, color: "mysecondary", label: "Not Started" },
  ];
  // const workcenterData = [{ id: 1, color: "info", label: "Scheduled" }];

  return (
    <div className="page-container">
      <Card border="light" className="shadow-sm mt-2">
        <Card.Header bg="white">
          <Row className="align-items-center">
            <Col>
              <h5>Assembly Demo</h5>
            </Col>
          </Row>
        </Card.Header>
      </Card>
      {/* <Card border="light" bg="light" className="shadow-sm"> */}
      <Row className="my-2">
        <Col xl={6}>
          <Row>
            <Col md={6}>
              <DataCard
                category="WC Utilization"
                title="84%"
                period="July 2021"
                percentage={28.4}
                icon={faChartLine}
                iconColor="shape-tertiary"
              />
            </Col>
            <Col md={6}>
              <DataCard
                category="SO July 2021"
                title="5"
                period="to be completed"
                percentage={15}
                icon={faCalendarCheck}
                iconColor="shape-tertiary"
              />
            </Col>
          </Row>
          <Row className="my-2">
            <Col xl={12}>
              <Chart
                data={allProjectsData}
                title="Projects"
                count="10"
                image={all_projects}
              />
            </Col>
          </Row>
        </Col>

        <Col xl={6}>
          <Row>
            <Col xl={12}>
              {/* <Chart
                data={workcenterData}
                title="Workcenters"
                count="10"
                image={workcenter}
              /> */}
              <PlotMachines />
            </Col>
          </Row>

          <Row className="my-2">
            <Col xl={12}>
              <ProjectFigure />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const Chart = (props) => {
  const { data, title, count, image } = props;

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
        <div className="d-block">
          <h6 className="fw-normal text-gray mb-2">{title}</h6>
          <h3>{count}</h3>
        </div>
        <div className="d-block ms-auto">
          {data.map((d) => (
            <div
              key={`bar-element-${d.id}`}
              className="d-flex align-items-center text-end mb-2"
            >
              <span className={`shape-xs rounded-circle bg-${d.color} me-2`} />
              <small className="fw-normal">{d.label}</small>
            </div>
          ))}
        </div>
      </Card.Body>
      <Card.Body className="p-2">
        <Card.Img src={image} />
      </Card.Body>
    </Card>
  );
};

const ProjectFigure = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [projectSchedule, setProjectSchedule] = useState();

  useEffect(() => {
    axiosInstance
      .get("ajs/projects/", {})
      .then((res) => {
        // console.log(res);
        const data = res.data;
        // console.log(data);
        if (data[0] !== undefined) {
          let tempProjects = [];
          data.forEach((project) => {
            if (project.parts[0] !== undefined) {
              tempProjects.unshift(project);
            }
          });
          setProjectSchedule(tempProjects);
          setLoaded(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-2">
        {loaded && (
          <>
            <Spinner animation="grow" variant="info" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="warning" />
          </>
        )}
        {!loaded && projectSchedule && (
          <PlotSchedule plotData={projectSchedule} />
        )}
      </Card.Body>
    </Card>
  );
};

const DataCard = (props) => {
  const { icon, iconColor, category, title, period, percentage } = props;
  const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
  const percentageColor = percentage < 0 ? "text-danger" : "text-success";
  return (
    <Card border="light" className="shadow-sm">
      <Card.Body>
        <Row className="d-block d-xl-flex align-items-center">
          <Col
            xl={4}
            className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0"
          >
            <div
              className={`icon icon-shape icon-md icon-${iconColor} rounded me-4 me-sm-0`}
            >
              <FontAwesomeIcon icon={icon} />
            </div>
            <div className="d-sm-none">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
          </Col>
          <Col xs={12} xl={8} className="px-xl-0">
            <div className="d-none d-sm-block">
              <h5>{category}</h5>
              <h3 className="mb-1">{title}</h3>
            </div>
            <small>{period}</small>
            <div className="small mt-2">
              <FontAwesomeIcon
                icon={percentageIcon}
                className={`${percentageColor} me-1`}
              />
              <span className={`${percentageColor} fw-bold`}>
                {percentage}%
              </span>{" "}
              Since last month
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export { AssemblyDemo, Chart, DataCard, ProjectFigure };
