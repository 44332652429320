import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faBuilding,
  faComputer,
  faEnvelope,
  faMobile,
  faPhone,
  faUnlockAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Alert,
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosSetup";

import { URLs } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { NewRegisterAPI, NewRegisterAPIerp } from "../../api/auth";
import { CircularProgress } from "@mui/material";

const Signup = () => {
  const navigate = useNavigate();
  const [formData, updateFormData] = useState({});
  const [companyEmail, setCompanyEmail] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValidate, setPasswordValidate] = useState(true);
  const [progressFlag, setProgressFlag] = useState(false);
  const [userMailExists, setUserMailExists] = useState({
    flag: false,
    msg1: "message",
    msg2: "message",
  });
  const [showMsg2, setShowMsg2] = useState({
    flag: false,
    msg1: "message",
    msg2: "message",
    variant: "success",
  });
  const [snackbarD, setSnackbarD] = useState({
    flag: false,
    msg: "",
    color: "",
  });
  useEffect(() => {
    if (userMailExists.flag === true) {
      const timer = setTimeout(() => {
        setUserMailExists({
          flag: false,
          msg1: "message",
          msg2: "message",
        });
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [userMailExists]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSnackbarD({
        flag: false,
        msg: "",
        color: "",
      });
    }, 4000);

    return () => clearTimeout(timeout);
  }, [snackbarD]);
  const isStrongPassword = (password) => {
    return password.length >= 8;
  };

  const handleChange = (e) => {
    if (e.target.name === "confirm_password") {
      if (e.target.value !== formData.password) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    }
    if (
      e.target.name === "password" &&
      formData.confirm_password !== undefined
    ) {
      if (e.target.value !== formData.confirm_password) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    }

    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/\D/g, ""); 
    updateFormData({
      ...formData,
      phone: numericInput,
    });
    // console.log(input);
  };
  const handleEmailChange = (e) => {
    const lowercaseEmail = e.target.value.toLowerCase();
    setCompanyEmail(lowercaseEmail);
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: lowercaseEmail,
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);

    const isValidEmail = validateEmail(formData.email);
    if (isValidEmail) {
      if (passwordMatch) {
        // console.log(isStrongPassword(formData.password));
        if (isStrongPassword(formData.password)) {
          makeApiCall();
          setProgressFlag(true);
        } else {
          setPasswordValidate(false);
        }
      } else {
        console.log("password do not match");
      }
    } else {
      console.log("Invalid email:");
      setSnackbarD({
        flag: true,
        msg: "Error: Incorrect Email Format !!!",
        color: "#dc3545",
      });
    }
  };

  const makeApiCall = async () => {
    try {
      const res = await NewRegisterAPIerp(formData);
      // console.log(res);

      if (res.status === 201) {
        setShowMsg2({
          flag: true,
          msg1: `Welcome ${res.data.full_name},`,
          msg2: `Your Registration is Successful.`,
          variant: "success",
        });
        setProgressFlag(false);
        // console.log(res.data);
      }
      setProgressFlag(false);
    } catch (error) {
      console.log(error);
      console.log(error.response.status);
      
      setProgressFlag(false);
      if (error.response.status === 400) {
        setUserMailExists({
          flag: true,
          msg1: "Error",
          msg2: error.response.data.message,
        });
        setProgressFlag(false);
      }
      if (error.response.status === 409) {
        setUserMailExists({
          flag: true,
          msg1: "User Already Exists!",
          msg2: "Use different credentials",
        });
        setProgressFlag(false);
      }
    }
  };
  const handlePolicyclick = () => {
    navigate(URLs.privacyPolicyURL.path);
  };
  return (
    <main>
      <section className="d-flex align-items-center my-5">
        <Container>
          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${BgImage})` }}
          >
            {showMsg2 && showMsg2.flag === true && (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 "
                  style={{ width: "800px" }}
                >
                  <Alert variant={showMsg2.variant}>
                    <div>{showMsg2.msg1}</div>
                    <div>{showMsg2.msg2}</div>
                  </Alert>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Redirect to
                      <Card.Link
                        as={Link}
                        to={URLs.Signin.path}
                        className="fw-bold"
                      >
                        {` Login `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            )}

            {showMsg2 && showMsg2.flag === false && (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 "
                  style={{ width: "800px" }}
                >
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Create New Account</h3>
                  </div>

                  <Form className="mt-4" onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <Form.Group id="full_name" className="mb-4">
                          <Form.Label>Your Name</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUser} />
                            </InputGroup.Text>
                            <Form.Control
                              // autoFocus
                              required
                              type="text"
                              name="full_name"
                              placeholder="Your Name"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group id="company_name" className="mb-4">
                          <Form.Label>Company Name</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faBuilding} />
                            </InputGroup.Text>
                            <Form.Control
                              // autoFocus
                              required
                              type="text"
                              name="company_name"
                              placeholder="Company Name"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      {/* <Col xs={12}>
                        <Form.Group id="company_domain" className="mb-4">
                          <Form.Label>Company Domain</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faComputer} />
                            </InputGroup.Text>
                            <Form.Control
                              autoFocus
                              required
                              type="text"
                              name="company_domain"
                              placeholder="Enter company domain (e.g., mannlowe.com)"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col> */}
                      <Col lg={6}>
                        <Form.Group id="phone" className="mb-4">
                          <Form.Label>Phone</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faPhone} />
                            </InputGroup.Text>
                            <Form.Control
                              // autoFocus
                              required
                              type="text"
                              name="phone"
                              placeholder="phone..."
                              onKeyDown={(e) => {
                                // Allow numeric keys, backspace, delete, arrow keys, home, and end keys
                                if (!/[\d\b]/.test(e.key) && !['ArrowLeft', 'ArrowRight', 'Home', 'End', 'Delete', 'Backspace'].includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={handlePhoneChange}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group id="email" className="mb-4">
                          <Form.Label>Company Email</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                            <Form.Control
                              // autoFocus
                              required
                              type="email"
                              name="email"
                              placeholder="example@company.com"
                              onChange={handleEmailChange}
                              value={companyEmail}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col lg={6}>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>New Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="password"
                              name="password"
                              placeholder="Password"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group id="confirm_password" className="mb-4">
                          <Form.Label>Confirm Password</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <Form.Control
                              required
                              type="password"
                              name="confirm_password"
                              placeholder="Confirm Password"
                              onChange={handleChange}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <FormCheck type="checkbox" className="d-flex mb-4">
                      <FormCheck.Input required id="terms" className="me-2" />
                      <FormCheck.Label htmlFor="terms">
                        I agree to the{" "}
                        <Card.Link onClick={handlePolicyclick}>
                          Privacy Policy
                        </Card.Link>
                      </FormCheck.Label>
                    </FormCheck>

                    {!passwordMatch && (
                      <Alert
                        variant="danger"
                        // onClose={() => setShowAlert(false)}
                        // dismissible
                      >
                        Password does not match!
                      </Alert>
                    )}

                    {userMailExists && userMailExists.flag === true && (
                      <Alert variant="danger">
                        <div>{userMailExists.msg1}</div>
                        <div>{userMailExists.msg2}</div>
                      </Alert>
                    )}
                    {snackbarD && snackbarD.flag === true && (
                      <Alert variant="danger">
                        <div>Incorrect EmailID Format !</div>
                      </Alert>
                    )}
                    {passwordValidate === false && (
                      <Alert variant="secondary">
                        Password should contain minimun 8 characters with Upper
                        & Lower case, Numeric & Special characters.
                      </Alert>
                    )}

                    <Button variant="primary" type="submit" className="w-100">
                      <div> Sign up </div>
                      {progressFlag && <CircularProgress />}
                    </Button>
                  </Form>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      Already have an account?
                      <Card.Link
                        as={Link}
                        to={URLs.Signin.path}
                        className="fw-bold"
                      >
                        {` Login here `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Signup;
