import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    contact_type: "",
    contact_status: "",
  },
  activePage: 1,
};

const visitingCardDataSlice = createSlice({
  name: "calculatorData",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filters = action.payload;
    },
    setActivePage: (state, action) => {
      state.activePage = action.payload;
    },
    clearVCState: (state, action) => {
      state.filters = {
        contact_type: "",
        contact_status: "",
      };
      state.activePage = 1;
    },
  },
});

export const { setFilter, setActivePage, clearVCState } =
  visitingCardDataSlice.actions;
//when you call it they are actions
export default visitingCardDataSlice.reducer;
