import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDetails,
  getHeadData,
  getHeadersToShow,
  getRows,
  getRowsToShow,
  getSummary,
  getTableFooterData,
  getTableHeaders,
  getAllEditableDetails,
  formatExportItems,
  getKeyValue,
  getDetailsCalculated,
  getPMTableHeaders,
  updatePMRowsData,
  updateAllPMRows,
  getPMTableFooterData,
} from "../pages/calculator/utilities/tableProcessing";

const initialState = {
  selectedItem: null,
  selectedSecondaryItem: null,
  primaryKeys: null,
  primaryLabel: null,
  head: null,
  details: {
    all: null,
    editable: null,
  },
  reference_types: null,
  selected_reference_type: null,
  reference: null,
  prospect: null,
  colInfo: null,
  editableColumns: {
    all: null,
    formatted: null,
  },
  tableHeaders: { all: null, toShow: null },
  rows: { all: null, toShow: null },
  tableFooter: null,
  pmHeaders: null,
  pmRows: [],
  pmTableFooter: null,
  costing_based_on: "total",
  summary: null,
  exportState: null,
  cost_calculation_name: null,
  rows_export: null,
  head_export: null,
  table_footer_export: null,
  pm_rows_export: null,
  pm_table_footer_export: null,
  summary_export: null,
};

const calculatorDataSlice = createSlice({
  name: "calculatorData",
  initialState,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload.selectedItem;
      state.selectedSecondaryItem = action.payload.selectedSecondaryItem;
      if (action.payload.selectedItem === null) {
        state.selectedSecondaryItem = null;
        state.colInfo = null;
        state.primaryKeys = null;
        state.primaryLabel = null;
        state.editableColumns = {
          all: null,
          formatted: null,
        };
        state.details = {
          all: null,
          editable: null,
        };
        state.head = null;
        state.reference_types = null;
        state.selected_reference_type = null;
        state.reference = null;
        state.prospect = null;
        state.tableHeaders = { all: null, toShow: null };
        state.rows = { all: null, toShow: null };
        state.tableFooter = null;
        state.pmHeaders = null;
        state.pmRows = [];
        state.pmTableFooter = null;
        state.costing_based_on = "total";
        state.summary = null;
        state.exportState = null;
        state.cost_calculation_name = null;
        state.head_export = null;
        state.rows_export = null;
        state.table_footer_export = null;
        state.pm_rows_export = null;
        state.pm_table_footer_export = null;
        state.summary_export = null;
      }
    },
    setSelectedSecondaryItem: (state, action) => {
      state.selectedSecondaryItem = action.payload;
      if (action.payload === null) {
        state.primaryKeys = null;
        state.primaryLabel = null;
        state.details = {
          all: null,
          editable: null,
        };
        state.head = null;
        state.reference_types = null;
        state.selected_reference_type = null;
        state.reference = null;
        state.prospect = null;
        state.colInfo = null;
        state.editableColumns = {
          all: null,
          formatted: null,
        };
        state.tableHeaders = { all: null, toShow: null };
        state.rows = { all: null, toShow: null };
        state.tableFooter = null;
        state.pmHeaders = null;
        state.pmRows = [];
        state.pmTableFooter = null;
        state.costing_based_on = "total";
        state.summary = null;
        state.exportState = null;
        state.cost_calculation_name = null;
        state.rows_export = null;
        state.head_export = null;
        state.table_footer_export = null;
        state.pm_rows_export = null;
        state.pm_table_footer_export = null;
        state.summary_export = null;
      }
    },
    setSelectedReferenceType: (state, action) => {
      state.selected_reference_type = action.payload;
    },
    setSelectedReference: (state, action) => {
      state.reference = action.payload;
    },
    setSelectedProspect: (state, action) => {
      state.prospect = action.payload;
    },
    setInitialCalculatorData: (state, action) => {
      const primaryLabel = action.payload.metadata.primary_label;
      const primaryKeys = action.payload.metadata.primary_keys;
      const tempColInfo = action.payload.metadata.columns;
      const tempTableHeaders = getTableHeaders(tempColInfo);
      const tempPMTableHeaders = getPMTableHeaders(tempColInfo);
      const updatedDetails = getDetailsCalculated(
        tempColInfo,
        action.payload.details,
        null,
        true
      );
      const tempDetails = getKeyValue(updatedDetails);
      const tempRows = getRows(
        action.payload.items,
        tempTableHeaders,
        tempColInfo,
        tempDetails,
        primaryKeys
      );
      const tempHeadersToShow = getHeadersToShow(tempTableHeaders, tempColInfo);
      const tempRowsToShow = getRowsToShow(tempRows, tempHeadersToShow);
      const tempHead = getHeadData(tempDetails, tempColInfo);

      const tempTableFooterData = getTableFooterData(
        tempRows,
        tempColInfo,
        tempDetails
      );
      const tempSummary = getSummary(
        tempColInfo,
        tempDetails,
        tempTableFooterData
      );
      const tempAllDetails = getAllDetails(
        updatedDetails,
        tempHead,
        tempTableFooterData,
        tempSummary
      );
      const tempAllEditableDetails = getAllEditableDetails([
        ...updatedDetails,
        ...tempHead,
        ...tempTableFooterData,
        ...tempSummary,
      ]);
      state.colInfo = tempColInfo;
      state.primaryLabel = primaryLabel;
      state.primaryKeys = primaryKeys;
      state.tableHeaders = {
        all: tempTableHeaders,
        toShow: tempHeadersToShow,
      };
      state.rows = {
        all: tempRows,
        toShow: tempRowsToShow,
      };
      state.details = {
        all: tempAllDetails,
        editable: tempAllEditableDetails,
      };
      state.head = tempHead;
      state.tableFooter = tempTableFooterData;
      state.costing_based_on = "total";
      state.summary = tempSummary;
      state.reference_types = action.payload.metadata.reference_types
        ? action.payload.metadata.reference_types
        : null;
      state.rows_export = action.payload.metadata.rows_export;
      state.head_export = action.payload.metadata.head_export;
      state.table_footer_export = action.payload.metadata.table_footer_export;
      state.pm_rows_export = action.payload.metadata.pm_rows_export;
      state.pm_table_footer_export =
        action.payload.metadata.pm_table_footer_export;
      state.summary_export = action.payload.metadata.summary_export;
      state.pmHeaders = tempPMTableHeaders;
    },
    setDataFromImport: (state, action) => {
      const primaryLabel = action.payload.metadata.primary_label;
      const primaryKeys = action.payload.metadata.primary_keys;
      const tempColInfo = action.payload.metadata.columns;
      const tempTableHeaders = getTableHeaders(tempColInfo);
      const tempPMTableHeaders = getPMTableHeaders(tempColInfo);
      const updatedDetails = getDetailsCalculated(
        tempColInfo,
        action.payload.details,
        null,
        true
      );
      const tempDetails = getKeyValue(updatedDetails);
      const inputSummary = action.payload.summary
        ? getKeyValue(action.payload.summary)
        : {};
      const tempRows = getRows(
        action.payload.items,
        tempTableHeaders,
        tempColInfo,
        tempDetails,
        primaryKeys
      );

      // );
      const tempHeadersToShow = getHeadersToShow(tempTableHeaders, tempColInfo);
      const tempRowsToShow = getRowsToShow(tempRows, tempHeadersToShow);
      const tempHead = getHeadData(tempDetails, tempColInfo);

      const tempTableFooterData = getTableFooterData(
        tempRows,
        tempColInfo,
        tempDetails
      );
      const tempPMData = updateAllPMRows(
        action.payload.pm_items,
        tempDetails,
        tempColInfo
      );
      const tempPMTableFooterData = getPMTableFooterData(
        tempColInfo,
        tempPMData,
        tempDetails
      );
      const tempSummary = getSummary(
        tempColInfo,
        { ...tempDetails, ...inputSummary },
        [...tempTableFooterData, ...tempPMTableFooterData]
      );
      const tempAllDetails = getAllDetails(
        updatedDetails,
        tempHead,
        [...tempTableFooterData, ...tempPMTableFooterData],
        tempSummary
      );
      const tempAllEditableDetails = getAllEditableDetails([
        ...updatedDetails,
        ...tempHead,
        ...tempTableFooterData,
        ...tempPMTableFooterData,
        ...tempSummary,
      ]);
      state.costing_based_on = action.payload.costing_based_on;
      state.colInfo = tempColInfo;
      state.primaryLabel = primaryLabel;
      state.primaryKeys = primaryKeys;
      state.tableHeaders = {
        all: tempTableHeaders,
        toShow: tempHeadersToShow,
      };
      state.rows = {
        all: tempRows,
        toShow: tempRowsToShow,
      };
      state.details = {
        all: tempAllDetails,
        editable: tempAllEditableDetails,
      };
      state.head = tempHead;
      state.tableFooter = tempTableFooterData;
      state.pmHeaders = tempPMTableHeaders;
      state.pmRows = tempPMData;
      state.pmTableFooter = tempPMTableFooterData;
      state.summary = tempSummary;
      state.cost_calculation_name = action.payload.cost_calculation_name
        ? action.payload.cost_calculation_name
        : null;
      state.selectedItem = action.payload.selectedItem
        ? action.payload.selectedItem
        : state.selectedItem;
      state.selectedSecondaryItem = action.payload.selectedSecondaryItem
        ? action.payload.selectedSecondaryItem
        : state.selectedSecondaryItem;
      state.prospect = action.payload.prospect
        ? action.payload.prospect
        : state.prospect;
      state.reference = action.payload.reference
        ? action.payload.reference
        : state.reference;
      state.selected_reference_type = action.payload.selected_reference_type
        ? action.payload.selected_reference_type
        : state.selected_reference_type;
      state.reference_types = action.payload.metadata.reference_types
        ? action.payload.metadata.reference_types
        : null;
      state.rows_export = action.payload.metadata.rows_export;
      state.head_export = action.payload.metadata.head_export;
      state.table_footer_export = action.payload.metadata.table_footer_export;
      state.pm_rows_export = action.payload.metadata.pm_rows_export;
      state.pm_table_footer_export =
        action.payload.metadata.pm_table_footer_export;
      state.summary_export = action.payload.metadata.summary_export;
    },
    updateRow: (state, action) => {
      const primaryKey = action.payload.primaryKey;
      const primaryKeyValue = action.payload.primaryKeyValue;
      const changedColumnKey = action.payload.changedColumnKey;
      const changedColumnValue = action.payload.changedColumnValue;
      let allRows = [...state.rows.all];
      const index = allRows.findIndex(
        (item) => item[primaryKey] === primaryKeyValue
      );
      if (index >= 0 && index < allRows.length) {
        allRows[index] = {
          ...allRows[index], // Clone the object at the specified index
          [changedColumnKey]: changedColumnValue, // Update the specific parameter with the new value
        };
      }
      const calculatedRow = getRows(
        [allRows[index]],
        state.tableHeaders.all,
        state.colInfo,
        state.details.all,
        state.primaryKeys
      );
      allRows = allRows.map((item, currentIndex) => {
        if (currentIndex === index) {
          // Replace the object at the specified index with the new object
          return { ...calculatedRow[0] };
        } else {
          // Keep the rest of the objects unchanged
          return item;
        }
      });
      const rowsToShow = getRowsToShow(allRows, state.tableHeaders.toShow);
      const tempTableFooterData = getTableFooterData(
        allRows,
        state.colInfo,
        state.details.all
      );
      const tempAllDetails = {
        ...state.details.all,
        ...getKeyValue(state.summary),
      };
      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...tempTableFooterData,
        ...(state.pmTableFooter ? [...state.pmTableFooter] : []),
      ]);
      state.rows = { all: allRows, toShow: rowsToShow };
      state.tableFooter = tempTableFooterData;
      state.summary = tempSummary;
    },
    updateDetail: (state, action) => {
      const changedColumnKey = action.payload.changedColumnKey;
      const changedColumnValue = action.payload.changedColumnValue;
      const updatedDetails = getDetailsCalculated(
        state.colInfo,
        {
          ...state.details.all,
          [changedColumnKey]: changedColumnValue,
        },
        changedColumnKey
      );
      const tempAllDetails = {
        ...getKeyValue(updatedDetails),
        ...getKeyValue(state.summary),
        [changedColumnKey]: changedColumnValue,
      };
      const tempRows = getRows(
        state.rows.all,
        state.tableHeaders.all,
        state.colInfo,
        tempAllDetails,
        state.primaryKeys
      );
      const tempPMData = updateAllPMRows(
        state.pmRows,
        tempAllDetails,
        state.colInfo
      );

      const tempHead = getHeadData(tempAllDetails, state.colInfo);

      const rowsToShow = getRowsToShow(tempRows, state.tableHeaders.toShow);
      const tempTableFooterData = getTableFooterData(
        tempRows,
        state.colInfo,
        tempAllDetails
      );
      const tempPMTableFooterData = getPMTableFooterData(
        state.colInfo,
        tempPMData,
        tempAllDetails
      );
      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...tempTableFooterData,
        ...tempPMTableFooterData,
      ]);

      state.details = {
        all: tempAllDetails,
        editable: state.details.editable,
      };
      state.rows = { all: tempRows, toShow: rowsToShow };
      state.pmRows = tempPMData;
      state.pmTableFooter = tempPMTableFooterData;
      state.head = tempHead;
      state.tableFooter = tempTableFooterData;
      state.summary = tempSummary;
    },
    updateSummary: (state, action) => {
      const changedColumnKey = action.payload.changedColumnKey;
      const changedColumnValue = action.payload.changedColumnValue;
      const tempAllDetails = {
        ...state.details.all,
        ...getKeyValue(state.summary),
        [changedColumnKey]: changedColumnValue,
      };

      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...state.tableFooter,
        ...(state.pmTableFooter ? [...state.pmTableFooter] : []),
      ]);
      state.summary = tempSummary;
    },
    setExportState: (state, action) => {
      const summaryData = formatExportItems(
        state.summary_export,
        state.summary
      );
      const exportedData = {
        // Extract and store the state properties you want to export
        // For example:
        cost_calculation_name: state.cost_calculation_name,
        calculatorData: {
          selectedItem: state.selectedItem,
          selectedSecondaryItem: state.selectedSecondaryItem,
          rows: formatExportItems(state.rows_export, state.rows.all),
          head: formatExportItems(state.head_export, state.head),
          tableFooter: formatExportItems(
            state.table_footer_export,
            state.tableFooter
          ),
          pmRows: formatExportItems(state.pm_rows_export, state.pmRows),
          pmTableFooter: formatExportItems(
            state.pm_table_footer_export,
            state.pmTableFooter
          ),
          summary: summaryData,
          details: { ...state.details.all, ...getKeyValue(summaryData) },
          prospect: state.prospect,
          reference: state.reference,
          selected_reference_type: state.selected_reference_type,
        },
      };

      // Dispatch an action to store the JSON data in the Redux state
      state.exportState = exportedData;
    },
    clearExportState: (state, action) => {
      state.exportState = null;
    },
    setCalculationName: (state, action) => {
      state.cost_calculation_name = action.payload;
    },
    addPMRow: (state, action) => {
      state.pmRows = [...state.pmRows, action.payload];
    },
    addRow: (state, action) => {
      const tempRows = [...state.rows.all, action.payload];
      const tempRowsToShow = getRowsToShow(tempRows, state.tableHeaders.toShow);
      state.rows = { all: tempRows, toShow: tempRowsToShow };
    },
    deleteRows: (state, action) => {
      const tempRows = state.rows.all.filter(
        (row) => row[action.payload.key] !== action.payload.value
      );
      const tempRowsToShow = getRowsToShow(tempRows, state.tableHeaders.toShow);
      const tempTableFooterData = getTableFooterData(
        tempRows,
        state.colInfo,
        state.details.all
      );
      const tempAllDetails = {
        ...state.details.all,
        ...getKeyValue(state.summary),
      };
      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...tempTableFooterData,
        ...(state.pmTableFooter ? [...state.pmTableFooter] : []),
      ]);
      state.rows = { all: tempRows, toShow: tempRowsToShow };
      state.tableFooter = tempTableFooterData;
      state.summary = tempSummary;
    },
    updateCompletePMRow: (state, action) => {
      const tempPMData = updatePMRowsData(
        action.payload.field,
        state.pmRows,
        action.payload.updatedRow,
        state.details.all,
        state.colInfo
      );

      const tempPMTableFooterData = getPMTableFooterData(
        state.colInfo,
        tempPMData,
        state.details.all
      );
      const tempAllDetails = {
        ...state.details.all,
        ...getKeyValue(state.summary),
      };
      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...state.tableFooter,
        ...tempPMTableFooterData,
      ]);
      state.pmRows = tempPMData;
      state.summary = tempSummary;
      state.pmTableFooter = tempPMTableFooterData;
    },
    updatePMRow: (state, action) => {
      const primaryKey = action.payload.primaryKey;
      const primaryKeyValue = action.payload.primaryKeyValue;
      const changedColumnKey = action.payload.changedColumnKey;
      const changedColumnValue = action.payload.changedColumnValue;
      let pmRows = [...state.pmRows];
      let updatedRow = {};
      const index = pmRows.findIndex(
        (item) => item[primaryKey] === primaryKeyValue
      );
      if (index >= 0 && index < pmRows.length) {
        updatedRow = {
          ...pmRows[index], // Clone the object at the specified index
          [changedColumnKey]: changedColumnValue, // Update the specific parameter with the new value
        };
      }

      const tempPMData = updatePMRowsData(
        action.payload.changedColumnKey,
        state.pmRows,
        updatedRow,
        state.details.all,
        state.colInfo
      );

      const tempPMTableFooterData = getPMTableFooterData(
        state.colInfo,
        tempPMData,
        state.details.all
      );
      const tempAllDetails = {
        ...state.details.all,
        ...getKeyValue(state.summary),
      };
      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...state.tableFooter,
        ...tempPMTableFooterData,
      ]);
      state.pmRows = tempPMData;
      state.summary = tempSummary;
      state.pmTableFooter = tempPMTableFooterData;
    },
    deletePMRows: (state, action) => {
      const tempPMData = state.pmRows.filter(
        (row) => row.id !== action.payload.id
      );
      const tempPMTableFooterData = getPMTableFooterData(
        state.colInfo,
        tempPMData,
        state.details.all
      );
      const tempAllDetails = {
        ...state.details.all,
        ...getKeyValue(state.summary),
      };
      const tempSummary = getSummary(state.colInfo, tempAllDetails, [
        ...state.tableFooter,
        ...tempPMTableFooterData,
      ]);
      state.pmRows = tempPMData;
      state.summary = tempSummary;
      state.pmTableFooter = tempPMTableFooterData;
    },
    clearCalculatorState: (state, action) => {
      state.selectedItem = null;
      state.selectedSecondaryItem = null;
      state.colInfo = null;
      state.primaryKeys = null;
      state.primaryLabel = null;
      state.editableColumns = {
        all: null,
        formatted: null,
      };
      state.details = {
        all: null,
        editable: null,
      };
      state.head = null;
      state.reference_types = null;
      state.selected_reference_type = null;
      state.reference = null;
      state.prospect = null;
      state.tableHeaders = { all: null, toShow: null };
      state.rows = { all: null, toShow: null };
      state.tableFooter = null;
      state.pmHeaders = null;
      state.pmRows = [];
      state.pmTableFooter = null;
      state.costing_based_on = "total";
      state.summary = null;
      state.exportState = null;
      state.cost_calculation_name = null;
      state.head_export = null;
      state.rows_export = null;
      state.table_footer_export = null;
      state.pm_rows_export = null;
      state.pm_table_footer_export = null;
      state.summary_export = null;
    },
    setCostingBasedOn: (state, action) => {
      state.costing_based_on = action.payload;
      state.details.all = {
        ...state.details.all,
        costing_based_on: action.payload,
      };
    },
  },
});

export const {
  setInitialCalculatorData,
  setSelectedItem,
  setSelectedSecondaryItem,
  setSelectedReferenceType,
  setSelectedReference,
  setSelectedProspect,
  updateRow,
  updateDetail,
  updateSummary,
  clearMetaData,
  setExportState,
  setDataFromImport,
  clearExportState,
  setCalculationName,
  addPMRow,
  addRow,
  deleteRows,
  updatePMRow,
  updateCompletePMRow,
  deletePMRows,
  clearCalculatorState,
  setCostingBasedOn,
} = calculatorDataSlice.actions;
//when you call it they are actions
export default calculatorDataSlice.reducer;
//when it changes you state its reducer
