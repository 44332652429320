import React from "react";
import Plotly from "plotly.js-basic-dist-min";
// import Plot from "react-plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";

import { Card, Col, Row } from "@themesberg/react-bootstrap";
const Plot = createPlotlyComponent(Plotly);

const PlotEstimate = (props) => {
  const { schedule } = props;
  const plotData = schedule.data.workcenter_wise_data;
  const unique_projects = [];
  const workcenters = [];
  plotData.forEach(function (dt, index) {
    let showLegend = false;
    if (unique_projects.includes(dt.mcsr_no)) {
      showLegend = false;
    } else {
      unique_projects.push(dt.mcsr_no);
      showLegend = true;
    }
    const newProject = dt.mcsr_no === "new" ? true : false;
    const opacity = newProject ? 1 : 1;
    const Color = newProject ? "#0E6F98 " : "#CDE5C7";
    const workcenter = {
      x: [dt.start, dt.finish],
      y: [dt.mcsr_no, dt.mcsr_no],
      mode: "lines",
      name: dt.mcsr_no,
      opacity: opacity,
      line: { width: 20, color: Color },
      showlegend: showLegend,
      legendgroup: dt.mcsr_no,
    };
    workcenters.push(workcenter);
  });

  const layout = {
    title: "Estimated Timeline",
    autosize: true,
    showlegend: true,
    xaxis: {
      title: "",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 10,
      },
      showgrid: true,
      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    yaxis: {
      title: "MCSR_NO.",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 10,
      },
      showgrid: true,
      rangemode: "nonnegative",
      autorange: unique_projects.length > 3 ? true : false,

      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    legend: {
      orientation: "h",
      x: 0.5,
      y: -0.2,
      xanchor: "center",
    },
    margin: {
      l: 80,
      b: 80,
      r: 30,
      t: 30,
      pad: 5,
    },
  };
  return (
    <>
      <Row>
        <Col xl={12}>
          <Card className="p-2 m-1">
            <Plot data={workcenters} layout={layout} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PlotEstimate;
