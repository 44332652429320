import axios from "axios";
import { axiosInstance } from "../axiosSetup.js";
import { resolve } from "./resolve.js";

const url = "auth";

export const UpdateConnectedAccount = async (updatedDetails) => {
  return await resolve(
    axiosInstance.patch(`${url}/connected_accounts/`, updatedDetails)
  );
};

// erp apis

export const getConnectedAccountsCredentialsAPI = async (connect2) => {
  const res = await axios.get(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.connected_accounts_login?app_name=${connect2}`,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const postReq4DemoAPI = async (product) => {
  const formData = new FormData();
  formData.append("request_type", "Demo");
  formData.append("product", product.name);
  const res = await axios.post(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.generate_new_request`,
    formData,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const getConnectedAccountsInfoAPIerp = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.get_connected_accounts_details`,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const patchConnectedAccountsDetailsAPIerp = async (data) => {
  const formdata = new FormData();
  formdata.append("app_name", data.accountName);
  formdata.append("usr", data.accountData.usr);
  formdata.append("pwd", data.accountData.pwd);
  formdata.append("base_url", data.accountData.base_url);
  formdata.append("login_url", data.accountData.login_url);
  formdata.append("redirecting_url", data.accountData.redirecting_url);

  const res = await axios.patch(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.change_connected_accounts_details`,
    formdata,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const deleteConnectedAccountAPIerp = async (data) => {
  const formdata = new FormData();
  formdata.append("app_name", data);

  const res = await axios.patch(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.delete_connected_account`,
    formdata,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
