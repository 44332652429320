import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Button,
  Grid,
  Avatar,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import TransitionsModal from "./TransitionsModal";
import { URLs } from "../../routes";
import { axiosInstance } from "../../axiosSetup";
import "./Overview.css";
import { getProductsListAPIerp } from "../../api/BuyPlansAPI";
import { useSelector } from "react-redux";

const Overview = () => {
  const boxColors = [
    "#6f2da8",
    "#0047ab",
    "#ca2c92",
    "#e4d008",
    "#9acd32",
    "#b05f03",
  ];

  const navigateTo = useNavigate();
  const { connected_apps } = useSelector((state) => state.connectedAccountData);

  const [productlist, setProductList] = useState([]);
  const [modalProduct, setModalProduct] = useState(null);
  const [selectedProductModal, setSelectedProductModal] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [open, setOpen] = useState(true);

  const companyName = localStorage.getItem("company")
    ? localStorage.getItem("company")
    : "Mannlowe";

  useEffect(() => {
    // axiosInstance
    //   .get("sds/products/", {})
    //   .then((res) => {
    //     const products = res.data.result;
    //     setProductList(products);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
    getProdList();
  }, []);
  const getProdList = async () => {
    try {
      const res = await getProductsListAPIerp();
      // console.log(res);
      if (res.status === 200) {
        setProductList(res.data.product_list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const subscribedProducts =
    JSON.parse(localStorage.getItem("subscribed_products")) || [];

  const viewselectedproduct = (product) => {
    return subscribedProducts.includes(product.name);
  };

  const openModal = (product) => {
    setSelectedProductModal(product);
    setModalProduct(product);
  };

  const closeModal = () => {
    setModalProduct(null);
  };

  const AmvigorList = ["Calculator", "Visting Cards"];

  const subscribedProductsOrder = productlist.filter((product) => {
    if (companyName === "Amvigor Organics Pvt Ltd") {
      return AmvigorList.includes(product.name);
    } else {
      return subscribedProducts.includes(product.name);
    }
  });

  const unsubscribedProductsOrder = productlist.filter(
    (product) => !subscribedProducts.includes(product.name)
  );

  const isMediumScreen = window.innerWidth < 1150;
  const isMediumsizeScreen = window.matchMedia("(min-width: 768px)").matches;

  const submitRequest = (severity, msg) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(msg);

    setSnackbarOpen(true);
    setOpen(false);

    setTimeout(() => {
      setSnackbarOpen(false);
    }, 5000);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleLabManagementClick = () => {
    const labManagement = connected_apps.hasOwnProperty("LabManagement")
      ? connected_apps["LabManagement"]
      : null;
    if (labManagement.credential_available === "YES") {
      const loginUrl = `${labManagement["base_url"]}${labManagement["redirecting_url"]}?usr=${labManagement["email"]}&sid=${labManagement["sid"]}`;
      // Open a new tab
      const newTab = window.open(loginUrl, "_blank");
    }
    if (labManagement.credential_available === "NO") {
      navigateTo("/redirecting-error", {
        state: {
          data: {
            connect2: labManagement.connect2,
            credential_available: "NO",
            productName: "LabManagement",
          },
        },
      });
    }
  };
  return (
    <div className="page-container" style={{ zoom: "75%" }}>
      <Card
        variant="outlined"
        sx={{ border: "1px solid lightgray" }}
        className="shadow-sm"
      >
        <CardHeader
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Overview</span>
            </div>
          }
          sx={{ margin: "15px" }}
        />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent style={{ backgroundColor: "#FF8FB" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                Subscribed Products
              </Typography>
            </Grid>
            {/* Subscribe Product Logic */}
            {subscribedProductsOrder.map((product, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={product.item_name}>
                <Card
                  variant="outlined"
                  style={{
                    border: "0.3px solid lightgray",
                    height: "200px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      variant="square"
                      sx={{
                        bgcolor: boxColors[index % boxColors.length],
                        marginLeft: "15px",
                        marginTop: "15px",
                        width: "55px",
                        height: "55px",
                        borderRadius: "10px",
                      }}
                    >
                      {product.item_name.substring(0, 2).toUpperCase()}
                    </Avatar>
                    <Typography
                      style={{
                        marginLeft: "15px",
                        marginTop: "15px",
                        fontFamily: "inherit",
                        fontWeight: "bold",
                        fontSize: isMediumScreen ? "18px" : "23px",
                      }}
                    >
                      {product.item_name}
                    </Typography>
                  </div>
                  <CardContent>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        maxHeight: "3.3em",
                        minHeight: "3.3em",
                      }}
                    >
                      {product.description}
                    </Typography>
                  </CardContent>

                  <footer
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 15px",
                    }}
                  >
                    {viewselectedproduct(product) && (
                      <Box>
                        {product.name !== "Lab Management" && (
                          <Button
                            onClick={() =>
                              navigateTo(URLs[product.custom_url].path)
                            }
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Open
                          </Button>
                        )}
                        {product.name === "Lab Management" && (
                          <Button
                            onClick={() => handleLabManagementClick()}
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Open
                          </Button>
                        )}
                      </Box>
                    )}
                    <Box>
                      <Button
                        onClick={() => openModal(product)}
                        style={{
                          fontWeight: "bold",
                          marginLeft: isMediumsizeScreen ? "auto" : "0", // Adjusted for medium screen
                        }}
                      >
                        More Info
                      </Button>
                    </Box>
                  </footer>
                </Card>
              </Grid>
            ))}
            {companyName !== "Amvigor Organics Pvt Ltd" && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: "10px",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    Other Products
                  </Typography>
                </Grid>
                {/* Unsubscribe Product Logic */}
                {unsubscribedProductsOrder.map((product, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={product.item_name}
                  >
                    <Card
                      variant="outlined"
                      style={{
                        border: "0.3px solid lightgray",
                        height: "200px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          variant="square"
                          sx={{
                            bgcolor: boxColors[index % boxColors.length],
                            marginLeft: "15px",
                            marginTop: "15px",
                            width: "55px",
                            height: "55px",
                            borderRadius: "10px",
                          }}
                        >
                          {product.item_name.substring(0, 2).toUpperCase()}
                        </Avatar>
                        <Typography
                          style={{
                            marginLeft: "15px",
                            marginTop: "15px",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                            fontSize: isMediumScreen ? "18px" : "23px",
                          }}
                        >
                          {product.item_name}
                        </Typography>
                      </div>
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            maxHeight: "3.3em",
                            minHeight: "3.3em",
                          }}
                        >
                          {product.description}
                        </Typography>
                      </CardContent>

                      <footer
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          padding: "0 15px",
                        }}
                      >
                        <Box>
                          <Button
                            onClick={() => openModal(product)}
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            More Info
                          </Button>
                        </Box>
                      </footer>
                    </Card>
                  </Grid>
                ))}
              </React.Fragment>
            )}
          </Grid>
        </CardContent>
      </Card>
      {modalProduct && (
        <TransitionsModal
          product={selectedProductModal}
          onClose={closeModal}
          submitRequest={submitRequest}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        style={{ minWidth: "100px" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%", minHeight: "50px" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Overview;
