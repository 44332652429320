import React, { useState, useRef, useEffect } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbar,
  gridClasses,
  useGridApiContext,
} from "@mui/x-data-grid";
import {
  EditToolbarRM,
  NumericEditComponentRM,
  SelectEditComponent,
  TextEditComponent,
} from "./TableGridComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Input,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { deleteRows, updateRow } from "../../../slices/calculatorSlice";
import StripedDataGridBase from "./StripedDataGridBase";

export default function ItemGrid(props) {
  // we are using 2 level editing to provide the functionality to edit in table,
  // first is which column is editable like rate -> editable field in column
  // second is which row allows editing that column -> editable field in row, used against isCellEditable
  const { toShow: headersToShow } = useSelector(
    (state) => state.calculatorData.tableHeaders
  );
  const { toShow: rowsToShow } = useSelector(
    (state) => state.calculatorData.rows
  );
  const dispatch = useDispatch();
  const [selectedDensity, setSelectedDensity] = useState("comfortable"); // State to manage selected density
  const [toRender, setToRender] = useState("1");
  const containerRef = useRef(null);
  const initialColumns = [
    { field: "id", headerName: "#", width: 10, align: "left" },
    ...headersToShow?.labels.map((header, index) => {
      const type = headersToShow.types[index];
      const commonProperties = {
        field: headersToShow.names[index],
        editable: headersToShow.editable[index],
        type: type,
        headerName: header,
        width: 20,
        align: "left",
        headerAlign: "left",
      };
      let additionalProperties = {};
      if (type === "singleSelect" || type === "string") {
        additionalProperties.renderEditCell = (params) => (
          <TextEditComponent
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
          />
        );
        // Add more properties specific to 'text' type if needed
      } else if (type === "number") {
        additionalProperties.renderEditCell = (params) => (
          <NumericEditComponentRM
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
          />
        );
        // Add more properties specific to 'number' type if needed
      } else {
        additionalProperties.renderEditCell = (params) => (
          <TextEditComponent
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
          />
        );
      }
      return {
        ...commonProperties,
        ...additionalProperties,
      };
      // renderEditCell: NumericEditComponentRM, // Use the custom edit component for numerical values
    }),
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(e) => handleDeleteClick(params)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const [columns, setColumns] = useState(initialColumns);
  const initialRowState = rowsToShow.map((row, index) => ({
    id: index + 1,
    ...row,
  }));
  const [rows, setRows] = useState(initialRowState);

  const updateTheRows = (rowsToShow) => {
    const tempRows = rowsToShow.map((row, index) => ({
      id: index + 1,
      ...row,
    }));
    setRows(tempRows);
  };
  useEffect(() => {
    updateTheRows(rowsToShow);
  }, [rowsToShow]);

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = containerRef.current.offsetWidth;
      const totalWidth = containerWidth - 0.35 * containerWidth;
      //   : 20; // lg and xl:
      let sizeWidth;
      if (window.innerWidth <= 400) {
        // for screens smaller or equal to 600px (xs)
        sizeWidth = 36; // 35% width
      } else if (window.innerWidth <= 825) {
        // for screens smaller or equal to 960px (sm to md)
        sizeWidth = 24; // 25% width
      } else {
        // for larger screens (lg and xl)
        sizeWidth = 14; // 20% width
      }
      // console.log(sizeWidth);
      const newColumns = columns.map((col, index) => {
        // Calculate percentage width based on the total width
        const width = index === 0 ? 10 : sizeWidth; // Default widths
        return {
          ...col,
          width: (totalWidth * width) / 100,
        };
      });
      // Set the columns with the updated width values
      setColumns(newColumns);
    };

    // Call the handleResize function initially and add it to the resize event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [headersToShow]);

  const onChange = (params) => {
    setToRender(!toRender);
  };
  const processRowUpdate = (newRow) => {
    const updatedRow = rows.find((row) => row.id === newRow.id);
    return updatedRow;
  };
  const handleDeleteClick = (params) => {
    const primary_key = params.row["primary_key"];
    const primary_key_value = params.row[primary_key];
    dispatch(deleteRows({ value: primary_key_value, key: primary_key }));
  };

  return (
    <Box sx={{ mb: 1, width: "100%" }} ref={containerRef}>
      {rows && toRender && (
        <StripedDataGridBase
          rows={rows}
          columns={columns}
          rowHeight={32}
          editMode="row"
          processRowUpdate={processRowUpdate}
          headerHeight={48}
          checkboxSelection={false}
          disableSelectionOnClick
          autoPageSize={false}
          density={selectedDensity}
          onCellEditStop={onChange}
          isCellEditable={(params) => params.row.editable}
          slots={{
            toolbar: EditToolbarRM,
            footer: TableFooter,
          }}
          slotProps={{
            toolbar: {
              // setRowModesModel,
              columns,
              rows,
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnsContainer": {
              borderBottom: "1px solid black",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "auto", // Allow horizontal scrolling
              minHeight: "200px", // Set max height for virtual scroller
            },
          }}
          // hideFooter:true
          // hideFooterRowCount:true
        />
      )}
      {rows && !toRender && (
        <StripedDataGridBase
          rows={rows}
          columns={columns}
          rowHeight={32}
          editMode="row"
          processRowUpdate={processRowUpdate}
          headerHeight={48}
          checkboxSelection={false}
          disableSelectionOnClick
          autoPageSize={false}
          density={selectedDensity}
          onCellEditStop={onChange}
          isCellEditable={(params) => params.row.editable}
          slots={{
            toolbar: EditToolbarRM,
            footer: TableFooter,
          }}
          slotProps={{
            toolbar: {
              // setRowModesModel,
              columns,
              rows,
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            backgroundColor: "white",
            "& .MuiDataGrid-columnsContainer": {
              borderBottom: "1px solid black",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "auto", // Allow horizontal scrolling
              minHeight: "200px", // Set max height for virtual scroller
            },
          }}
          // hideFooter:true
          // hideFooterRowCount:true
        />
      )}
    </Box>
  );
}

const TableFooter = (props) => {
  const { toShow: headersToShow } = useSelector(
    (state) => state.calculatorData.tableHeaders
  );
  const { tableFooter } = useSelector((state) => state.calculatorData);
  // console.log(tableFooter);
  return (
    <React.Fragment>
      {tableFooter && (
        <Table sx={{ minWidth: 200 }} aria-label="simple table" stickyHeader>
          <TableBody>
            {tableFooter.map((item, index) => {
              return (
                <TableRow
                  key={`table-footer-${index}`}
                  sx={{
                    backgroundColor: item.editable ? "#E4FA97" : "white",
                  }}
                >
                  <TableCell colSpan={headersToShow.labels.length - 2} />
                  <TableCell
                    colSpan={2}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {item.label}
                  </TableCell>
                  <TableCell align="right">{item.value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
};
