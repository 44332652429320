import { Parser } from "expr-eval";

export const getPropsDestructured = (details) => {
  const otherProps = {};
  Object.entries(details).forEach(([key, value]) => {
    otherProps[key] = value;
  });
  return otherProps;
};

export const removeNullValues = (arr) => {
  return arr.filter((value) => value !== null);
};

export const getTableHeaders = (colInfo) => {
  const headers = { labels: [], names: [] };
  // Filter out items without table_index
  const itemsWithTableIndex = colInfo.filter((item) => "table_index" in item);
  // Sort items with table_index
  itemsWithTableIndex.sort((a, b) => a.table_index - b.table_index);

  // Add labels and names from items with table_index
  itemsWithTableIndex.forEach((item) => {
    if (item.show_in.includes("table")) {
      headers.labels.push(item.label);
      headers.names.push(item.name);
    }
  });
  return headers;
};

export const getPMTableHeaders = (colInfo) => {
  const headers = { labels: [], names: [], editable: [], types: [] };
  // Filter out items without table_index
  const itemsWithTableIndex = colInfo.filter((item) => "table_index" in item);
  // Sort items with table_index
  itemsWithTableIndex.sort((a, b) => a.table_index - b.table_index);

  // Add labels and names from items with table_index
  itemsWithTableIndex.forEach((item) => {
    if (item.show_in.includes("pm_table") && item.visible) {
      headers.labels.push(item.label);
      headers.names.push(item.name);
      headers.editable.push(item.editable);
      headers.types.push(item.type);
    }
  });
  return headers;
};

export const getRows = (rows, headers, colInfo, details, primaryKeys) => {
  const destructuredRows = rows.map((item) => {
    const destructuredProps = getDestructuredProps(item, headers);
    const updatedProps = getUpdatedProps(destructuredProps, colInfo, details);
    const editableProps = getEditableProps(updatedProps, colInfo, primaryKeys);
    return editableProps;
  });
  return destructuredRows;
};

export const getDestructuredProps = (row, headers) => {
  //destructuredProps gets us key value of row items, row contains only values and headers contains array of header/key
  const destructuredProps = {};
  headers.names.forEach((header) => {
    destructuredProps[header] = row[header];
  });
  return destructuredProps;
};

export const getUpdatedProps = (destructuredProps, colInfo, details) => {
  //takes one row at a time, if row contains formula it evaluates and put valune infront of key
  const parser = new Parser();
  const updatedProps = {};

  //updatedProps gets the final output to show in table, eg evaluate formulas etc
  Object.entries(destructuredProps).forEach(([key, value]) => {
    const column = colInfo.filter((item) => item.name === key);
    if (column.length > 0 && column[0].formula) {
      //check if given column needs to get its value from formula
      const formula = column[0].formula;

      // Replace property names with their corresponding values in the formula
      const formulaWithValues = formula.replace(/\w+/g, (match) => {
        if (match.trim() in destructuredProps) {
          //this condition checks whether the column header formula is using exist in our destructuredProps or not
          //if it exist it gets the value from destructuredProp and return
          return destructuredProps[match.trim()];
        } else if (match.trim() in details) {
          //this condition checks whether the column header formula is using exist in our detail prop
          //if it exist it gets the value from details and return
          return details[match.trim()];
        }
        return match;
      });

      // Evaluate the updated formula with the values using eval (Note: Be cautious of potential security risks)
      let output;
      try {
        const expression = parser.parse(formulaWithValues);
        output = expression.evaluate(destructuredProps);
      } catch (error) {
        console.error("Error evaluating expression:", error);
      }
      value = (output || output === 0) ? output.toFixed(2) : null; // Assuming you want to round the output to 2 decimal places
    } else if (
      column.length > 0 &&
      value === undefined &&
      column[0].hasOwnProperty("default_value")
    ) {
      value = column[0].default_value;
    }
    destructuredProps[key] = value;
    updatedProps[key] = value;
  });
  return updatedProps;
};

const getEditableProps = (row, colInfo, primaryKeys) => {
  //takes one row at at time, checks condition whether it is editable and adds editable and primary_keys properties to row
  const editableItemInRow = getEditableColumns(row, colInfo);
  const primaryKey = primaryKeys.length > 0 ? primaryKeys[0] : null;
  if (editableItemInRow.length > 0 && primaryKey) {
    return { ...row, editable: true, primary_key: primaryKey };
  } else {
    return { ...row, editable: false };
  }
};

export const getAllEditableColumns = (rows, colInfo, primaryKeys) => {
  //it takes all the rows and checks them one by one whether they are editable and adds editable & primaryKey properties
  const temp = rows.map((item) => {
    const editableItemInRow = getEditableColumns(item, colInfo);
    const primaryKey = primaryKeys.length > 0 ? primaryKeys[0] : null;
    if (editableItemInRow.length > 0 && primaryKey) {
      return {
        [primaryKey]: item[primaryKey],
        editable: editableItemInRow,
      };
    } else {
      return null;
    }
  });
  return temp;
};

export const getEditableColumns = (rowItem, colInfo) => {
  //evaluates each key-value in a row, and returns list of keys which are editable
  const parser = new Parser();
  const editableProps = [];
  // console.log(destructuredProps);
  Object.entries(rowItem).forEach(([key, value]) => {
    const column = colInfo.filter((item) => item.name === key);
    if (column.length > 0 && column[0].editable) {
      const editableConditions = column[0].editable_conditions;

      if (editableConditions && editableConditions.length > 0) {
        let isConditionTrue = false; // Initialize the condition result as false
        for (let i = 0; i < editableConditions.length; i++) {
          const formattedCondition = editableConditions[i].replace(
            /(\w+)(\s+\w+)/g,
            '"$1$2"'
          );
          // console.log(formattedCondition);
          try {
            const expression = parser.parse(formattedCondition);
            const conditionResult = expression.evaluate({
              ...rowItem,
              // type: row.type,
            });
            if (conditionResult) {
              isConditionTrue = true; // Set the condition result as true if any condition satisfies
              break; // Break out of the loop if any condition satisfies
            }
          } catch (error) {
            console.error("Error evaluating expression:", error);
            isConditionTrue = false;
            break;
          }
        }

        if (isConditionTrue) {
          editableProps.push(column[0].name);
        }
      }
    }
  });
  return editableProps;
};

export const getHeadersToShow = (headers, colInfo) => {
  // Returns an object containing properties: labels, names, and editable.
  // Each property has a value as an array and contains only those column names, labels, and editable values which are marked visible to show in the table.
  const labels = [];
  const names = [];
  const editable = [];
  const types = [];

  headers.names.forEach((header) => {
    const columns = colInfo.filter((item) => item.name === header);

    // Check if any column with the same name is visible
    if (columns.some((column) => column.visible)) {
      columns.forEach((column) => {
        if (column.visible) {
          labels.push(column.label);
          names.push(column.name);
          editable.push(column.editable);
          types.push(column.type);
        }
      });
    }
  });
  return { labels, names, editable, types };
};

export const getRowsToShow = (rows, headersToShow) => {
  //rows generally contains lots of properties, this function return rows with key-values which are to be shown in table
  const output = rows.map((row) => {
    const filteredRow = {};
    for (const key in row) {
      if (
        headersToShow.names.includes(key) ||
        key === "editable" ||
        key === "primary_key"
      ) {
        filteredRow[key] = row[key];
      }
    }
    return filteredRow;
  });
  return output;
};

export const getEditableColumnDetails = (
  editableColumns,
  colInfo,
  rows,
  primaryKeys,
  primaryLabel
) => {
  // puts extra details in colmns which are editable like label, name, type, primary_key
  const editableRows = removeNullValues(editableColumns);
  const primaryKey = primaryKeys.length > 0 ? primaryKeys[0] : null;
  const output = [];
  if (primaryKey) {
    editableRows.forEach((item) => {
      const rowData = rows.find((row) => row[primaryKey] === item[primaryKey]);
      if (rowData) {
        item.editable.forEach((editablColumn) => {
          const tempColInfo = colInfo.find(
            (column) => column.name === editablColumn
          );
          if (tempColInfo) {
            output.push({
              ...rowData,
              label: tempColInfo["label"],
              name: tempColInfo["name"],
              type: tempColInfo["type"],
              primary_key: primaryKey,
              primary_label: primaryLabel,
            });
          }
        });
      }
    });
  }
  return output;
};

export const getHeadData = (details, colInfo) => {
  //prepares data to be shown in head
  const headColumns = colInfo.filter(
    (item) => item.show_in.includes("head") && item.visible
  );
  const output = [];
  headColumns.forEach((item) => {
    output.push({
      ...item,
      value: details.hasOwnProperty(item.name)
        ? details[item.name]
        : item.default_value
        ? item.default_value
        : 0,
    });
  });
  return output;
};

export const getSummary = (colInfo, details, tableFooter) => {
  const summaryColumns = colInfo.filter((item) =>
    item.show_in.includes("summary")
  );

  const parser = new Parser();

  const evaluateFormula = (column) => {
    // Check if the formula is null or empty
    const formula = column.formula;
    if (!formula) {
      return 0;
    }

    try {
      // Replace the variable names in the formula with their corresponding values
      const evaluatedFormula = formula.replace(/([a-z_]+)/gi, (match) => {
        const tableFooterInput = tableFooter.find(
          (item) => item.name === match
        );
        if (tableFooterInput !== undefined) {
          return tableFooterInput.value;
        }

        const processedDataInput = processedData.find(
          (item) => item.name === match
        );
        if (processedDataInput !== undefined) {
          return processedDataInput.value;
        }

        const detailsInput = details[match];
        if (detailsInput !== undefined) {
          return detailsInput;
        }

        return 0;
      });

      // Evaluate the formula using the parser
      const result = parser.evaluate(evaluatedFormula);
      // Round the result to 2 decimals
      return Number(result.toFixed(2));
    } catch (error) {
      console.error("Error evaluating formula:", error);
      return 0;
    }
  };

  const processedData = [];

  // Create a new array of updated column objects
  const updatedSummaryColumns = summaryColumns.map((column) => {
    const { name, formula } = column;

    // Check if the formula is present
    if (formula) {
      // Evaluate the formula with the provided inputs
      const value = evaluateFormula(column);

      // Add the evaluated value to the processed data
      processedData.push({ name, value });

      // Return a new column object with the updated value
      return { ...column, value };
    } else {
      // Handle the case when the formula is null
      // Check if the input has a value property associated with the column
      const detailsInput = details[name];
      const tableFooterInput = tableFooter.find((item) => item.name === name);
      const value =
        detailsInput !== undefined
          ? Number(detailsInput)
          : tableFooterInput !== undefined
          ? Number(tableFooterInput.value)
          : 0;
      // console.log(name, value);
      // Round the value to 2 decimals
      const roundedValue = Number(+value.toFixed(2));

      // Add the rounded value to the processed data
      processedData.push({ name, value: roundedValue });

      // Return a new column object with the updated value
      return { ...column, value: roundedValue };
    }
  });
  return updatedSummaryColumns;
};

export const getTableFooterData = (rows, colInfo, details) => {
  const parser = new Parser();
  const tableFooterColumns = colInfo.filter((item) =>
    item.show_in.includes("table_footer")
  );

  // Create a lookup object to store calculated values
  const lookup = {};

  const calculateColumnValue = (column) => {
    if (lookup[column.name]) {
      return lookup[column.name]; // Return the cached value if already calculated
    }

    if (!column.formula && column.editable) {
      if (column.name in details) {
        // Perform a lookup from the 'details' object
        const value = details[column.name];
        lookup[column.name] = value; // Cache the looked-up value
        return value;
      }
    }
    const formula = column.formula;

    if (!formula) {
      console.error(`Formula is missing for column: ${column.name}`);
      return 0;
    }

    // Check if the formula can be evaluated directly by the parser
    if (
      formula.includes("+") ||
      formula.includes("-") ||
      formula.includes("*") ||
      formula.includes("/")
    ) {
      try {
        const parsedExpression = parser.parse(formula);
        const result = parsedExpression.evaluate({
          ...details,
          ...lookup,
        });

        if (typeof result === "number") {
          lookup[column.name] = result; // Cache the calculated value
          return result;
        }
      } catch (error) {
        console.error(`Error evaluating formula for column: ${column.name}`);
        console.error(error);
        return 0;
      }
    }

    // Custom processing for formulas like 'sum(total)'
    const operation = formula.split("(")[0].trim();
    const expression = formula.split("(")[1].split(")")[0].trim();

    if (operation === "sum") {
      const propertyName = expression;
      const result = rows.reduce((acc, obj) => {
        const value = parseFloat(obj[propertyName]);
        return acc + value;
      }, 0);

      lookup[column.name] = result; // Cache the calculated value
      // console.log(result);
      return result;
    } else if (operation === "multiply") {
      const propertyName = expression;
      const result = rows.reduce((acc, obj) => {
        const value = parseFloat(obj[propertyName]);
        return acc * value;
      }, 1);

      lookup[column.name] = result; // Cache the calculated value
      return result;
    }

    console.error(
      `Invalid formula format or result for column: ${column.name}`
    );
    return 0;
  };

  // Calculate the values for each column in tableFooterColumns
  const results = tableFooterColumns.map((column) => {
    const value = calculateColumnValue(column);
    const roundedValue = column.type === "number" ? +value.toFixed(2) : value; // Round to 2 decimal places
    return {
      ...column,
      value: roundedValue,
    };
  });
  return results;
};

export const getAllDetails = (
  tempDetails,
  headData,
  footerData,
  summaryData
) => {
  const allDetails = [
    ...tempDetails,
    ...headData,
    ...footerData,
    ...summaryData,
  ];
  // console.log(tempDetails, headData);
  const output = {};
  allDetails.forEach((item) => {
    output[item.name] = item.value;
  });
  return output;
};

export const getAllEditableDetails = (allDetails) => {
  const output = [];
  allDetails.forEach((detail) => {
    if (detail.editable) {
      output.push(detail);
    }
  });
  return output;
};

export const formatExportItems = (keysToInclude, allRows) => {
  // Create a new array to store the modified rows
  const modifiedRows = [];

  if (allRows) {
    // Loop through each row in allRows
    for (const row of allRows) {
      // Create a new object for the modified row
      const modifiedRow = {};

      // Loop through the keysToInclude array and add the corresponding values to modifiedRow
      for (const key of keysToInclude) {
        if (row.hasOwnProperty(key)) {
          modifiedRow[key] = row[key];
        }
      }

      // Push the modifiedRow to the modifiedRows array
      modifiedRows.push(modifiedRow);
    }
  }
  // Return the array of modified rows
  return modifiedRows;
};

export const getKeyValue = (data) => {
  const details = {};
  data.forEach((item) => {
    details[item["name"]] = item["value"];
  });
  return details;
};

export const getDetailsCalculated = (
  colInfo,
  details,
  changedColumnKey = null,
  isInitialState = false
) => {
  const detailColumns = colInfo.filter((item) => item.show_in.includes("head"));
  const changedColumnDetail = changedColumnKey
    ? detailColumns.find((item) => item.name === changedColumnKey) || null
    : null;
  const parser = new Parser();

  const evaluateFormula = (column) => {
    // Check if the formula is null or empty
    const formula = column.formula;
    if (!formula) {
      return 0;
    }

    // Replace the variable names in the formula with their corresponding values
    const evaluatedFormula = formula.replace(/([a-z_]+)/gi, (match) => {
      const processedDataInput = processedData.find(
        (item) => item.name === match
      );
      if (processedDataInput !== undefined) {
        return processedDataInput.value;
      }

      const detailsInput = details[match];
      if (detailsInput !== undefined) {
        return detailsInput;
      }
      console.log(column.name, match, "no match found");
      return 0; // Return 0 if a match is not found
    });

    try {
      // Evaluate the formula using the parser
      const result = parser.evaluate(evaluatedFormula);
      // Round the result to 2 decimals
      return Number(result.toFixed(2));
    } catch (error) {
      console.error("Error evaluating formula:", column.name, error);
      // Use the column's default_value if available, or return 0 if not provided
      return column.default_value !== undefined ? column.default_value : 0;
    }
  };

  const processedData = [];

  // Create a new array of updated column objects
  const updatedDetailColumns = detailColumns.map((column) => {
    const { name, formula, type } = column;
    const detailsInput = details[name];
    if (
      isInitialState &&
      type === "number" &&
      (detailsInput !== undefined || column.default_value !== undefined)
    ) {
      const value =
        detailsInput !== undefined
          ? Number(detailsInput)
          : column.default_value
          ? column.default_value
          : 0;
      const roundedValue = Number(+value.toFixed(2));
      processedData.push({ name, value: roundedValue });
      return { ...column, value: roundedValue };
    }

    if (name === changedColumnKey) {
      return { ...column, value: details[name] };
    }

    if (type === "checkbox") {
      const value =
        detailsInput !== undefined ? detailsInput : column.default_value;
      processedData.push({ name, value: value });
      return { ...column, value: value };
    }
    if (type === "dropdown") {
      const value =
        detailsInput !== undefined ? detailsInput : column.default_value;
      processedData.push({ name, value: value });
      return { ...column, value: value };
    }

    if (
      changedColumnDetail &&
      changedColumnDetail.hasOwnProperty("what_not_to_change") &&
      changedColumnDetail.what_not_to_change.includes(name)
    ) {
      return { ...column, value: details[name] };
    }

    if (formula) {
      const value = evaluateFormula(column);
      const outputValue = value !== Infinity ? value : 0;
      processedData.push({ name, value: outputValue });
      return { ...column, value: outputValue };
    } else if (!formula && type === "number") {
      const value = detailsInput !== undefined ? Number(detailsInput) : 0;
      const roundedValue = Number(+value.toFixed(2));
      processedData.push({ name, value: roundedValue });
      return { ...column, value: roundedValue };
    } else {
      return { ...column, value: details[name] };
    }
  });
  return updatedDetailColumns;
};

const calculatePMRow = (row, details, pmColumns, changed_field = null) => {
  const parser = new Parser();
  const evaluateFormula = (column, processedData) => {
    // Check if the formula is null or empty
    const formula = column.formula;
    if (!formula) {
      return 0;
    }

    try {
      // Replace the variable names in the formula with their corresponding values
      const evaluatedFormula = formula.replace(/([a-z_]+)/gi, (match) => {
        const processedDataInput = processedData.find(
          (item) => item.name === match
        );
        if (processedDataInput !== undefined) {
          return processedDataInput.value;
        }

        const detailsInput = details[match];
        if (detailsInput !== undefined) {
          return detailsInput;
        }
        return 0;
      });

      // Evaluate the formula using the parser
      const result = parser.evaluate(evaluatedFormula);
      // Round the result to 2 decimals
      return Number(result.toFixed(2));
    } catch (error) {
      console.error("Error evaluating formula:", error);
      return 0;
    }
  };

  const processedData = [];
  const updatedColumns = {};
  pmColumns.forEach((column) => {
    const { name, formula } = column;
    // Check if the formula is present
    if (formula && name !== changed_field) {
      // Evaluate the formula with the provided inputs
      const value = evaluateFormula(column, processedData);
      // Add the evaluated value to the processed data
      processedData.push({ name, value });

      // Return a new column object with the updated value
      updatedColumns[name] = value;
    } else {
      processedData.push({ name, value: row[name] });
      updatedColumns[name] = row[name];
    }
  });
  return updatedColumns;
};

export const updatePMRowsData = (
  changed_field,
  currentRows,
  updatedRow,
  details,
  colInfo
) => {
  const existingRow = currentRows.find((row) => row.id === updatedRow.id);
  const pmColumns = colInfo.filter((item) => item.show_in.includes("pm_table"));
  if (existingRow) {
    const updatedRows = currentRows.map((row) =>
      row.id === updatedRow.id
        ? calculatePMRow(updatedRow, details, pmColumns, changed_field)
        : row
    );
    return updatedRows;
  }
  return currentRows;
};

export const updateAllPMRows = (
  currentRows,
  details,
  colInfo,
  changedColumnKey = null,
  changedColumnID = null
) => {
  const pmColumns = colInfo.filter((item) => item.show_in.includes("pm_table"));

  const updatedPMRows = [];
  // Create a new array of updated column objects
  currentRows.forEach((row) => {
    const updatedColumns = calculatePMRow(row, details, pmColumns);
    updatedPMRows.push(updatedColumns);
  });
  return updatedPMRows;
};

export const getPMTableFooterData = (colInfo, pmRows, details) => {
  const pmFooterColumns = colInfo.filter((item) =>
    item.show_in.includes("pm_table_footer")
  );
  const parser = new Parser();
  // Create a lookup object to store calculated values
  const lookup = {};

  const calculateColumnValue = (column) => {
    if (lookup[column.name]) {
      return lookup[column.name]; // Return the cached value if already calculated
    }

    if (!column.formula && column.editable) {
      if (column.name in details) {
        // Perform a lookup from the 'details' object
        const value = details[column.name];
        lookup[column.name] = value; // Cache the looked-up value
        return value;
      }
    }
    const formula = column.formula;

    if (!formula) {
      console.error(`Formula is missing for column: ${column.name}`);
      return 0;
    }

    // Check if the formula can be evaluated directly by the parser
    if (
      formula.includes("+") ||
      formula.includes("-") ||
      formula.includes("*") ||
      formula.includes("/")
    ) {
      try {
        const parsedExpression = parser.parse(formula);
        const result = parsedExpression.evaluate({
          ...details,
          ...lookup,
        });

        if (typeof result === "number") {
          lookup[column.name] = result; // Cache the calculated value
          return result;
        }
      } catch (error) {
        console.error(`Error evaluating formula for column: ${column.name}`);
        console.error(error);
        return 0;
      }
    }

    // Custom processing for formulas like 'sum(total)'
    const operation = formula.split("(")[0].trim();
    const expression = formula.split("(")[1].split(")")[0].trim();

    if (operation === "sum") {
      const propertyName = expression;
      const result = pmRows.reduce((acc, obj) => {
        const value = parseFloat(obj[propertyName]);
        return acc + value;
      }, 0);

      lookup[column.name] = result; // Cache the calculated value
      // console.log(result);
      return result;
    } else if (operation === "multiply") {
      const propertyName = expression;
      const result = pmRows.reduce((acc, obj) => {
        const value = parseFloat(obj[propertyName]);
        return acc * value;
      }, 1);

      lookup[column.name] = result; // Cache the calculated value
      return result;
    }

    console.error(
      `Invalid formula format or result for column: ${column.name}`
    );
    return 0;
  };

  // Calculate the values for each column in tableFooterColumns
  const results = pmFooterColumns.map((column) => {
    const value = calculateColumnValue(column);
    const roundedValue = column.type === "number" ? +value.toFixed(2) : value; // Round to 2 decimal places
    return {
      ...column,
      value: roundedValue,
    };
  });
  return results;
};
