import React, { useState } from "react";
import NavbarBP from "./NavbarBP";
import FooterBP from "./FooterBP";
import {
  Alert,
  Button,
  Card,
  CircularProgress,
  Fab,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import { postBuyPlanAPIerp } from "../../api/BuyPlansAPI";

const PlanCheckout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const [buyData, setBuyData] = useState(data);
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);

  const handleSubmitF = async () => {
    setFlag1(true);
    let planNames = buyData.buy_now
      .map((p) => {
        return p.plan_name;
      })
      .join(", ");

    // console.log(buyData, planNames);
    try {
      const res = await postBuyPlanAPIerp(buyData, planNames);
      console.log(res);
      if (res.status === 200) {
        setBuyData(null);
        setFlag1(false);
        setFlag2(true);
      }
    } catch (error) {
      console.log(error);
      setFlag1(false);
    }
  };
  return (
    <div>
      <NavbarBP />
      {buyData !== null && buyData?.buy_now.length >= 1 && flag2 === false && (
        <div
          style={{
            minHeight: "75vh",
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Card
              sx={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "0 10px 10px rgba(0, 0, 0, 0.3)",
                padding: "20px",
                marginBottom: isExtraSmallScreen ? "15px" : "0",
                width: isExtraSmallScreen ? "auto" : "500px",
                minHeight: isExtraSmallScreen ? "auto" : "500px",
                borderLeft: "5px solid #00c875",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <FontAwesomeIcon
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  icon={faGift}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                {buyData &&
                  buyData.buy_now.map((data, index) => {
                    return (
                      <Alert key={index} style={{ backgroundColor: "#e5f6fd" }}>
                        {" "}
                        <h4> {data.name}</h4>
                      </Alert>
                    );
                  })}
              </div>
              <div>
                {flag1 === true ? (
                  <>
                    <CircularProgress size={50} color="inherit" />{" "}
                  </>
                ) : (
                  <Button onClick={handleSubmitF} variant="contained">
                    Buy Now
                  </Button>
                )}
              </div>
            </Card>
          </Grid>
        </div>
      )}
      {flag2 === true && buyData === null && (
        <div
          style={{
            minHeight: "75vh",
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item lg={3} md={4} sm={12} xs={12}>
            <Card
              sx={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "0 10px 10px rgba(0, 0, 0, 0.3)",
                padding: "20px",
                marginBottom: isExtraSmallScreen ? "15px" : "0",
                width: isExtraSmallScreen ? "300px" : "500px",
                minHeight: isExtraSmallScreen ? "300px" : "500px",
                borderLeft: "5px solid #00c875",
                backgroundColor: "#00c875",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <FontAwesomeIcon
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  icon={faGift}
                />
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                Thank you for considering our services.
              </div>
            </Card>
          </Grid>
        </div>
      )}
      {flag2 !== true && buyData !== null && buyData !== undefined && (
        <Fab
          color="primary"
          aria-label="next"
          style={{
            position: "fixed",
            top: "90px",
            left: "16px",
          }}
          onClick={() => {
            navigate(URLs.BuyPlanURL.path);
          }}
        >
          <ArrowBackIosNewIcon />
        </Fab>
      )}
      {/* {buyData !== undefined && <FooterBP />} */}
    </div>
  );
};

export default PlanCheckout;
