import React, { useState, useEffect } from "react";
import { Button, Card, Table } from "@themesberg/react-bootstrap";
import { FormatDateTime } from "../../components/dateformat";
import { axiosInstance } from "../../axiosSetup";
import {
  Button as ButtonMui,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CardHeader, Card as CardMui } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import Snackbar from "@mui/material/Snackbar";
import { Add, Summarize } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllReportsAPI, postNewReportAPI } from "../../api/ReportsAPI";
import { Alert, Form, Modal, Spinner } from "react-bootstrap";

const ReportHome = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [reportsData, setReportsData] = useState([]);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [fileST, setFileST] = useState(null);
  const [description, setDescription] = useState(null);
  useEffect(() => {
    // Loads data for the first page
    // axiosInstance
    //   .get("reports/", {})
    //   .then((res) => {
    //     setReportsData(res.data);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
    getAllReportsF();
  }, []);

  const getAllReportsF = async () => {
    try {
      const res = await getAllReportsAPI();
      // console.log(res);

      if (res.status == 200) {
        setReportsData(res.data.reports);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleClose = () => setshowModal(false);
  const handleChange = (e) => {
    setDescription(e.target.value);
  };
  const handleChangeFile = (e) => {
    const selectedFile = e.target.files[0];
    setFileST(selectedFile);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // console.log(description);
    // console.log(fileST);

    let form_data = new FormData();
    form_data.append("description", description);
    form_data.append("file", fileST);

    try {
      const res = await postNewReportAPI(form_data);
      // console.log(res);
      if (res.status == 200) {
        let temp1 = [...reportsData];
        temp1.unshift(res.data.report);
        setReportsData(temp1);
        setShowSpinner(false);
        setshowModal(false);
         
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-container">
      <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title="Reports"
          action={
            <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
              <Button
                onClick={() => {
                  setshowModal(true);
                }}
                variant="outlined"
                style={{
                  marginRight: "8px",
                  borderColor: "#050092",
                  color: "#050092",
                }}
              >
                <Add /> New
              </Button>{" "}
            </div>
          }
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        />
      </CardMui>
      {!reportsData.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <CircularProgress style={{ width: "100px", height: "100px" }} />
        </div>
      )}
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0">#</th>
                <th className="border-0 table__header">Report </th>
                <th className="border-0 table__header">Uploaded_On </th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {reportsData.map((item, index) => (
                <ReportsRow
                  key={`report-${index}`}
                  data={item}
                  index={index}
                  setSnackbarOpen={setSnackbarOpen}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {snackbarOpen && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message="File must be in .pdf format for download."
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          ContentProps={{
            sx: {
              fontSize: "16px",
              padding: "16px",
            },
          }}
        />
      )}
      {showModal && (
        <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header>
              <Modal.Title className="h6">Add New Report</Modal.Title>
              <Button
                variant="close"
                aria-label="Close"
                onClick={handleClose}
              />
            </Modal.Header>
            <Modal.Body>
              {showSpinner && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="grow" variant="info" size="md" />
                </div>
              )}

              <React.Fragment>
                {showAlert && (
                  <Alert
                    variant={alertVariant}
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    {alertMsg}
                  </Alert>
                )}
              </React.Fragment>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Name..."
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Select file</Form.Label>
                <Form.Control
                  type="file"
                  accept=".jpg,.jpeg,.png,.svg,.ico,.pdf,.docx,.doc,.xls,.xlsx"
                  multiple={false}
                  onChange={handleChangeFile}
                  name="file"
                  required
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="secondary" className="ms-auto" type="submit">
                Upload
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
};

const ReportsRow = (props) => {
  const { file, description, creation, name } = props.data;
  const index = props.index;
  const formatted_date = FormatDateTime(creation);

  const handleViewReport = () => {
    window.open(`${localStorage.getItem("base_url")}${file}`, "_blank");
  };

  const handleDownload = () => {
    if (!file.endsWith(".pdf")) {
      // Show the Snackbar if the file is not a .pdf extension
      props.setSnackbarOpen(true);
    } else {
      // Create an anchor element for downloading the file
      const downloadLink = document.createElement("a");
      downloadLink.href = `${localStorage.getItem("base_url")}${file}`;
      downloadLink.target = "_blank";
      downloadLink.download = description || "Downloaded_report";

      // Simulate a click on the anchor element to trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <tr>
      <td className="border-0">
        <Card.Link href="#" className="d-flex align-items-center">
          <div>
            <span className="h6">{index + 1}</span>
          </div>
        </Card.Link>
      </td>
      {/* <td className="border-0">{name}</td> */}
      <td className="border-0">{description ? description : "-"}</td>
      <td className="fw-bold border-0">
        {formatted_date ? formatted_date : "-"}
      </td>
      <td className="border-0">
        {file && (
          <ButtonMui
            onClick={handleViewReport}
            variant="outlined"
            size="small"
            style={{
              marginRight: "8px",
              borderColor: "#050092",
              color: "#050092",
            }}
          >
            View
            <span style={{ marginLeft: "4px" }}>
              <Summarize />
            </span>
          </ButtonMui>
        )}
        {/* {file.endsWith(".pdf") && (
          <ButtonMui
            onClick={handleDownload}
            variant="outlined"
            size="small"
            style={{
              marginRight: "8px",
              borderColor: "#050092",
              color: "#050092",
            }}
          >
            <span style={{ marginLeft: "4px" }}>
              <GetAppIcon />
            </span>
          </ButtonMui>
        )} */}
      </td>
    </tr>
  );
};

export default ReportHome;
