export const templateData = [
  {
    name: "Boiler Maintenance",
    parameters: [
      {
        name: "Steam Temperature",
        value: null,
      },
      {
        name: "Feed Water Temperature",
        value: null,
      },
      {
        name: "PH Level",
        value: null,
      },
    ],
  },
  {
    name: "Furnace Maintenance",
    parameters: [
      {
        name: "Pressure",
        value: null,
      },
      {
        name: "Fuel Input",
        value: null,
      },
      {
        name: "Emissions",
        value: null,
      },
    ],
  }
];
