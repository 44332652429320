import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { URLs } from "../../routes";
import {
  Email4PasswordAPI,
  Email4PasswordResetAPIerp,
} from "../../api/auth.js";
import { Spinner, Toast } from "react-bootstrap";

const GetEmailForResetPassword = () => {
  const location = useLocation();
  const [emailSent, setEmailSent] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [snackbarD, setSnackbarD] = useState({
    flag: false,
    msg: "",
    color: "",
  });
  const [processingAPI, setProcessingAPI] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSnackbarD({
        flag: false,
        msg: "",
        color: "",
      });
    }, 4000);

    return () => clearTimeout(timeout);
  }, [snackbarD]);

  const handleEmailData = (e) => {
    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(emailData.email);
    if (isValidEmail) {
      callEmail4PasswordAPI();
      setProcessingAPI(true);

      // console.log("Email is valid:", emailData);
    } else {
      console.log("Invalid email:", emailData);
      setSnackbarD({
        flag: true,
        msg: "Error: Incorrect Email Format !!!",
        color: "#dc3545",
      });
    }
  };

  // api call
  const callEmail4PasswordAPI = async () => {
    let base_domain = window.location.origin;

    try {
      const response = await Email4PasswordResetAPIerp(emailData, base_domain);
      // console.log(response);
      
      if (response.status === 200) {
        setProcessingAPI(false);
        setEmailSent(true);
      }
      setProcessingAPI(false);
    } catch (error) {
      console.log(error);
      setProcessingAPI(false);
    }
  };

  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={URLs.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Go to
                Sign in
              </Card.Link>
            </p>
            {!emailSent ? (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <h3 className="mb-4">Forgot Your Password ? </h3>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-4">
                      <Form.Label>
                        Just type in your email & We will send you a link to
                        reset your password!
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          name="email"
                          onChange={handleEmailData}
                          autoFocus
                          required
                          type="email"
                          placeholder="example@company.com"
                        />
                      </InputGroup>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      <div> Get Link to Reset Password </div>
                      {processingAPI === true && (
                        <div>
                          <Spinner animation="border" variant="info" />
                        </div>
                      )}
                    </Button>
                  </Form>

                  {snackbarD && snackbarD.flag === true && (
                    <Toast
                      style={{
                        marginTop: "20px",
                        border: `2px solid ${snackbarD.color}`,
                        width: "auto",
                      }}
                    >
                      <Toast.Body style={{ color: snackbarD.color }}>
                        {snackbarD.msg}
                      </Toast.Body>
                    </Toast>
                  )}
                </div>
              </Col>
            ) : (
              <></>
            )}

            {emailSent === true && (
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  style={{ border: "2px solid green" }}
                  className="bg-white shadow-soft  p-4 p-lg-5 w-100 fmxw-500"
                >
                  <h3 style={{ color: "green" }} className="mb-4">
                    We have sent Pasword Reset Link to your EmailId{" "}
                  </h3>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default GetEmailForResetPassword;
