import React from "react";
import { Card, Button, Table } from "@themesberg/react-bootstrap";
import { Button as ButtonMui } from "@mui/material";
import { FormatDate } from "../../../components/dateformat";

const OrderTable = (props) => {
  // const headings = [
  //   "#",
  //   "so",
  //   "customer",
  //   "mcsr_no",
  //   "status",
  //   "status_today",
  //   "ETA",
  //   " ",
  // ];
  const { orderData, pageNum } = props;

  //   console.log(orderData);

  const TableRow = (props) => {
    // console.log(props);
    const { so, customer, model_name, mcsr_no, status, start_time, eta } =
      props.data;
    const index = props.index;
    const formattedETA = FormatDate(eta);
    const formattedStartDate = FormatDate(start_time);

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            {/* <Image src={status} className="image-small rounded-circle me-2" /> */}
            <div>
              <span className="h6">{index + 1}</span>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{so ? so : "-"}</td>
        <td className="border-0 text-info">{customer ? customer : "-"}</td>
        <td className="border-0">{model_name ? model_name : "-"}</td>
        <td className="fw-bold border-0">{mcsr_no ? mcsr_no : "-"}</td>
        <td className="border-0">{status ? status : "-"}</td>
        <td className="fw-bold border-0 ">
          {start_time ? formattedStartDate : "-"}
        </td>
        <td className="fw-bold border-0">{eta ? formattedETA : "-"}</td>
        <td className="border-0">
          {/* <Button
           variant="secondary"
            size="sm"
          >
            View1
          </Button> */}
           <ButtonMui
           
            variant="outlined"
            size="small"
            style={{
             
              borderColor: "#050092",
              color: "#050092",
            }}
          >
            View
          </ButtonMui>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0 table__header">SO</th>
              <th className="border-0 table__header">Customer</th>
              <th className="border-0 table__header">Model</th>
              <th className="border-0 table__header">MCSR No</th>
              <th className="border-0 table__header">Status</th>
              <th className="border-0 table__header">Start Date</th>
              <th className="border-0">ETA</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {orderData.map((item, index) => (
              <TableRow
                key={`sds-${index}`}
                data={item}
                index={(pageNum - 1) * 10 + index}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </>
  );
};

export default OrderTable;
