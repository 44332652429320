import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Spinner } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link as RRLink, useParams, useLocation } from "react-router-dom";
import "./SDS.css";
import TableRow from "./detail/TableData";
import InfoTable from "./detail/GeneralInfo";
import SyncDetails from "./detail/SyncDetails";
import { URLs } from "../../routes";
import { axiosInstance } from "../../axiosSetup";
import { Accordion } from "react-bootstrap";
import { Button as ButtonMui } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import CommentSection from "../exhibition/detail/CommentSection";
import { isItemInLocalStorage } from "../../components/Utilities";
import { getSDSDetailsAPIerp,  } from "../../api/SdsAPI";

const SDSDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { entryId } = useParams();
  const [detail, setDetail] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const metadata = JSON.parse(localStorage.getItem("metadata"))?.sds;
  const [generalMetadata, setGeneralMetadata] = useState(null);
  const [tableMetadata, setTableMetadata] = useState(null);

  const show_sync_button = isItemInLocalStorage("show_sync_button")
    ? localStorage.getItem("show_sync_button") == "true"
      ? true
      : false
    : false;

  useEffect(() => {
    if (detail) {
      if (detail.unique_document_id) {
        try {
          setGeneralMetadata(metadata[`${detail.unique_document_id}`].general);
          const generalMetadata =
            metadata[`${detail.unique_document_id}`].general;
          try {
            generalMetadata.sort(function (a, b) {
              return a.order - b.order;
            });
          } catch (err) {
            generalMetadata.sort(function (a, b) {
              if (a.name > b.name) return 1;
              else if (a.name < b.name) return -1;
              return 0;
            });
          }
          setTableMetadata(metadata[`${detail.unique_document_id}`].table);
          const tableMetadata = metadata[`${detail.unique_document_id}`].table;
          tableMetadata.sort(function (a, b) {
            return a.order - b.order;
          });
        } catch (err) {
          // localStorage.removeItem("metadata");
          console.log(err);
        }
      }
    }
  }, [detail]);

  useEffect(() => {
    const loadDetails = async () => {
      var url1 = `${localStorage.getItem(
        "base_url"
      )}/api/method/drs_backend.api.return_all_transaction_documents?document_id=${entryId}`;
      try {
        const res = await getSDSDetailsAPIerp(url1);
        // console.log(res);

        let tempRes = res.data.results[0];
        let temp2 = {
          belongs_to: tempRes.company,
          unique_document_id: tempRes.unique_document_id,
          document_type: tempRes.document_type,
          created_at: tempRes.creation,
          id: tempRes.name,
          image: tempRes.image,
          file: tempRes.file,
          status: tempRes.status,
          sync_status: tempRes.sync_status,
          sync_details: tempRes.sync_details,
          misc_data: tempRes.misc_data,
          table_data: tempRes.table_data_metadata,
          comments: tempRes._comments,
        };
        // console.log(temp2);

        setDetail(temp2);
        setShowSpinner(false);
      } catch (error) {
        console.log(error);
        setShowSpinner(false);
      }
     
    };
    loadDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshDetails = (data) => {
    setDetail(data);
  };

  const PushToSap = (e) => {
    setShowSpinner(true);
    e.preventDefault();
    // console.log(entryId);

    axiosInstance
      .post(
        "sds/publish/",
        { doc_id: entryId },
        { timeout: 1 * 60 * 1000 },
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        // console.log(response.data);
        setDetail(response.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setShowSpinner(false);
      });
  };

  return (
    <div className="page-container">
      <Card border="light" className="bg-white shadow-sm mb-2">
        <Card.Header>
          <div className="d-flex justify-content-between">
            {/* <Button to={URLs.SDS.path} size="sm" color="success" as={RRLink}>
              Back
            </Button> */}
            <ButtonMui
              onClick={() => navigate(URLs.SDS.path)}
              variant="contained"
              style={{
                marginRight: "8px",
                backgroundColor: "#262b40",
                color: "#fff",
              }}
            >
              back
            </ButtonMui>
            {detail && (
              <>
                {/* <Button
                variant={detail.sync_status === "Success" ? "dark" : "tertiary"}
                size="sm"
                className="ms-auto"
                disabled={detail.sync_status === "Success"}
                onClick={PushToSap}
                >
                <FontAwesomeIcon icon={faSyncAlt} className="me-1" />
                Request Sync
              </Button> */}
                {/* {show_sync_button && (
                  <ButtonMui
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      backgroundColor:
                        detail.sync_status === "Success"
                          ? "#A3ABBD"
                          : "#050092",
                      color: "#fff",
                    }}
                    disabled={detail.sync_status === "Success"}
                    onClick={PushToSap}
                    startIcon={<SyncIcon />}
                  >
                    Request Sync
                  </ButtonMui>
                )} */}
              </>
            )}
            {/* <Col>
              <h5>Details</h5>
            </Col> */}
            {showSpinner && (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="grow" variant="info" size="md" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="warning" />
              </div>
            )}
          </div>
        </Card.Header>
      </Card>

      <Row>
        <Col xs={12} xl={5}>
          <Accordion defaultActiveKey="general-info">
            {detail && <SyncDetails detail={detail} />}
            <Accordion.Item
              eventKey="general-info"
              className="no__background multi-collapse"
            >
              <Accordion.Button
                variant="link"
                className="w-100 d-flex justify-content-between product__button"
                style={{ backgroundColor: "#eaedf2" }}
                bg="light"
              >
                <div className="d-flex justify-content-between">
                  <span className="h6 mb-0 fw-bold">General information</span>
                </div>
              </Accordion.Button>
              <Accordion.Body className="no__background bg-white shadow-sm">
                <Row>
                  {detail && generalMetadata && (
                    <InfoTable
                      detail={detail}
                      generalMetadata={generalMetadata}
                      refreshDetails={refreshDetails}
                    />
                  )}
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col xs={12} xl={7}>
          <Row>
            <Accordion defaultActiveKey="table-data">
              <Accordion.Item eventKey="table-data" className="no__background">
                <Accordion.Button
                  variant="link"
                  className="w-100 d-flex justify-content-between product__button"
                  bg="light"
                >
                  <div className="d-flex justify-content-between">
                    <span className="h6 mb-0 fw-bold">Itemized List</span>
                  </div>
                </Accordion.Button> 
                <Accordion.Body className="no__background bg-white shadow-sm">
                  <React.Fragment>
                    <Accordion defaultActiveKey="0" flush>
                      {detail &&
                        detail.table_data &&
                        detail.table_data?.map((row, index) => (
                          <TableRow
                            key={`accordion-table-${index}`}
                            tableRowData={row}
                            index={index}
                            detail={detail}
                            tableMetadata={tableMetadata}
                            refreshDetails={refreshDetails}
                          />
                        ))}
                    </Accordion>
                  </React.Fragment>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <Row>
            <Accordion defaultActiveKey="Comments">
              <Accordion.Item eventKey="Comments" className="no__background">
                <Accordion.Button
                  variant="link"
                  className="w-100 d-flex justify-content-between product__button"
                  bg="light"
                >
                  <div className="d-flex justify-content-between">
                    <span className="h6 mb-0 fw-bold">Comments</span>
                  </div>
                </Accordion.Button>
                <Accordion.Body className="no__background bg-white shadow-sm">
                  {/* comment section common for both sds, visiting cards */}
                  <React.Fragment>
                    {detail?.comments && (
                      <CommentSection
                        data={detail.comments}
                        document={detail.id}
                      />
                    )}
                  </React.Fragment>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </Col>
      </Row>

      <Row></Row>
    </div>
  );
};

export default SDSDetail;
