import React, { useState } from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Form,
  Modal,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faEdit,
  faTrashAlt,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../../../axiosSetup";
import { Accordion } from "react-bootstrap";
import {
  updateSDSTableDataAPIerp,
  updateSDSdocAPIerp,
} from "../../../api/SdsAPI";

const TableRow = (props) => {
  const { index, tableRowData, tableMetadata, refreshDetails, detail } = props;

  const [itemData, updateItemData] = useState({});
  const [itemReceived, updateItemReceived] = useState(
    tableRowData.data.received !== undefined
      ? tableRowData.data.received
      : false
  );
  let column1Metadata = [],
    column2Metadata = [];
  try {
    column1Metadata = tableMetadata.slice(
      0,
      Math.ceil(tableMetadata.length / 2)
    );
    column2Metadata = tableMetadata.slice(Math.ceil(tableMetadata.length / 2));
  } catch (err) {}
  const [tableRow, updateTableRow] = useState(tableRowData.data);
  const [mode, setMode] = useState("READ");
  const [showModal, setShowModal] = useState(false);
  let itemColor = "text-dark";
  let flag = {};
  try {
    flag = tableRowData.data.flag;
    // console.log(flag);
  } catch (err) {
    console.log("Has No Flag");
  }
  try {
    let issue = tableRowData.data["flag"]["issue"];
    if (issue === "Warning") {
      itemColor = "text-warning";
    } else if (issue === "Error") {
      itemColor = "text-danger";
    }
  } catch (err) {}

  const handleWarnings = (item_name) => {
    try {
      // console.log(flag[item_name]);
      let columnStatus = flag[item_name];
      let statusTxtColor;
      // console.log(columnStatus);
      switch (columnStatus) {
        case "OK":
          statusTxtColor = "text-dark";
          break;
        case "Warning":
          statusTxtColor = "text-warning";
          break;
        case "Error":
          statusTxtColor = "text-danger";
          break;
        default:
          statusTxtColor = "text-dark";
      }
      // console.log(statusTxtColor);
      return statusTxtColor;
    } catch (err) {
      return "";
    }
  };

  const changeMode = () => {
    if (mode === "READ") setMode("EDIT");
    else if (mode === "EDIT") setMode("READ");
    else setMode("READ");
    // console.log(tableRowData);
  };

  const handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    let tempItemData = itemData;
    tempItemData[e.target.name] = e.target.value;
    updateItemData(tempItemData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(itemData);

    let temp1 = tableRowData;
    Object.keys(itemData).map((k1) => {
      temp1.data[k1] = itemData[k1];
    });

    let temp2 = detail.table_data;
    temp2[index] = temp1;
    // console.log(temp2);
    // console.log(detail);

    try {
      const res = await updateSDSTableDataAPIerp(detail, temp2);
      // console.log(res);

      if (res.status == 200) {
        updateTableRow(temp1.data);
        updateItemData({});
        changeMode();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = async (e) => {
    e.preventDefault();
    // console.log(detail);
    // console.log(index);

    let index5 = index;
    let temp = detail.table_data.filter((data, index) => index !== index5);
    // console.log(temp);

    try {
      const res = await updateSDSTableDataAPIerp(detail, temp);
      // console.log(res);

      if (res.status == 200) {
        // instant table data is not getting updated so using reload()
        // let tempRes = res.data.document;
        // let temp2 = {
        //   belongs_to: tempRes.company,
        //   unique_document_id: tempRes.unique_document_id,
        //   document_type: tempRes.document_type,
        //   created_at: tempRes.creation,
        //   id: tempRes.name,
        //   image: tempRes.image,
        //   file: tempRes.file,
        //   status: tempRes.status,
        //   sync_status: tempRes.sync_status,
        //   sync_details: tempRes.sync_details,
        //   misc_data: tempRes.misc_data,
        //   table_data: tempRes.table_data_metadata,
        //   comments: tempRes._comments,
        // };
        // refreshDetails(temp2);

        setShowModal(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReceiveStatusChange = async (e) => {
    e.preventDefault();
    if (tableRowData.data.received === itemReceived) {
      console.log("Not Changed");
      setMode("READ");
    } else {
      let temp1 = tableRowData;
      temp1.data = { ...temp1.data, received: itemReceived };

      let temp2 = detail.table_data;
      temp2[index] = temp1;
      // console.log(temp2);

      try {
        const res = await updateSDSTableDataAPIerp(detail, temp2);
        // console.log(res);
        if (res.status == 200) {
          changeMode();
        }
      } catch (error) {
        console.log(error);
      }

      // axiosInstance
      //   .patch(`sds/item/${tableRowData.id}/`, {
      //     received: itemReceived,
      //   })
      //   .then((res) => {
      //     const data = res.data;
      //     console.log(data);
      //     changeMode();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  };

  const TableSecondary = (props) => {
    const { column2Metadata } = props;
    return (
      <>
        {column2Metadata &&
          column2Metadata.map((item, index) => {
            return (
              <tr key={`${tableRow.product}-col1-${index}`}>
                <th
                  scope="row"
                  className={`font-weight-bold text-sm small col-sm-2 ${handleWarnings(
                    item.loc
                  )} `}
                >
                  {item.name}
                </th>
                {(mode === "READ" || mode === "STATUS-CHANGE") && (
                  <td className="small">{tableRow[item.loc]}</td>
                )}
                {mode === "EDIT" && (
                  <td>
                    <Form.Control
                      name={item.loc}
                      defaultValue={tableRow[item.loc]}
                      onChange={handleChange}
                    ></Form.Control>
                  </td>
                )}
              </tr>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Accordion.Item
        eventKey={index.toString()}
        className="no__background no__background"
      >
        {
          <Col lg="2" md="4">
            <Accordion.Button
              variant="success"
              className="d-flex justify-content-between detail__button"
              bg="light"
            >
              <span className={`h6 mb-0 fw-bold ${itemColor}`}>{`Item-${
                index + 1
              }`}</span>
            </Accordion.Button>
          </Col>
        }

        <Accordion.Body className="border-bottom">
          <div className="d-flex justify-content-between">
            <div>
              <Form>
                <Form.Check
                  inline
                  checked={itemReceived}
                  onChange={(e) => {
                    itemReceived
                      ? updateItemReceived(false)
                      : updateItemReceived(true);
                    setMode("STATUS-CHANGE");
                  }}
                />
                {itemReceived ? "Received" : "Not Received"}
              </Form>
            </div>

            <div>
              {mode === "READ" && (
                <>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={(e) => setShowModal(true)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="me-1" />
                  </Button>
                  <Button
                    size="sm"
                    variant="info"
                    className="mx-2"
                    onClick={changeMode}
                  >
                    <FontAwesomeIcon icon={faEdit} className="me-1" />
                  </Button>

                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{`Item-${index + 1}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Are you sure, you want to delete {`Item-${index + 1}`}?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" onClick={deleteItem}>
                        Delete
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}

              {mode === "EDIT" && (
                <>
                  <Button size="sm" variant="primary" onClick={changeMode}>
                    <FontAwesomeIcon icon={faWindowClose} className="me-1" />
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="tertiary"
                    onClick={handleSubmit}
                    className="mx-2"
                  >
                    <FontAwesomeIcon icon={faCheckSquare} className="me-1" />
                    Save
                  </Button>
                </>
              )}

              {mode === "STATUS-CHANGE" && (
                <Button
                  size="sm"
                  variant="tertiary"
                  onClick={handleReceiveStatusChange}
                  className="mx-2"
                >
                  Save{" "}
                </Button>
              )}
            </div>
          </div>
          <Row>
            <Col xl={6} sm={12}>
              <Table
                responsive
                borderless
                className="align-items-center justify-content-start table-flush"
              >
                <tbody>
                  {column1Metadata &&
                    column1Metadata.map((item, index) => {
                      return (
                        <tr key={`${tableRowData.id}-col1-${index}`}>
                          <th
                            scope="row"
                            className={`font-weight-bold text-sm small col-sm-2 ${handleWarnings(
                              item.loc
                            )} `}
                          >
                            {item.name}
                          </th>

                          {(mode === "READ" || mode === "STATUS-CHANGE") && (
                            <td className="small">{tableRow[item.loc]}</td>
                          )}

                          {mode === "EDIT" && (
                            <td>
                              <Form.Control
                                name={item.loc}
                                defaultValue={tableRow[item.loc]}
                                onChange={handleChange}
                              ></Form.Control>
                            </td>
                          )}
                        </tr>
                      );
                    })}

                  {window.matchMedia("(max-width: 1200px)").matches && (
                    <TableSecondary
                      column2Metadata={column2Metadata}
                      className="d-xl-none d-md-block"
                    />
                  )}
                  {/* <div className="d-xl-none d-lg-block ">
                        <TableSecondary product={product} />
                      </div> */}
                </tbody>
              </Table>
            </Col>

            <Col xl={6} className="d-none d-xl-block">
              <Table
                responsive
                borderless
                className="align-items-center table-flush"
              >
                <tbody>
                  <TableSecondary column2Metadata={column2Metadata} />
                </tbody>
              </Table>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default TableRow;
