import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearAllCart, confirmOrder } from "../../slices/OrderSlice";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  IconButton,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  Divider,
  Fab,
  CardActions,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URLs } from "../../routes";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { SubscribePlansProductsAPI } from "../../api/BuyPlansAPI";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Cart = () => {
  const dispatch = useDispatch();
  const {
    selectedProduct,
    selectedPlan,
    selectedSubPlan,
    selectedSubPlanLabel,
    credits,
    total_users,
    sub_plan_validity_by_date,

    price,
    paymentStatus,

    sub_plan_validity_by_days,
    plan_type,
  } = useSelector((state) => state.ordersData);
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();
  // State for handling menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate(URLs.Logout.path);
    handleMenuClose();
  };
  const handleBackClick = (e) => {
    navigate(URLs.BuyPlanURL.path);
  };

  const handleClear = (e) => {
    dispatch(clearAllCart());
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleConfirmOrder = async () => {
    const data = {
      product: selectedProduct,
      plan: selectedPlan,
      sub_plan: selectedSubPlan,
      credits: credits,
      total_users: total_users,
      payment_details: {},
      sub_plan_validity_by_date: sub_plan_validity_by_date,
      sub_plan_validity_by_days: sub_plan_validity_by_days,
      price: price,
    };
    try {
      const response = await SubscribePlansProductsAPI(data);
      if (response.status === 201) {
        if (response.data.metadata) {
          localStorage.setItem(
            "metadata",
            JSON.stringify(response.data.metadata)
          );
        }
        if (response.data.subscribed_products) {
          localStorage.setItem(
            "subscribed_products",
            JSON.stringify(response.data.subscribed_products)
          );
        }
        // if (response.data.connected_accounts) {
        //   localStorage.setItem(
        //     "connected_accounts",
        //     JSON.stringify(response.data.connected_accounts)
        //   );
        // }
        // Show success Snackbar for 3 seconds
        handleSnackbar("success", response.data.message);
        dispatch(clearAllCart());
        setTimeout(() => {
          navigate(URLs.Overview.path);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      handleSnackbar("error", error.response.data.error);
    }
  };

  const handleConfirmClick = () => {
    handleConfirmOrder();
  };

  return (
    <div>
      <Card
        variant="outlined"
        style={{ overflowY: "auto", minHeight: "100vh" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "15px",
          }}
        >
          {/* Buy Plans Title */}
          <CardHeader title="Summary" style={{ position: "sticky", top: 0 }} />
          <div>
            {/* Cart Button */}
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Box>
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent
          sx={{
            maxWidth: isMediumScreen ? "none" : "50%", // Set the desired width
            margin: "auto", // Center the content horizontally
          }}
        >
          <Card style={{ marginBottom: "10px" }}>
            <CardContent>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {/* <RenderTableRow
                      label={"Items total"}
                      value={products_list.length}
                      customeBg={"white"}
                    /> */}
                    <RenderTableRow
                      label={"Plan"}
                      value={selectedSubPlanLabel}
                      customeBg={"white"}
                    />
                    <RenderTableRow
                      label={"Plan Type"}
                      value={plan_type}
                      customeBg={"white"}
                    />
                    <RenderTableRow
                      label={"Credits"}
                      value={credits}
                      customeBg={"white"}
                    />

                    <RenderTableRow
                      label={"Total Users"}
                      value={total_users}
                      customeBg={"white"}
                    />
                    <RenderTableRow
                      label={"Validity"}
                      value={
                        plan_type !== "Specific Date"
                          ? sub_plan_validity_by_days
                          : sub_plan_validity_by_date
                      }
                      customeBg={"white"}
                    />

                    {/* <RenderTableRow
                      label={"Version"}
                      value={"Beta"}
                      customeBg={"white"}
                    /> */}

                    <RenderTableRow
                      label={"Total Price"}
                      value={`₹${price}`}
                      customeBg={"#E4FA97"}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {/* <Button
                disabled={products_list.length > 0 ? false : true}
                variant="contained"
                onClick={handleConfirmOrder}
              >
                Confirm
              </Button>{" "} */}
              <Button
                variant="outlined"
                onClick={handleConfirmClick}
                disabled={selectedSubPlan ? false : true}
              >
                Confirm
              </Button>
            </CardActions>
          </Card>
        </CardContent>
      </Card>
      <Fab
        color="primary"
        aria-label="next"
        style={{
          position: "fixed",
          bottom: "40px", // Adjust this value as needed
          left: "16px", // Adjust this value as needed
        }}
        onClick={handleBackClick}
      >
        <ArrowBackIosNewIcon />
      </Fab>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbarVariant} onClose={handleSnackbarClose}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const RenderTableRow = (props) => {
  const { label, value, customeBg } = props;
  // console.log(customeBg);
  return (
    <TableRow
      sx={{
        backgroundColor: customeBg,
      }}
    >
      <TableCell
        sx={{
          fontWeight: "bold",
        }}
      >
        {label}
      </TableCell>
      <TableCell
        sx={{
          fontWeight: "bold",
        }}
        align="right"
      >
        <div>{value}</div>
      </TableCell>
    </TableRow>
  );
};
export default Cart;
