import axios from "axios";

export const getAllReportsAPI = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.return_all_reports`,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const postNewReportAPI = async (form_data) => {
  const res = await axios.post(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.create_new_report`,
    form_data,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(
          "api_key"
        )}:${localStorage.getItem("api_secret")}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
