import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Row,
  Card,
  Button,
  Form,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Link as RRLink } from "react-router-dom";
import { URLs } from "../../routes";
import { axiosInstance } from "../../axiosSetup";
import { Button as ButtonMui } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CardHeader, Card as CardMui, Divider } from "@mui/material";

const TestDoc = () => {
  const navigate = useNavigate();
  const options = [
    "Generic Invoice 3.0",
    "Generic Invoice 3.3 (31/07/23)",
    "Generic Receipt 3.0",
    "Generic Layout 3.0",
    "Generic Layout 3.3 (31/07/23)",
    "Generic Layout 3.0 + Generic Invoice 3.0",
    "Business Card",
    "Go4Fresh Group 1 V4",
    "Go4Fresh Group 2 V3",
    "Kothari Custom Model Group 1 V4",
    "Kothari Custom Model Group 1 V6",
    "Kothari Custom Model Group 2 V2",
    "Kothari Custom Model Group 3 V2",
    "Kothari Custom Model Group 4 V1",
    "Kothari Custom Model Group 5 V2",
    "Inox Custom Model V1",
  ];

  const [showSpinner, setShowSpinner] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewType, setPreviewType] = useState("image");
  const [model, setModel] = useState(options[0]);
  const [incomingData, setIncomingData] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [customClass, setCustomClass] = useState("");
  const [customStyle, setCustomStyle] = useState({ height: "auto" });
  const [showExtractedData, setShowExtractedData] = useState(false);

  useEffect(() => {
    // console.log(window.innerHeight, window.innerWidth);
    if ([window.matchMedia("(min-width: 900px)").matches]) {
      setCustomClass("overflow-auto");
      setCustomStyle({ height: "90vh" });
    } else {
      setCustomClass("");
      setCustomStyle({ height: "auto" });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    // console.log(image, model);
    if (image) {
      // console.log(image.name);
      let form_data = new FormData();
      form_data.append("model", model);
      form_data.append("image", image, image.name);
      axiosInstance
        .post(
          "sds/test/",
          form_data,
          { timeout: 4 * 60 * 1000 },
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          // console.log("success");
          // console.log(response.data);
          // console.log(JSON.stringify(response.data));
          setShowSpinner(false);
          setIncomingData(response.data);
          setShowExtractedData(true);
        })
        .catch((error) => {
          console.log(error);
          setShowSpinner(false);
          setShowExtractedData(false);
        });
    } else {
      setShowSpinner(false);
      alert("No image");
    }

    // setPreview(null);
    // setImage(null);
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    setShowExtractedData(false);
    if (e.target.name === "model") {
      setModel(e.target.value);
    } else {
      const img = e.target.files[0];
      //console.log(img);
      if (img) {
        setPreview(URL.createObjectURL(img));
        setImage(img);
        const patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;

        //Get the file Extension
        const fileExtension = img.name.match(patternFileExtension);
        // console.log(fileExtension);

        if (fileExtension[1] === "pdf") {
          setPreviewType("pdf");
        } else {
          setPreviewType("image");
        }
      } else {
        setPreview(null);
        setImage(null);
      }
    }
  };

  const PushToSap = (e) => {
    e.preventDefault();
    // console.log(incomingData);

    if (incomingData) {
      // console.log(image.name);
      // let form_data = new FormData();
      // form_data.append("data", incomingData);
      axiosInstance
        .post(
          "sds/test-publish/",
          incomingData,
          { timeout: 1 * 60 * 1000 },
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then(function (response) {
          setShowAlert(true);
          // console.log(response.data);

          let response_data = response.data.TEXT;
          let wether_error =
            response_data.filter(
              (item) =>
                item.STATUS === "Failed" ||
                item.STATUS === "Partially Successful"
            ).length > 0;
          if (wether_error) {
            setAlertVariant("warning");
            let msg = "";
            response.data.TEXT.forEach((item, index) => {
              msg += ` // ${index + 1}. PO No. : ${item.PO_NUM} -- STATUS : ${
                item.STATUS
              } -- GR : ${item.GR}  --  ERROR : ${item.ERROR}`;
            });
            setAlertMsg(msg);
          } else {
            setAlertVariant("success");
            setAlertMsg(
              `Successful=> PO No. : ${response_data[0].PO_NUM} & GR : ${response_data[0].GR}`
            );
          }
          // console.log(wether_error);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowAlert(true);
          setAlertMsg("Error");
          setAlertVariant("danger");
          console.log(error);
          setShowSpinner(false);
        });
    } else {
      setShowSpinner(false);
      alert("No Data available");
    }
  };

  return (
    <div className="page-container">
      <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title="Test"
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </CardMui>
      <Card border="light" className="bg-white shadow-sm mb-2">
        {/* <Card.Header>
          <Row className="align-items-center">
           
              <Button to={URLs.SDS.path} size="md" color="success" as={RRLink}>
                Back
              </Button>
              <ButtonMui
                onClick={() => navigate(URLs.SDS.path)}
                variant="contained"
                style={{
                  marginRight: "8px",
                  backgroundColor: "#262b40",
                  color: "#fff",
                }}
              >
                back 
              </ButtonMui>
          
            <Col>
              <h5>Test Page</h5>
            </Col>
          </Row>
        </Card.Header> */}

        <Card.Body>
          <Row>
            <Col sm="5" className={customClass} style={customStyle}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Model</Form.Label>
                  <Form.Select name="model" onChange={handleChange}>
                    {options.map((item, index) => {
                      return (
                        <option value={item} key={`item-${index}`}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Choose Picture</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpg,.jpeg,.png,.pdf"
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="align-items-end my-2">
                  {/* <Button variant="info" className="ms-auto mx-2" type="submit">
                    Extract1
                  </Button> */}
                  <ButtonMui
                    type="submit"
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#050092",
                      color: "#fff",
                    }}
                  >
                    Extract
                  </ButtonMui>
                  {incomingData && (
                    <Button
                      variant="tertiary"
                      className="ms-auto"
                      onClick={PushToSap}
                    >
                      Push to SAP
                    </Button>
                  )}
                </div>

                {showAlert && (
                  <Alert
                    variant={alertVariant}
                    onClose={() => setShowAlert(false)}
                    dismissible
                  >
                    {alertMsg}
                  </Alert>
                )}

                <React.Fragment>
                  {image && (
                    <div>
                      <br />
                      {previewType === "image" && (
                        <img
                          src={preview}
                          className="card-img-top"
                          alt="preview"
                        />
                      )}
                      {previewType === "pdf" && (
                        <object
                          // width="100%"
                          height="600"
                          className="card-img-top"
                          data={preview}
                          type="application/pdf"
                        >
                          {" "}
                        </object>
                      )}
                      <br />
                    </div>
                  )}
                </React.Fragment>
              </Form>
            </Col>

            <Col sm="7" className={customClass} style={customStyle}>
              {showSpinner && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="grow" variant="info" size="md" />
                  <Spinner animation="grow" variant="success" />
                  <Spinner animation="grow" variant="warning" />
                </div>
              )}
              {showExtractedData && (
                <ShowGeneralData data={incomingData.general} />
              )}
              {showExtractedData &&
                (model === "Generic Layout 3.0 + Generic Invoice 3.0" ||
                  model === "Generic Layout 3.0" ||
                  model === "Generic Layout 3.3 (31/07/23)") &&
                incomingData?.table_data.map((item, index) => {
                  return (
                    <ShowTableData data={item} key={`table-data${index}`} />
                  );
                })}
              {showExtractedData &&
                model !== "Generic Layout 3.0 + Generic Invoice 3.0" &&
                model !== "Generic Layout 3.0" &&
                model !== "Generic Layout 3.3 (31/07/23)" && (
                  <ShowTableData data={incomingData.table_data} />
                )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

const ShowGeneralData = (props) => {
  const { data } = props;
  // console.log(data);
  return (
    <Card>
      <Card.Header>Fields</Card.Header>
      <Card.Body>
        {Object.keys(data).map((item, index) => {
          return (
            <Row key={`item-${index}`} className="my-1 d-flex ">
              <Col sm="4" className="font-weight-bold">
                {item}
              </Col>
              <Col sm="4" className="text-center">
                {data[item][0]}
              </Col>
              <Col sm="4" className="text-center">
                {data[item][1]}
              </Col>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

const ShowTableData = (props) => {
  const { data } = props;
  // console.log(data);
  return (
    <Card>
      <Card.Header>Items</Card.Header>
      <Card.Body>
        {Object.keys(data).map((item, index) => {
          return (
            <Row key={`item-${index}`} className="my-1 d-flex ">
              <Col sm="12" className="font-weight-bold">
                <h6>{`Item:${index + 1}`}</h6>
                {Object.keys(data[item]).map((field, detailIndex) => {
                  return (
                    <Row
                      key={`item-detail-${detailIndex}`}
                      className="my-1 d-flex "
                    >
                      <Col sm="4" className="font-weight-bold">
                        {field}
                      </Col>
                      <Col sm="4" className="text-center">
                        {data[item][field][0]}
                      </Col>
                      <Col sm="4" className="text-center">
                        {data[item][field][1]}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default TestDoc;
