import React, { useState, useEffect, useRef } from "react";
import {
  DataGrid,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  getGridStringOperators,
  gridFilteredSortedRowIdsSelector,
  selectedGridRowsSelector,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { isObjectEmpty } from "../../components/Utilities";
import { useMediaQuery } from "@mui/material";
import { axiosInstance } from "../../axiosSetup";
import {
  CharComponent,
  DateComponent,
  DatetimeComponent,
  StatusComponent,
} from "./GridCellComponents";
import MuiPagination from "@mui/material/Pagination";
import { useDispatch } from "react-redux";
import {
  setPaginationModel,
  setQueryOptions,
  setToRefreshData,
} from "../../slices/sdsSlice";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { getAllSDSlistAPIerp } from "../../api/SdsAPI";

const getSelectedRowsToExport = ({ apiRef }) => {
  const selectedRowIds = selectedGridRowsSelector(apiRef);
  if (selectedRowIds.size > 0) {
    return Array.from(selectedRowIds.keys());
  }

  return gridFilteredSortedRowIdsSelector(apiRef);
};

const CustomGridToolbar = () => {
  return (
    <GridToolbarContainer style={{ justifyContent: "flex-start" }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
};

const getInitialColumns = (columnHeaders) => {
  const fieldNames = [];
  const initialColumns = [
    ...columnHeaders?.map((header, index) => {
      const type = header.type;
      const wheretoLook = header.loc?.length === 1 ? "Object" : header.loc[0];
      const fieldName = header.loc[header.loc.length - 1];
      fieldNames.push({ wheretoLook, fieldName });
      const commonProperties = {
        field: fieldName,
        loc: header.loc,
        type: type,
        headerName: header.name.toUpperCase(),
        width: 20,
        align: "left",
        headerAlign: "left",
        cellClassName:
          fieldName === "Address"
            ? "truncate-cell"
            : fieldName === "SKU"
            ? "truncate-cell-sku"
            : "",
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === "contains"
        ),
      };
      let additionalProperties = {};
      if (type === "status") {
        additionalProperties.renderCell = (params) => (
          <StatusComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
      } else if (type === "date") {
        additionalProperties.renderCell = (params) => (
          <DateComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
        additionalProperties.valueGetter = (params) => {
          const dateString = params.row[params.field];
          if (dateString) {
            const dateObject = new Date(dateString);
            return dateObject;
          }
        };
      } else if (type === "char") {
        additionalProperties.renderCell = (params) => (
          <CharComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
      } else if (type === "datetime") {
        additionalProperties.renderCell = (params) => (
          <DatetimeComponent
            id={params.id}
            value={params.value}
            loc={params.loc}
            field={params.field}
            row={params.row}
          />
        );
      } else {
        additionalProperties.renderCell = (params) => (
          <CharComponent
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
          />
        );
      }

      return {
        ...commonProperties,
        ...additionalProperties,
      };
    }),
  ];
  return { initialColumns, fieldNames };
};

export default function SDSGrid(props) {
  const { handleRowsSelection } = props;
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const dispatch = useDispatch();
  const { queryOptions, paginationModel, toRefreshData } = useSelector(
    (state) =>
      state.sdsData || {
        queryOptions: {},
        paginationModel: {
          page: 0,
          pageSize: 10,
          lastPage: null,
          rowCountState: null,
        },
        toRefreshData: false,
      }
  );
  const metadata = JSON.parse(localStorage.getItem("metadata"))?.sds;
  const columnHeaders = metadata.home.filter((item) => item.order);
  columnHeaders.sort(function (a, b) {
    return a.order - b.order;
  });

  const navigate = useNavigate();

  const { initialColumns, fieldNames } = getInitialColumns(columnHeaders);

  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState(initialColumns);
  const [rows, setRows] = useState([]);

  const columnsRef = useRef(initialColumns);
  const containerRef = useRef(null);

  const handleRowClick = (params) => {
    const dbId = params?.row?.db_id;
    // console.log(dbId);
    if (dbId) {
      navigate(`/business-automation/documents/${params.row.db_id}`);
    }
  };

  const handlePageChange = (event, newPage) => {
    let query = {};
    if (!isObjectEmpty(queryOptions)) {
      queryOptions?.items.forEach((item) => {
        query[item.field] = item.value;
      });
    }
    fetchServerData(newPage, paginationModel.pageSize, query);
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    let query = {};
    if (!isObjectEmpty(queryOptions)) {
      queryOptions?.items.forEach((item) => {
        query[item.field] = item.value;
      });
    }
    fetchServerData(0, newPageSize, query);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedRows = [];
    newSelectionModel.forEach((selectedRowID) => {
      const row = rows.find((row) => row.id === selectedRowID);
      selectedRows.push({
        id: row.db_id,
        contact_status: row.contact_status,
        contact_type: row.contact_type,
      });
    });
    handleRowsSelection(selectedRows);
  };

  const onFilterChange = (filterModel) => {
    dispatch(setQueryOptions({ ...filterModel }));

    let query = {};
    filterModel.items.forEach((item) => {
      query[item.field] = item.value;
    });
    fetchServerData(0, paginationModel?.pageSize, query);
  };

  const handleResize = (columns) => {
    const containerWidth = containerRef.current.offsetWidth;
    const totalWidth = containerWidth;
    const numOfColumns = columns.length;
    let ColumnWidth = 9.7;
    if (columns.length === 9) {
      ColumnWidth = 10.75;
    } else if (columns.length === 8) {
      ColumnWidth = 12.12;
    } else if (columns.length === 7) {
      ColumnWidth = 13.85;
    } else if (columns.length === 6) {
      ColumnWidth = 16.15;
    }
    let sizeWidth;
    if (window.innerWidth <= 400) {
      sizeWidth = 37;
    } else if (window.innerWidth <= 825) {
      sizeWidth = 23;
    } else {
      sizeWidth = ColumnWidth;
    }

    const newColumns = columns.map((col, index) => {
      let width = sizeWidth;
      return {
        ...col,
        width: (totalWidth * width) / 100,
      };
    });
    columnsRef.current = newColumns;
    setColumns(newColumns);
  };

  const fetchServerData = async (page, pageSize, filterModel) => {
    try {
      setIsLoading(true);

      const params = {
        page: page + 1,
        page_size: pageSize,
        ...filterModel,
      };

      // creating dynamic url
      let parameterLink = Object.entries(params).reduce(
        (parameterLink, [itemKey, itemValue]) => {
          if (itemValue) {
            if (parameterLink !== "") {
              parameterLink += "&";
            }
            parameterLink += `${itemKey}=${itemValue}`;
          }
          return parameterLink;
        },
        ""
      );
      // console.log(parameterLink);
      let dynamicUrl = `${localStorage.getItem(
        "base_url"
      )}/api/method/drs_backend.api.return_all_transaction_documents?${parameterLink}`;
     
      const res = await getAllSDSlistAPIerp(dynamicUrl);
      // console.log(res);

      if (res.status == 200) {
        const data = await res.data;

        const resultsList = res.data.results;
        let tempList = resultsList.map((card, index) => {
          let temp = {
            belongs_to: card.company,
            unique_document_id: card.unique_document_id,
            document_type: card.document_type,
            created_at: card.creation,
            id: card.name,
            name: card.name,
            image: card.image,
            file: card.file,
            status: card.status,
            sync_status: card.sync_status,
            sync_details: card.sync_details,
            misc_data: card.misc_data,
            table_data: card.table_data_metadata,
            comments: card._comments,
          };
          return temp;
        });

        const tempRowData = [];
        const totalRowCount = data?.totalCount || 0;
        dispatch(
          setPaginationModel({
            page: page,
            pageSize: pageSize,
            rowCountState: totalRowCount,
            lastPage: data?.lastPage || 1,
          })
        );

        tempList.forEach((row, index) => {
          const item = {};
          item["db_id"] = row.name;
          item["id"] = page * pageSize + index + 1;
          fieldNames.forEach((fieldItem) => {
            if (fieldItem.wheretoLook === "Object") {
              item[fieldItem.fieldName] = row[fieldItem.fieldName];
            } else {
              let value = row[fieldItem.wheretoLook]
                ? row[fieldItem.wheretoLook][fieldItem.fieldName]
                : null;
              value = value !== "" ? value : null;
              item[fieldItem.fieldName] = value;
            }
          });
          tempRowData.push(item);
        });
        setIsLoading(false);
        handleResize(initialColumns);
        setRows(tempRowData);
      }

      // old code
      // const response = await axiosInstance.get(`sds`, { params });
      // const data = await response.data;
      // const tempRowData = [];
      // const totalRowCount = data?.totalCount || 0;

      // dispatch(
      //   setPaginationModel({
      //     page: page,
      //     pageSize: pageSize,
      //     rowCountState: totalRowCount,
      //     lastPage: data?.lastPage || 1,
      //   })
      // );
      // data.results.forEach((row, index) => {
      //   const item = {};
      //   item["db_id"] = row.id;
      //   item["id"] = page * pageSize + index + 1;
      //   fieldNames.forEach((fieldItem) => {
      //     if (fieldItem.wheretoLook === "Object") {
      //       item[fieldItem.fieldName] = row[fieldItem.fieldName];
      //     } else {
      //       let value = row[fieldItem.wheretoLook]
      //         ? row[fieldItem.wheretoLook][fieldItem.fieldName]
      //         : null;
      //       value = value !== "" ? value : null;
      //       item[fieldItem.fieldName] = value;
      //     }
      //   });
      //   tempRowData.push(item);
      // });
      // setIsLoading(false);
      // handleResize(initialColumns);
      // setRows(tempRowData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const query = {};
    if (queryOptions && !isObjectEmpty(queryOptions)) {
      queryOptions?.items.forEach((item) => {
        query[item.field] = item.value;
      });
    }
    fetchServerData(
      paginationModel?.page || 0,
      paginationModel?.pageSize || 10,
      query
    );
    const handleWindowResize = () => {
      handleResize(columnsRef.current);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (toRefreshData === true) {
      let query = {};
      if (!isObjectEmpty(queryOptions)) {
        queryOptions?.items.forEach((item) => {
          query[item.field] = item.value;
        });
      }
      fetchServerData(0, paginationModel.pageSize, query);
      dispatch(setToRefreshData(false));
    }
  }, [toRefreshData]);

  function Pagination(props) {
    const { onPageChange, className, onRowsPerPageChange } = props;
    const { lastPage, totalCount, page } = paginationModel;
    return (
      <React.Fragment>
        {lastPage && (
          <MuiPagination
            color="primary"
            className={className}
            count={lastPage}
            page={page + 1}
            siblingCount={1}
            boundaryCount={isExtraSmallScreen ? 0 : 1}
            size="small"
            onChange={(event, newPage) => {
              onPageChange(event, newPage - 1);
            }}
            onRowsPerPageChange={(event) => onRowsPerPageChange(event)}
          />
        )}
      </React.Fragment>
    );
  }

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  return (
    <div style={{ height: "100%", width: "100%" }} ref={containerRef}>
      {isLoading && <LinearProgress style={{ width: "100%" }} />}

      {paginationModel?.lastPage && (
        <DataGrid
          loading={isLoading}
          rows={rows}
          rowCount={paginationModel.rowCountState}
          columns={columns}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          initialState={{
            filter: {
              filterModel: queryOptions,
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          pageSize={paginationModel.pageSize}
          paginationMode="server"
          onRowClick={handleRowClick}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleSelectionModelChange}
          // hideFooterPagination={true}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          slots={{ toolbar: CustomGridToolbar, pagination: CustomPagination }}
          slotProps={{
            pagination: {
              onPageChange: handlePageChange,
              onRowsPerPageChange: handlePageSizeChange,
            },
            toolbar: {
              printOptions: { getRowsToExport: getSelectedRowsToExport },
            },
          }}
          sx={{
            ".MuiDataGrid-columnHeader": {
              backgroundColor: "#F5F8FB",
            },
            "& .truncate-cell-sku": {
              whiteSpace: "normal !important",
              overflow: "hidden !important",
              textOverflow: "ellipsis !important",
              display: "-webkit-box !important",
              WebkitBoxOrient: "vertical !important",
              verticalAlign: "middle !important",
              WebkitLineClamp: 2,
              display: "flex",
              alignItems: "center",
            },
            ".MuiDataGrid-cell": {
              verticalAlign: "middle",
            },
            ".MuiTablePagination-selectLabel": {
              display: isMediumScreen ? "none !important" : "block",
              marginTop: "15px",
            },
            ".MuiTablePagination-displayedRows": {
              marginTop: "15px",
            },
          }}
        />
      )}
    </div>
  );
}
