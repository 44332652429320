import { configureStore } from "@reduxjs/toolkit";
import { save, load } from "redux-localstorage-simple";
import calculatorDataSlice from "./slices/calculatorSlice";
import visitingCardDataSlice from "./slices/visitingCardSlice";
import SDSdDataSlice from "./slices/sdsSlice";
import OrderDataSlice from "./slices/OrderSlice";
import connectedAccountDataSlice from "./slices/connectedAccountSlice";

const store = configureStore({
  reducer: {
    calculatorData: calculatorDataSlice,
    visitingCardData: visitingCardDataSlice,
    sdsData: SDSdDataSlice,
    ordersData: OrderDataSlice,
    connectedAccountData: connectedAccountDataSlice,
  },
  preloadedState: load(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()),
});

export default store;
