import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import StripedDataGridBase from "./StripedDataGridBase";
import {
  EditToolbar,
  NumericEditComponentPM,
  SelectEditComponent,
  TextEditComponentPM,
} from "./TableGridComponents";
import { getPackagingMaterialItems } from "../api/erpItems";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePMRows, setPMRows } from "../../../slices/calculatorSlice";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { cleanFilterItem } from "@mui/x-data-grid/hooks/features/filter/gridFilterUtils";
import { useRef } from "react";
import { useTheme } from "@mui/material/styles";

export default function PMTable() {
  const [options, setOptions] = useState([]);
  const [pmItemsInfo, setPmItemsInfo] = useState(null);
  const [stateLoaded, setStateLoaded] = useState(false);
  const { pmRows, pmHeaders } = useSelector((state) => state.calculatorData);
  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Calculator")
      ? connectedApps["Calculator"]
      : null
    : null;
  const myElement = useRef(null);
  const getPMItems = async () => {
    try {
      const res = await getPackagingMaterialItems(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      if (res.error) {
        console.log(res.error);
      } else {
        const itemNames = res.data.data.item_list.map((item) => item.pm_name);
        setOptions(itemNames);
        setPmItemsInfo(res.data.data.item_list);
      }
      setStateLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPMItems();
  }, []);

  return (
    <React.Fragment>
      {stateLoaded && pmHeaders && (
        <RenderTable
          options={options}
          pmItemsInfo={pmItemsInfo}
          pmHeaders={pmHeaders}
          pmRows={pmRows}
        />
      )}
    </React.Fragment>
  );
}

const RenderTable = (props) => {
  const { options, pmItemsInfo, pmRows, pmHeaders } = props;

  const [rows, setRows] = useState(pmRows ? pmRows : []);
  const dispatch = useDispatch();
  const pmContainerRef = useRef(null);

  const handleColumnChange = (columns) => {
    const containerWidth = pmContainerRef.current.offsetWidth;
    const totalWidth = containerWidth - 0.4 * containerWidth; // Subtract any fixed width elements if present
    let sizeWidth;
    if (window.innerWidth <= 400) {
      // for screens smaller or equal to 600px (xs)
      sizeWidth = 42; // 35% width
    } else if (window.innerWidth <= 825) {
      // for screens smaller or equal to 960px (sm to md)
      sizeWidth = 28; // 25% width
    } else {
      // for larger screens (lg and xl)
      sizeWidth = 20; // 20% width
    }
    const newColumns = columns.map((col, index) => {
      // Calculate percentage width based on the total width
      const width = index === 0 ? 10 : index === 1 ? 40 : sizeWidth; // Default widths
      return {
        ...col,
        width: (totalWidth * width) / 100,
      };
    });
    // Set the columns with the updated width values
    setColumns(newColumns);
  };

  useEffect(() => {
    // Call the handleResize function initially and add it to the resize event listener
    const handleResize = () => {
      const containerWidth = pmContainerRef.current.offsetWidth;
      const totalWidth = containerWidth - 0.4 * containerWidth; // Subtract any fixed width elements if present
      let sizeWidth;
      if (window.innerWidth <= 400) {
        // for screens smaller or equal to 600px (xs)
        sizeWidth = 43; // 35% width
      } else if (window.innerWidth <= 825) {
        // for screens smaller or equal to 960px (sm to md)
        sizeWidth = 28; // 25% width
      } else {
        // for larger screens (lg and xl)
        sizeWidth = 18; // 20% width
      }
      const newColumns = columns.map((col, index) => {
        // Calculate percentage width based on the total width
        const width = index === 0 ? 10 : index === 1 ? 35 : sizeWidth; // Default widths
        return {
          ...col,
          width: (totalWidth * width) / 100,
        };
      });
      // Set the columns with the updated width values
      setColumns(newColumns);
    };
    handleResize(columns);
    window.addEventListener("resize", handleResize);

    // Remove the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pmHeaders]);

  // console.log(rows);
  const initialColumns = [
    // { field: "id", headerName: "#", width: 70, align: "left" },
    ...pmHeaders?.labels.map((header, index) => {
      const type = pmHeaders.types[index]; // Assuming 'type' is determined by pmHeaders.editable[index]
      const commonProperties = {
        field: pmHeaders.names[index],
        editable: pmHeaders.editable[index],
        type: pmHeaders.types[index],
        headerName: header,
        // width: index === 0 ? 70 : index === 1 ? 250 : 100,
        width: index === 0 ? 10 : index === 1 ? 40 : 20,
        align: "left",
        headerAlign: "left",
      };
      // Conditional properties based on 'type'
      let additionalProperties = {};
      if (type === "singleSelect") {
        additionalProperties.renderEditCell = (params) => (
          <SelectEditComponent
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
            options={options}
            pmItemsInfo={pmItemsInfo}
            handleChange={handleChange}
          />
        );
        // Add more properties specific to 'text' type if needed
      } else if (type === "number") {
        additionalProperties.renderEditCell = (params) => (
          <NumericEditComponentPM
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
            handleChange={handleChange}
          />
        );
        // Add more properties specific to 'number' type if needed
      } else if (type === "string") {
        additionalProperties.renderEditCell = (params) => (
          <TextEditComponentPM
            id={params.id}
            value={params.value}
            field={params.field}
            row={params.row}
            pmItemsInfo={pmItemsInfo}
            handleChange={handleChange}
          />
        );
      }

      return {
        ...commonProperties,
        ...additionalProperties,
      };
    }),
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const [columns, setColumns] = useState(initialColumns);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    // console.log("Updated rows:", pmRows);
    if (pmRows !== null) {
      setRows(
        pmRows.map((row, index) => ({
          // id: index + 1,
          ...row,
        }))
      );
    } else {
      setRows([]);
    }
  }, [pmRows]);

  const handleChange = (id, keepModeTo = "edit") => {
    handleColumnChange(initialColumns);
    if (keepModeTo === "view") {
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.View, fieldToFocus: "id" },
      }));
    }
  };

  const handleDeleteClick = (id) => () => {
    handleColumnChange(initialColumns);
    dispatch(deletePMRows({ id }));
  };

  const processRowUpdate = (newRow) => {
    // console.log("process row update");
    const updatedRow = rows.find((row) => row.id === newRow.id);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <Box
      ref={pmContainerRef}
      sx={{
        // minHeight: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <StripedDataGridBase
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
          // footer: TableFooter,
        }}
        hideFooter={true}
        slotProps={{
          toolbar: {
            setRowModesModel,
            columns,
            rows,
            handleColumnChange,
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        sx={{
          backgroundColor: "white",
          "& .MuiDataGrid-columnsContainer": {
            borderBottom: "1px solid black",
          },
          // minHeight: "300px",
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "auto", // Allow horizontal scrolling
            minHeight: "200px", // Set max height for virtual scroller
          },
        }}
      />
      <TableFooter />
    </Box>
  );
};

const TableFooter = (props) => {
  const { toShow: headersToShow } = useSelector(
    (state) => state.calculatorData.tableHeaders
  );
  const { pmTableFooter } = useSelector((state) => state.calculatorData);
  // console.log(tableFooter);
  return (
    <React.Fragment>
      {pmTableFooter && (
        <Table sx={{ minWidth: 200 }} aria-label="simple table" stickyHeader>
          <TableBody>
            {pmTableFooter.map((item, index) => {
              return (
                <TableRow
                  key={`table-footer-${index}`}
                  sx={{
                    backgroundColor: item.editable ? "#E4FA97" : "white",
                  }}
                >
                  <TableCell colSpan={headersToShow.labels.length - 2} />
                  <TableCell
                    colSpan={2}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {"Total PM Cost"}
                  </TableCell>
                  <TableCell align="right">{item.value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </React.Fragment>
  );
};
