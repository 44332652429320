import React from 'react';
import { Box, ThemeProvider } from '@mui/system';
import { CardContent, Divider } from '@mui/material';
import img1 from '../../assets/images/Puzzle.png';
import icon1 from '../../assets/images/Icon1.png';
import icon2 from '../../assets/images/Icon2.png'


const CustomBox = () => {
    const divStyling = {
        marginBottom: '-20px',
        marginLeft: '40px',
        color: '#050092',
    }
    const blackFooterStyle = {
        backgroundColor: 'black', // Set the background color to black
        height: '100px', // Adjust the height as needed
        width: '100%',
    };
    return (
        <ThemeProvider>
            <Box
                sx={{
                    width: '77%',
                    height: 550,
                    bgcolor: 'white',
                    fontSize: '36px',
                    marginLeft: '400px',
                    borderRadius: '10px',
                    marginTop: '30px',
                    display: 'flex', // Make the main box a flex container
                }}
            >
                <Box
                    sx={{
                        flex: 1, // Take up 1/3 of the available space
                        padding: '16px',
                        // borderRight: '1px solid #ccc', // Add a border to separate columns
                    }}
                >
                    <img
                        src={img1}
                        style={{ width: '70px', marginTop: '100px', marginLeft: '40px' }}
                    />
                    <div style={{ ...divStyling, marginTop: '20px' }}>
                        <span style={{ fontSize: '35px' }}>Success starts</span>
                    </div>
                    <div style={divStyling}>
                        <span style={{ fontSize: '35px' }}>with a great product</span>
                    </div>
                    <p style={{
                        fontSize: '22px', marginTop: '50px', marginLeft: '40px', color: 'GrayText',
                        fontFamily: 'initial'
                    }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>

                </Box>
                <Box
                    sx={{
                        flex: 1, // Take up 1/3 of the available space
                        padding: '16px',
                        // borderRight: '1px solid #ccc', // Add a border to separate columns
                    }}
                >
                    <img
                        src={icon1}
                        style={{ width: '70px', marginTop: '100px', marginLeft: '40px' }}
                    />
                    <div style={{ ...divStyling, marginTop: '20px' }}>
                        <span style={{ fontSize: '35px' }}>Success starts</span>
                    </div>
                    <div style={divStyling}>
                        <span style={{ fontSize: '35px' }}>with a great product</span>
                    </div>
                    <p style={{
                        fontSize: '22px', marginTop: '50px', marginLeft: '40px', color: 'GrayText',
                        fontFamily: 'initial'
                    }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </Box>
                <Box
                    sx={{
                        flex: 1, // Take up 1/3 of the available space
                        padding: '16px',
                    }}
                >
                    <img
                        src={icon2}
                        style={{ width: '70px', marginTop: '100px', marginLeft: '40px' }}
                    />

                    <div style={{ ...divStyling, marginTop: '20px' }}>
                        <span style={{ fontSize: '35px' }}>Success starts</span>
                    </div>
                    <div style={divStyling}>
                        <span style={{ fontSize: '35px' }}>with a great product</span>
                    </div>
                    <p style={{
                        fontSize: '22px', marginTop: '50px', marginLeft: '40px', color: 'GrayText',
                        fontFamily: 'initial'
                    }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default CustomBox;
