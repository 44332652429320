import axios from "axios";
import { axiosInstance } from "../axiosSetup";

export const UpdateLogoAPI = async (companyID, data) => {
  const res = await axiosInstance.patch(
    `auth/company-settings/${companyID}/`,
    data
  );
  return res;
};

export const getCompantDetailsAPIerp = async () => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");
  // console.log(url);
  const res = await axios.get(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.get_company_details`,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  return res;
};

export const patchCompantDetailsAPIerp = async (data, img) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const formData = new FormData();
  formData.append("phone_no", data.phone_no);
  if (img !== null) {
    formData.append("company_logo", img);
  }

  const res = await axios.put(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.update_company_details`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
