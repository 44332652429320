import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Grid,
  useMediaQuery,
  Button,
  MenuItem,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { getInvoicesAPI } from "../../api/RazorpayXAPI";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllContact } from "../../api/RazorpayXAPI";
import { getAllFundAccount } from "../../api/RazorpayXAPI";
import PaymentModal from "./PaymentModal";

const InvoiceDetail = () => {
  const location = useLocation();
  const invoiceData = location.state && location.state.invoiceData;

  const [contactList, setContactList] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);

  const [FundAccountList, setFundAccountList] = useState(null);
  const [selectedContactFundAccountList, setSelectedContactFundAccountList] = useState([]);
  const [selectedFundAccount, setSelectedFundAccount] = useState([]);

  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);

  const navigateTo = useNavigate();

  const handleBackButtonClick = () => {
    navigateTo(`/payments/invoices`);
  };

  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false);
  };

  const [invoiceDetails, setInvoiceDetail] = useState(null);

  const { connected_apps: connectedApps, connected_accounts } = useSelector(
    (state) => state.connectedAccountData
  );

  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Make Payments")
      ? connectedApps["Make Payments"]
      : null
    : null;

  const connectingRazorPay = connected_accounts
    ? connected_accounts.hasOwnProperty("RazorPayX")
      ? connected_accounts["RazorPayX"]
      : null
    : null;

  // console.log(invoiceData); 
  
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const InvoiceResponse = await getInvoicesAPI(
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret,
          '["*"]',
          { name: invoiceData.name }
        );
        // console.log(InvoiceResponse);
        if (InvoiceResponse.data.data.length > 0) {
          setInvoiceDetail(InvoiceResponse.data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, [invoiceData]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contactsResponse = await getAllContact(
          connectingRazorPay.usr,
          connectingRazorPay.pwd
        );
        setContactList(contactsResponse.data.items);
        setSelectedContact(contactsResponse.data.items[0].name);
        // console.log(contactsResponse.data)
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    fetchContacts();

    const fetchFundAccounts = async () => {
      try {
        const AccountResponse = await getAllFundAccount(
          connectingRazorPay.usr,
          connectingRazorPay.pwd
        );
        setFundAccountList(AccountResponse.data.items);

        
      } catch (error) {
        console.error("Error fetching fund accounts:", error);
      }
    };

    fetchFundAccounts();
  }, []);

  useEffect(() => {
    // console.log(selectedContact, FundAccountList)
    if(selectedContact && FundAccountList){
    // console.log(selectedContact)
    // Find the selected contact
    const selectedContactObj = contactList.find(
      (contact) => contact.name === selectedContact
    );

    // Filter fund accounts based on the selected contact_id
    const filteredFundAccounts = FundAccountList.filter(
      (account) => account.contact_id=== selectedContactObj.id
    );
    
//    console.log("Selected Contact:", selectedContact);
// console.log("Contact ID:", selectedContactObj.id);
// console.log("Filtered Fund Accounts:", filteredFundAccounts);
    
setSelectedContactFundAccountList(filteredFundAccounts)
    if (filteredFundAccounts.length > 0) {
      setSelectedFundAccount(filteredFundAccounts[0].bank_account.account_number);
    }
  }
  }, [selectedContact, FundAccountList]);

  return (
    <div className="page-container">
      <Card
        variant="outlined"
        sx={{ border: "1px solid lightgray" }}
        className="shadow-sm"
      >
        <CardHeader
          title={invoiceDetails ? invoiceDetails.name : "Invoice Details"}
          sx={{ margin: "15px" }}
        />
        <Divider sx={{ backgroundColor: "dark" }} />
        <CardContent sx={{ backgroundColor: "#F5F8FB" }}>
          {invoiceDetails && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Name"
                  value={invoiceDetails.name || ""}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Customer"
                  value={
                    invoiceDetails.customer ? invoiceDetails.customer : "N/A"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Date"
                  value={
                    invoiceDetails.creation ? invoiceDetails.creation : "N/A"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Amount"
                  value={
                    invoiceDetails.grand_total
                      ? invoiceDetails.grand_total
                      : "N/A"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="PO_NO"
                  value={invoiceDetails.po_no ? invoiceDetails.po_no : "N/A"}
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {contactList && (
                  <TextField
                    select
                    label="Select Contact"
                    value={selectedContact}
                    onChange={(event) => setSelectedContact(event.target.value)}
                    fullWidth
                  >
                    {contactList.map((option, index) => (
                      <MenuItem
                        key={`${option.name}${index}`}
                        value={option.name}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                {selectedContactFundAccountList && (
                  <TextField
                    select
                    label="Fund Account Number"
                    value={selectedFundAccount}
                    // disabled={selectedContactFundAccountList.length < 1}
                    onChange={(event) =>
                      setSelectedFundAccount(event.target.value)
                    }
                    fullWidth
                  >
                    {selectedContactFundAccountList.map((option, index) => (
                      <MenuItem
                        key={`${option.name}${index}`}
                        value={option.bank_account.account_number}
                      >
                        {option.bank_account.account_number}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                    onClick={handleBackButtonClick}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      marginTop: "20px",
                      marginLeft: "10px",
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                    onClick={() => setPaymentModalOpen(true)}
                  >
                    Pay Now
                  </Button>
                </Grid>
              </Grid>
              {/* Add more Grid and TextField components to display other invoice details */}
            </Grid>
          )}
        </CardContent>
      </Card>
      {invoiceDetails &&
      <PaymentModal
        open={isPaymentModalOpen}
        onClose={handlePaymentModalClose}
        grandTotal={invoiceDetails ? invoiceDetails.grand_total : 0}
      />
}
    </div>
  );
};

export default InvoiceDetail;
