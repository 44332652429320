import project1 from "../assets/img/pages/project1.png";
import project2 from "../assets/img/pages/project2.png";

const Orders = [
  {
    id: "1",
    sku: "P-0361",
    so: "2003008",
    customer: "BCUS_HF CHEYNNE 01_2B2Y-1.30_1",
    mcsr_no: "200541",
    cdd: "18-06-21",
    paw_wk_no: "2119",
    start_time: "10-May-21",
    bbrd_planned: "03-Jun-21",
    status: "Completed",
    model_name: "2BY",
    leadt_time: "56",
    machine_shop_start_time: "15-Mar-21",
    machine_shop_paw_start: "2111",
    status_today: "OK",
    eta: "10-Jul-21",
  },
  {
    id: "2",
    sku: "P-0361",
    so: "2003008",
    customer: "BCUS_HF CHEYNNE 01_2B2Y-1.30_1",
    mcsr_no: "200542",
    cdd: "18-06-21",
    paw_wk_no: "2120",
    start_time: "17-May-21",
    bbrd_planned: "10-Jun-21",
    status: "Completed",
    model_name: "2BY",
    leadt_time: "56",
    machine_shop_start_time: "2-Mar-21",
    machine_shop_paw_start: "2112",
    status_today: "OK",
    eta: "12-Jul-21",
  },
  {
    id: "3",
    sku: "C-0240",
    so: "2003069",
    customer: "TCPL GRTRNOIDA 16_C3T210.3 GP-1",
    mcsr_no: "700647",
    cdd: "15-04-21",
    paw_wk_no: "2120",
    start_time: "17-May-21",
    bbrd_planned: "23-May-21",
    status: "In Process",
    model_name: "CT",
    leadt_time: "42",
    machine_shop_start_time: "5-Apr-21",
    machine_shop_paw_start: "2114",
    status_today: "IN PROGRESS - 80%",
    eta: "30-Jul-21",
  },
  {
    id: "4",
    sku: "C-0240",
    so: "2003069",
    customer: "TCPL GRTRNOIDA 16_C3T210.3 GP-1",
    mcsr_no: "700648",
    cdd: "15-04-21",
    paw_wk_no: "2120",
    start_time: "17-May-21",
    bbrd_planned: "23-May-21",
    status: "In Process",
    model_name: "SW",
    leadt_time: "42",
    machine_shop_start_time: "5-Apr-21",
    machine_shop_paw_start: "2114",
    status_today: "IN PROGRESS - 72%",
    eta: "9-Aug-21",
  },
  {
    id: "5",
    sku: "C-0213",
    so: "2003034",
    customer: "GRASIM VILAYAT 02_C5T223 GP-1",
    mcsr_no: "700610",
    cdd: "30-06-21",
    paw_wk_no: "2122",
    start_time: "31-May-21",
    bbrd_planned: "06-Jun-21",
    status: "In Process",
    model_name: "CT",
    leadt_time: "42",
    machine_shop_start_time: "19-Apr-21",
    machine_shop_paw_start: "2116",
    status_today: "IN PROGRESS - 58%",
    eta: "31-Aug-21",
  },
  {
    id: "6",
    sku: "C-0240",
    so: "2003069",
    customer: "TCPL GRTRNOIDA 16_C3T210.3 GP-3",
    mcsr_no: "700649",
    cdd: "30-04-21",
    paw_wk_no: "2122",
    start_time: "31-May-21",
    bbrd_planned: "06-Jun-21",
    status: "In Process",
    model_name: "CU",
    leadt_time: "42",
    machine_shop_start_time: "19-Apr-21",
    machine_shop_paw_start: "2116",
    status_today: "IN PROGRESS - 60%",
    eta: "31-Aug-21",
  },
  {
    id: "7",
    sku: "C-0213",
    so: "2003034",
    customer: "TCPL GRTRNOIDA 16_C3T210.3 GP-4",
    mcsr_no: "700650",
    cdd: "30-06-21",
    paw_wk_no: "2122",
    start_time: "31-May-21",
    bbrd_planned: "06-Jun-21",
    status: "In Process",
    model_name: "3CY",
    leadt_time: "42",
    machine_shop_start_time: "19-Apr-21",
    machine_shop_paw_start: "2116",
    status_today: "IN PROGRESS - 60%",
    eta: "31-Aug-21",
  },
  // {
  //   id: "8",
  //   sku: "C-0231",
  //   so: "2003040",
  //   customer: "GACL DAHEJ 03_C4T224 GP",
  //   mcsr_no: "700620",
  //   cdd: "15-07-21",
  //   paw_wk_no: "2123",
  //   start_time: "07-Jun-21",
  //   bbrd_planned: "13-Jun-21",
  //   status: "In Process",
  //   model_name: "CU",
  //   leadt_time: "42",
  //   machine_shop_start_time: "26-Apr-21",
  //   machine_shop_paw_start: "2117",
  //   status_today: "IN PROCESS",
  //   eta: "07-Sep-21",
  // },
  // {
  //   id: "9",
  //   sku: "C-0213",
  //   so: "2003040",
  //   customer: "GACL DAHEJ 03_C4T224 GP",
  //   mcsr_no: "700621",
  //   cdd: "15-07-21",
  //   paw_wk_no: "2124",
  //   start_time: "14-Jun-21",
  //   bbrd_planned: "20-Jun-21",
  //   status: "In Process",
  //   model_name: "2BY",
  //   leadt_time: "42",
  //   machine_shop_start_time: "03-May-21",
  //   machine_shop_paw_start: "2118",
  //   status_today: "IN PROGRESS - 20%",
  //   eta: "14-Sep-21",
  // },
];

const ProjectData = [
  {
    id: "0",
    name: "Project1",
    image: project1,
  },
  { id: "1", name: "Project2", image: project2 },
  { id: "2", name: "Project3", image: project1 },
  { id: "3", name: "Project4", image: project1 },
  { id: "4", name: "Project5", image: project1 },
  { id: "5", name: "Project6", image: project1 },
  { id: "6", name: "Project7", image: project1 },
  { id: "7", name: "Project8", image: project1 },
  { id: "8", name: "Project9", image: project1 },
  { id: "9", name: "Project10", image: project1 },
];

const multiProject = [
  {
    Project: "1",
    Resource: "VTL",
    Task: "BEARING HOUSE NDE",
    Start: 0,
    Duration: 5,
    Finish: 5,
  },
  {
    Project: "1",
    Resource: "VTL",
    Task: "PILOT CYLINDER",
    Start: 5,
    Duration: 1,
    Finish: 6,
  },
  {
    Project: "2",
    Resource: "VTL",
    Task: "2.BEARING HOUSE NDE",
    Start: 6,
    Duration: 5,
    Finish: 11,
  },
  {
    Project: "2",
    Resource: "VTL",
    Task: "2.PILOT CYLINDER",
    Start: 11,
    Duration: 1,
    Finish: 12,
  },
  {
    Project: "3",
    Resource: "VTL",
    Task: "3.BEARING HOUSE NDE",
    Start: 12,
    Duration: 5,
    Finish: 17,
  },
  {
    Project: "3",
    Resource: "VTL",
    Task: "3.PILOT CYLINDER",
    Start: 17,
    Duration: 1,
    Finish: 18,
  },
  {
    Project: "1",
    Resource: "HON",
    Task: "PILOT CYLINDER",
    Start: 6,
    Duration: 5,
    Finish: 11,
  },
  {
    Project: "2",
    Resource: "HON",
    Task: "2.PILOT CYLINDER",
    Start: 12,
    Duration: 5,
    Finish: 17,
  },
  {
    Project: "3",
    Resource: "HON",
    Task: "3.PILOT CYLINDER",
    Start: 18,
    Duration: 5,
    Finish: 23,
  },
  {
    Project: "1",
    Resource: "DEB",
    Task: "BEARING HOUSE NDE",
    Start: 8,
    Duration: 1,
    Finish: 9,
  },
  {
    Project: "1",
    Resource: "DEB",
    Task: "PILOT CYLINDER",
    Start: 11,
    Duration: 4,
    Finish: 15,
  },
  {
    Project: "1",
    Resource: "DEB",
    Task: "CYLINDER HEAD",
    Start: 5,
    Duration: 3,
    Finish: 8,
  },
  {
    Project: "2",
    Resource: "DEB",
    Task: "2.BEARING HOUSE NDE",
    Start: 15,
    Duration: 1,
    Finish: 16,
  },
  {
    Project: "2",
    Resource: "DEB",
    Task: "2.PILOT CYLINDER",
    Start: 17,
    Duration: 4,
    Finish: 21,
  },
  {
    Project: "2",
    Resource: "DEB",
    Task: "2.CYLINDER HEAD",
    Start: 21,
    Duration: 3,
    Finish: 24,
  },
  {
    Project: "3",
    Resource: "DEB",
    Task: "3.BEARING HOUSE NDE",
    Start: 24,
    Duration: 1,
    Finish: 25,
  },
  {
    Project: "3",
    Resource: "DEB",
    Task: "3.PILOT CYLINDER",
    Start: 25,
    Duration: 4,
    Finish: 29,
  },
  {
    Project: "3",
    Resource: "DEB",
    Task: "3.CYLINDER HEAD",
    Start: 29,
    Duration: 3,
    Finish: 32,
  },
  {
    Project: "1",
    Resource: "INP",
    Task: "SUCTION VALVE",
    Start: 46,
    Duration: 2,
    Finish: 48,
  },
  {
    Project: "1",
    Resource: "INP",
    Task: "DISCHARGE SEAT",
    Start: 26,
    Duration: 3,
    Finish: 29,
  },
  {
    Project: "1",
    Resource: "INP",
    Task: "BEARING HOUSE NDE",
    Start: 13,
    Duration: 3,
    Finish: 16,
  },
  {
    Project: "1",
    Resource: "INP",
    Task: "PILOT CYLINDER",
    Start: 16,
    Duration: 1,
    Finish: 17,
  },
  {
    Project: "1",
    Resource: "INP",
    Task: "CYLINDER HEAD",
    Start: 8,
    Duration: 3,
    Finish: 11,
  },
  {
    Project: "1",
    Resource: "INP",
    Task: "COMP SEAL",
    Start: 11,
    Duration: 2,
    Finish: 13,
  },
  {
    Project: "2",
    Resource: "INP",
    Task: "2.SUCTION VALVE",
    Start: 53,
    Duration: 2,
    Finish: 55,
  },
  {
    Project: "2",
    Resource: "INP",
    Task: "2.DISCHARGE SEAT",
    Start: 33,
    Duration: 3,
    Finish: 36,
  },
  {
    Project: "2",
    Resource: "INP",
    Task: "2.BEARING HOUSE NDE",
    Start: 17,
    Duration: 3,
    Finish: 20,
  },
  {
    Project: "2",
    Resource: "INP",
    Task: "2.PILOT CYLINDER",
    Start: 22,
    Duration: 1,
    Finish: 23,
  },
  {
    Project: "2",
    Resource: "INP",
    Task: "2.CYLINDER HEAD",
    Start: 30,
    Duration: 3,
    Finish: 33,
  },
  {
    Project: "2",
    Resource: "INP",
    Task: "2.COMP SEAL",
    Start: 51,
    Duration: 2,
    Finish: 53,
  },
  {
    Project: "3",
    Resource: "INP",
    Task: "3.SUCTION VALVE",
    Start: 36,
    Duration: 2,
    Finish: 38,
  },
  {
    Project: "3",
    Resource: "INP",
    Task: "3.DISCHARGE SEAT",
    Start: 48,
    Duration: 3,
    Finish: 51,
  },
  {
    Project: "3",
    Resource: "INP",
    Task: "3.BEARING HOUSE NDE",
    Start: 38,
    Duration: 3,
    Finish: 41,
  },
  {
    Project: "3",
    Resource: "INP",
    Task: "3.PILOT CYLINDER",
    Start: 29,
    Duration: 1,
    Finish: 30,
  },
  {
    Project: "3",
    Resource: "INP",
    Task: "3.CYLINDER HEAD",
    Start: 41,
    Duration: 3,
    Finish: 44,
  },
  {
    Project: "3",
    Resource: "INP",
    Task: "3.COMP SEAL",
    Start: 24,
    Duration: 2,
    Finish: 26,
  },
  {
    Project: "1",
    Resource: "HYDRO",
    Task: "BEARING HOUSE NDE",
    Start: 16,
    Duration: 4,
    Finish: 20,
  },
  {
    Project: "1",
    Resource: "HYDRO",
    Task: "PILOT CYLINDER",
    Start: 24,
    Duration: 4,
    Finish: 28,
  },
  {
    Project: "1",
    Resource: "HYDRO",
    Task: "CYLINDER HEAD",
    Start: 11,
    Duration: 2,
    Finish: 13,
  },
  {
    Project: "2",
    Resource: "HYDRO",
    Task: "2.BEARING HOUSE NDE",
    Start: 20,
    Duration: 4,
    Finish: 24,
  },
  {
    Project: "2",
    Resource: "HYDRO",
    Task: "2.PILOT CYLINDER",
    Start: 36,
    Duration: 4,
    Finish: 40,
  },
  {
    Project: "2",
    Resource: "HYDRO",
    Task: "2.CYLINDER HEAD",
    Start: 34,
    Duration: 2,
    Finish: 36,
  },
  {
    Project: "3",
    Resource: "HYDRO",
    Task: "3.BEARING HOUSE NDE",
    Start: 41,
    Duration: 4,
    Finish: 45,
  },
  {
    Project: "3",
    Resource: "HYDRO",
    Task: "3.PILOT CYLINDER",
    Start: 30,
    Duration: 4,
    Finish: 34,
  },
  {
    Project: "3",
    Resource: "HYDRO",
    Task: "3.CYLINDER HEAD",
    Start: 45,
    Duration: 2,
    Finish: 47,
  },
  {
    Project: "1",
    Resource: "PAINT",
    Task: "BEARING HOUSE NDE",
    Start: 20,
    Duration: 1,
    Finish: 21,
  },
  {
    Project: "1",
    Resource: "PAINT",
    Task: "PILOT CYLINDER",
    Start: 28,
    Duration: 3,
    Finish: 31,
  },
  {
    Project: "1",
    Resource: "PAINT",
    Task: "CYLINDER HEAD",
    Start: 13,
    Duration: 4,
    Finish: 17,
  },
  {
    Project: "2",
    Resource: "PAINT",
    Task: "2.BEARING HOUSE NDE",
    Start: 24,
    Duration: 1,
    Finish: 25,
  },
  {
    Project: "2",
    Resource: "PAINT",
    Task: "2.PILOT CYLINDER",
    Start: 41,
    Duration: 3,
    Finish: 44,
  },
  {
    Project: "2",
    Resource: "PAINT",
    Task: "2.CYLINDER HEAD",
    Start: 37,
    Duration: 4,
    Finish: 41,
  },
  {
    Project: "3",
    Resource: "PAINT",
    Task: "3.BEARING HOUSE NDE",
    Start: 45,
    Duration: 1,
    Finish: 46,
  },
  {
    Project: "3",
    Resource: "PAINT",
    Task: "3.PILOT CYLINDER",
    Start: 34,
    Duration: 3,
    Finish: 37,
  },
  {
    Project: "3",
    Resource: "PAINT",
    Task: "3.CYLINDER HEAD",
    Start: 47,
    Duration: 4,
    Finish: 51,
  },
  {
    Project: "1",
    Resource: "GR",
    Task: "SUCTION VALVE",
    Start: 58,
    Duration: 3,
    Finish: 61,
  },
  {
    Project: "1",
    Resource: "GR",
    Task: "DISCHARGE SEAT",
    Start: 29,
    Duration: 4,
    Finish: 33,
  },
  {
    Project: "1",
    Resource: "GR",
    Task: "BEARING HOUSE NDE",
    Start: 22,
    Duration: 4,
    Finish: 26,
  },
  {
    Project: "1",
    Resource: "GR",
    Task: "PILOT CYLINDER",
    Start: 33,
    Duration: 5,
    Finish: 38,
  },
  {
    Project: "1",
    Resource: "GR",
    Task: "CYLINDER HEAD",
    Start: 17,
    Duration: 5,
    Finish: 22,
  },
  {
    Project: "1",
    Resource: "GR",
    Task: "COMP SEAL",
    Start: 13,
    Duration: 3,
    Finish: 16,
  },
  {
    Project: "2",
    Resource: "GR",
    Task: "2.SUCTION VALVE",
    Start: 55,
    Duration: 3,
    Finish: 58,
  },
  {
    Project: "2",
    Resource: "GR",
    Task: "2.DISCHARGE SEAT",
    Start: 38,
    Duration: 4,
    Finish: 42,
  },
  {
    Project: "2",
    Resource: "GR",
    Task: "2.BEARING HOUSE NDE",
    Start: 42,
    Duration: 4,
    Finish: 46,
  },
  {
    Project: "2",
    Resource: "GR",
    Task: "2.PILOT CYLINDER",
    Start: 71,
    Duration: 5,
    Finish: 76,
  },
  {
    Project: "2",
    Resource: "GR",
    Task: "2.CYLINDER HEAD",
    Start: 46,
    Duration: 5,
    Finish: 51,
  },
  {
    Project: "2",
    Resource: "GR",
    Task: "2.COMP SEAL",
    Start: 61,
    Duration: 3,
    Finish: 64,
  },
  {
    Project: "3",
    Resource: "GR",
    Task: "3.SUCTION VALVE",
    Start: 64,
    Duration: 3,
    Finish: 67,
  },
  {
    Project: "3",
    Resource: "GR",
    Task: "3.DISCHARGE SEAT",
    Start: 67,
    Duration: 4,
    Finish: 71,
  },
  {
    Project: "3",
    Resource: "GR",
    Task: "3.BEARING HOUSE NDE",
    Start: 51,
    Duration: 4,
    Finish: 55,
  },
  {
    Project: "3",
    Resource: "GR",
    Task: "3.PILOT CYLINDER",
    Start: 76,
    Duration: 5,
    Finish: 81,
  },
  {
    Project: "3",
    Resource: "GR",
    Task: "3.CYLINDER HEAD",
    Start: 81,
    Duration: 5,
    Finish: 86,
  },
  {
    Project: "3",
    Resource: "GR",
    Task: "3.COMP SEAL",
    Start: 26,
    Duration: 3,
    Finish: 29,
  },
  {
    Project: "1",
    Resource: "ST",
    Task: "CYLINDER HEAD",
    Start: 0,
    Duration: 4,
    Finish: 4,
  },
  {
    Project: "2",
    Resource: "ST",
    Task: "2.CYLINDER HEAD",
    Start: 4,
    Duration: 4,
    Finish: 8,
  },
  {
    Project: "3",
    Resource: "ST",
    Task: "3.CYLINDER HEAD",
    Start: 8,
    Duration: 4,
    Finish: 12,
  },
  {
    Project: "1",
    Resource: "HMC",
    Task: "BEARING HOUSE NDE",
    Start: 5,
    Duration: 3,
    Finish: 8,
  },
  {
    Project: "1",
    Resource: "HMC",
    Task: "CYLINDER HEAD",
    Start: 4,
    Duration: 1,
    Finish: 5,
  },
  {
    Project: "2",
    Resource: "HMC",
    Task: "2.BEARING HOUSE NDE",
    Start: 11,
    Duration: 3,
    Finish: 14,
  },
  {
    Project: "2",
    Resource: "HMC",
    Task: "2.CYLINDER HEAD",
    Start: 8,
    Duration: 1,
    Finish: 9,
  },
  {
    Project: "3",
    Resource: "HMC",
    Task: "3.BEARING HOUSE NDE",
    Start: 17,
    Duration: 3,
    Finish: 20,
  },
  {
    Project: "3",
    Resource: "HMC",
    Task: "3.CYLINDER HEAD",
    Start: 14,
    Duration: 1,
    Finish: 15,
  },
  {
    Project: "1",
    Resource: "BAND SAW",
    Task: "SUCTION VALVE",
    Start: 7,
    Duration: 3,
    Finish: 10,
  },
  {
    Project: "1",
    Resource: "BAND SAW",
    Task: "DISCHARGE SEAT",
    Start: 3,
    Duration: 2,
    Finish: 5,
  },
  {
    Project: "2",
    Resource: "BAND SAW",
    Task: "2.SUCTION VALVE",
    Start: 0,
    Duration: 3,
    Finish: 3,
  },
  {
    Project: "2",
    Resource: "BAND SAW",
    Task: "2.DISCHARGE SEAT",
    Start: 5,
    Duration: 2,
    Finish: 7,
  },
  {
    Project: "3",
    Resource: "BAND SAW",
    Task: "3.SUCTION VALVE",
    Start: 10,
    Duration: 3,
    Finish: 13,
  },
  {
    Project: "3",
    Resource: "BAND SAW",
    Task: "3.DISCHARGE SEAT",
    Start: 13,
    Duration: 2,
    Finish: 15,
  },
  {
    Project: "1",
    Resource: "CTX",
    Task: "SUCTION VALVE",
    Start: 10,
    Duration: 1,
    Finish: 11,
  },
  {
    Project: "1",
    Resource: "CTX",
    Task: "DISCHARGE SEAT",
    Start: 6,
    Duration: 3,
    Finish: 9,
  },
  {
    Project: "1",
    Resource: "CTX",
    Task: "COMP SEAL",
    Start: 2,
    Duration: 4,
    Finish: 6,
  },
  {
    Project: "2",
    Resource: "CTX",
    Task: "2.SUCTION VALVE",
    Start: 9,
    Duration: 1,
    Finish: 10,
  },
  {
    Project: "2",
    Resource: "CTX",
    Task: "2.DISCHARGE SEAT",
    Start: 11,
    Duration: 3,
    Finish: 14,
  },
  {
    Project: "2",
    Resource: "CTX",
    Task: "2.COMP SEAL",
    Start: 22,
    Duration: 4,
    Finish: 26,
  },
  {
    Project: "3",
    Resource: "CTX",
    Task: "3.SUCTION VALVE",
    Start: 14,
    Duration: 1,
    Finish: 15,
  },
  {
    Project: "3",
    Resource: "CTX",
    Task: "3.DISCHARGE SEAT",
    Start: 19,
    Duration: 3,
    Finish: 22,
  },
  {
    Project: "3",
    Resource: "CTX",
    Task: "3.COMP SEAL",
    Start: 15,
    Duration: 4,
    Finish: 19,
  },
  {
    Project: "1",
    Resource: "VMC",
    Task: "SUCTION VALVE",
    Start: 16,
    Duration: 2,
    Finish: 18,
  },
  {
    Project: "1",
    Resource: "VMC",
    Task: "DISCHARGE SEAT",
    Start: 9,
    Duration: 2,
    Finish: 11,
  },
  {
    Project: "2",
    Resource: "VMC",
    Task: "2.SUCTION VALVE",
    Start: 11,
    Duration: 2,
    Finish: 13,
  },
  {
    Project: "2",
    Resource: "VMC",
    Task: "2.DISCHARGE SEAT",
    Start: 14,
    Duration: 2,
    Finish: 16,
  },
  {
    Project: "3",
    Resource: "VMC",
    Task: "3.SUCTION VALVE",
    Start: 18,
    Duration: 2,
    Finish: 20,
  },
  {
    Project: "3",
    Resource: "VMC",
    Task: "3.DISCHARGE SEAT",
    Start: 22,
    Duration: 2,
    Finish: 24,
  },
  {
    Project: "1",
    Resource: "VIBRO DEB",
    Task: "SUCTION VALVE",
    Start: 30,
    Duration: 4,
    Finish: 34,
  },
  {
    Project: "1",
    Resource: "VIBRO DEB",
    Task: "DISCHARGE SEAT",
    Start: 11,
    Duration: 3,
    Finish: 14,
  },
  {
    Project: "1",
    Resource: "VIBRO DEB",
    Task: "COMP SEAL",
    Start: 6,
    Duration: 3,
    Finish: 9,
  },
  {
    Project: "2",
    Resource: "VIBRO DEB",
    Task: "2.SUCTION VALVE",
    Start: 37,
    Duration: 4,
    Finish: 41,
  },
  {
    Project: "2",
    Resource: "VIBRO DEB",
    Task: "2.DISCHARGE SEAT",
    Start: 16,
    Duration: 3,
    Finish: 19,
  },
  {
    Project: "2",
    Resource: "VIBRO DEB",
    Task: "2.COMP SEAL",
    Start: 34,
    Duration: 3,
    Finish: 37,
  },
  {
    Project: "3",
    Resource: "VIBRO DEB",
    Task: "3.SUCTION VALVE",
    Start: 22,
    Duration: 4,
    Finish: 26,
  },
  {
    Project: "3",
    Resource: "VIBRO DEB",
    Task: "3.DISCHARGE SEAT",
    Start: 26,
    Duration: 3,
    Finish: 29,
  },
  {
    Project: "3",
    Resource: "VIBRO DEB",
    Task: "3.COMP SEAL",
    Start: 19,
    Duration: 3,
    Finish: 22,
  },
  {
    Project: "1",
    Resource: "GLASS BEE",
    Task: "SUCTION VALVE",
    Start: 34,
    Duration: 2,
    Finish: 36,
  },
  {
    Project: "1",
    Resource: "GLASS BEE",
    Task: "DISCHARGE SEAT",
    Start: 15,
    Duration: 1,
    Finish: 16,
  },
  {
    Project: "2",
    Resource: "GLASS BEE",
    Task: "2.SUCTION VALVE",
    Start: 41,
    Duration: 2,
    Finish: 43,
  },
  {
    Project: "2",
    Resource: "GLASS BEE",
    Task: "2.DISCHARGE SEAT",
    Start: 19,
    Duration: 1,
    Finish: 20,
  },
  {
    Project: "3",
    Resource: "GLASS BEE",
    Task: "3.SUCTION VALVE",
    Start: 26,
    Duration: 2,
    Finish: 28,
  },
  {
    Project: "3",
    Resource: "GLASS BEE",
    Task: "3.DISCHARGE SEAT",
    Start: 29,
    Duration: 1,
    Finish: 30,
  },
  {
    Project: "1",
    Resource: "LAPPING",
    Task: "SUCTION VALVE",
    Start: 38,
    Duration: 5,
    Finish: 43,
  },
  {
    Project: "1",
    Resource: "LAPPING",
    Task: "DISCHARGE SEAT",
    Start: 16,
    Duration: 4,
    Finish: 20,
  },
  {
    Project: "1",
    Resource: "LAPPING",
    Task: "COMP SEAL",
    Start: 9,
    Duration: 2,
    Finish: 11,
  },
  {
    Project: "2",
    Resource: "LAPPING",
    Task: "2.SUCTION VALVE",
    Start: 45,
    Duration: 5,
    Finish: 50,
  },
  {
    Project: "2",
    Resource: "LAPPING",
    Task: "2.DISCHARGE SEAT",
    Start: 24,
    Duration: 4,
    Finish: 28,
  },
  {
    Project: "2",
    Resource: "LAPPING",
    Task: "2.COMP SEAL",
    Start: 43,
    Duration: 2,
    Finish: 45,
  },
  {
    Project: "3",
    Resource: "LAPPING",
    Task: "3.SUCTION VALVE",
    Start: 28,
    Duration: 5,
    Finish: 33,
  },
  {
    Project: "3",
    Resource: "LAPPING",
    Task: "3.DISCHARGE SEAT",
    Start: 33,
    Duration: 4,
    Finish: 37,
  },
  {
    Project: "3",
    Resource: "LAPPING",
    Task: "3.COMP SEAL",
    Start: 22,
    Duration: 2,
    Finish: 24,
  },
  {
    Project: "1",
    Resource: "ULTRA",
    Task: "SUCTION VALVE",
    Start: 43,
    Duration: 3,
    Finish: 46,
  },
  {
    Project: "1",
    Resource: "ULTRA",
    Task: "DISCHARGE SEAT",
    Start: 20,
    Duration: 5,
    Finish: 25,
  },
  {
    Project: "2",
    Resource: "ULTRA",
    Task: "2.SUCTION VALVE",
    Start: 50,
    Duration: 3,
    Finish: 53,
  },
  {
    Project: "2",
    Resource: "ULTRA",
    Task: "2.DISCHARGE SEAT",
    Start: 28,
    Duration: 5,
    Finish: 33,
  },
  {
    Project: "3",
    Resource: "ULTRA",
    Task: "3.SUCTION VALVE",
    Start: 33,
    Duration: 3,
    Finish: 36,
  },
  {
    Project: "3",
    Resource: "ULTRA",
    Task: "3.DISCHARGE SEAT",
    Start: 37,
    Duration: 5,
    Finish: 42,
  },
  {
    Project: "1",
    Resource: "SR",
    Task: "COMP SEAL",
    Start: 0,
    Duration: 2,
    Finish: 2,
  },
  {
    Project: "2",
    Resource: "SR",
    Task: "2.COMP SEAL",
    Start: 2,
    Duration: 2,
    Finish: 4,
  },
  {
    Project: "3",
    Resource: "SR",
    Task: "3.COMP SEAL",
    Start: 4,
    Duration: 2,
    Finish: 6,
  },
];
export { Orders, ProjectData, multiProject };
