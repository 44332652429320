import React, { useEffect } from 'react'
import { Spinner, Alert, Row, Container, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ActivateAccountAPI } from '../../api/auth';
import { URLs } from '../../routes';

const VerifyEmail = () => {

    const { token } = useParams();
    let navigate = useNavigate();



    const extractData = (tokenData) => {

        let temp = tokenData.slice(5);
        let temp2 = temp.slice(0, temp.length - 5);

        return temp2;
    }

    const sendActivateAccountAPI = async (id) => {
        const res = await ActivateAccountAPI(id);
        if (res.error) {
            console.log(res.error)

        } else {
            navigate(URLs.Signin.path, {
                state: {
                    showAlertWL: true,
                    alertVariantWL: "success",
                    alertMsgWL: "Email Verification Successfull",
                },
            });
        }

    }
    useEffect(() => {
        if (token) {

            const idData = extractData(token);
            sendActivateAccountAPI(idData);
        }

    }, []);

    return (


        <main>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <Row
                        className="justify-content-center"
                    >
                        <Spinner animation="border" role="status"> </Spinner>
                    </Row>
                    <Row
                        className="justify-content-center my-3"
                    >
                        <Alert className='text-center' variant="secondary">
                            Verifing Your Email......
                        </Alert>
                    </Row>
                </Container>
            </section>
        </main>
    )
}

export default VerifyEmail