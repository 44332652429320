import {
  Add,
  AddCircleOutline,
  ArrowBack,
  CheckBox,
  CheckBoxOutlineBlank,
  Close,
  ContentCopy,
  DeleteOutline,
  LibraryAdd,
  ListAlt,
  Share,
  Upload,
  GetApp,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  useMediaQuery,
  Pagination,
  Grid,
  Stepper,
  Typography,
  Step,
  Box,
  StepLabel,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  FormControl,
  OutlinedInput,
  Chip,
  Snackbar,
  Alert,
  Stack,
  Modal,
  Input,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./VQform.css";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getQuestionBankNameListAPI } from "../../api/FormsAPI";
import { URLs } from "../../routes.js";
import { useSelector } from "react-redux";
import RetryCA from "../../components/RetryCA.jsx";

const VQform = () => {
  // const isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  // const [newbtnClicked, setNewbtnClicked] = useState(false);
  const [newFormCSVData, setNewFormCSVData] = useState(null);
  const [qBNameList, setQBNameList] = useState(null);
  const [snakbarST, setSnakbarST] = useState({
    openSnakbar: false,
    snackbarMessage: "",
    severity: "info",
  });
  const [openCsvModal, setOpenCsvModal] = useState(false);

  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("DigitalForms")
      ? connectedApps["DigitalForms"]
      : null
    : null;

  useEffect(() => {
    getQBNameList();
  }, []);

  const getQBNameList = async () => {
    try {
      const res = await getQuestionBankNameListAPI(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      setQBNameList(res.data.result);
      // console.log(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadCsv = () => setOpenCsvModal(true);
  const handleCloseCsvModal = () => {
    setOpenCsvModal(false);
    setNewFormCSVData(null);
  };

  const handleChangeNewCSVData = (event) => {
    const { name, value } = event.target;
    setNewFormCSVData({ ...newFormCSVData, [name]: value });
  };
  const handleValidationNewCSVData = (keyName) => {
    if (
      newFormCSVData !== null &&
      newFormCSVData !== undefined &&
      newFormCSVData.hasOwnProperty(keyName)
    ) {
      console.log("case 1 ");
      // console.log(newFormCSVData[keyName]);
      if (
        newFormCSVData[keyName] !== null &&
        newFormCSVData[keyName] !== undefined &&
        newFormCSVData[keyName].trim() !== ""
      ) {
        console.log("case 1 - nested 1 - false ");
        return false;
      }
      if (
        newFormCSVData[keyName] === null ||
        newFormCSVData[keyName] === undefined ||
        newFormCSVData[keyName].trim() === ""
      ) {
        console.log("case 1 - nested 2 - true ");
        return true;
      }
    } else if (
      newFormCSVData === null ||
      newFormCSVData === undefined ||
      newFormCSVData.hasOwnProperty(keyName) === false
    ) {
      console.log("case2 --- false");
      return false;
    }
  };

  const handleGenerateFormURL = (formName, item) => () => {
    // console.log(item);
    const currentDateTime = new Date();
    const currentDay = currentDateTime.getDate();
    const currentMonth = currentDateTime.getMonth();
    const currentYear = currentDateTime.getFullYear();
    const currentSeconds = currentDateTime.getSeconds();
    const currentMinutes = currentDateTime.getMinutes();
    const currentHours = currentDateTime.getHours();
    const currentMilliseconds = currentDateTime.getMilliseconds();

    const currentURL = window.location;

    let customeURL = `${currentURL.origin}/#${URLs.openDigitalFormURL.path}?form_UID=${item.que_id}&name=${formName}&time_stamp=${currentDateTime}`;

    navigator.clipboard
      .writeText(customeURL)
      .then(() => {
        // Success: You can show a success message or perform any other action here.
        // console.log("Item name copied to clipboard: " + customeURL);

        setSnakbarST({
          ...snakbarST,
          openSnakbar: true,
          snackbarMessage: `URL Copied`,
          severity: "info",
        });
      })
      .catch((error) => {
        // Handle any errors that occur during copying here.
        console.error("Error copying to clipboard: " + error);
      });
  };

  const handleCloseSnakbar = () => {
    setSnakbarST({
      ...snakbarST,
      openSnakbar: false,
      // snackbarMessage: "",
      // severity: "info",
    });
  };
  const handleCreateFormByCsv = () => {
    // console.log(newFormCSVData);

    handleCloseCsvModal();
    setSnakbarST({
      ...snakbarST,
      openSnakbar: true,
      snackbarMessage: `Csv Uploaded Successfully`,
      severity: "info",
    });
  };

  const handleImageUpload = (event) => {
    const { name } = event.target;
    // console.log(event.target.files);

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageData = e.target.result;
        setNewFormCSVData({ ...newFormCSVData, [name]: imageData });
      };

      reader.readAsDataURL(file);
    }
  };
  const handleCSVUpload = (event) => {
    const { name } = event.target;
    // console.log(event.target.files[0].name);
    
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const csvData = e.target.result;

        setNewFormCSVData({
          ...newFormCSVData,
          [name]: csvData,
          csv_file_name: event.target.files[0].name,
        });
      };

      reader.readAsDataURL(file);
    }
  };
  const handleDownloadSampleCSV = () => {
    const anchor = document.createElement("a");
    anchor.href = "/sampleData/template.csv";
    anchor.download = "template.csv";
    anchor.click();
    setSnakbarST({
      ...snakbarST,
      openSnakbar: true,
      snackbarMessage: `Csv Downloaded`,
      severity: "info",
    });
  };
  return (
    <div className="page-container">
      {connectingCredentials.credential_available == "NO" && (
        <RetryCA data={{ ...connectingCredentials, productName: "DigitalForms" }} />
      )}{" "}
      {connectingCredentials.credential_available == "YES" && (
      <Card
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
          minHeight: "90vh",
        }}
      >
        <CardHeader
          title="Digital Forms"
          // action={
          //   <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
          //     <Button
          //       onClick={handleDownloadSampleCSV}
          //       variant="outlined"
          //       style={{
          //         marginRight: "8px",
          //         borderColor: "#050092",
          //         color: "#050092",
          //       }}
          //     >
          //       <GetApp />
          //     </Button>{" "}
          //     <Button
          //       variant="contained"
          //       style={{
          //         marginRight: "8px",
          //         backgroundColor: "#050092",
          //         color: "#fff",
          //       }}
          //       onClick={handleUploadCsv}
          //       startIcon={<Upload />}
          //     >
          //       CSV
          //     </Button>
          //   </div>
          // }
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        />

        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent>
          {qBNameList && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {qBNameList.map((item, index) => (
                    <TableRow
                      key={item.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{index + 1}.</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        <Button
                          style={{
                            borderColor: "#050092",
                            color: "#050092",
                          }}
                          variant="outlined"
                          onClick={handleGenerateFormURL(item.name, item)}
                          // startIcon={<Share />}
                        >
                          <ContentCopy />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        {/* <Divider sx={{ backgroundColor: "darkgray" }} /> */}

        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snakbarST.openSnakbar}
            autoHideDuration={3000}
            onClose={handleCloseSnakbar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert onClose={handleCloseSnakbar} severity={snakbarST.severity}>
              {snakbarST.snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
      </Card>)}
      {openCsvModal && (
        <Modal
          open={true}
          onClose={handleCloseCsvModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              height: "auto",
              bgcolor: "white",
              border: "2px solid #050092",
              borderRadius: "10px",
              boxShadow: 24,
              p: 3,
            }}
          >
            <Box>
              <h3 style={{ color: "#050092" }}>
                Create New Form using CSV format
              </h3>
            </Box>{" "}
            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
              <TextField
                type="text"
                name="form_name"
                onChange={(event) => handleChangeNewCSVData(event)}
                multiline
                fullWidth
                maxRows={3}
                variant="outlined"
                label="Enter Form Name..."
                value={newFormCSVData ? newFormCSVData["form_name"] || "" : ""}
                error={handleValidationNewCSVData("form_name")}
              />
            </Box>
            <Box>
              <InputLabel
                htmlFor="csvInput"
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  width: "auto",
                  padding: "10px",
                }}
              >
                <Input
                  name="csv_file"
                  type="file"
                  accept=".csv"
                  id="csvInput"
                  onChange={handleCSVUpload}
                />
              </InputLabel>
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "8px",
                  backgroundColor: "#050092",
                  color: "#fff",
                }}
                onClick={handleCreateFormByCsv}
                startIcon={<Add />}
              >
                Create
              </Button>
              <Button
                onClick={handleCloseCsvModal}
                variant="outlined"
                style={{
                  marginRight: "8px",
                  borderColor: "#050092",
                  color: "#050092",
                }}
              >
                <Close />
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default VQform;
