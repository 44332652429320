import { Hub, Replay } from "@mui/icons-material";
import { Box, Button, Card, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCalculatorState } from "../slices/calculatorSlice";

const RetryCA = ({ data }) => {
  console.log(data);
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");
  const dispatch = useDispatch();

  const handleCA = () => {
    if (data.productName === "Calculator") {
      // console.log("clr clearCalculatorState")
      dispatch(clearCalculatorState());
    }
    navigate(URLs.ConnectedAccounts.path);
  };
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          border: "1px solid red",
          marginBottom: "8px",
          backgroundColor: "#FFE4E4",
          minHeight: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        className="shadow-sm"
      >
        <h3> This Service is not connected to {data.connect2}</h3>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#050092",
            color: "#fff",
            marginBottom: isExtraSmallScreen ? "8px" : "0",
          }}
          onClick={handleCA}
          startIcon={<Hub />}
          size={isMediumScreen ? "small" : "medium"}
        >
          Configure Connected Accounts
        </Button>

        <h6
          style={{
            marginTop: "8px",
          }}
        >
          or
        </h6>

        <Button
          variant="outlined"
          style={{
            marginTop: "8px",
            borderColor: "#050092",
            color: "#050092",
          }}
          onClick={() => navigate(URLs.ConnectedAccountSetup.path)}
          startIcon={<Replay />}
          size={isMediumScreen ? "small" : "medium"}
        >
          Retry Connecting...
        </Button>
      </Card>
    </Box>
  );
};

export default RetryCA;
