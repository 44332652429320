
import axios from 'axios';

export const getInvoicesAPI = async (base_url, api_key, api_secret, fields, filters) => {
  const url = `${base_url}api/resource/Purchase%20Invoice?fields=${encodeURIComponent(fields)}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  return await(
    axios.get(`${url}`, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

export const getSuppliersAPI = async (base_url, api_key, api_secret, fields, filters) => {
  const url = `${base_url}api/resource/Supplier?fields=${encodeURIComponent(fields)}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  console.log(api_key)
  console.log(api_secret)
  return await(
    axios.get(`${url}`, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        // Authorization: `token c9f1c5dbb04db4a:981d5d6f94ffbb2`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

export const getBankAccountAPI = async (base_url, api_key, api_secret, fields, filters) => {
  const url = `${base_url}api/resource/Bank Account?fields=${encodeURIComponent(fields)}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  return await(
    axios.get(`${url}`, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

//GET the list of contacts
export const getAllContact = async (usr, pwd) => {
  try {
    const response = await axios.get(
      "/v1/contacts",  // Relative path, the proxy will prepend the base URL
      {
        timeout: 30 * 1000,
        auth: {
          username: usr,
          password: pwd,
        },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Error fetching list:", error);
    throw error;
  }
};

//CREATE the new contact
export const postAddEmployee = async (usr, pwd, formData) => {
  try {
    const response = await axios.post(
      "/v1/contacts",
      formData,  // Pass formData to the request body
      {
        timeout: 30 * 1000,
        auth: {
          username: usr,
          password: pwd,
        },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error adding contact:", error);
    throw error;
  }
};


//GET the list of Fund Account
export const getAllFundAccount = async (usr, pwd) => {
  try {
    const response = await axios.get(
      "/v1/fund_accounts",  // Relative path, the proxy will prepend the base URL
      {
        timeout: 30 * 1000,
        auth: {
          username: usr,
          password: pwd,
        },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Error fetching list:", error);
    throw error;
  }
};

export const postAddFundAccount = async (usr, pwd, formData) => {
  try {
    const response = await axios.post(
      "/v1/fund_accounts",
      formData,  // Pass formData to the request body
      {
        timeout: 30 * 1000,
        auth: {
          username: usr,
          password: pwd,
        },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error adding contact:", error);
    throw error;
  }
};

export const postAddPayout = async (usr, pwd, formData) => {
  try {
    const response = await axios.post(
      "/v1/payouts",
      formData,  // Pass formData to the request body
      {
        timeout: 30 * 1000,
        auth: {
          username: usr,
          password: pwd,
        },
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error adding contact:", error);
    throw error;
  }
};
