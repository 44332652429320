import {
  CardContent,
  CardHeader,
  Card as CardMui,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const CustomDashboard = () => {
  const { dashboard_label } = useParams();
  const dashboard_setup = JSON.parse(localStorage.getItem("metadata"))[
    "dashboard"
  ];
  const [dashboardSelected, setDashboardSelected] = useState(null);
  const [iFrameURL, setIFrameURL] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const userEmail = localStorage.getItem("user_email");

  useEffect(() => {
    const dashboard_details = dashboard_setup?.dashboards?.find(
      (item) => item.label === dashboard_label
    );
    const filter_identify_key = dashboard_details.filter_identify_key;
    let tempURL = `${dashboard_setup?.base_url}${dashboard_details.url}`;
    const params = dashboard_details.params.reduce((output, current, index) => {
      if (index === 0) {
        // For the first parameter, don't add "&"
        if (current.key === "user" && current.value === "") {
          return `?${current.key}=${userEmail}`;
        } else {
          return `?${current.key}=${current.value}`;
        }
      } else {
        // For subsequent parameters, add "&"
        if (current.key === "user" && current.value === "") {
          return `${output}&${current.key}=${userEmail}`;
        } else {
          return `${output}&${current.key}=${current.value}`;
        }
      }
    }, "");
    tempURL = `${tempURL}${params}`;
    const defaultfilterValue = dashboard_details.params?.find(
      (param) => param.key === filter_identify_key
    )?.value;
    // console.log(defaultfilterValue);
    const appliedFilter = dashboard_details?.links?.find((link) =>
      link.params.some(
        (param) =>
          param.key === filter_identify_key &&
          param.value === defaultfilterValue
      )
    );
    // console.log(appliedFilter);
    if (appliedFilter) {
      setSelectedFilter(appliedFilter.label);
    }
    setDashboardSelected(dashboard_details);
    setIFrameURL(tempURL);
  }, [dashboard_label]);

  const handleFilterClick = (item) => {
    let tempURL = `${dashboard_setup?.base_url}${dashboardSelected.url}/`;
    const params = item.params.reduce((output, current, index) => {
      if (index === 0) {
        // For the first parameter, don't add "&"
        if (current.key === "user" && current.value === "") {
          return `?${current.key}=${userEmail}`;
        } else {
          return `?${current.key}=${current.value}`;
        }
      } else {
        // For subsequent parameters, add "&"
        if (current.key === "user" && current.value === "") {
          return `${output}&${current.key}=${userEmail}`;
        } else {
          return `${output}&${current.key}=${current.value}`;
        }
      }
    }, "");
    tempURL = `${tempURL}${params}`;
    setSelectedFilter(item.label);
    setIFrameURL(tempURL);
  };

  return (
    <div className="page-container">
      <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title={dashboard_label}
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        />
      </CardMui>
      {/* <Divider sx={{ backgroundColor: "darkgray" }} /> */}
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "5px", marginBottom: "20px" }}
      >
        {dashboardSelected?.links?.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              key={`dashboard-link-${index}`}
            >
              <Link
                href={item.linkUrl}
                color="inherit"
                underline="none"
                onClick={() => handleFilterClick(item)} // Call your function with the desired parameters
              >
                <CardMui
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border:
                      selectedFilter === item.label
                        ? "2px solid #262b40"
                        : "none",
                  }}
                >
                  <CardContent>
                    <Typography variant="subtitle2" sx={{ marginTop: "5px" }}>
                      {item.label}
                    </Typography>
                  </CardContent>
                </CardMui>
              </Link>
            </Grid>
          );
        })}
      </Grid>

      <div style={{ height: "88vh", width: "100%" }}>
        <iframe
          src={iFrameURL}
          height="100%"
          width="100%"
          title="Dashboard"
        ></iframe>
      </div>
    </div>
  );
};

export default CustomDashboard;
