import {
  Add,
  Close,
  Delete,
  Favorite,
  GetApp,
  MoreVert,
  OpenInFullOutlined,
  Share,
  Upload,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getAllCustomerListAPI,
  getAllLeadListAPI,
  getAllNotesAPI,
  postSubmitNewNoteAPI,
} from "../../api/NotesAPI";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import RetryCA from "../../components/RetryCA";

const MainNotes = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [detailModalB, setDetailModalB] = useState(false);
  const [detailModalD, setDetailModalD] = useState(null);
  const [notesList, setNotesList] = useState(null);
  const [newNoteB, setNewNoteB] = useState(null);
  const [newNoteD, setNewNoteD] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [leadsList, setLeadsList] = useState([]);

  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Notes")
      ? connectedApps["Notes"]
      : null
    : null;

  useEffect(() => {
    getAllNotesF();
    getAllCustomerListF();
    getAllLeadListF();
  }, []);
  const getAllNotesF = async () => {
    try {
      const res = await getAllNotesAPI(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );

      if (res.status === 200) {
        if (res.data.msg === "notes found") {
          setNotesList(res.data.notes);
        }
        if (
          res.data.msg === "failed" &&
          res.data.notes === "Could Not Fetch Notes"
        ) {
          setNotesList(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCustomerListF = async () => {
    try {
      const res = await getAllCustomerListAPI(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      setCustomerList(res.data.customers);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllLeadListF = async () => {
    try {
      const res = await getAllLeadListAPI(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      setLeadsList(res.data.leads);
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenDetailModal = (noteData) => {
    setDetailModalB(true);
    setDetailModalD(noteData);
  };
  const handleCloseDetailModal = () => {
    setDetailModalB(false);
    setDetailModalD(null);
  };
  const handleOpenNewNote = () => {
    setNewNoteB(true);
  };
  const handleCloseNewNote = () => {
    setSelectedImage(null);
    setNewNoteD(null);
    setNewNoteB(false);
  };
  const handleChangeNewData = (event) => {
    const { name, value } = event.target;
    setNewNoteD({ ...newNoteD, [name]: value });
    // console.log(name, value);
  };
  const handleSubmitNewNote = async () => {
    try {
      const res = await postSubmitNewNoteAPI(
        newNoteD,
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );

      if (res.status === 200) {
        // console.log(res.data.note);
        // let temp1 = notesList;
        // let temp2 = temp1;
        // temp2.unshift(res.data.note);
        // setNotesList(temp2);
        window.location.reload();

        setSelectedImage(null);
        setNewNoteD(null);
        setNewNoteB(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      const fileExtension = getFileExtension(file.name);

      // Create a base64 representation of the image
      convertToBase64(file).then((base64Image) => {
        setNewNoteD({
          ...newNoteD,
          image: base64Image,
          image_ext: getFileExtension(file.name),
        });
      });
    }
  };
  const validateFileType1 = (file) => {
    const acceptedTypes = ["image/jpeg", "image/png", "image/svg+xml"];

    return acceptedTypes.includes(file.type);
  };

  const getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  const handleButtonClick = () => {
    const inputElement = document.getElementById("contained-button-file");
    inputElement.click();
  };
  const handleRemoveImg = () => {
    setSelectedImage(null);
  };
  const handleCustomerChange = (event, newValue) => {
    setNewNoteD({ ...newNoteD, ["customer"]: newValue.customer_name });
  };
  const handleLeadChange = (event, newValue) => {
    setNewNoteD({ ...newNoteD, ["lead"]: newValue.name });
  };
  const handleTagChange = (event, newValue) => {
    setNewNoteD({ ...newNoteD, ["_user_tags"]: newValue });
  };
  return (
    <div className="page-container">
      {connectingCredentials.credential_available == "NO" && (
        <RetryCA data={{ ...connectingCredentials, productName: "Notes" }} />
      )}{" "}
      {connectingCredentials.credential_available == "YES" && (
        <>
          <Card
            className="shadow-sm"
            variant="outlined"
            sx={{
              border: "1px solid lightgray",
              borderRadius: "10px",
              width: "100%",
              minHeight: "90vh",
            }}
          >
            <CardHeader
              title="Notes"
              action={
                <div style={{ marginTop: isMobileOrTablet ? "5px" : "0px" }}>
                  <Button
                    onClick={handleOpenNewNote}
                    variant="outlined"
                    style={{
                      marginRight: "8px",
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                  >
                    <Add /> New
                  </Button>
                </div>
              }
              sx={{
                margin: "15px",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isMobileOrTablet ? "column" : "row",
              }}
            />

            <Divider sx={{ backgroundColor: "darkgray" }} />
            <CardContent>
              <Grid container spacing={2}>
                {notesList !== null &&
                  notesList?.map((note, index) => {
                    return (
                      <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOpenDetailModal(note);
                          }}
                        >
                          <CardContent
                            style={{
                              height: "50px",

                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <h3
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {note.title}
                              </h3>
                            </div>
                          </CardContent>
                          <CardContent style={{ height: "250px" }}>
                            {note.image_list === null && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",

                                  height: "100%",
                                }}
                              >
                                {" "}
                                <Avatar
                                  sx={{
                                    bgcolor: "#f5f8fb",
                                    height: "50%",
                                    width: "50%",
                                  }}
                                  aria-label="recipe"
                                >
                                  <h1> {note.title.charAt(0)}</h1>
                                </Avatar>
                              </div>
                            )}
                            {note.image_list !== null && (
                              <CardMedia
                                component="img"
                                height="194"
                                image={note.image}
                                alt="img123"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                  backgroundColor: "white",
                                }}
                              />
                            )}
                          </CardContent>
                          <Divider sx={{ backgroundColor: "darkgray" }} />

                          <CardContent style={{ height: "50px" }}>
                            <Typography
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              variant="body2"
                              color="text.secondary"
                            >
                              {note.content}
                            </Typography>
                          </CardContent>
                          {/* <Divider sx={{ backgroundColor: "darkgray" }} /> */}
                          <CardActions
                            style={{ height: "50px" }}
                            disableSpacing
                          >
                            <div
                              style={{
                                display: "flex",
                                overflowX: "auto",
                                padding: "1px",
                              }}
                            >
                              {note._user_tags?.length > 0 &&
                                note._user_tags.map((tag, index) => {
                                  return (
                                    <Chip
                                      key={index}
                                      label={tag}
                                      variant="outlined"
                                    />
                                  );
                                })}
                            </div>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>

            {/* <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snakbarST.openSnakbar}
            autoHideDuration={3000}
            onClose={handleCloseSnakbar}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert onClose={handleCloseSnakbar} severity={snakbarST.severity}>
              {snakbarST.snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack> */}
          </Card>
        </>
      )}
      {detailModalB && (
        <Modal
          open={true}
          onClose={handleCloseDetailModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90vw",
              minHeight: "60vh",
              bgcolor: "white",
              border: "2px solid #050092",
              borderRadius: "10px",
              boxShadow: 24,
              p: 3,
              overflowY: "auto",
              maxHeight: "90vh",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <h3 style={{ color: "#050092" }}>{detailModalD.title}</h3>
                </Box>{" "}
                <Box>
                  <Button
                    onClick={handleCloseDetailModal}
                    variant="outlined"
                    style={{
                      marginRight: "8px",
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                  >
                    <Close />
                  </Button>
                </Box>{" "}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Content
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <div>{detailModalD.content}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Customer
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <div>{detailModalD.customer}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Lead
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <div> {detailModalD.lead_name}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Tags
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <div>
                            {" "}
                            {detailModalD._user_tags?.length > 0 &&
                              detailModalD._user_tags.map((tag, index) => {
                                return (
                                  <Chip
                                    key={index}
                                    label={tag}
                                    variant="outlined"
                                  />
                                );
                              })}
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Created on:
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <div>
                            {detailModalD.creation && (
                              <Chip
                                label={dayjs(detailModalD.creation).format(
                                  "DD MMM YYYY"
                                )}
                                variant="outlined"
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>{" "}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                  {detailModalD.image_list === null && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        height: "100%",
                      }}
                    >
                      {/* <Avatar
                        sx={{
                          bgcolor: "#f5f8fb",
                          height: "50%",
                          width: "50%",
                        }}
                        aria-label="recipe"
                      >
                        <h1> {detailModalD.title.charAt(0)}</h1>
                      </Avatar> */}
                    </div>
                  )}
                  {detailModalD.image_list !== null && (
                    <ImageList
                      sx={{ width: "100%", height: 450 }}
                      cols={1}
                      rowHeight={164}
                    >
                      {detailModalD.image_list.map((item, index) => (
                        <ImageListItem
                          key={index}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                            backgroundColor: "white",
                          }}
                        >
                          <img
                            srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item}?w=164&h=164&fit=crop&auto=format`}
                            alt={index}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                    // <CardMedia
                    //   component="img"
                    //   height="194"
                    //   image={detailModalD.image}
                    //   alt="img123"
                    //   // style={{
                    //   //   height: "100%",
                    //   //   width: "100%",
                    //   //   objectFit: "contain",
                    //   //   backgroundColor: "white",
                    //   // }}
                    // />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
      {newNoteB && (
        <Modal
          open={true}
          onClose={handleCloseNewNote}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90vw",
              minHeight: "95vh",
              bgcolor: "white",
              border: "2px solid #050092",
              borderRadius: "10px",
              boxShadow: 24,
              p: 3,
              overflowY: "auto",
              maxHeight: "95vh",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <h3 style={{ color: "#050092" }}>New Note</h3>
                </Box>{" "}
                <Box>
                  <Button
                    onClick={handleCloseNewNote}
                    variant="outlined"
                    style={{
                      // marginRight: "8px",
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                  >
                    <Close />
                  </Button>
                </Box>{" "}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <TextField
                            type="text"
                            name="title"
                            onChange={(event) => handleChangeNewData(event)}
                            variant="outlined"
                            label="Enter Title..."
                            style={{
                              width: "100%",
                              backgroundColor: "#f0f0f0",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          <Autocomplete
                            onChange={handleTagChange}
                            multiple
                            id="tags-filled"
                            options={[
                              "Important",
                              "Not Started",
                              "Pending",
                              "Completed",
                            ].map((option) => option)}
                            // defaultValue={[]}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={
                                  isMobileOrTablet
                                    ? "Tags..."
                                    : "Tags: type any words / select from dropdown"
                                }
                                placeholder="tags..."
                              />
                            )}
                            style={{
                              width: "100%",
                              border: "1px solid #bdbdbd",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      {customerList && (
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={customerList}
                              getOptionLabel={(option) => option.customer_name}
                              renderInput={(params) => (
                                <TextField {...params} label="Customers" />
                              )}
                              // value={customer}
                              onChange={handleCustomerChange}
                              style={{
                                backgroundColor: "#f0f0f0",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      {leadsList && (
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={leadsList}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField {...params} label="Lead..." />
                              )}
                              // value={customer}
                              onChange={handleLeadChange}
                              style={{
                                backgroundColor: "#f0f0f0",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                          }}
                          align="left"
                        >
                          <TextField
                            type="text"
                            name="content"
                            style={{
                              width: "100%",
                              backgroundColor: "#f0f0f0",
                            }}
                            onChange={(event) => handleChangeNewData(event)}
                            multiline
                            rows={isMobileOrTablet ? 8 : 8}
                            variant="outlined"
                            label="Description..."
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
                {/* <Typography variant="h6">Image Upload</Typography> */}

                {selectedImage && (
                  <div
                    style={{
                      height: isMobileOrTablet ? "85%" : "450px",
                      width: isMobileOrTablet ? "88%" : "100%",
                      border: "1px solid grey",
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginLeft: isMobileOrTablet ? "15px" : "0px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      // height="194"
                      image={selectedImage}
                      alt="img123"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                        backgroundColor: "white",
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border:
                      selectedImage === null
                        ? "1px solid #bdbdbd"
                        : "1px solid white",
                    height: selectedImage === null ? "100%" : "auto",
                    marginBottom: "10px",
                    marginLeft: isMobileOrTablet ? "15px" : "0px",
                    marginRight: isMobileOrTablet ? "15px" : "0px",
                  }}
                >
                  <input
                    accept=".jpg,.jpeg,.png,.svg"
                    id="contained-button-file"
                    multiple={false}
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="outlined"
                      component="span"
                      onClick={handleButtonClick}
                      style={{
                        marginRight: "8px",
                        borderColor: "#050092",
                        color: "#050092",
                        marginLeft: isMobileOrTablet ? "15px" : "0px",
                      }}
                    >
                      <Upload /> Image
                    </Button>
                  </label>{" "}
                  {selectedImage !== null && (
                    <Button
                      variant="outlined"
                      component="span"
                      onClick={handleRemoveImg}
                      style={{
                        marginRight: "8px",
                        borderColor: "#050092",
                        color: "#050092",
                        marginLeft: "10px",
                      }}
                    >
                      <Delete />
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#050092",
                      color: "#fff",
                    }}
                    onClick={handleSubmitNewNote}
                  >
                    Create
                  </Button>
                  <Button
                    onClick={handleCloseNewNote}
                    variant="outlined"
                    style={{
                      marginRight: "8px",
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                  >
                    <Close />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default MainNotes;
