import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  queryOptions: { items: [] },
  paginationModel: {
    page: 0,
    pageSize: 10,
    lastPage: null,
    rowCountState: null,
  },
  toRefreshData: false,
};

const SDSdDataSlice = createSlice({
  name: "SDSData",
  initialState,
  reducers: {
    setQueryOptions: (state, action) => {
      state.queryOptions = action.payload;
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    setToRefreshData: (state, action) => {
      state.toRefreshData = action.payload;
    },
    clearSDSState: (state, action) => {
      state.queryOptions = {};
      state.paginationModel = {
        page: 0,
        pageSize: 10,
        lastPage: null,
        rowCountState: null,
      };
    },
  },
});

export const {
  setQueryOptions,
  setPaginationModel,
  clearSDSState,
  setToRefreshData,
} = SDSdDataSlice.actions;
//when you call it they are actions
export default SDSdDataSlice.reducer;
