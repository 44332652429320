import { Autocomplete, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { getBOMDetails, getFGItems } from "../api/erpItems";
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialCalculatorData,
  setSelectedItem,
} from "../../../slices/calculatorSlice";
import bomMetaData from "../data/bom_details.json";

const ItemSearch = () => {
  const [options, setOptions] = useState([]);
  const [dataList, setDataList] = useState([]);
  const { selectedItem, selectedSecondaryItem } = useSelector(
    (state) => state.calculatorData
  );
  const [itemSelected, setItemSelected] = useState(
    selectedItem !== "" ? selectedItem : null
  );
  const [bomSelected, setBOMSelected] = useState(
    selectedSecondaryItem !== "" ? selectedSecondaryItem : null
  );
  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Calculator")
      ? connectedApps["Calculator"]
      : null
    : null;

  const dispatch = useDispatch();
  const getFGItemsData = async () => {
    try {
      const res = await getFGItems(
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      if (res.error) {
        console.log(res.error);
      } else {
        // console.log(res.data);
        const itemNames = res.data.data.data_list.map((item) => item.item);
        setDataList(res.data.data.data_list);
        setOptions(itemNames);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFGItemsData();
  }, []);

  useEffect(() => {
    setItemSelected(selectedItem);
  }, [selectedItem]);

  const getBOMDetailsData = async (bomID) => {
    try {
      if (bomID) {
        const res = await getBOMDetails(
          bomID,
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret
        );
        if (res.error) {
          console.log(res.error);
        } else {
          const data = res.data.data;
          const items = data.bom_details["items"].map((item) => {
            return { ...item, ["source"]: "Connected Account" };
          });
          let details = {};
          for (const [key, value] of Object.entries(data.bom_details)) {
            if (
              ![
                "items",
                "exploded_items",
                "operations",
                "scrap_items",
              ].includes(key)
            ) {
              details[key] = value;
            }
          }
          let bomData = bomMetaData;
          bomData["details"] = details;
          bomData["items"] = items;
          dispatch(setInitialCalculatorData(bomData));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e, newValue) => {
    setItemSelected(newValue);
    let bom = null;
    const item = dataList.filter((item) => item.item === newValue);
    if (item.length > 0) {
      bom = item[0].bom;
      setBOMSelected(bom);
      getBOMDetailsData(bom);
    }
    dispatch(
      setSelectedItem({ selectedItem: newValue, selectedSecondaryItem: bom })
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Handle Enter key press here
      dispatch(
        setSelectedItem({
          selectedItem: itemSelected,
          selectedSecondaryItem: bomSelected,
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        style={{ background: "white", width: "100%" }}
        disablePortal
        id="combo-box-demo"
        onChange={handleChange}
        onKeyPress={handleKeyPress} // Add this line
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Item" // Add this line
          />
        )}
        value={itemSelected}
      />
    </React.Fragment>
  );
};

export default ItemSearch;
