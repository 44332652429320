import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getAllFundAccount } from "../../../api/RazorpayXAPI";
import AddAccountModal from "./AddAccountModal";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { postAddFundAccount } from "../../../api/RazorpayXAPI";

const FundAccount = () => {
  const { connected_accounts: connectedAccounts } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedAccounts
    ? connectedAccounts.hasOwnProperty("RazorPayX")
      ? connectedAccounts["RazorPayX"]
      : null
    : null;

  const [FundAccountData, setFundAccountData] = useState([]);
  const [isContactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contactsResponse = await getAllFundAccount(
          connectingCredentials.usr,
          connectingCredentials.pwd
        );
        setFundAccountData(contactsResponse.data.items);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, [connectingCredentials.usr, connectingCredentials.pwd]);

  const handleContactModalClose = () => {
    // Your custom logic here, if needed
    setContactModalOpen(false);
  };

  const handleSubmit = async (formData) => {
    try {
      const response = await postAddFundAccount(
        connectingCredentials.usr,
        connectingCredentials.pwd,
        formData
      );

      // Log the response or handle it as needed
      console.log(response);

      // Update the state with the new data
      setFundAccountData([...FundAccountData, formData]);

      // Close the modal after successful submission
      handleContactModalClose();
    } catch (error) {
      console.error("Error submitting contact:", error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="page-container">
      <Card
        variant="outlined"
        sx={{ border: "1px solid lightgray" }}
        className="shadow-sm"
      >
        <CardHeader
          title="Fund Account List"
          sx={{ margin: "15px" }}
          action={
            <div style={{ display: "flex" }}>
              <Button
                variant="contained"
                size="medium"
                style={{
                  backgroundColor: "#050092",
                  color: "#fff",
                }}
                onClick={() => setContactModalOpen(true)}
              >
                Add Fund Account
              </Button>
            </div>
          }
        />

        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent style={{ backgroundColor: "#F5F8FB" }}>
          {/* Table for displaying contacts */}
          <TableContainer component={Paper} sx={{ width: 1500 }} style={{marginLeft:"30px"}}>
            <Table sx={{ width: 1500 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Contact ID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Account Type</StyledTableCell>
                  <StyledTableCell>Account No</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {FundAccountData.map((account) => (
                  <StyledTableRow key={account.id}>
                    <StyledTableCell component="th">
                      {account.id || ""}
                    </StyledTableCell>
                    <StyledTableCell component="th" >
                      {account.contact_id || ""}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                    {account.bank_account.name || ""}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                      {account.account_type || ""}
                    </StyledTableCell>
                    <StyledTableCell component="th">
                    {account.bank_account.account_number || ""}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <AddAccountModal
        open={isContactModalOpen}
        onClose={handleContactModalClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default FundAccount;
