import axios from "axios";
import { resolve } from "./resolve.js";

const url = "/api/method/costing_calculator.api";

export const getFGItems = async (base_url, api_key, api_secret) => {
  // console.log(base_url);
  // console.log(api_key);
  // console.log(api_secret);
  return await resolve(
    axios.get(`${base_url}${url}.item_bom`, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

export const getBOMList = async (itemCode, base_url, api_key, api_secret) => {
  return await resolve(
    axios.get(
      `${base_url}${url}.get_bom_list?item_code=${encodeURIComponent(
        itemCode
      )}`,
      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
  );
};

export const getBOMDetails = async (bomID, base_url, api_key, api_secret) => {
  return await resolve(
    axios.get(
      `${base_url}${url}.get_bom_details?bom_id=${encodeURIComponent(bomID)}`,
      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    )
  );
};

export const postNewCalculatorData = async (
  calculatorData,
  base_url,
  api_key,
  api_secret
) => {
  return await resolve(
    axios.post(`${base_url}${url}.new_cost_calculation`, calculatorData, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

export const updateCalculatorData = async (
  calculatorData,
  base_url,
  api_key,
  api_secret
) => {
  return await resolve(
    axios.put(`${base_url}${url}.cost_calculation_update`, calculatorData, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

export const getItems = async (custom_url, base_url, api_key, api_secret) => {
  //gets list of customer or prospects
  return await resolve(
    axios.get(`${base_url}${url}.${custom_url}`, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};

export const getPackagingMaterialItems = async (
  base_url,
  api_key,
  api_secret
) => {
  //gets list of customer or prospects
  return await resolve(
    axios.get(`${base_url}${url}.packaging_material_item`, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    })
  );
};
