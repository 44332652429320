import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connected_accounts: {},
  connected_apps: {},
};

const connectedAccountDataSlice = createSlice({
  name: "connectedAccountData",
  initialState,
  reducers: {
    setConnectedData: (state, action) => {
      state.connected_accounts = action.payload.connected_accounts;
      state.connected_apps = action.payload.connected_apps;
    },
    clearConnectedData: (state, action) => {
      state.connected_accounts = {};
      state.connected_apps = {};
    },
  },
});

export const { setConnectedData, clearConnectedData } =
  connectedAccountDataSlice.actions;
//when you call it they are actions
export default connectedAccountDataSlice.reducer;
