import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Modal,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import WorkCenter from "./monitor/Workcenter";
import { axiosInstance } from "../../axiosSetup";

const Monitor = () => {
  const [workcenters, setWorkcenters] = useState();
  const [showRefreshModal, setShowRefreshModal] = useState(false); //modal to confirm refresh schedule
  const [showAlert, setShowAlert] = useState(false); //schedule updated alert
  const [loadSpinner, setLoadSpinner] = useState(false); //loading inside moadal when refreshing schedule

  const handleCloseRefreshModal = () => setShowRefreshModal(false);
  const handleShowRefreshModal = () => setShowRefreshModal(true);

  const loadWorkcenterData = () => {
    axiosInstance
      .get("ajs/workcenters/", {})
      .then((res) => {
        // console.log(res);
        const data = res.data;
        // console.log(data);
        setWorkcenters(data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleChangeinWorkcenter = () => {
    //call back provided to workcenter, in order to reload workcenter data, if any workcenter submit changes
    loadWorkcenterData();
  };
  const handleSubmit = (e) => {
    //submit request to refresh schedule
    e.preventDefault();
    setLoadSpinner(true);
    //first generate new schedule
    axiosInstance
      .get("ajs/schedule/", {})
      .then((res) => {
        if (res.status === 200) {
          //then apply generated schedule
          axiosInstance
            .post("ajs/schedule/", { schedule_id: res.data.id })
            .then((res) => {
              // console.log(data);
              setLoadSpinner(false);
              setShowAlert(true);
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
        setShowRefreshModal(false);
        // setWorkcenters(data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  useEffect(() => {
    loadWorkcenterData();
  }, []);
  return (
    <div className="page-container">
      <Card border="light" className="shadow-sm mt-2">
        <Card.Header bg="white">
          {showAlert && (
            <div className="d-flex justify-content-center">
              <Alert
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
              >
                Schedule Refreshed!
              </Alert>
            </div>
          )}

          <div className="d-flex justify-content-between">
            <h4>Workcenters</h4>
            <Button
              variant="primary"
              style={{
                backgroundColor: "#050092",
                color: "#fff",
              }}
              size="sm"
              className="blue-btn mx-2"
              onClick={handleShowRefreshModal}
            >
              <FontAwesomeIcon icon={faSyncAlt} className="me-1" />
              Schedule
            </Button>

            <Modal
              as={Modal.Dialog}
              centered
              show={showRefreshModal}
              onHide={handleCloseRefreshModal}
            >
              <Modal.Header>
                <Modal.Title className="h6">Refresh Schedule</Modal.Title>
                <Button
                  variant="close"
                  aria-label="Close"
                  onClick={handleCloseRefreshModal}
                />
              </Modal.Header>

              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  Do you want to Refresh Schedule?
                  {loadSpinner && (
                    <>
                      <Spinner animation="grow" variant="info" />
                      <Spinner animation="grow" variant="success" />
                      <Spinner animation="grow" variant="warning" />
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="gray" onClick={handleCloseRefreshModal}>
                    No
                  </Button>
                  <Button
                    variant="tertiary"
                    className="ms-auto green-btn"
                    type="submit"
                  >
                    Yes
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </Card.Header>
      </Card>

      <Card>
        <Card.Body>
          <Row>
            {workcenters &&
              workcenters.map((workcenter, index) => (
                <WorkCenter
                  key={index}
                  workcenter={workcenter}
                  handleChangeinWorkcenter={handleChangeinWorkcenter}
                />
              ))}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Monitor;
