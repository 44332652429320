import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { postAddEmployee } from "../../../api/RazorpayXAPI";
import { useSelector } from "react-redux";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ContactModal = (props) => {

  const buttonStyle = {
    borderColor: "#050092", 
    color: "#050092", 
    marginTop:"10px"
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    type: "",
    reference_id: "",
    notes: "",
  });

  const handleInputChange = (field) => (event) => {
    if (field === "notes") {
      setFormData({
        ...formData,
        notes: {
          ...formData.notes,
          ["Note1"]: event.target.value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [field]: event.target.value,
      });
    }
  };

  const { connected_accounts: connectedAccounts } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedAccounts
    ? connectedAccounts.hasOwnProperty("RazorPayX")
      ? connectedAccounts["RazorPayX"]
      : null
    : null;

  const handleSubmit = async () => {
    // Check if all required fields are filled
    if (
      formData.name &&
      formData.email &&
      formData.contact &&
      formData.type &&
      formData.reference_id &&
      formData.notes
    ) {
      // All required fields are filled, proceed with form submission
      try {
        const contactsResponse = await postAddEmployee(
          connectingCredentials.usr,
          connectingCredentials.pwd,
          formData
        );
        console.log(contactsResponse);
        // Call the onSubmit prop to pass data to the parent component
        props.onSubmit(formData);
        console.log(formData);

        // Close the modal after successful submission
        props.onClose();
      } catch (error) 
      {
        console.error("Error submitting contact:", error);
      }
    } else {
      // Display an error message or handle the validation in a way that fits your UI
      console.error("All required fields must be filled");
    }
  };

  return (
    <div>
      <Modal
        keepMounted
        open={props.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.onClose();
          }
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Contact Details
          </Typography>

          <TextField
            required
            label="Name"
            fullWidth
            margin="normal" 
            value={formData.name}
            onChange={handleInputChange("name")}
          />
          <TextField
            required
            label="Email"
            fullWidth
            margin="normal"
            value={formData.email}
            onChange={handleInputChange("email")}
          />
          <TextField
            required
            label="Contact"
            fullWidth
            margin="normal"
            value={formData.contact}
            onChange={handleInputChange("contact")}
          />
          <TextField
            required
            label="Type"
            fullWidth
            margin="normal"
            value={formData.type}
            onChange={handleInputChange("type")}
          />
          <TextField
            required
            label="Reference ID"
            fullWidth
            margin="normal"
            value={formData.reference_id}
            onChange={handleInputChange("reference_id")}
          />
          <TextField
            label="Note"
            fullWidth
            margin="normal"
            value={formData.notes.Note1}
            onChange={handleInputChange("notes")}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "auto",
            }}
          >
            <Button
              onClick={props.onClose}
              variant="outlined"
              style={buttonStyle}
            >
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              variant="outlined"
              style={{ ...buttonStyle, marginLeft: 8 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactModal;
