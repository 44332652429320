import React, { useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import {
  contactStatusList,
  contactTypesList,
  VCActionList,
} from "../../data/sds_metadata";
import { axiosInstance } from "../../axiosSetup";
import {
  Box,
  FormControl,
  InputLabel,
  Modal,
  Select,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { postTableActionsVCardAPIerp } from "../../api/SdsAPI";

export function TableActions(props) {
  const { selectedRows, invoiceData, callback } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const [openModal, setOpenModal] = useState(false);
  const [optionNameST, setOptionNameST] = useState(null);
  const [updatedNameST, setUpdatedNameST] = useState("");

  const options = VCActionList;
  // console.log(selectedRows);

  const handleClick = () => {
    // console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index, optionName) => {
    setOpen(false);
    if (optionName === "Export as CSV") {
    } else {
      handleOpenModal(optionName);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleOpenModal = (optionName) => {
    setOpenModal(true);
    setOptionNameST(optionName);
    // console.log(optionName);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOptionNameST(null);
    setUpdatedNameST("");
  };
  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    // console.log(value);
    setUpdatedNameST(value);
  };
  const handleUpdateData = async () => {
    // console.log(updatedNameST);
    // console.log(selectedRows, invoiceData);
    const output = {};
    const rowsToUpdate = [];
    if (optionNameST === "Update Status") {
      selectedRows.forEach((selectedRow) => {
        rowsToUpdate.push(selectedRow.id);
      });
      output["document_id"] = rowsToUpdate;
      output["contact_status"] = updatedNameST;
    }
    if (optionNameST === "Update Contact Type") {
      selectedRows.forEach((selectedRow) => {
        rowsToUpdate.push(selectedRow.id);
      });
      output["document_id"] = rowsToUpdate;
      output["contact_type"] = updatedNameST;
    }
    // console.log(output);
    try {
      const response = await postTableActionsVCardAPIerp(output);
      // console.log(response);
      callback(response);
    } catch (error) {
      console.log(error);
    }
    // axiosInstance
    //   .patch("sds/business_cards/", output, {})
    //   .then(function (response) {
    //     callback(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    setOpenModal(false);
    setOptionNameST(null);
    setUpdatedNameST("");
  };
  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        {/* <Button onClick={handleClick}>{options[selectedIndex]}</Button> */}
        <Button onClick={handleClick}>{"Actions"}</Button>

        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      // disabled={index === 2}
                      onClick={(event) =>
                        handleMenuItemClick(event, index, option)
                      }
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {openModal && (
        <Modal
          open={true}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              minWidth: isMediumScreen ? "80%" : "40%",
              minHeight: isMediumScreen ? "auto" : "35%",
              bgcolor: "white",
              border: "2px solid #050092",
              borderRadius: "10px",
              boxShadow: 24,
              p: 3,

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: isMediumScreen ? "space-between" : "center",
            }}
          >
            <Box>
              <h3 style={{ color: "#050092" }}>{optionNameST}</h3>
            </Box>{" "}
            <Box
              sx={{
                marginTop: "10px",
                marginBottom: "10px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {optionNameST === "Update Status" && (
                <FormControl
                  style={{ minWidth: isMediumScreen ? "80%" : "50%" }}
                >
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    // style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={updatedNameST}
                    label="Select"
                    name={optionNameST}
                    onChange={(event) => handleChangeSelect(event)}
                  >
                    {contactStatusList.map((opt, index) => {
                      return (
                        <MenuItem key={opt + index} value={opt}>
                          {opt}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              {optionNameST === "Update Contact Type" && (
                <FormControl
                  style={{ minWidth: isMediumScreen ? "80%" : "50%" }}
                >
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={updatedNameST}
                    label="Select"
                    name={optionNameST}
                    onChange={(event) => handleChangeSelect(event)}
                  >
                    {contactTypesList.map((opt, index) => {
                      return (
                        <MenuItem key={opt + index} value={opt}>
                          {opt}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              <Button
                variant="contained"
                style={{
                  marginRight: "8px",
                  backgroundColor: "#050092",
                  color: "#fff",
                }}
                onClick={handleUpdateData}
                // startIcon={<Add />}
              >
                Update
              </Button>
              <Button
                onClick={handleCloseModal}
                variant="outlined"
                style={{
                  marginRight: "8px",
                  borderColor: "#050092",
                  color: "#050092",
                }}
              >
                <Close />
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </React.Fragment>
  );
}
