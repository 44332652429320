import React from "react";
import {
  FormatDate,
  FormatDateTime,
  FormatSimpleDate,
} from "../../components/dateformat";

export const CharComponent = ({ value }) => {
  return <React.Fragment>{value}</React.Fragment>;
};

export const DateComponent = ({ value }) => {
  return <React.Fragment>{FormatDate(value)}</React.Fragment>;
};

export const DatetimeComponent = ({ value }) => {
  if (window.innerWidth < 1500 && window.innerWidth > 950) {
    return <React.Fragment>{FormatDate(value)}</React.Fragment>;
  } else if (window.innerWidth < 950) {
    return <React.Fragment>{FormatSimpleDate(value)}</React.Fragment>;
  }
  return <React.Fragment>{FormatDateTime(value)}</React.Fragment>;
};

export const CompanyStatusUpdate = ({ status, suffix }) => {
  let statusTxtColor;
  switch (status) {
    case "Contact":
      statusTxtColor = "text-primary";
      break;
    case "Prospect":
      statusTxtColor = "text-success";
      break;
    case "Lead":
      statusTxtColor = "text-success";
      break;
    case "Opportunity":
      statusTxtColor = "text-success";
      break;
    default:
      statusTxtColor = "text-dark";
  }

  return status ? (
    <span className={statusTxtColor}>
      <span className="ms-1">
        {status}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const StatusComponent = ({ value }) => {
  return (
    <React.Fragment>
      {value ? <CompanyStatusUpdate status={value} /> : "-"}
    </React.Fragment>
  );
};
