import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { URLs } from "../routes";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const isAuthenticated =
    localStorage.getItem("api_key") && localStorage.getItem("api_secret")
      ? true
      : false;
  const company = localStorage.getItem("company");
  const isVerified =
    localStorage.getItem("company") && localStorage.getItem("metadata")
      ? true
      : false;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(URLs.Signin.path, { state: { from: "/", msg: null } });
    } else if (!isVerified) {
      navigate(URLs.Signin.path, {
        state: { from: "/", msg: "not-validated" },
      });
    }
  }, [isAuthenticated, isVerified]);

  if (isAuthenticated && isVerified) {
    return <Layout component={Component} />;
  }

  return null;
};

export default AppRoute;
