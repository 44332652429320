import React, { useState } from "react";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";


const RenderImage = (props) => {
  const { alt, src, height, maxWidth } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: maxWidth,
          height: height,
          marginTop: "10px",
          cursor: "pointer",
        }}
        onClick={openModal}
      />

      <Modal show={isModalOpen} onHide={closeModal} aria-labelledby="contained-modal-title-vcenter" size="lg">
        <Modal.Header>
        <Button variant="close" aria-label="Close" onClick={closeModal} />
        </Modal.Header>
        <Modal.Body>
          <Card.Img
            src={src}
            alt={alt}
            className="mx-auto"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RenderImage;
