import React from "react";
import {
  FormatDate,
  FormatDateTime,
  FormatSimpleDate,
} from "../../components/dateformat";

export const CharComponent = ({ value }) => {
  return <React.Fragment>{value}</React.Fragment>;
};

export const DateComponent = ({ value }) => {
  if (window.innerWidth < 950) {
    return (
      <React.Fragment>{value ? FormatSimpleDate(value) : ""}</React.Fragment>
    );
  }
  return <React.Fragment>{value ? FormatDate(value) : ""}</React.Fragment>;
};

export const DatetimeComponent = ({ value }) => {
  if (window.innerWidth < 1500 && window.innerWidth > 950) {
    return <React.Fragment>{value ? FormatDate(value) : ""}</React.Fragment>;
  } else if (window.innerWidth < 950) {
    return (
      <React.Fragment>{value ? FormatSimpleDate(value) : ""}</React.Fragment>
    );
  }
  return <React.Fragment>{value ? FormatDateTime(value) : ""}</React.Fragment>;
};

export const StatusUpdate = ({ status, suffix, issue }) => {
  let statusTxtColor;
  switch (status) {
    case "Uploaded":
      statusTxtColor = "text-primary";
      break;
    case "Processed":
      statusTxtColor = "text-success";
      break;
    case "Synced":
      statusTxtColor = "text-success";
      break;
    case "Validated":
      statusTxtColor = "text-warning";
      break;
    case "Needs Attention":
      if (issue === "Warning") {
        statusTxtColor = "text-warning";
      } else if (issue === "Error") {
        statusTxtColor = "text-danger";
      }

      break;
    default:
      statusTxtColor = "text-dark";
  }

  return status ? (
    <span className={statusTxtColor}>
      <span className="ms-1">
        {status}
        {suffix}
      </span>
    </span>
  ) : (
    "--"
  );
};

export const StatusComponent = ({ value }) => {
  return (
    <React.Fragment>
      {value ? <StatusUpdate status={value} /> : "-"}
    </React.Fragment>
  );
};
