import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CancelIcon from "@mui/icons-material/Cancel";
import { postAddPayout } from "../../api/RazorpayXAPI";
import { useSelector } from "react-redux";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': {
    width: '90%', // Adjust the width for small screens
  },
  '@media (max-width: 960px)': {
    width: '70%', // Adjust the width for medium screens
  },
};

const PaymentModal = (props) => {
  const buttonStyle = {
    marginTop: "10px",
  };

  const [formData, setFormData] = useState({ 
    account_number: "",
    fund_account_id: "",
    amount: props.grandTotal.toString(),
    currency: "",
    mode: "",
    purpose: "",
    reference_id: "",
    narration: "",
    notes: "",
  });

  const handleInputChange = (field) => (event) => {
    if (field === "notes") {
      setFormData({
        ...formData,
        notes: {
          ...formData.notes,
          ["Note1"]: event.target.value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [field]: event.target.value,
      });
    }
  };

  const { connected_accounts: connectedAccounts } = useSelector(
    (state) => state.connectedAccountData
  );

  const connectingCredentials = connectedAccounts
    ? connectedAccounts.hasOwnProperty("RazorPayX")
      ? connectedAccounts["RazorPayX"]
      : null
    : null;

  const handleSubmit = async () => {
    // Check if all required fields are filled
    if (
      formData.account_number &&
      formData.fund_account_id &&
      formData.amount &&
      formData.currency &&
      formData.mode &&
      formData.purpose &&
      formData.reference_id &&
      formData.notes
    ) {
      // All required fields are filled, proceed with form submission
      try {
        const payoutResponse = await postAddPayout(
          connectingCredentials.usr,
          connectingCredentials.pwd,
          formData
        );
        console.log(payoutResponse);
        // Call the onSubmit prop to pass data to the parent component
        props.onSubmit(formData);
        console.log(formData);
        // Close the modal after successful submission
        props.onClose();
      } catch (error) 
      {
        console.error("Error submitting contact:", error);
      }
    } else {
      // Display an error message or handle the validation in a way that fits your UI
      console.error("All required fields must be filled");
    }
  };
    
  return (
    <div>
      <Modal
        keepMounted
        open={props.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.onClose();
          }
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <CancelIcon
            onClick={props.onClose}
            style={{ position: "absolute", top: 15, right: 10, cursor: "pointer" }}
          />

          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Payout Details
          </Typography>

          <Grid container spacing={2}>
            {/* Left Column - 4 text fields */}
            <Grid item xs={6}>
              <TextField
                required
                label="Account Number"
                type="number"
                fullWidth
                margin="normal"
                value={formData.account_number}
                onChange={handleInputChange("account_number")}
              />
            </Grid>
        
            <Grid item xs={6}>
              <TextField
                required
                label="Fund Account ID"
                fullWidth
                margin="normal"
                value={formData.fund_account_id}
                onChange={handleInputChange("fund_account_id")}
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                required
                label="Amount"
                type="number"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                margin="normal"
                value={formData.amount}
                onChange={handleInputChange("amount")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                label="Mode"
                fullWidth
                margin="normal"
                value={formData.mode}
                onChange={handleInputChange("mode")}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {/* Right Column - 5 text fields */}
            <Grid item xs={6}>
              <TextField
                label="Currency"
                required
                fullWidth
                margin="normal"
                value={formData.currency}
                onChange={handleInputChange("currency")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Purpose"
                required
                fullWidth
                margin="normal"
                value={formData.purpose}
                onChange={handleInputChange("purpose")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Reference_id"
                required
                fullWidth
                margin="normal"
                value={formData.reference_id}
                onChange={handleInputChange("reference_id")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Narration"
                required
                fullWidth
                margin="normal"
                value={formData.narration}
                onChange={handleInputChange("narration")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Notes"
                fullWidth
                margin="normal"
                value={formData.notes.Note1}
                onChange={handleInputChange("notes")}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "auto",
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="success"
              style={{ ...buttonStyle, marginLeft: 8 }}
            >
              Pay
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentModal;
