import React, { useState } from 'react';

const ButtonWithAnimation = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    color: isHovered ? 'black' : 'white',
    borderColor: 'white',
    borderRadius: '50px', // Adjust the value as needed for the desired roundness
    background: isHovered ? 'linear-gradient(316deg, #6247aa 0%, #a594f9 74%)' : 'transparent',
    transition: 'background-color 0.3s, color 0.3s',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </button>
  );
};

export default ButtonWithAnimation;
