import React, { useState, useEffect } from "react";
import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
// import CustomModal from "./CustomModal";
import TransitionsModal from "./TransitionsModal";
import { URLs } from "../../routes";
import { useNavigate } from "react-router-dom";
import MenuAppBar from "./MenuAppBar";
import { axiosInstance } from "../../axiosSetup";
import { getProductsListAPIerp } from "../../api/BuyPlansAPI";
import { useSelector } from "react-redux";

const OverviewMobile = () => {
  const navigateTo = useNavigate();
  const { connected_apps } = useSelector((state) => state.connectedAccountData);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const [productList, setProductList] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    // Assuming axiosInstance is properly configured
    // axiosInstance
    //   .get("sds/products/", {})
    //   .then((res) => {
    //     const products = res.data.result;
    //     setProductList(products);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });

    getProdList();
  }, []);
  const getProdList = async () => {
    try {
      const res = await getProductsListAPIerp();
      // console.log(res);

      if (res.status === 200) {
        setProductList(res.data.product_list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const gridItemStyle = {
    width: "75%",
    height: "90px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
    textAlign: "center",
    marginLeft: "15px",
  };

  const productNameStyle = {
    marginTop: "7px",
    fontWeight: "bold",
    color: "black",
    fontSize: isSmallScreen ? "14px" : "17px",
    fontFamily: "inherit",
    width: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginLeft: "30px",
  };

  const ContainerStyle = {
    marginLeft: "2px",
    marginTop: "20px",
    backgroundColor: "white",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    maxWidth: "100%",
    paddingBottom: "20px",
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const openModalOnClick = (event, product) => {
    event.stopPropagation();
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const viewSelectedProduct = (product) => {
    // navigateTo(URLs[product.url].path);
    if (product.name !== "Lab Management") {
      navigateTo(URLs[product.custom_url].path);
    } else {
      const labManagement = connected_apps.hasOwnProperty("LabManagement")
        ? connected_apps["LabManagement"]
        : null;
      if (labManagement.credential_available === "YES") {
        const loginUrl = `${labManagement["base_url"]}${labManagement["redirecting_url"]}?usr=${labManagement["email"]}&sid=${labManagement["sid"]}`;
        // Open a new tab
        const newTab = window.open(loginUrl, "_blank");
      }
      if (labManagement.credential_available === "NO") {
        navigateTo("/redirecting-error", {
          state: {
            data: {
              connect2: labManagement.connect2,
              credential_available: "NO",
              productName: "LabManagement",
            },
          },
        });
      }
    }
  };

  const subscribedProducts =
    JSON.parse(localStorage.getItem("subscribed_products")) || [];
  const isProductSubscribed = (product) => {
    return subscribedProducts.includes(product.name);
  };
  const subscribedProductsOrder = [];
  const unsubscribedProductsOrder = [];

  productList?.forEach((product) => {
    if (isProductSubscribed(product)) {
      subscribedProductsOrder.push(product);
    } else {
      unsubscribedProductsOrder.push(product);
    }
  });

  return (
    <React.Fragment>
      {/* <MenuAppBar /> */}
      <Grid container spacing={2} style={{ ...ContainerStyle }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ marginLeft: "10px", fontWeight: "bold" }}
          >
            Subscribed Products
          </Typography>
        </Grid>

        {/* Subscribe Products */}
        {productList !== null &&
          subscribedProductsOrder.map((product, index) => {
            const productName = product.name.trim();
            const imageUrl =
              hoveredIndex === `s-${index}`
                ? `${process.env.REACT_APP_ERP_BASE_URL}${product.image}`
                : `${process.env.REACT_APP_ERP_BASE_URL}${product.image}`;

            return (
              <Grid item xs={4} md={4} lg={2} key={`s-${index}`}>
                <div
                  style={{
                    ...gridItemStyle,
                    position: "relative",
                  }}
                  onClick={() => viewSelectedProduct(product)}
                >
                  <InfoIcon
                    style={{
                      cursor: "pointer",
                      color: "black",
                      fontSize: "15px",
                      position: "absolute",
                      top: -1.5,
                      right: -4,
                      margin: "5px",
                    }}
                    onClick={(event) => openModalOnClick(event, product)}
                  />
                  <img
                    src={imageUrl}
                    alt={productName}
                    style={{
                      width: isSmallScreen ? "50px" : "65px",
                      height: isSmallScreen ? "50px" : "65px",
                    }}
                    onMouseEnter={() => handleMouseEnter(`s-${index}`)}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
                <div style={productNameStyle}>{productName}</div>
              </Grid>
            );
          })}
      </Grid>

      <Grid container spacing={2} style={{ ...ContainerStyle }}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ marginLeft: "10px", fontWeight: "bold" }}
          >
            Other Products
          </Typography>
        </Grid>

        {/*UnSubscribe Products */}
        {productList !== null &&
          unsubscribedProductsOrder.map((product, index) => {
            const productName = product.name.trim();
            const imageUrl =
              hoveredIndex === `i-${index}`
                ? `${process.env.REACT_APP_ERP_BASE_URL}${product.image}`
                : `${process.env.REACT_APP_ERP_BASE_URL}${product.image}`;

            return (
              <Grid item xs={4} md={3} lg={2} key={`i-${index}`}>
                <div
                  style={{
                    ...gridItemStyle,
                    position: "relative",
                  }}
                >
                  <InfoIcon
                    style={{
                      cursor: "pointer",
                      color: "black",
                      fontSize: "15px",
                      position: "absolute",
                      top: -1.5,
                      right: -4,
                      margin: "5px",
                    }}
                    onClick={(event) => openModalOnClick(event, product)}
                  />
                  <img
                    src={imageUrl}
                    alt={productName}
                    style={{
                      width: isSmallScreen ? "50px" : "65px",
                      height: isSmallScreen ? "50px" : "65px",
                    }}
                    onMouseEnter={() => handleMouseEnter(`i-${index}`)}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
                <div style={productNameStyle}>{productName}</div>
              </Grid>
            );
          })}
      </Grid>

      {modalOpen && (
        <TransitionsModal
          product={selectedProduct}
          onClose={() => setModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

export default OverviewMobile;
