import axios from "axios";

export const getAllNotesAPI = async (
  base_url,
  api_key,
  api_secret,
  pageNo = 0
) => {
  try {
    // console.log(base_url);
    const response = await axios.get(
      `${base_url}/api/method/note.api.get_recent_notes?page_no=${pageNo}`,

      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // Handle any errors or exceptions here
    console.error("Error fetching user list:", error);
    throw error;
  }
};

export const postSubmitNewNoteAPI = async (
  dataBody,
  base_url,
  api_key,
  api_secret
) => {
  // console.log(dataBody);
  try {
    var modifiedContent = "";
    if (
      dataBody &&
      dataBody.content !== undefined &&
      dataBody.content !== null
    ) {
      modifiedContent = dataBody.content.replace(/\n/g, ",");
    }
    // console.log(modifiedContent);

    const response = await axios.post(
      `${base_url}/api/method/note.api.create_new_note`,
      {
        params: {
          title: dataBody.title,
          content: modifiedContent,
          customer: dataBody.customer,
          lead: dataBody.lead,
        },
        image: dataBody.image,
        image_ext: dataBody.image_ext,
        _user_tags: dataBody._user_tags,
      },
      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCustomerListAPI = async (
  base_url,
  api_key,
  api_secret,
  customer = null
) => {
  try {
    const response = await axios.get(
      `${base_url}/api/method/note.api.get_all_customers?customer=`,

      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // Handle any errors or exceptions here
    console.error("Error fetching user list:", error);
    throw error;
  }
};

export const getAllLeadListAPI = async (base_url, api_key, api_secret) => {
  try {
    const response = await axios.get(
      `${base_url}/api/method/note.api.get_all_leads?lead=`,

      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${api_key}:${api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // Handle any errors or exceptions here
    console.error("Error fetching user list:", error);
    throw error;
  }
};