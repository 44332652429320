import axios from "axios";
import { axiosInstance } from "../axiosSetup";
import { resolve } from "./resolve.js";

const url = "auth";

export const LoginAPI = async (loginDetails) => {
  return await resolve(axiosInstance.post(`${url}/login/`, loginDetails));
};
export const NewRegisterAPI = async (Details) => {
  const res = await axiosInstance.post(`${url}/register/`, Details);
  return res;
};
export const ChangePasswordAPI = async (newData) => {
  return await resolve(axiosInstance.post(`${url}/change-password/`, newData));
};
export const ResetPasswordAPI = async (newData) => {
  try {
    const response = await resolve(
      axiosInstance.post(`${url}/password_reset/confirm/`, newData)
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const Email4PasswordAPI = async (emailData) => {
  try {
    const response = await axiosInstance.post(
      `${url}/password_reset/`,
      emailData
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const GetUserDetailsAPI = async () => {
  return await resolve(axiosInstance.get(`${url}/user/`));
};

export const UpdateUserDetailsAPI = async (editedData) => {
  return await resolve(axiosInstance.patch(`${url}/user/`, editedData));
};
export const ActivateAccountAPI = async (id) => {
  return await resolve(axiosInstance.post(`${url}/activate-account/${id}/`));
};

// erpDRS apis
export const NewRegisterAPIerp = async (Details) => {
  const formData = new FormData();
  formData.append("email", Details.email);
  formData.append("password", Details.password);
  formData.append("confirm_password", Details.confirm_password);
  formData.append("full_name", Details.full_name);
  formData.append("company_name", Details.company_name);
  formData.append("phone", Details.phone);

  const res = await axios.post(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.sign_up`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const LoginAPIerp = async (erpLoginCred) => {
  const formD = new FormData();
  formD.append("usr", erpLoginCred.email);
  formD.append("pwd", erpLoginCred.password);

  const res = await axios.put(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.user_login`,
    formD,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const Email4PasswordResetAPIerp = async (erpLoginCred, base_domain) => {
  const formD = new FormData();
  formD.append("mail", erpLoginCred.email);
  formD.append("base_domain", base_domain);

  const res = await axios.post(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.password_reset`,
    formD,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const ConfirmPasswordResetAPIerp = async (resetData, hash) => {
  const formD = new FormData();
  formD.append("password", resetData.password);
  formD.append("hash", hash);

  const res = await axios.put(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.password_reset_confirm`,
    formD,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const getBackendVersionAPIerp = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.get_version`,
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    }
  );
  return res;
};
