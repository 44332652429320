import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";
import { contactTypesList, contactStatusList } from "../../data/sds_metadata";

const FilterData = (props) => {
  const { handleFiltering, filterRequest } = props;
  const [size, setSize] = useState({ fontSize: "15px", height: "50px" });
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");

  const handleChange = (e) => {
    handleFiltering(e.target.name, e.target.value);
  };

  useEffect(() => {
    if (isExtraSmallScreen) {
      setSize({ fontSize: "11px", height: "45px" });
    } else if (isMediumScreen) {
      setSize({ fontSize: "12px", height: "48px" });
    }
  }, []);

  return (
    <React.Fragment>
      {size && (
        <Box sx={{ flexGrow: 1, margin: "10px", position: "relative" }}>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            <IconButton color="primary">
              <FilterListIcon />
            </IconButton>
            Filter
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} sm={6} xs={12}>
              <Box display="flex" alignItems="center">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: size.fontSize }}
                  >
                    Contact Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterRequest.contact_status || ""}
                    label="Filter Contact status"
                    name="contact_status"
                    onChange={handleChange}
                    sx={{
                      height: size.height,
                      background: "#F5F8FB",
                      fontSize: "14px",
                    }} // Adjust the height as needed
                  >
                    <MenuItem value="" sx={{ fontWeight: "bold" }}>
                      Select an option
                    </MenuItem>
                    {contactStatusList.map((contactStatus, index) => (
                      <MenuItem
                        key={`contactStatus-${index}`}
                        value={contactStatus}
                      >
                        {contactStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Box display="flex" alignItems="center">
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ fontSize: size.fontSize }}
                  >
                    Contact Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterRequest.contact_type || ""}
                    label="Contact Type"
                    name="contact_type"
                    onChange={handleChange}
                    sx={{
                      height: size.height,
                      background: "#F5F8FB",
                      fontSize: "14px",
                    }} // Adjust the height as needed
                  >
                    <MenuItem value="" sx={{ fontWeight: "bold" }}>
                      Select an option
                    </MenuItem>
                    {contactTypesList.map((contactType, index) => (
                      <MenuItem
                        key={`contact_type-${index}`}
                        value={contactType}
                      >
                        {contactType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

export { FilterData };
