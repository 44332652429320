import { Grid } from "@mui/material";
import React from "react";
import ItemSearch from "./ItemSearch";
import SecondaryItemSearch from "./SecondaryItemSearch";
import ReferenceSelect from "./ReferenceSelect";
import ReferenceTypeSelect from "./ReferenceTypeSelect";
import { useSelector } from "react-redux";
import ProspectSelect from "./ProspectSelect";

const SearchPanel = (props) => {
  const { selectedItem, selectedSecondaryItem, selected_reference_type } =
    useSelector((state) => state.calculatorData);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ItemSearch />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {selectedItem && selectedSecondaryItem && <ProspectSelect />}
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          {selectedItem && <SecondaryItemSearch />}
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {selectedItem && selectedSecondaryItem && <ReferenceTypeSelect />}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {selected_reference_type && <ReferenceSelect />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SearchPanel;
