import OMform from "./pages/OandM/OMform";

export const URLs = {
  //auth
  Signin: { path: "/sign-in" },
  Logout: { path: "/logout" },
  Signup: { path: "/register" },
  VerifyEmail: { path: "/verify-email/:token" },
  Support: { path: "/support" },
  Logout: { path: "/logout" },
  ForgotPassword: { path: "/forgot-password1" },
  ResetPassword: { path: "/reset-password/:token" },
  ChangePassword: { path: "/change-password/" },
  GetEmailForResetPassword: { path: "/forgot-password" },
  NotFound: { path: "/404" },
  ServerError: { path: "/500" },
  RedirectingErrURL: { path: "/redirecting-error" },

  // landing, pricing without login
  landingURL: { path: "/home" },
  privacyPolicyURL: { path: "/privacy-policy" },
  contactURL: { path: "/contact-us" },
  pricingURL: { path: "/pricing" },

  //dashboard
  Overview: { path: "/" },
  Dashboard: { path: "/dashboard/:dashboard_label" },
  //sds
  SDS: { path: "/business-automation/documents" },
  TestDoc: { path: "/business-automation/test-doc" },
  SDSDetail: { path: "/business-automation/documents/:entryId" },

  //ajs
  AJS: { path: "/ajs/orders" },
  AJSMonitor: { path: "/ajs/monitor" },
  AJSAssemblyStatus: { path: "/ajs/assembly-status" },
  // AJSAssemblyDemo: { path: "/ajs/assembly-demo" },
  AJSMasterData: { path: "/ajs/masterdata" },

  //network-optimization
  NetworkOptimizationMasterData: { path: "/network-optimization/masterdata" },

  //reports
  IntelligentReporting: { path: "/intelligent-reporting" },

  //kit
  productBundling: { path: "/product-bundling" },
  projectManagementURL: { path: "/resource-allocation" },
  digitalFormURL: { path: "/digital-forms-list" },
  openDigitalFormURL: { path: "/digital-forms" },
  // openDigitalFormV2URL: { path: "/digital-forms-v2" },
  omInspectionURL: {
    path: "/inspection",
  },

  //calculator
  Calculator: { path: "/calculator" },

  //chat-bot
  ChatbotURL: { path: "/chatbot" },
  BotImgURL: { path: "/chatbot/upload-image" },

  //exhibition
  Exhibition: { path: "/exhibition/visiting-cards" },
  ExhibitionDetail: { path: "/exhibition/visiting-cards/:entryId" },
  ExhibitionReceipt: { path: "/exhibition/receipt" },

  // company settings
  CompanySettingsURL: { path: "/company-settings" },
  // connected accounts
  ConnectedAccounts: { path: "/connected-accounts" },
  ConnectedAccountSetup: { path: "/connected-accounts-setup" },

  // plans
  MyPlanPlans: { path: "/my-plans" },
  BuyPlanURL: { path: "/buy-plans" },
  CheckoutPlanURL: { path: "/buy-plans/checkout" },
  Cart: { path: "/cart" },

  //payments
  Payments: { path: "/payments/Invoices" },
  //Contacts
  AddContacts : { path :"/payments/AddContacts"},
  //Fund Account
  FundAccount : { path :"/payments/FundAccount"},

  Suppliers : { path :"/payments/Suppliers"},
  //InvoiceDetail
  InvoiceDetail: { path: "/payments/invoiceDetail/:invoiceNumber" },
  //PaymentDetail
  PaymentDetail: { path: "/payments/PaymentDetail/:invoiceNumber" },

  //Notes
  Notes: { path: "/notes" },
  //timesheet
  TimesheetURL: { path: "/timesheet" },

   //Lab Management
  LabManagement: { path: "/formulation,R&D-labs" },
    //user manual
  UserManual: { path: "/userManual" },


};
