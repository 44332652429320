import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getSuppliersAPI } from "../../../api/RazorpayXAPI";
import { getBankAccountAPI } from "../../../api/RazorpayXAPI";
import { Button, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Suppliers = () => {
  const navigateTo = useNavigate();

  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Make Payments")
      ? connectedApps["Make Payments"]
      : null
    : null;

  const columns = [
    { field: "name", headerName: "Name", flex: 0.5, minWidth: 150 },
    { field: "bank", headerName: "Bank", flex: 0.5, minWidth: 150 },
    { field: "bank_account_no", headerName: "Bank A/C NO", flex: 0.5, minWidth: 150 },
    { field: "custom_ifsc", headerName: "IFSC", flex: 0.5, minWidth: 150 },
    // {
    //   field: "view",
    //   headerName: "",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Button
    //       variant="outlined"
    //       onClick={() => handleViewClick(params.row.id)}
    //       style={{ borderColor: "#050092", color: "#050092" }}
    //     >
    //       View
    //     </Button>
    //   ),
    // },
  ];

  const [contactsData, setContactsData] = useState([]);

  const handleViewClick = (id) => {
    const selectedInvoice = contactsData.find((item) => item.id === id);
    navigateTo(`/payments/invoiceDetail/${id}`, {
      state: { invoiceData: selectedInvoice },
    });
  };

  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const suppliersResponse = await getSuppliersAPI(
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret,
          '["name"]',
          {}
        );
        const bankAccountsResponse = await getBankAccountAPI(
          connectingCredentials.base_url,
          connectingCredentials.api_key,
          connectingCredentials.api_secret,
          '["custom_ifsc", "account_name", "bank","bank_account_no"]',
          {}
        );
  
        const combinedData = suppliersResponse.data.data.map((supplier, index) => {
          const matchingBankAccount = bankAccountsResponse.data.data.find(account =>
            account.account_name === supplier.name
          );
          return {
            id: index + 1,
            name: supplier.name,
            custom_ifsc: matchingBankAccount ? matchingBankAccount.custom_ifsc : "",
            bank: matchingBankAccount ? matchingBankAccount.bank : "",
            bank_account_no: matchingBankAccount ? matchingBankAccount.bank_account_no : "",
          };
        });
  
        setRowData(combinedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  

  return (
    <div className="page-container">
      <Card
        variant="outlined"
        sx={{ border: "1px solid lightgray" }}
        className="shadow-sm"
      >
        <CardHeader title="Suppliers" sx={{ margin: "15px" }} />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardContent style={{ backgroundColor: "#F5F8FB" }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowData}
              columns={columns}
              style={{ fontSize: "16px" }}
              pageSize={5}
              onSelectionModelChange={(newSelection) => {
                setSelectedRow(newSelection[0]);
              }}
            />
            {/* <p>Selected Row: {selectedRow}</p> */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Suppliers;
