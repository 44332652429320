import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

// const ItemSkeleton = () => {
//   return (
//     <Grid item  spacing={2}>
//       {/* Spacing at the top */}
//       <Grid item xs={12}>
//         <div style={{ height: 20 }} />
//       </Grid>
//       <Grid container spacing={2}>
//         <Grid sm={8}>
//           {/* <Card variant="outlined"> */}
//           <Grid item xs={12} sm={6} md={4} lg={3}>
//             <Stack spacing={4}>
//               <Skeleton variant="rectangular" width={160} height={100} />
//               <Skeleton variant="rectangular" width={160} height={100} />
//             </Stack>
//           </Grid>
//           {/* </Card> */}
//           {/* <Divider light /> */}
//           <Box my={2}>{/* <EditComponent /> */}</Box>
//         </Grid>

//         <Grid sm={4}>
//           {/* <Card variant="outlined"> */}
//           {/* <SummaryComponent /> */}
//           {/* </Card> */}
//         </Grid>
//       </Grid>
//       {/* <Divider light /> */}

//       {/* Item table */}
//       {/* <Box my={4}><ItemTable /></Box> */}
//     </Grid>
//   );
// };

const ItemSkeleton = () => {
  const rows = 3;
  const columns = 4;
  return (
    <Grid container spacing={2}>
      {/* Spacing at the top */}
      <Grid item md={12}>
        <div style={{ height: 20 }} />
      </Grid>

      {/* Mini Cards - 4 per row */}
      <Grid
        container
        item
        lg={8}
        md={7}
        sm={7}
        xs={12}
        spacing={2}
        style={{ marginTop: 2 }}
      >
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={120} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Big Card - Spanning 2 rows */}
      <Grid item sm={4} md={4} lg={4} xs={12} style={{ marginTop: 15 }}>
        <Skeleton variant="rectangular" width="100%" height={360} />
      </Grid>

      <Grid item md={12}>
        <div style={{ height: 20 }} />
      </Grid>

      <Grid container item sm={12} spacing={2} style={{ marginTop: 2 }}>
        <TableSkeleton
          rows={8}
          columns={[
            "Column 1",
            "Column 2",
            "Column 3",
            "Column 4",
            "Column 5",
            "Column 6",
            "Column 7",
          ]}
        />
      </Grid>
    </Grid>
  );
};

const TableSkeleton = ({ rows, columns }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: rows }, (_, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((_, columnIndex) => (
                <TableCell key={columnIndex}>
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemSkeleton;
