import {
  Button,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { attachFiles2TaskAPI } from "../../api/ChatbotAPI";
import { CheckBox, Verified } from "@mui/icons-material";

const BotImgUpload = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const chatIDbyURL = queryParams.get("chatID");
  const userIDbyURL = queryParams.get("userID");
  const taskIDbyURL = queryParams.get("taskID");
  const projectIDbyURL = queryParams.get("projectID");
  //   console.log(taskIDbyURL, chatIDbyURL, userIDbyURL);
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitApiRes, setSubmitApiRes] = useState(false);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setSubmitApiRes(false);
  //     window.close();
  //   }, 3000);

  //   return () => clearTimeout(timeout);
  // }, [submitApiRes]);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    // console.log(files);
    setSelectedFiles([...selectedFiles, ...Array.from(files)]);
  };

  const handleSubmitForm = async () => {
    // console.log(selectedFiles);
    if (selectedFiles.length > 0) {
      let data = {
        task_id: taskIDbyURL,
        attach_image: selectedFiles[0],
      };
      try {
        const res = await attachFiles2TaskAPI(data);
        // console.log(res);
        if (
          res.status === 200 &&
          res.data.msg === "Image uploaded successfully"
        ) {
          setSelectedFiles([]);
          setSubmitApiRes(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="page-container">
      {submitApiRes === false &&
        chatIDbyURL &&
        userIDbyURL &&
        taskIDbyURL &&
        projectIDbyURL && (
          <Card
            className="shadow-sm"
            variant="outlined"
            sx={{
              border: "1px solid #050092",
              borderRadius: "5px",
              width: "auto",
              minHeight: "90vh",
              marginTop: "20px",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <CardContent
              style={{
                backgroundColor: "#ffffff",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                border: "1px solid #050092",
                flexDirection: isMobileOrTablet ? "column" : "row",
              }}
            >
              <div>
                <h3>Upload Files</h3>
              </div>
              <div>
                {/* <h6>Chat ID: {chatIDbyURL}</h6>
              <h6>User ID: {userIDbyURL}</h6> */}
                <h6>Task ID: {taskIDbyURL}</h6>
                <h6>Project ID: {projectIDbyURL}</h6>
              </div>
            </CardContent>
            <CardContent
              style={{
                backgroundColor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                // borderRadius: "10px",
                //   border: "1px solid red",
                textAlign: "center",
                height: "75vh",
                overflow: "auto",
              }}
            >
              <div></div>
              <div>
                <input
                  type="file"
                  id="file-upload"
                  accept=".pdf,.doc,.docx,.csv,.jpg,.jpeg,.png,.gif,.mp4,.mov,.avi,.ppt,.txt,.xls,.xlsx"
                  multiple
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
                <label htmlFor="file-upload">
                  <Button
                    style={{
                      borderColor: "#050092",
                      color: "#050092",
                    }}
                    variant="outlined"
                    component="span"
                  >
                    Select the files to upload
                  </Button>
                </label>

                {selectedFiles.length > 0 && (
                  <div>
                    <h4 style={{ marginTop: "5px" }}>Selected Files:</h4>
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <Button
                //   style={{
                //     borderColor: "#050092",
                //     color: "#050092",
                //   }}
                variant="contained"
                onClick={handleSubmitForm}
                disabled={selectedFiles.length > 0 ? false : true}
              >
                Submit
              </Button>
            </CardContent>
          </Card>
        )}
      {(!chatIDbyURL || !userIDbyURL || !taskIDbyURL) && (
        <Card
          className="shadow-sm"
          variant="outlined"
          sx={{
            border: "1px solid #050092",
            borderRadius: "10px",
            width: "auto",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <h4>Incomplete url</h4>
        </Card>
      )}
      {submitApiRes === true && (
        <Card
          className="shadow-sm"
          variant="outlined"
          sx={{
            border: "1px solid #050092",
            borderRadius: "10px",
            width: "auto",
            margin: "20px",
            minHeight: "90vh",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Verified
              style={{
                width: "30vw",
                height: "30vh",
                color: "lightgreen",
              }}
            />
            <Button
              variant="contained"
              style={{
                width: "50vw",
                height: "30vh",
                margin: "10px",
                backgroundColor: "lightgreen",
                color: "#36724d",
              }}
            >
              <h6> Files Uploaded Successfully</h6>
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "50vw",
                height: "20vh",
                margin: "10px",

                color: "#36724d",
              }}
            >
              <h6> Close this window & reply "Done" in Whatsapp</h6>
            </Button>
          </div>
        </Card>
      )}
    </div>
  );
};

export default BotImgUpload;
