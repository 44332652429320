import React, { useState } from "react";
import { Row, Col, Card, Container } from "@themesberg/react-bootstrap";
import { Divider, Modal } from "@mui/material";
// import ImageModal from "./ImageModal";
import RenderImage from "./ImageModal";

import productDetailpng from "../../../src/ManualImages/projectDetail.png";
import preliminaryApproval from "../../../src/ManualImages/preliminary approval.png";
import preliminaryApproval1 from "../../../src/ManualImages/1preliminaryapproved.png";
import additionaldetail from "../../../src/ManualImages/additionaldetails.png";
import additionalreview from "../../../src/ManualImages/detailedreview.png";
import sendforapproval from "../../../src/ManualImages/sendforapproval.png";
import sendforapproval1 from "../../../src/ManualImages/ApprovedIMG.png";
import projectpage1 from "../../../src/ManualImages/projectpage1.png";
import projectsummary from "../../../src/ManualImages/projectsummary.png";
import projectexecution from "../../../src/ManualImages/ProjectExecution.png";
import prospect1 from "../../../src/ManualImages/prospect1.png";
import labrequisite from "../../../src/ManualImages/labrequisition1.png";
import labrequisite1 from "../../../src/ManualImages/labrequisition2.png";
import excipient from "../../../src/ManualImages/excipient1.png";
import excipient1 from "../../../src/ManualImages/excipient2.png";
import BOM from "../../../src/ManualImages/BOM.png";
import manufacturer from "../../../src/ManualImages/Manufacture.png";
import designreqisition from "../../../src/ManualImages/DesignRequisition.png";
import technicalreqisition from "../../../src/ManualImages/technicalrequisition.png";
import QCTemplate from "../../../src/ManualImages/qualityinspectiontemplate.png";
import qualityinspection from "../../../src/ManualImages/qualityinspection.png";
import COA from "../../../src/ManualImages/COA.png";
import COA1 from "../../../src/ManualImages/COA 1.png";
import stabilitytest from "../../../src/ManualImages/STABILITYTEST.png";
import abbott from "../../../src/ManualImages/AbbottCUSTOMER.png";

const Usermanual = () => {
  return (
    <Container className="px-0">
      <Row>
        <Col xs={12} className="p-3">
          <Card>
            <Card.Body>
              <article>
                <h1 className="h2" id="overview">
                  1. Introduction{" "}
                </h1>

                <p>
                  This manual describes the complete process for the system
                  designed for Project and Lab Management. The system was
                  designed to handle Project Requests, Projects, and Lab
                  Activities, along with Quality Control for the formulations
                  created. In addition to Lab Activities, Design and Technical
                  Requisitions are also part of the system.
                </p>
                <p>
                  {" "}
                  This User Manual (UM) provides the information necessary for
                  types of users to effectively use the System Name (Acronym).
                </p>

                <div>
                  <h3
                    className="h3"
                    id="overview"
                    style={{ marginTop: "20px" }}
                  >
                    1.1 Overview{" "}
                  </h3>
                  <p>
                    {" "}
                    The system manages Lab Activities, such as the creation of
                    BOM/Formulations, along with stock movements (inventory).
                  </p>
                  <ul className="docs-list">
                    <li>
                      {" "}
                      We have designed a multi-level approval workflow for
                      Project Requests that can be customized to align with the
                      organization’s hierarchy.
                    </li>
                    <li>
                      Sale and Purchase transactions can be conducted seamlessly
                    </li>
                    <li>
                      The Quality Module oversees quality control activities,
                      including quality inspection and the generation of
                      Certificates of Analysis
                    </li>
                    <li>
                      Project Management is facilitated through tasks and
                      reports, such as Gantt charts. Standard project templates
                      can be created to manage tasks within a project
                    </li>
                  </ul>
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div>
                  <h3
                    className="h2"
                    id="overview"
                    style={{ marginTop: "20px" }}
                  >
                    2. Project Request{" "}
                  </h3>
                  <p>
                    {" "}
                    Instructions: Provide a general walkthrough of the system
                    from initiation through exit. The logical arrangement of the
                    information should enable the user to understand the
                    sequence and flow of the system. Use screen prints to depict
                    examples of text under each heading. All screen prints must
                    have a caption and an associated tag providing appropriate
                    alternative text for Section 508 compliance.
                  </p>
                </div>

                <div>
                  <h3
                    className="h3"
                    id="overview"
                    style={{ marginTop: "20px" }}
                  >
                    2.1 Workflow{" "}
                  </h3>

                  <ul className="docs-list">
                    <li>
                      {" "}
                      Fill Preliminary Details Workflow:
                      <li style={{ marginLeft: "30px" }}>
                        User fills in the preliminary project details and then
                        sends the filled information for preliminary approval to
                        Project Manager
                      </li>
                    </li>
                    <li>
                      Preliminary Approval Process:
                      <li style={{ marginLeft: "30px" }}>
                        The project manager provides approval for the
                        preliminary stage
                      </li>
                    </li>
                    <li>
                      Continue Project Details:
                      <li style={{ marginLeft: "30px" }}>
                        User completes the remaining project details and then
                        sends the detailed information for review to the project
                        manager
                      </li>
                    </li>
                    <li>
                      Detailed Review:
                      <li style={{ marginLeft: "30px" }}>
                        The project manager thoroughly reviews the detailed
                        information
                      </li>
                    </li>
                    <li>
                      Final Approval Process:
                      <li style={{ marginLeft: "30px" }}>
                        The Project Manager sends the approved details
                        (Proposal) for final approval to the system manager.
                      </li>
                      <li style={{ marginLeft: "30px" }}>
                        Upon system manager approval, the project is created and
                        advances to the next phase
                      </li>
                    </li>
                  </ul>
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      2.2 Project Details
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Project Details Page:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            User adds project information in project request
                          </li>
                        </ul>
                      </li>
                      <li>
                        Fields to fill:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>Project Name</li>
                          <li>Project Description</li>
                          <li>Aim</li>
                          <li>Objectives</li>
                        </ul>
                      </li>
                      <li>
                        Project Type/Category:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            If Internal Project: Specify Potential Customers
                          </li>
                          <li>If Customer Project: Provide Customer Details</li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={productDetailpng}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      2.3 Send for Preliminary Approval – Preliminary Approved
                    </h3>

                    <ul className="docs-list">
                      <li>
                        After filling in the project details, the user initiates
                        the process by sending the information for preliminary
                        approval
                      </li>
                      <li>
                        The project manager provides approval for the
                        preliminary stage
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={preliminaryApproval}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h5 style={{ marginTop: "20px", marginLeft: "10px" }}>
                      Step - 2
                    </h5>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={preliminaryApproval1}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3
                      className="h3"
                      id="overview"
                      style={{ marginTop: "20px" }}
                    >
                      2.4 Additional Details{" "}
                    </h3>

                    <ul className="docs-list">
                      <li> Budget Evaluation</li>
                      <li>Project timeline</li>
                      <li>
                        Task timeline - In a custom template with predefined
                        tasks, user can add specific timelines for each task
                      </li>
                      <li>
                        Project Management is facilitated through tasks and
                        reports, such as Gantt charts. Standard project
                        templates can be created to manage tasks within a
                        project
                      </li>
                      <li> Concept Development</li>
                      <li> Feasibility Analysis</li>
                    </ul>
                  </div>
                  <RenderImage
                    src={additionaldetail}
                    alt="project detail"
                    height="600px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      2.5 Send for detailed review
                    </h3>

                    <ul className="docs-list">
                      <li>
                        After filling in the remaining project details, the user
                        sends the detailed information for review to the project
                        manager
                      </li>
                      <li>
                        The project manager thoroughly reviews the detailed
                        information
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={additionalreview}
                    alt="project detail"
                    height="600px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      2.6 Send for Approval - Approved
                    </h3>

                    <ul className="docs-list">
                      <li>
                        The Project Manager sends the approved details
                        (Proposal) for final approval to the system manager
                      </li>
                      <li>
                        Upon system manager approval, the project is created and
                        advances to the next phase
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={sendforapproval}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h5 style={{ marginTop: "20px", marginLeft: "10px" }}>
                      Step - 2
                    </h5>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={sendforapproval1}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div>
                  <h3
                    className="h2"
                    id="overview"
                    style={{ marginTop: "20px" }}
                  >
                    3. Project{" "}
                  </h3>
                  <p>
                    {" "}
                    Instructions: Provide a detailed description of each user
                    function and/or feature, explaining in detail the
                    characteristics of the required input and system-produced
                    output. Each function/feature should be described under a
                    separate sub-section header, 4.1-4.x, and should correspond
                    sequentially to the system functions (e.g., menu items)
                    and/or features listed in certain sub-sections found in this
                    document. Include screen prints as needed to depict
                    examples. This section of the User Manual may also be
                    tailored or customized based on defined user roles, if
                    appropriate.
                  </p>
                  <ul className="docs-list">
                    <li>
                      output formats and specifying the output’s purpose,
                      frequency, options, media, and location
                    </li>
                  </ul>
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      3.1 Project Page
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Fields Captured:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>Project Name</li>
                          <li>Status</li>
                          <li>Is Active</li>
                          <li>Project Type</li>
                          <li>Expected Start Date</li>
                          <li>Expected End Date</li>
                          <li>Customer Details</li>
                          <li>Users</li>
                          <li>Monitor Progress</li>
                        </ul>
                      </li>
                      <li>
                        Additional Tabs:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            Requisitions Tab: Users can create, review, and
                            track resource requests tied to the project
                          </li>
                          <li>
                            Prospect Tab: Facilitates the creation and
                            management of potential opportunities or prospects
                            associated with the project
                          </li>
                          <li>
                            Tasks List: Displays a list of tasks associated with
                            the project, fetched from a predefined project
                            template
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={projectpage1}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      3.2 Project Summary
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Quality Inspection Summary:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            Presents a summarized overview of quality
                            inspections conducted during the project, offering
                            insights into the quality performance specifically
                            related to laboratory activities
                          </li>
                        </ul>
                      </li>
                      <li>
                        Conclusion of Quality Inspections:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            Project users can manually write conclusions related
                            to quality inspections, providing a narrative
                            insight into the inspection outcomes
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={projectsummary}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      3.3 Project Template
                    </h3>

                    <ul className="docs-list">
                      <li>
                        It outlines tasks, assignees, due dates, and
                        dependencies for effective project management
                      </li>
                      <li>
                        It provides a structured framework to streamline task
                        planning, enhance communication, and ensure a systematic
                        approach to task execution throughout the project
                        lifecycle
                      </li>
                      <li>
                        Tasks from a standard template can be assigned to
                        specific employees depending upon the type of task{" "}
                      </li>
                      <li>
                        Tasks can be listed in a project template without set
                        timelines. The timelines can be added later in the
                        project request phase for flexibility and better
                        alignment with project needs
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={projectexecution}
                    alt="project detail"
                    height="500px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h2" id="overview">
                      4. Prospect
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Sales Team (Seller) creates Prospect, against the lead
                        if the lead is verified
                      </li>
                      <li>
                        Against the prospect, the seller selects items, their
                        dosage, price per KG from the price master.
                      </li>
                      <li>
                        There can be a creation of new Prospect against the same
                        lead if in case there is a change in requested Product.
                      </li>
                      <li>
                        The seller can record essential information, such as
                        contact details, organization, and specific
                        requirements, to facilitate effective communication and
                        tailored engagement with potential clients.
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={prospect1}
                    alt="project detail"
                    height="350px"
                  />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h2" id="overview">
                      5. Lab Requisition
                    </h3>

                    <ul className="docs-list">
                      <li>
                        The seller creates a request for the lab to create
                        formulation (FG) for the prospect (project).
                      </li>
                      <li>
                        Formulator creates a new FG item for the requisition and
                        experiments with different items for BOM creation.
                      </li>
                      <li>
                        When the BOM is finalized, BOM is submitted in the
                        system and consumption of the items is carried out
                        (stock entry)
                      </li>
                      <li>
                        FG Item is created and submitted to the QC department
                        for analysis.
                      </li>
                      <li>
                        If the FG item fails Quality inspection, BOM is revised,
                        new batch is prepared.
                      </li>{" "}
                      <li>
                        After the analysis is approved by the QC, the item is
                        submitted to the seller.
                      </li>
                      <li>
                        Certificate of Analysis [CoA] is created by the QC
                        (based on the inputs).
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={labrequisite}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      5.1 Lab Details
                    </h3>

                    <ul className="docs-list">
                      <li>
                        The Lab Details section incorporates text fields for
                        preparation, procedure, and safety measures.
                      </li>
                      <li>
                        Users can input relevant information, including
                        materials and steps for preparation, procedural
                        instructions, and safety guidelines.{" "}
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={labrequisite1}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      5.2 Items
                    </h3>

                    <ul className="docs-list">
                      <li>Relevant Fields</li>
                      <li>Item Name</li>
                      <li>Item Group</li>
                      <li>UOM</li>
                      <li>Description</li>
                      <li>
                        The Test License Requisition Details section becomes
                        accessible when the item group corresponds to categories
                        (Ex. Active Pharmaceutical Ingredients) requiring a test
                        license.
                      </li>
                      <li>
                        In this section, users can input crucial details such as
                        Application Reference No., Application date, and
                        Requisition status, streamlining the management of test
                        license requisitions for the specific item group in
                        focus.
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={excipient}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      5.3 Stability Test Template
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Users can easily add stability test templates to the
                        quality tab of an item
                      </li>
                      <li>
                        This simplifies the stability test process, requiring
                        users only to input readings directly
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={excipient1}
                    alt="project detail"
                    height="400px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      5.4 Bill Of Material(BOM)
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Relevant Fields
                        <ul style={{ marginLeft: "30px" }}>
                          <li>Item</li>
                          <li>Item UOM</li>
                          <li>Quantity</li>
                          <li>Is Active</li>
                          <li>Is Default</li>{" "}
                          <li>Rate of Materials Based On</li>
                          <li>Currency</li>
                          <li>Raw Material Table</li>
                        </ul>
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Phase</li>
                          <li>Raw Material Table</li>
                          <li>Quantity</li>
                          <li>Dose%</li>
                          <li>UOM</li>
                        </ul>
                        <ul style={{ marginLeft: "30px" }}>
                          <li>More Info</li>
                          <ul>
                            <li>Process Description</li>
                            <li>Critical Notes</li>
                          </ul>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage src={BOM} alt="project detail" height="450px" />
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      5.5 Stock Entry
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Stock Consumption is triggered by the user to consume
                        the stock as per BOM.
                      </li>
                      <li>
                        With this stock entry, effortlessly track and record the
                        movement of components, ensuring precise adjustments in
                        inventory levels for enhanced control and transparency.
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={manufacturer}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h2" id="overview">
                      6. Design Requisition
                    </h3>

                    <ul className="docs-list">
                      <li>Customer Requests for Design Documents.</li>
                      <li>
                        The seller creates a requisition for Design Document
                        along with the item names.
                      </li>
                      <li>Design team creates the document</li>
                      <li>in requested format.</li>
                      <li>Design Document is attached (document/URL).</li>
                      <li>Requisition status is updated.</li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={designreqisition}
                    alt="project detail"
                    height="550px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h2" id="overview">
                      7. Technical Requisition
                    </h3>

                    <ul className="docs-list">
                      <li>Customer Requests for Technical Documents.</li>
                      <li>
                        The seller creates a requisition for the Technical
                        Document along with the item names.
                      </li>
                      <li>
                        Technical team creates the document in requested format.
                      </li>
                      <li>Technical Document is attached (document/URL).</li>
                      <li>Requisition status is updated.</li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={technicalreqisition}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h2" id="overview">
                      8. Quality Check
                    </h3>

                    <ul className="docs-list">
                      <li>
                        List of activities/scenarios
                        <ul style={{ marginLeft: "30px" }}>
                          <li>Lab Requisition Item QC</li>
                        </ul>
                        <ul style={{ marginLeft: "50px" }}>
                          <li> Initial analysis (parameters)</li>
                          <li> Generated COA</li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="docs-list">
                      <li>
                        QC LTS
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            Initial analysis (parameters) done multiple times
                          </li>
                          <li>Stability Report</li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                </div>

                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      8.1 Quality Inspection Template
                    </h3>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={QCTemplate}
                    alt="project detail"
                    height="400px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      8.2 Quality Inspection
                    </h3>

                    <ul className="docs-list">
                      <li>Relevant Fields</li>
                      <ul style={{ marginLeft: "30px" }}>
                        <li>Report Date</li>
                        <li>Manual Inspection and type</li>
                        <li>Reference Type , Name</li>
                        <li>Item Code</li>
                        <li>Batch Number</li>
                        <li>Sample Size</li>
                        <li>Description</li>
                        <li>Readings</li>
                        <li>Inspected By</li>
                      </ul>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={qualityinspection}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      8.3 COA (Certificate of Analysis)
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Certificate of Analysis (CoA) is a document created by
                        the QC department for the FG or Raw Material (SFG).
                      </li>
                      <li>
                        CoA contains the following details/columns:
                        <li>Parameters - on which the testing is done.</li>
                        <li>Specifications - Values or ranges.</li>
                        <li>Results - from the testing.</li>
                      </li>
                      <li>
                        The Certificate of Analysis (CoA) report displays a list
                        of both created and pending CoAs, offering a clear
                        overview of the status and progress of certificate
                        generation.
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage src={COA} alt="project detail" height="500px" />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h5" id="overview">
                      Step - 2
                    </h3>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage src={COA1} alt="project detail" height="400px" />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h3" id="overview">
                      8.4 Stability Test
                    </h3>

                    <ul className="docs-list">
                      <li>
                        Readings from stability tests conducted at various
                        intervals and temperatures are documented in the
                        stability table. This record captures the essential
                        data, providing a comprehensive overview of the item's
                        stability under different conditions over time.
                      </li>
                      <li>
                        The stability table comprises columns for parameters,
                        limits, initial results, and stability data under both
                        room temperature and specific conditions like Chembur.
                      </li>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={stabilitytest}
                    alt="project detail"
                    height="600px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ flex: 1 }}>
                    <h3 className="h2" id="overview">
                      9. Customer Details
                    </h3>

                    <ul className="docs-list">
                      <li>Relevant Fields</li>
                      <ul style={{ marginLeft: "30px" }}>
                        <li>Customer Name</li>
                        <li>Customer Type</li>
                        <li>Customer Group</li>
                        <li>Contact & Address</li>
                        <li>From Lead</li>
                        <li>From Opportunity</li>
                      </ul>
                    </ul>
                  </div>

                  {/* Reduce the size of the image and move it to the right */}
                  <RenderImage
                    src={abbott}
                    alt="project detail"
                    height="500px"
                  />
                </div>
                <Divider sx={{ backgroundColor: "black", marginTop: "40px" }} />
              </article>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Usermanual;
