import React, { useState } from "react";
import { Card } from "@themesberg/react-bootstrap";
import AddEntry from "./AddEntry";
import "./SDS.css";
import {
  Alert,
  CardHeader,
  Card as CardMui,
  Divider,
  LinearProgress,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setToRefreshData } from "../../slices/sdsSlice";
import { useSelector } from "react-redux";
import SDSGrid from "./SDSGridServerSide";
import { TableActions } from "./TableActions";

const SDSHome = (props) => {
  const dispatch = useDispatch();
  const { toRefreshData } = useSelector((state) => state.sdsData);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState(null);

  const [whetherRowsSelected, setWhetherRowsSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const isMediumScreen = useMediaQuery("(max-width:960px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 500px)");

  const onSuccessfulEntry = (alertVariant, alertMsg, toRefreshData = false) => {
    setShowAlert(true);
    setAlertVariant(alertVariant);
    setAlertMsg(alertMsg);
    if (toRefreshData) {
      dispatch(setToRefreshData(true));
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const handleRowsSelection = (selectedRows) => {
    setSelectedRows(selectedRows);
    if (selectedRows.length === 0) {
      setWhetherRowsSelected(false);
    } else {
      setWhetherRowsSelected(true);
    }
  };

  return (
    <div className="page-container">
      <CardMui
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid #eaedf2",
          borderRadius: "10px",
          width: "100%",
          height: "100px",
        }}
      >
        <CardHeader
          title="Documents"
          sx={{
            margin: "15px",
          }}
          action={
            <div
              style={{
                display: "flex",
                flexDirection: isExtraSmallScreen
                  ? "column"
                  : isMediumScreen
                  ? "row"
                  : "row",
              }}
            >
              {!whetherRowsSelected && (
                <AddEntry callback={onSuccessfulEntry} />
              )}
              {whetherRowsSelected && (
                <React.Fragment>
                  <TableActions
                    selectedRows={selectedRows}
                    // invoiceData={invoiceData}
                    callback={onSuccessfulEntry}
                  />
                </React.Fragment>
              )}
            </div>
          }
        />
      </CardMui>
      <Divider sx={{ backgroundColor: "darkgray" }} />
      <Card border="light" className="shadow-sm">
        <SDSGrid
          toRefreshData={toRefreshData}
          handleRowsSelection={handleRowsSelection}
        />

        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer>
      </Card>

      <React.Fragment>
        <Snackbar
          open={showAlert}
          onClose={() => setShowAlert(false)}
          style={{ minWidth: "100px" }}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertVariant}
            sx={{ width: "100%", minHeight: "50px" }}
          >
            {alertMsg}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </div>
  );
};

export default SDSHome;
