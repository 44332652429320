import React, { useState } from "react";
import {
  Button,
  Form,
  ListGroupItem,
  ListGroup,
  Modal,
} from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../../axiosSetup";

const Report = (props) => {
  const {
    currentTask,
    nextTasks,
    workcenter,
    handleTaskChange,
    promptSubmit,
    workcenterForm,
    callbackChangeinWorkcenter,
  } = props;
  const [showModal, setshowModal] = useState(false);

  //styling
  let currentTaskDisplay = currentTask.part_name
    ? `(${currentTask.mcsr_no}) ${currentTask.part_name}`
    : "None";
  let nextTaskDisplay = nextTasks[0]?.part_name
    ? `(${nextTasks[0].mcsr_no}) ${nextTasks[0].part_name}`
    : "None";
  //text in button for taking tasks input inside modal
  const submitText =
    nextTaskDisplay === "None"
      ? "Finish Current Task"
      : "Finish Current & Assign Next";

  //state and form for handling changes
  const initialFormData = {
    current_task: currentTask.id,
    next_task: nextTasks[0]?.id,
  };
  const [taskForm, updateTaskForm] = useState(initialFormData);
  //submit changes button
  const submitDisable =
    currentTaskDisplay === "None" && nextTaskDisplay === "None" ? true : false;

  const handleClose = () => setshowModal(false); //handles closing modal for task changes
  const handleChange = (e) => {
    //handles next task selection inside modal
    // console.log(e.target.name, e.target.value);
    let tempForm = taskForm;
    tempForm[e.target.name] = parseInt(e.target.value, 10);
    updateTaskForm(tempForm);
  };
  const handleSubmitTasks = (e) => {
    e.preventDefault();
    handleTaskChange(taskForm);
    setshowModal(false);
  };
  const handleSubmitChanges = (e) => {
    e.preventDefault();
    // console.log(workcenterForm);
    axiosInstance
      .patch(`ajs/workcenters/${workcenter.id}/`, workcenterForm)
      .then((res) => {
        // console.log(res);
        // const data = res.data;
        // console.log(data);
        callbackChangeinWorkcenter();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  return (
    <div className="d-flex justify-content-start">
      <Button
         variant="primary"
         style={{
           backgroundColor: "#050092",
           color: "#fff",
         }}
        size="sm"
        className="blue-btn"
        onClick={() => setshowModal(true)}
      >
        Report
      </Button>
      {promptSubmit && (
        <Button
          variant="tertiary"
          size="sm"
          className="green-btn mx-2"
          onClick={handleSubmitChanges}
        >
          Submit
        </Button>
      )}
      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="h6">Report : {workcenter.name}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={handleSubmitTasks}>
          <Modal.Body>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <b>Current : </b>
                <small>{currentTaskDisplay}</small>
              </ListGroupItem>
            </ListGroup>

            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <b>Scheduled Next : </b>
                <small>{nextTaskDisplay}</small>
              </ListGroupItem>
            </ListGroup>

            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <b>Next Task</b>
                <small>
                  <Form.Select name="next_task" onChange={handleChange}>
                    {nextTasks.map((task, index) => {
                      return (
                        <option key={index} value={task.id}>
                          {task.mcsr_no} / {task.part_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </small>
              </ListGroupItem>
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="gray" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="tertiary"
              className="ms-auto green-btn"
              disabled={submitDisable}
              type="submit"
            >
              {submitText}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Report;
