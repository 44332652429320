import React from "react";
import { Card } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

const MasterData = () => {
  return (
    <div>
      <Card style={{ height: "80vh" }} bg="light">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Card
            bg="white"
            text="dark"
            style={{ width: "30rem" }}
            className="p-2 shadow-lg"
          >
            <Card.Body
              style={{ border: "2px dotted #000" }}
              className="text-center"
              as="a"
            >
              <Card.Title>
                <FontAwesomeIcon
                  icon={faCloudUploadAlt}
                  size="2x"
                  className="icon_upload"
                />
              </Card.Title>
              <Card.Text className="font-weight-bold" as="h4">
                Drop your file here, or{" "}
                <span className="icon_upload">Browse</span>
              </Card.Text>
              <Card.Text>Supports .xls, .csv</Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MasterData;
