import React, { useState } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { Button as ButtonMui, LinearProgress } from "@mui/material";
import { ConnectedAccountsOptions } from "../../data/ca_metadata";
import {
  UpdateConnectedAccount,
  patchConnectedAccountsDetailsAPIerp,
} from "../../api/connectedAccountsAPI";
import { useDispatch } from "react-redux";
import { setConnectedData } from "../../slices/connectedAccountSlice";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { URLs } from "../../routes";
import { useNavigate } from "react-router-dom";

const AddAccount = (props) => {
  const navigate = useNavigate();

  const { callback } = props;
  const [showModal, setshowModal] = useState(false);
  const [accountType, setAccountType] = useState(ConnectedAccountsOptions[0]);
  const [accountData, setAccountData] = useState({
    base_url: "",
    usr: "",
    pwd: "",
    app_name: "",
    redirecting_url: "/app",
    login_url: "/api/method/drs_connector.api.user_login",
  });
  const { connected_apps } = useSelector((state) => state.connectedAccountData);
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const handleClose = () => {
    setAccountData({ base_url: "", usr: "", pwd: "", app_name: "" });
    setshowModal(false);
    setShowSpinner(false);
  };

  const updateData = async (data) => {
    // console.log(data);
    try {
      const response = await patchConnectedAccountsDetailsAPIerp(data);
      // console.log(response);

      //start- this logic most important
      let tempFormat = JSON.parse(
        localStorage.getItem("connected_apps_erpFormat")
      );
      const convert2Obj = (connectedApps) => {
        let tempData = {};
        for (let app in connectedApps) {
          if (connectedApps[app] === "DRS") {
            tempData[app] = {
              connect2: connectedApps[app],
              credential_available: "YES",
              product_name: app,
              api_key: localStorage.getItem("api_key"),
              api_secret: localStorage.getItem("api_secret"),
              base_url: localStorage.getItem("base_url"),
              sid: localStorage.getItem("sid"),
            };
          } else {
            tempData[app] = {
              connect2: connectedApps[app],
              credential_available: "NO",
            };
          }
        }
        // console.log(tempData)
        return tempData;
      };
      localStorage.setItem(
        "connected_apps_erpFormat2",
        JSON.stringify(convert2Obj(tempFormat))
      );
      //end- this logic most important

      dispatch(
        setConnectedData({
          connected_accounts: response.data.message,
          connected_apps: convert2Obj(tempFormat),
        })
      );
      setShowSpinner(false);
      callback("success", "Successfully added account details!");
      setAccountData({
        base_url: "",
        usr: "",
        pwd: "",
        app_name: "",
      });
      setAccountType(null);
      setTimeout(() => {
        setshowModal(false);
      }, 500);
      setTimeout(() => {
        navigate(URLs.ConnectedAccountSetup.path);
      }, 1000);
    } catch (error) {
      console.log(error);
      setShowSpinner(false);
      callback("error", "Failed!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    updateData({ accountName: accountType, accountData: accountData });
  };

  const handleChange = (e) => {
    // console.log(e);
    setAccountData({ ...accountData, [e.target.name]: e.target.value });
  };
  const handleAccountChange = (e) => {
    // console.log(e.target.name, e.target.value);

    setAccountType(e.target.value);
    setAccountData({ ...accountData, [e.target.name]: e.target.value });
  };
  return (
    <React.Fragment>
      {/* <Button
        variant="tertiary"
        className="my-3"
        onClick={() => setshowModal(true)}
      >
        Add New Entry
      </Button> */}
      <ButtonMui
        onClick={() => setshowModal(true)}
        variant="contained"
        style={{
          marginRight: "8px",
          backgroundColor: "#050092",
          color: "#fff",
        }}
      >
        <Add /> Add New Account
      </ButtonMui>

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="h6">Add Connected Account</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
            <LinearProgress />
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Account Type</Form.Label>

              <Form.Select name="app_name" onChange={handleAccountChange}>
                {ConnectedAccountsOptions.map((accountType, index) => {
                  return (
                    <option value={accountType} key={`accountType-${index}`}>
                      {accountType}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="usr"
                placeholder="Enter Username"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                name="pwd"
                placeholder="Enter Password"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Base URL</Form.Label>
              <Form.Control
                type="text"
                name="base_url"
                placeholder="Enter Base URL"
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" className="ms-auto" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
        {showSpinner && <LinearProgress />}
      </Modal>
    </React.Fragment>
  );
};

export default AddAccount;
