import React from "react";
import NavbarBP from "./NavbarBP";
import ProductListBP from "./ProductListBP";
import FooterBP from "./FooterBP";

const BuyPlans = () => {
  return (
    <div style={{ backgroundColor: "#EFFDF3" }}>
      <NavbarBP />
      <ProductListBP />
      <FooterBP />
    </div>
  );
};

export default BuyPlans;
