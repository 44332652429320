export const AllData = {
  items_bucket: [
    {
      id: "23",
      name: "item 23",
      category: "sun screen",
    },
    {
      id: "3",
      name: "item 3",
      category: "face wash",
    },
    {
      id: "4",
      name: "item 4",
      category: "moisturizer",
    },
    {
      id: "55",
      name: "item 55",
      category: "sun screen",
    },
    {
      id: "65",
      name: "item 65",
      category: "face wash",
    },
    {
      id: "71",
      name: "item 71",
      category: "moisturizer",
    },
    {
      id: "58",
      name: "item 58",
      category: "sun screen",
    },
    {
      id: "12",
      name: "item 12",
      category: "moisturizer",
    },
    {
      id: "652",
      name: "item 652",
      category: "face wash",
    },
    {
      id: "713",
      name: "item 713",
      category: "moisturizer",
    },
    {
      id: "29",
      name: "item 29",
      category: "face wash",
    },
    {
      id: "10",
      name: "item 10",
      category: "moisturizer",
    },
  ],
  kits: {
    kit_1: [
      {
        id: "713",
        name: "item 713",
        category: "moisturizer",
        uuid: "kit_1-713",
      },
    ],
    kit_2: [],
    kit_3: [
      {
        id: "29",
        name: "item 29",
        category: "face wash",
        uuid: "kit_3-29",
      },
      {
        id: "10",
        name: "item 10",
        category: "moisturizer",
        uuid: "kit_3-10",
      },
    ],
    kit_11: [
      {
        id: "713",
        name: "item 713",
        category: "moisturizer",
        uuid: "kit_11-713",
      },
    ],
    kit_21: [],
    kit_31: [
      {
        id: "29",
        name: "item 29",
        category: "face wash",
        uuid: "kit_31-29",
      },
      {
        id: "10",
        name: "item 10",
        category: "moisturizer",
        uuid: "kit_31-10",
      },
    ],
  },
};
