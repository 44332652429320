import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { postAddFundAccount } from "../../../api/RazorpayXAPI";
import { useSelector } from "react-redux";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddAccountmodal = (props) => {
  const buttonStyle = {
    borderColor: "#050092", 
    color: "#050092", 
    marginTop:"10px"
  };

  const [formData, setFormData] = useState({
    contact_id: "",
    account_type: "",
    bank_account: {
      name: "",
      ifsc: "",
      account_number: "",
    },
  });

  const handleInputChange = (field) => (event) => {
    if (field.startsWith("bank_account.")) {
      // If the field is within the bank_account object
      const bankField = field.split(".")[1];
      setFormData({
        ...formData,
        bank_account: {
          ...formData.bank_account,
          [bankField]: event.target.value,
        },
      });
    } else {
      // If the field is outside the bank_account object
      setFormData({
        ...formData,
        [field]: event.target.value, 
      });
    }
  };
  

  const { connected_accounts: connectedAccounts } = useSelector
  (
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedAccounts
    ? connectedAccounts.hasOwnProperty("RazorPayX")
      ? connectedAccounts["RazorPayX"]
      : null
    : null;

  const handleSubmit = async () => {
    // Check if all required fields are filled
    if (
      formData.contact_id &&
      formData.account_type &&
      formData.bank_account.name &&
      formData.bank_account.ifsc &&
      formData.bank_account.account_number 
    ) {
      // All required fields are filled, proceed with form submission
      try {
        const contactsResponse = await postAddFundAccount(
          connectingCredentials.usr,
          connectingCredentials.pwd,
          formData
        );
        console.log(contactsResponse);
        // Call the onSubmit prop to pass data to the parent component
        props.onSubmit(formData);
        console.log(formData);
        // Close the modal after successful submission
        props.onClose();
      } catch (error) {
        console.error("Error submitting contact:", error);
      }
    } else {
      // Display an error message or handle the validation in a way that fits your UI
      console.error("All required fields must be filled");
    }
  };

  return (
    <div>
      <Modal
        keepMounted
        open={props.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            props.onClose();
          }
        }}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Fund Account Details
          </Typography>

          <TextField
            required
            label="Contact ID"
            fullWidth
            margin="normal"
            value={formData.contact_id}
            onChange={handleInputChange("contact_id")}
          />
          <TextField
            required
            label="Account Type"
            fullWidth
            margin="normal"
            value={formData.account_type}
            onChange={handleInputChange("account_type")}
          />
          <Typography style={{marginTop:"5px"}}>Bank Account Details :</Typography>
          <TextField
            required
            label="Name"
            fullWidth
            margin="normal"
            value={formData.bank_account.name}
            onChange={handleInputChange("bank_account.name")}
          />
          <TextField
            required
            label="IFSC"
            fullWidth
            margin="normal"
            value={formData.bank_account.ifsc}
            onChange={handleInputChange("bank_account.ifsc")}
          />
          <TextField
            required
            label="Account Number"
            fullWidth
            margin="normal"
            value={formData.bank_account.account_number}
            onChange={handleInputChange("bank_account.account_number")}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "auto",
            }}
          >
            <Button
              onClick={props.onClose}
              variant="outlined"
              style={buttonStyle}
            >
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              variant="outlined"
              style={{ ...buttonStyle, marginLeft: 8 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddAccountmodal;
