import {
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  TextField,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { updateDetail } from "../../../slices/calculatorSlice";

const KeyValueCard = ({ keyName, title, value, editable, type }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const [editedValue, setEditedValue] = useState(
    type === "number" ? +value : type === "checkbox" ? JSON.parse(value) : value
  );
  const [lastValue, setLastValue] = useState(
    type === "number" ? +value : type === "checkbox" ? JSON.parse(value) : value
  );
  useEffect(() => {
    if (type !== "checkbox") {
      setEditedValue(
        type === "number"
          ? +value
          : type === "checkbox"
          ? JSON.parse(value)
          : value
      );
    }
  }, [value]);

  const handleIconClick = () => {
    setIsEditing(true);
  };
  const handleCellBlur = () => {
    setIsEditing(false);
    if (editedValue === "") {
      setEditedValue(lastValue);
    }
  };

  const handleInputChange = (event) => {
    let tempValue = event.target.value;
    if (type === "checkbox") {
      tempValue = event.target.checked;
    }
    if (tempValue !== "") {
      dispatch(
        updateDetail({
          changedColumnKey: keyName,
          changedColumnValue: tempValue,
        })
      );
    }
    setLastValue(editedValue);
    setEditedValue(tempValue);
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <CardContent style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            {editable && type === "number" && (
              <IconButton onClick={handleIconClick}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
          <Typography variant="subtitle2" component="div">
            {title}
          </Typography>
          {type == "number" &&
            (isEditing ? (
              <TextField
                type={type}
                value={editedValue}
                onChange={handleInputChange}
                onBlur={handleCellBlur}
                autoFocus
                size="small" // Adjust the size
                fullWidth
                variant="outlined"
              />
            ) : (
              <Typography component="div" color="navy">
                {editedValue}
              </Typography>
            ))}
          {type === "checkbox" && (
            <Checkbox checked={editedValue} onChange={handleInputChange} />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const DetailTable = (props) => {
  const { head: formattedDetails } = useSelector(
    (state) => state.calculatorData
  );
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {formattedDetails &&
          formattedDetails.map((item, index) => (
            <KeyValueCard
              key={`detail-item-${index}`}
              keyName={item.name}
              title={item.label}
              value={`${item.value} ${item.uom ? item.uom : ""}`}
              editable={item.editable}
              type={item.type}
            />
          ))}
      </Grid>
    </React.Fragment>
  );
};

export default DetailTable;
