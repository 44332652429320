import React from "react";
import { Card, Col } from "@themesberg/react-bootstrap";
import Plotly from "plotly.js-basic-dist-min";
// import Plot from "react-plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

const PlotWorkCenter = (props) => {
  const { plotData } = props;
  // console.log(plotData);
  const workcenters = [];
  const Colors = [
    "#0059b3",
    "#67af26",
    "#fcdc04",
    "#4285f4",
    "#35b995",
    "#dbbc21",
    "#ff9800",
    "#591b58",
    "#7d4540",
    "#887add",
  ];
  const all_partmanufacture = [];
  plotData.forEach((project) => {
    project.parts.forEach((part) =>
      part.partmanufacture.forEach((pm) => all_partmanufacture.push(pm))
    );
  });
  // console.log(all_partmanufacture);
  const unique_projects = [];
  const unique_workcenters = [];

  all_partmanufacture.forEach((dt) => {
    let showLegend = false;
    if (unique_projects.includes(dt.mcsr_no)) {
      showLegend = false;
    } else {
      unique_projects.push(dt.mcsr_no);
      showLegend = true;
    }
    if (!unique_workcenters.includes(dt.workcenter)) {
      unique_workcenters.push(dt.workcenter);
    }
    const workcenter = {
      x: [dt.start_time, dt.eta],
      y: [dt.workcenter_name, dt.workcenter_name],
      mode: "lines",
      name: `${dt.mcsr_no}`,
      line: {
        width: 14,
        color: Colors[unique_projects.indexOf(dt.mcsr_no)],
      },
      showlegend: showLegend,
      legendgroup: dt.mcsr_no,
      text: [
        [`(${dt.mcsr_no}) ${dt.part_name}`],
        [`(${dt.mcsr_no}) ${dt.part_name}`],
      ],
      hovertemplate: " <b>%{text}</b> <br>X: %{x}<br>",
    };
    workcenters.push(workcenter);
  });

  const layout = {
    title: "WorkCenter Timeline",
    autosize: true,
    showlegend: true,
    hovermode: "closest",
    hoverlabel: { bgcolor: "#FFF" },
    xaxis: {
      title: "",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 12,
      },
      showgrid: true,
      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    yaxis: {
      title: "",
      titlefont: {
        size: 10,
      },
      tickfont: {
        size: 12,
      },
      showgrid: true,
      rangemode: "nonnegative",
      autorange: true,

      zerolinecolor: "#969696",
      zerolinewidth: 1,
    },
    legend: {
      orientation: "h",
      x: 0.5,
      y: -0.2,
      xanchor: "center",
    },
    margin: {
      l: 50,
      b: 80,
      r: 30,
      t: 30,
      pad: 5,
    },
  };
  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body className="d-flex flex-row align-items-center flex-0 border-bottom">
          <div className="d-block">
            <h6 className="fw-normal text-gray mb-2">Workcenters</h6>
            <h3>{unique_workcenters.length}</h3>
          </div>
          {/* <div className="d-block ms-auto">
            <div className="d-flex align-items-center text-end mb-2">
              <span
                className={`shape-xs rounded-circle  me-2`}
                style={{ backgroundColor: "#0050a4" }}
              />
              <small className="fw-normal">Scheduled</small>
            </div>
          </div> */}
        </Card.Body>
      </Card>

      <Col xl={12}>
        <Card className="p-2 m-0">
          <Plot data={workcenters} layout={layout} />
        </Card>
      </Col>

      {/* <Card className="p-2 m-1">
        <Plot data={workcenters} layout={layout} />
      </Card> */}
    </>
  );
};

export default PlotWorkCenter;
