import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import SyncIcon from "@mui/icons-material/Sync";
import dayjs from "dayjs";
import { templateData } from "../../assets/devData/OMinspectionData";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircleOutline, Save } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";

const today = dayjs();
const formattedDate = today.format("DD-MM-YYYY HH:mm:ss");

const OMform = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log(location);

  const customerNameQP = queryParams.get("customer_name");
  const siteQP = queryParams.get("site");
  const itemNameQP = queryParams.get("item_name");
  const serialNoQP = queryParams.get("serial_no");
  const templateQP = queryParams.get("template_name");

  const isMobileOrTablet = useMediaQuery("(max-width: 768px)");
  const [savebtnClicked, setSavebtnClicked] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [templateSelectedData, setTemplateSelectedData] = useState(null);
  const [templateData2, setTemplateData2] = useState(
    templateData ? templateData : null
  );
  const [newData, setNewData] = useState({
    customerName: null,
    site: null,
    item: null,
    serialNumber: null,
    inspectedBy: null,
    inspectedOn: formattedDate,
    templateName: null,
    parameters: [],
  });

  useEffect(() => {
    if (queryParams.size > 0) {
      console.log("prefilled data available");
      handleAddInspection();
      setNewData({
        customerName: customerNameQP || null,
        site: siteQP || null,
        item: itemNameQP || null,
        serialNumber: serialNoQP || null,
        inspectedBy: null,
        inspectedOn: formattedDate,
        templateName: templateQP || null,
        parameters: [],
      });
      if (templateQP !== null) {
        const paraData = templateData2?.filter((product) => {
          if (product.name === templateQP) {
            setTemplateSelectedData(product.parameters);
            return product;
          }
        });
      }
    }
  }, []);

  const handleChange = (name) => (event, value) => {
    // console.log(name, value);

    if (name === "templateName") {
      setNewData({
        ...newData,
        [name]: value,
        parameters: [],
      });
      if (value !== null) {
        const paraData = templateData2.filter((product) => {
          if (product.name === value) {
            setTemplateSelectedData(product.parameters);
            return product;
          }
        });
      } else if (value === null) {
        setTemplateSelectedData(null);
      }
    } else {
      setNewData({
        ...newData,
        [name]: value,
      });
    }
  };

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    const updatedParameters = [...newData.parameters]; // Create a copy of the parameters array
    const parameterIndex = newData.parameters.findIndex(
      (param) => param.name === name
    );

    if (parameterIndex !== -1) {
      // If a parameter with the same name exists, update its value
      updatedParameters[parameterIndex] = {
        name,
        value,
      };
    } else {
      // If a parameter with the same name doesn't exist, add a new parameter
      updatedParameters.push({
        name,
        value,
      });
    }

    // Update the newData state with the updated parameters
    setNewData({
      ...newData,
      parameters: updatedParameters,
    });
  };

  const handleDataSync = () => {
    // console.log(newData);
    setSavebtnClicked(true);
    setIsAdd(false);

    setNewData({
      customerName: null,
      site: null,
      item: null,
      serialNumber: null,
      inspectedBy: null,
      inspectedOn: formattedDate,
      templateName: null,
      parameters: [],
    });
    setTemplateSelectedData(null);
  };
  const handleAddInspection = () => {
    setIsAdd(true);
    setSavebtnClicked(false);
  };
  return (
    <div className="page-container">
      <Card
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <CardHeader
          title="Asset Inspection"
          action={
            <div style={{ marginTop: isMobileOrTablet ? "10px" : "0px" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#050092",
                  color: "#fff",
                  marginLeft: isMobileOrTablet ? "50px" : "0px",
                }}
                onClick={handleAddInspection}
                startIcon={<AddCircleOutline />}
              >
                Add Inspection
              </Button>
            </div>
          }
          sx={{
            margin: "15px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        />

        <Divider sx={{ backgroundColor: "darkgray" }} />
        {newData && templateData && isAdd && (
          <>
            <CardContent style={{ backgroundColor: "#ffffff" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    id="customerName"
                    options={["Customer 1", "Customer 2", "Customer 3"]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Customer" />
                    )}
                    value={newData.customerName}
                    onChange={handleChange("customerName")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    id="site"
                    options={["Pune", "Mumbai", "Delhi"]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Site" />
                    )}
                    value={newData.site}
                    onChange={handleChange("site")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    id="item"
                    options={["Item 1", "Item 2", "Item 3"]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Item" />
                    )}
                    value={newData.item}
                    onChange={handleChange("item")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    id="serialNumber"
                    options={["a001", "a002", "a003"]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Serial No." />
                    )}
                    value={newData.serialNumber}
                    onChange={handleChange("serialNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    id="inspectedBy"
                    options={["Supervisor 1", "Supervisor 2", "Supervisor 3"]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Inspected By" />
                    )}
                    value={newData.inspectedBy}
                    onChange={handleChange("inspectedBy")}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={4}>
                  date picker
                </Grid> */}
              </Grid>
            </CardContent>
            <Divider sx={{ backgroundColor: "darkgray" }} />
            <CardContent style={{ backgroundColor: "#ffffff" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    id="templateName"
                    options={[
                      ...new Set([...templateData2.map((temp) => temp.name)]),
                    ]}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Inspection Template" />
                    )}
                    value={newData.templateName}
                    onChange={handleChange("templateName")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  {templateSelectedData?.map((para, index) => {
                    return (
                      <Box
                        key={para.name}
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "row", // Stack items vertically
                          alignItems: "center", // Center items horizontally
                          justifyContent: "space-between", // Center items vertically
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            width: "100px",
                          }}
                        >
                          {para.name}
                        </Typography>
                        <TextField
                          id="outlined-basic"
                          label={para.name}
                          variant="outlined"
                          type="number"
                          name={para.name}
                          onChange={handleChange2}
                          sx={{
                            width: "170px",
                          }}
                        />
                      </Box>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      marginRight: "8px",
                      backgroundColor: "#050092",
                      color: "#fff",
                    }}
                    onClick={handleDataSync}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
              </Grid>
            </CardContent>
          </>
        )}
      </Card>
      {savebtnClicked && (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <Box sx={{ width: "300px", height: "auto" }}>
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              <strong>Inspection Saved</strong>
            </Alert>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default OMform;
