import axios from "axios";

export const getVisitingCardsAPIerp = async (url) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const res = await axios.get(`${url}`, {
    headers: {
      Authorization: `token ${api_key}:${api_secret}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  return res;
};
export const postVisitingCardAPIerp = async (formData) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.upload_document`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const postCommentsVCardAPIerp = async (formData) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.add_new_comment`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
export const postTableActionsVCardAPIerp = async (data) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const formData = new FormData();
  formData.append("document_id", JSON.stringify(data.document_id));
  if (data.hasOwnProperty("contact_status")) {
    formData.append("contact_status", data.contact_status);
  }
  if (data.hasOwnProperty("contact_type")) {
    formData.append("contact_type", data.contact_type);
  }

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.bulk_update_visiting_cards`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const updateVisitingCardAPIerp = async (data) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const formData = new FormData();
  formData.append("document_id", data.id);
  formData.append("document_data", JSON.stringify(data));

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.update_sds_document`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

// sds apis
export const getAllSDSlistAPIerp = async (url) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");
  // console.log(url);
  const res = await axios.get(`${url}`, {
    headers: {
      Authorization: `token ${api_key}:${api_secret}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  return res;
};
export const getSDSDetailsAPIerp = async (url) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const res = await axios.get(`${url}`, {
    headers: {
      Authorization: `token ${api_key}:${api_secret}`,
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
  return res;
};
export const postSDSAPIerp = async (formData) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.upload_document`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const updateSDSdocAPIerp = async (data) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const formData = new FormData();
  formData.append("document_id", data.id);
  formData.append("document_data", JSON.stringify(data));

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.update_sds_document`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};

export const updateSDSTableDataAPIerp = async (detail, temp2) => {
  let api_key = localStorage.getItem("api_key");
  let api_secret = localStorage.getItem("api_secret");

  const formData = new FormData();
  formData.append("document_id", detail.id);
  formData.append(
    "document_data",
    JSON.stringify({ table_data_metadata: temp2 })
  );

  const res = await axios.post(
    `${localStorage.getItem(
      "base_url"
    )}/api/method/drs_backend.api.update_sds_document`,
    formData,
    {
      headers: {
        Authorization: `token ${api_key}:${api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return res;
};
