import axios from "axios";

export const attachFiles2TaskAPI = async (data) => {
  try {
    const formData = new FormData();
    formData.append("task_id", data.task_id);
    formData.append("attach_image", data.attach_image);
    const response = await axios.post(
      `https://demo.mannlowe.com/api/method/demopharma.api.attach_image`,
      formData,
      {
        timeout: 1 * 25000,
        headers: {
          //   Authorization: "token b920c5680f2ac19:945f7b0a23eb5c3",
          //   accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log(response);
    return response;
  } catch (error) {
    // Handle any errors or exceptions here
    console.error("Error fetching user list:", error);
    throw error;
  }
};
