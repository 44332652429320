import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CancelIcon from '@mui/icons-material/Cancel';
import { axiosInstance } from "../../axiosSetup";
import { useMediaQuery } from "@mui/material";
import { postReq4DemoAPI } from "../../api/connectedAccountsAPI";

export default function TransitionsModal({ product, onClose, submitRequest }) {
  const [open, setOpen] = useState(true);
  const [productList, setProductList] = useState([]);
  
  const handleClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleRequestDemo = async () => {
    
  try {
    const res = await postReq4DemoAPI(product)
    // console.log(res)
    // const products = res.data.result;
    // setProductList(products);

    submitRequest('success', 'Request Sent Successfully')
    handleClose();

  } catch (error) {
    console.error(error.response);
    submitRequest('error', 'Something Went Wrong !!!')
  } 
};

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? 350 : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} onExited={onClose}>
          <Box sx={style}>
            {/* Add CancelIcon for closing the modal */}
            <CancelIcon
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
              }}
            />
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {product.product_label}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {product.description}
            </Typography>
            <Button
              variant="outlined"
              size="small"
              style={{
                marginTop: "10px",
                marginLeft: "170px",
              }}
              onClick={handleRequestDemo}
            >
              Request For Demo
            </Button>
          </Box>
        </Fade>
      </Modal>      
    </div>
  );
}
