import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedReference } from "../../../slices/calculatorSlice";
import { getItems } from "../api/erpItems";

const ReferenceSelect = () => {
  const [options, setOptions] = useState([]);
  const { reference_types, selected_reference_type, reference } = useSelector(
    (state) => state.calculatorData
  );
  const [itemSelected, setItemSelected] = useState(
    reference !== "" ? reference : null
  );
  const dispatch = useDispatch();
  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Calculator")
      ? connectedApps["Calculator"]
      : null
    : null;

  const getReferenceList = async (url) => {
    try {
      const res = await getItems(
        url,
        connectingCredentials.base_url,
        connectingCredentials.api_key,
        connectingCredentials.api_secret
      );
      if (res.error) {
        console.log(res.error);
      } else {
        const itemNames = res.data.data.data_list.map((item) => item.name);
        setOptions(itemNames);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selected_reference_type) {
      const reference_object = reference_types.filter(
        (item) => item.name === selected_reference_type
      );
      if (reference_object.length > 0) {
        const url = reference_object[0].url;
        getReferenceList(url);
      }
      // const itemNames = reference_types.map((item) => item.name);
      // setOptions(itemNames);
    }
  }, [selected_reference_type]);

  const handleChange = (e, newValue) => {
    setItemSelected(newValue);
    dispatch(setSelectedReference(newValue));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Handle Enter key press here
      dispatch(setSelectedReference(itemSelected));
    }
  };
  return (
    <React.Fragment>
      <Autocomplete
        style={{ background: "white", width: "100%" }}
        disablePortal
        id="combo-box-demo"
        onChange={handleChange}
        onKeyPress={handleKeyPress} // Add this line
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Reference" // Add this line
          />
        )}
        value={itemSelected}
      />
    </React.Fragment>
  );
};

export default ReferenceSelect;
