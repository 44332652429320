import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { URLs } from "./routes";

// components
import AppRoute from "./components/AppRoute";

//layout
import AdminLayout from "./layout/AdminLayout";

//pages

//auth
import Signin from "./pages/auth/Signin.js";
import Signup2 from "./pages/auth/Signup2";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import LogOut from "./pages/auth/Logout";
import NotFoundPage from "./pages/auth/NotFound";
import ServerError from "./pages/auth/ServerError";
import VerifyEmail from "./pages/auth/VerifyEmail";
import GetEmailForResetPassword from "./pages/auth/GetEmailForResetPassword";

//dashboard
import Overview from "./pages/overview/Overview";

//kit
import KitMain from "./pages/kit/KitMain";

//sds
import SDSHome from "./pages/sds/SDSHome";
import TestDoc from "./pages/sds/TestDoc";
import SDSDetail from "./pages/sds/SDSDetail";

//ajs
import Monitor from "./pages/ajs/Monitor";
import MasterData from "./pages/ajs/MasterData";
import AssemblyStatus from "./pages/ajs/AssemblyStatus";
import AJSHome from "./pages/ajs/AJSHome";

//report
import ReportHome from "./pages/reporting/ReportHome";

//chatbot
import Chatbot from "./pages/chatbot/Chatbot";

//price-calculator
import PriceCalculator from "./pages/calculator/PriceCalculator";

//project management
import ProjectManagement from "./pages/projectManagement/ProjectManagement";

//forms
import OMform from "./pages/OandM/OMform";
import VQform from "./pages/DigitalForm/VQform";
import DigitalFormsV2 from "./pages/DigitalForm/openForms/DigitalFormsV2";

//custom dashboard
import CustomDashboard from "./pages/customDashboard/CustomDashboard";

//exhibition
import Exhibition from "./pages/exhibition/Exhibition";
import ExhibitionDetail from "./pages/exhibition/ExhibitionDetail";
import ExhibitionReceipt from "./pages/exhibition/ExibitionReceipt.js";
import CompanySettings from "./pages/companySettings/CompanySettings";

//payments
import Payments from "./pages/payments/Payments";
import InvoiceDetail from "./pages/payments/InvoiceDetail";
// import PaymentModal from "./pages/payments/PaymentModal.jsx";
import AddContacts from "./pages/payments/Contacts/AddContacts.jsx";
import FundAccount from "./pages/payments/FundAccounts/FundAccount.jsx";
import Suppliers from "./pages/payments/Suppliers/Suppliers.jsx";

//connected accounts
import ConnectedAccounts from "./pages/connectedAccounts/ConnectedAccounts";

//notes
import Notes from "./pages/notes/Notes";

//website
import Landing from "./pages/landing/Landing.js";
import Contact from "./pages/landing/Contact.js";
import PricingView from "./pages/landing/PricingView.js";

//subscription
import MyPlans from "./pages/plans/MyPlans.jsx";
import Buy from "./pages/plansPricing/Buy.js";
// import BuyPlans from "./pages/plans/BuyPlans.jsx";
import BuyPlans from "./pages/plans3/BuyPlans.jsx";
import Cart from "./pages/plans/Cart.jsx";
import MainNotes from "./pages/notes/MainNotes.js";
import PrivacyPolicy from "./pages/landing/PrivacyPolicy.jsx";

import OverviewMobile from "./pages/overview/OverviewMobile.jsx";
import { useMediaQuery } from "@mui/material";
import BotImgUpload from "./pages/chatbot/BotImgUpload.js";

import UserManual from "./pages/UserManual/Usermanual.jsx";
import ConnectedAccountSetup from "./pages/connectedAccounts/ConnectedAccountSetup.js";
import RedirectingErr from "./pages/messages/RedirectingErr.jsx";
import PlanCheckout from "./pages/plans3/PlanCheckout.jsx";
import TimesheetMain from "./pages/timesheet/TimesheetMain.js";

const App = () => {
  const isExtraSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Routes>
      {/* Auth */}
      <Route exact path={URLs.Signin.path} element={<Signin />} />
      <Route exact path={URLs.Signup.path} element={<Signup2 />} />
      <Route exact path={URLs.Logout.path} element={<LogOut />} />
      <Route
        exact
        path={URLs.ForgotPassword.path}
        element={<ForgotPassword />}
      />
      <Route exact path={URLs.VerifyEmail.path} element={<VerifyEmail />} />
      <Route exact path={URLs.ResetPassword.path} element={<ResetPassword />} />
      <Route
        exact
        path={URLs.GetEmailForResetPassword.path}
        element={<GetEmailForResetPassword />}
      />
      <Route exact path={URLs.NotFound.path} element={<NotFoundPage />} />
      <Route exact path={URLs.ServerError.path} element={<ServerError />} />

      {/* Website */}
      <Route exact path={URLs.landingURL.path} element={<Landing />} />
      <Route
        exact
        path={URLs.privacyPolicyURL.path}
        element={<PrivacyPolicy />}
      />
      <Route exact path={URLs.contactURL.path} element={<Contact />} />
      <Route exact path={URLs.pricingURL.path} element={<PricingView />} />

      {/* Overview */}

      <Route
        exact
        path={URLs.Overview.path}
        element={
          <AppRoute
            component={isExtraSmallScreen ? OverviewMobile : Overview}
            layout={AdminLayout}
          />
        }
      />

      {/* SDS */}
      <Route
        exact
        path={URLs.SDS.path}
        element={<AppRoute component={SDSHome} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.TestDoc.path}
        element={<AppRoute component={TestDoc} layout={AdminLayout} />}
      />
      <Route
        path={URLs.SDSDetail.path}
        element={<AppRoute component={SDSDetail} layout={AdminLayout} />}
      />

      {/* Dashboards */}
      <Route
        exact
        path={URLs.Dashboard.path}
        element={<AppRoute component={CustomDashboard} layout={AdminLayout} />}
      />

      {/* AJS */}
      <Route
        exact
        path={URLs.AJS.path}
        element={<AppRoute component={AJSHome} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.AJSAssemblyStatus.path}
        element={<AppRoute component={AssemblyStatus} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.AJSMonitor.path}
        element={<AppRoute component={Monitor} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.AJSMasterData.path}
        element={<AppRoute component={MasterData} layout={AdminLayout} />}
      />

      {/* Network Optimization */}
      <Route
        exact
        path={URLs.NetworkOptimizationMasterData.path}
        element={<AppRoute component={MasterData} layout={AdminLayout} />}
      />

      {/* Reports */}
      <Route
        path={URLs.IntelligentReporting.path}
        element={<AppRoute component={ReportHome} layout={AdminLayout} />}
      />

      {/* KIT MANAGEMENT */}
      <Route
        exact
        path={URLs.productBundling.path}
        element={<AppRoute component={KitMain} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.projectManagementURL.path}
        element={
          <AppRoute component={ProjectManagement} layout={AdminLayout} />
        }
      />
      <Route
        exact
        path={URLs.omInspectionURL.path}
        element={<AppRoute component={OMform} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.digitalFormURL.path}
        element={<AppRoute component={VQform} layout={AdminLayout} />}
      />

      {/* Calculator */}
      <Route
        path={URLs.Calculator.path}
        element={<AppRoute component={PriceCalculator} layout={AdminLayout} />}
      />

      {/* Chatbot */}
      <Route
        path={URLs.ChatbotURL.path}
        element={<AppRoute component={Chatbot} layout={AdminLayout} />}
      />

      {/* Exhibition */}
      <Route
        path={URLs.Exhibition.path}
        element={<AppRoute component={Exhibition} layout={AdminLayout} />}
      />
      <Route
        path={URLs.ExhibitionReceipt.path}
        element={
          <AppRoute component={ExhibitionReceipt} layout={AdminLayout} />
        }
      />
      <Route
        path={URLs.ExhibitionDetail.path}
        element={<AppRoute component={ExhibitionDetail} layout={AdminLayout} />}
      />

      {/* Plans */}
      <Route
        path={URLs.MyPlanPlans.path}
        element={<AppRoute component={MyPlans} layout={AdminLayout} />}
      />
      {/* Payments */}
      <Route
        path={URLs.Payments.path}
        element={<AppRoute component={Payments} layout={AdminLayout} />}
      />

      <Route
        path={URLs.AddContacts.path}
        element={<AppRoute component={AddContacts} layout={AdminLayout} />}
      />

      <Route
        path={URLs.FundAccount.path}
        element={<AppRoute component={FundAccount} layout={AdminLayout} />}
      />

      <Route
        path={URLs.InvoiceDetail.path}
        element={<AppRoute component={InvoiceDetail} layout={AdminLayout} />}
      />

      <Route
        path={URLs.Suppliers.path}
        element={<AppRoute component={Suppliers} layout={AdminLayout} />}
      />

      {/* <Route
        path={URLs.Payment.path}
        element={<AppRoute component={PaymentDetail} layout={AdminLayout} />}
      /> */}

      {/* Notes */}
      <Route
        path={URLs.Notes.path}
        element={<AppRoute component={MainNotes} layout={AdminLayout} />}
      />
      <Route
        path={URLs.TimesheetURL.path}
        element={<AppRoute component={TimesheetMain} layout={AdminLayout} />}
      />
      <Route
        path={URLs.RedirectingErrURL.path}
        element={<AppRoute component={RedirectingErr} layout={AdminLayout} />}
      />

      {/* Setup */}
      <Route
        exact
        path={URLs.CompanySettingsURL.path}
        element={<AppRoute component={CompanySettings} layout={AdminLayout} />}
      />
      <Route
        exact
        path={URLs.ConnectedAccounts.path}
        element={
          <AppRoute component={ConnectedAccounts} layout={AdminLayout} />
        }
      />
      <Route
        exact
        path={URLs.ConnectedAccountSetup.path}
        element={<ConnectedAccountSetup />}
      />

      {/* Plans */}
      <Route exact path={URLs.BuyPlanURL.path} element={<BuyPlans />} />
      <Route exact path={URLs.Cart.path} element={<Cart />} />
      <Route
        exact
        path={URLs.CheckoutPlanURL.path}
        element={<PlanCheckout />}
      />
      <Route
        exact
        path={URLs.openDigitalFormURL.path}
        element={<DigitalFormsV2 />}
      />
      <Route exact path={URLs.BotImgURL.path} element={<BotImgUpload />} />

      {/* Formulation R&D Labs */}

      <Route
        path={URLs.UserManual.path}
        element={<AppRoute component={UserManual} layout={AdminLayout} />}
      />
    </Routes>
  );
};

export default App;
