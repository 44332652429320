import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/volt.scss";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InstallPrompt from "./IntstallPrompt.js";
import { Workbox } from "workbox-window";
import { axiosInstance } from "./axiosSetup.js";
import axios from "axios";

// if ("serviceWorker" in navigator) {
//   const wb = new Workbox("/service-worker.js", { scope: "/" });

//   wb.register()
//     .then((registration) => {
//       console.log("Service Worker registered with scope:", registration.scope);
//     })
//     .catch((error) => {
//       console.error("Service Worker registration failed:", error);
//     });
// }

if ("serviceWorker" in navigator) {
  const wb = new Workbox("/service-worker.js", { scope: "/" });

  // Function to check for updates and trigger service worker update
  const checkForUpdates = () => {
    // .get("/sds/get-version/?app_name=frontend")
    axios
      .get(
        `${process.env.REACT_APP_ERP_BASE_URL}/api/method/drs_backend.api.get_version`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((response) => response.data)
      .then((data) => {
        let temp = data.apps.filter((item) => item.app_name === "frontend");
        // console.log(data);
        const apiVersion = temp[0].version;
        // const apiVersion = data.apps[0].version;
        const packageVersion = require("../package.json").version;

        if (apiVersion > packageVersion) {
          console.log(
            "New version available. Triggering service worker update."
          );
          wb.messageSW({ type: "SKIP_WAITING" });
        } else {
          console.log("Application is up to date.");
        }
      })
      .catch((error) => {
        console.error("Error fetching version from API:", error);
      });
  };

  // Register the service worker and check for updates on application startup
  wb.register()
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);

      // Check for updates on application startup
      checkForUpdates();
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <App />
        </LocalizationProvider>
      </HashRouter>
      {/* <InstallPrompt /> */}
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
