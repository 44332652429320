import React, { useState } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../axiosSetup";
import { Button as ButtonMui } from "@mui/material";
import { contactTypesList } from "../../data/sds_metadata";
import { postVisitingCardAPIerp } from "../../api/SdsAPI";

const compressImage = async (img) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(img);

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);

      // Convert the canvas to a blob with the desired image quality (0.8 in this case)
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.4
      );
    };
  });
};
const AddEntry = (props) => {
  const { callback } = props;
  const [showModal, setshowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [commentData, setCommentData] = useState(null);
  const [contactType, setContactType] = useState(contactTypesList[0]);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [type, setType] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const handleClose = () => setshowModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    //console.log(image);
    if (image) {
      let form_data = new FormData();
      form_data.append("contact_type", contactType);
      form_data.append("document_type", "VisitingCard");
      if (commentData) {
        form_data.append("comment", commentData);
      }
      if (preview) {
        const compressedImageBlob = await compressImage(image);
        form_data.append("image", compressedImageBlob, image.name);
      } else {
        form_data.append("file", image, image.name);
      }
      // console.log(form_data);
      try {
        const res = await postVisitingCardAPIerp(form_data);
        // console.log(res);
        setShowSpinner(false);
        setShowAlert(true);
        setAlertVariant("success");
        setAlertMsg("Uploaded!");
        callback(res);

      } catch (error) {
        console.log(error);
        setShowSpinner(false);
        setShowAlert(true);
        setAlertVariant("danger");
        if (error.response.status === 403) {
          setAlertMsg("Invalid Subscription!");
        } else {
          setAlertMsg("Upload Failed!");
        }
      }

      // if (type !== "csv") {
      //   axiosInstance
      //     .post("sds/upload_business_card/", form_data, {
      //       headers: {
      //         "content-type": "multipart/form-data",
      //       },
      //     })
      //     .then(function (response) {
      //       //console.log("success");
      //       setShowSpinner(false);
      //       setShowAlert(true);
      //       setAlertVariant("success");
      //       setAlertMsg("Uploaded!");
      //       callback(response);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       setShowSpinner(false);
      //       setShowAlert(true);
      //       setAlertVariant("danger");
      //       if (error.response.status === 403) {
      //         setAlertMsg("Invalid Subscription!");
      //       } else {
      //         setAlertMsg("Upload Failed!");
      //       }
      //     });
      // } else {
      //   axiosInstance
      //     .post("sds/queue/", form_data, {
      //       headers: {
      //         "content-type": "multipart/form-data",
      //       },
      //     })
      //     .then(function (response) {
      //       //console.log("success");
      //       setShowSpinner(false);
      //       setShowAlert(true);
      //       setAlertVariant("success");
      //       setAlertMsg("Uploaded!");
      //       callback(response);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       setShowSpinner(false);
      //       setShowAlert(true);
      //       setAlertVariant("danger");
      //       setAlertMsg("Upload Failed!");
      //     });
      // }
    } else {
      setShowSpinner(false);
      setShowAlert(true);
      setAlertVariant("warning");
      setAlertMsg("No Image Selected!");
      //alert("No image");
    }

    setPreview(null);
    setImage(null);
    setCommentData(null);
  };

  const handleChange = (e) => {
    // console.log(e);
    setShowAlert(false);
    if (e.target.name === "contact_type") {
      setContactType(e.target.value);
    } else {
      const img = e.target.files[0];
      if (img) {
        // console.log(img.type);
        if (img.type.includes("image")) {
          setPreview(URL.createObjectURL(img));
        }
        if (img.type.includes("pdf")) {
          setType("pdf");
          setPreview(null);
        }
        if (img.type.includes("csv")) {
          setType("csv");
          setPreview(null);
        }
        setImage(img);
      } else {
        setPreview(null);
        setImage(null);
      }
    }
  };
  const handleCommentChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setCommentData(e.target.value);
  };
  return (
    <React.Fragment>
      {/* <Button
        variant="tertiary"
        className="my-3"
        onClick={() => setshowModal(true)}
      >
        Add New Entry
      </Button> */}
      <ButtonMui
        onClick={() => setshowModal(true)}
        variant="contained"
        style={{
          marginRight: "8px",
          backgroundColor: "#050092",
          color: "#fff",
        }}
      >
        Add New Entry
      </ButtonMui>

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="h6">Upload Visiting Card</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {showSpinner && (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="grow" variant="info" size="md" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="warning" />
              </div>
            )}

            <React.Fragment>
              {showAlert && (
                <Alert
                  variant={alertVariant}
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  {alertMsg}
                </Alert>
              )}
            </React.Fragment>
            <Form.Group className="mb-3">
              <Form.Label>Contact Type</Form.Label>
              <Form.Select name="contact_type" onChange={handleChange}>
                {contactTypesList.map((contactType, index) => {
                  return (
                    <option value={contactType} key={`contactType-${index}`}>
                      {contactType}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Take picture</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg,.jpeg,.png,.pdf,.csv"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                name="comment"
                placeholder="Comment..."
                onChange={handleCommentChange}
              />
            </Form.Group>

            <React.Fragment>
              {image && preview && (
                <div>
                  <br />
                  <img src={preview} className="card-img-top" alt="preview" />
                  <br />
                </div>
              )}
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" className="ms-auto" type="submit">
              Upload
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AddEntry;
