import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Typography,
  Button,
  CardContent,
} from '@mui/material';
import { Navbar } from 'react-bootstrap';
import CustomBox from './CustomBox';
import form1 from '../../assets/images/form1.png';
import boxes from '../../assets/images/boxes.png';
import product3 from '../../assets/images/product3.png';
import product2 from '../../assets/images/productImage.jpg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Information from './Information';
import ButtonWithAnimation from './ButtonWithAnimation';
import { Link } from 'react-router-dom';
import { URLs } from "../../routes";

const sliderImages = [
  boxes, product3, product2, form1
];

const Landing = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? sliderImages.length - 1 : prevIndex - 1
    );
  };

  // useEffect(() => {
  //   const timer = setInterval(nextImage, 7000); // for changing image after every 5 seconds

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const sliderContainerStyle = {
    position: 'relative',
    width: '95%',
    height: '600px', // Adjust the height as needed
    marginTop: '40px',
    marginLeft: '40px',
    borderRadius: '15px',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '20px',
  };

  const buttonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
  };

  const listStyle = {
    fontSize: '24px',
    fontFamily: 'inherit',
    color:'#3e187a'
  };

  const cardContentStyle =
  {
    marginTop: '70px', // Add margin to move the CardContent down
    marginLeft: '70px',
    fontFamily: 'inherit',
    background: 'linear-gradient(316deg, #3e187a 0%, #994ecc 74%)', // Set the background with linear-gradient
    marginRight: '70px',
    borderRadius: '10px',
    marginBottom: '50px',
  };

  return (
    <div style={{ marginBottom: '70px' }}>
      <AppBar position="static" style={{
        height: '100px', background: 'linear-gradient(316deg, #3e187a 0%, #994ecc 74%)',
      }}>
        <Navbar style={{ marginTop: '12px' }}>
          <div>
            <Typography
              variant="h6"
              sx={{
                ml: 6,
                fontFamily: 'sans-serif',
                textDecoration: 'none',
                fontSize: '32px',
                color: 'white',
              }}
            >
              Mannlowe
            </Typography>
          </div>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}> */}
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              display: 'flex',
              gap: '20px', // Add gap between menu items
              marginLeft: '50px',
              alignItems: 'center', // Align items vertically in the middle

            }}
          >
            <li style={listStyle}><a href="/">Home</a></li>
            <li style={listStyle}><a href="/about">Pricing</a></li>
            <li style={listStyle}><a href="/services">Contact Us</a></li>
            <div style={{ display: 'flex', gap: '10px', marginLeft: '1000px' }}>
              <Link to="/sign-in">
                <ButtonWithAnimation text="Sign In" />
              </Link>
              <Link to="/register">
              <ButtonWithAnimation text="Create Account" />
              </Link>
            </div>
          </ul>
          {/* </Box> */}
        </Navbar>
      </AppBar>

      <CardContent style={cardContentStyle}>
        {/* Your existing content */}
        <div style={{ marginBottom: '-20px', marginLeft: '20px' }}>
          <span style={{ fontSize: '70px', color: 'white' }}>Success starts</span>
        </div>
        <div style={{ marginBottom: '-20px', marginLeft: '20px' }}>
          <span style={{ fontSize: '70px', color: 'white' }}>with a great new</span>
        </div>
        <div>
          <span style={{ fontSize: '70px', color: 'white', marginLeft: '20px' }}>product</span>
        </div>
        <div>
          <p style={{ fontSize: '22px', color: 'white', marginLeft: '20px' }}>
            Lorem ipsum dolor sit amet, consectetur <br />adipiscing elit,
            sed do eiusmod tempor incididunt<br /> ut labore et dolore magna aliqua.
          </p>
        </div>
        <CustomBox />
      </CardContent>
      <div>
        <h1 style={{ marginTop: '60px', marginLeft: '560px', color: 'black', textDecoration: 'underline' }}>Make your work and life easy...</h1>
      </div>
      <div style={sliderContainerStyle}>
        <img
          src={sliderImages[currentImageIndex]}
          alt={`Image ${currentImageIndex}`}
          style={imageStyle}
        />
        <button
          style={{ ...buttonStyle, left: '10px' }}
          onClick={prevImage}
        >
          <ArrowBackIosNewIcon />
        </button>
        <button
          style={{ ...buttonStyle, right: '10px' }}
          onClick={nextImage}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
      <Information />

    </div>
  );
};

export default Landing;

