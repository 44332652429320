import { Copyright } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Card,
  CssBaseline,
  Grid,
  IconButton,
  List,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

const FooterBP = () => {
  return (
    <>
    <CssBaseline />

      <Card
        // position="fixed"
        sx={{
          // top: "auto",
          // bottom: 0,
          backgroundColor: "#f4f5f7",
          color: "#050092",
        }}
      >
        <Toolbar>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Button
                sx={{
                  marginLeft: "5px",
                  color: "#050092",
                  backgroundColor: "#fff",
                }}
              >
                Privacy Policy
              </Button>

              <Button
                sx={{
                  marginLeft: "5px",
                  color: "#050092",
                  backgroundColor: "#fff",
                }}
              >
                Terms
              </Button>
              <Button
                sx={{
                  marginLeft: "5px",
                  color: "#050092",
                  backgroundColor: "#fff",
                }}
              >
                FAQ
              </Button>
              <Button
                sx={{
                  marginLeft: "5px",
                  color: "#050092",
                  backgroundColor: "#fff",
                }}
              >
                Help
              </Button>
            </Grid>
            <Grid
              item
              lg={6}
              md={12}
              sm={12}
              xs={12}
              sx={{ textAlign: "right" }}
            >
              Copyright
              <IconButton color="inherit">
                <Copyright />
              </IconButton>
              2024 Mannlowe Information Services
            </Grid>
          </Grid>
        </Toolbar>
      </Card>
    </>
  );
};

export default FooterBP;
