import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "@themesberg/react-bootstrap";

const WorkcenterStatus = (props) => {
  const { workcenter, setHeadingVariant, handleStatusChange } = props;
  const [showModal, setshowModal] = useState(false); //modal to take inputs for machine down

  //styling
  const [switchStatus, updateSwitchStatus] = useState(
    workcenter.status === "Up" ? true : false
  );
  //state and form for handling changes
  const initialFormData = {
    status: workcenter.status,
  };
  const [statusForm, updateStatusForm] = useState(initialFormData);

  const handleClose = () => setshowModal(false); //modal on close
  const handleStatusSwitch = (e) => {
    if (switchStatus === true) {
      //i.e. workcenter was Up, and user is trying to put in down, so pop up the modal
      let tempForm = statusForm;
      tempForm.status = "Down";
      updateStatusForm(tempForm);
      setshowModal(true);
      //rest will be handled after submit
    } else {
      //i.e. was Down, and user is trying to turn it on
      initialFormData.status = "Up";
      updateSwitchStatus(true);
      setHeadingVariant("success");
      handleStatusChange(initialFormData);
    }
  };

  const handleChange = (e) => {
    //handle changes inside modal for status change inputs
    let tempForm = statusForm;
    tempForm[e.target.name] = e.target.value;
    updateStatusForm(tempForm);
  };

  const handleSubmit = (e) => {
    //handles status changes submit in modal
    e.preventDefault();
    if (switchStatus === true) {
      updateSwitchStatus(false);
      setHeadingVariant("danger");
      handleStatusChange(statusForm);
    }
    setshowModal(false);
  };
  return (
    <>
      <Form>
        <Form.Group className="mb-3">
          <Form.Check
            name="status"
            type="switch"
            id="custom-switch"
            checked={switchStatus}
            onChange={handleStatusSwitch}
          />
        </Form.Group>
      </Form>
      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="h6">
              Workcenter Down : {workcenter.name}
            </Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Reason
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  required
                  name="note"
                  placeholder="Write Reason"
                  onChange={handleChange}
                ></Form.Control>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" className="ms-auto" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default WorkcenterStatus;
