import { Card, Col, Nav, Row } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { CardHeader, Card as CardMui, Divider } from "@mui/material";
import RetryCA from "../../components/RetryCA";
import { useSelector } from "react-redux";

const Chatbot = () => {
  const { connected_apps: connectedApps } = useSelector(
    (state) => state.connectedAccountData
  );
  const connectingCredentials = connectedApps
    ? connectedApps.hasOwnProperty("Chatbot")
      ? connectedApps["Chatbot"]
      : null
    : null;

  const [activeTab, setActiveTab] = useState("first1");

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <div className="page-container">
      {connectingCredentials.credential_available == "NO" && (
        <RetryCA data={{ ...connectingCredentials, productName: "Chatbot" }} />
      )}{" "}
      {connectingCredentials.credential_available == "YES" && (
        <>
          <CardMui
            className="shadow-sm"
            variant="outlined"
            sx={{
              border: "1px solid lightgray",
              borderRadius: "10px",
              width: "100%",
              height: "100px",
            }}
          >
            <CardHeader
              title="Chatbots"
              sx={{
                margin: "15px",
                display: "flex",
                justifyContent: "space-between",
              }}
            />
          </CardMui>
          <Card border="light" className="shadow-sm">
            {/* <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5>Chatbot</h5>
            </Col>
          </Row>
        </Card.Header> */}

            <Card.Body>
              <Row>
                <Col sm={2}>
                  <Nav variant="tabs" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first1"
                        className={activeTab === "first1" ? "active" : ""}
                        onClick={() => handleTabClick("first1")}
                      >
                        ErpNext
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second2"
                        className={activeTab === "second2" ? "active" : ""}
                        onClick={() => handleTabClick("second2")}
                      >
                        Mars Rover
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  {activeTab === "first1" && (
                    <iframe
                      id="erpchatbotFrame"
                      style={{
                        border: "1px solid grey",
                        width: "100%",
                        height: "500px",
                      }}
                      src="https://devsds.eastus.cloudapp.azure.com/botpress/lite/erpchatbot/?m=channel-web&v=Fullscreen&options=%7B%22hideWidget%22%3Atrue%2C%22config%22%3A%7B%22enableReset%22%3Atrue%2C%22enableTranscriptDownload%22%3Atrue%7D%7D"
                    ></iframe>
                  )}
                  {activeTab === "second2" && (
                    <iframe
                      id="nasaBotFrame"
                      style={{
                        border: "1px solid grey",
                        width: "100%",
                        height: "500px",
                      }}
                      src="https://devsds.eastus.cloudapp.azure.com/botpress/lite/nasa-bot/?m=channel-web&v=Fullscreen&options=%7B%22hideWidget%22%3Atrue%2C%22config%22%3A%7B%22enableReset%22%3Atrue%2C%22enableTranscriptDownload%22%3Atrue%7D%7D"
                    ></iframe>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};

export default Chatbot;
