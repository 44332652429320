import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { URLs } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import Footer from "../../components/Footer";
import { LoginAPI, LoginAPIerp } from "../../api/auth";
import { useDispatch } from "react-redux";
import { urPK } from "@mui/x-date-pickers";
import { setConnectedData } from "../../slices/connectedAccountSlice";
import TimesheetMain from "../timesheet/TimesheetMain";

const SignIn = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  let referrer = state?.from === undefined ? undefined : state.from;
  // console.log(state);
  const [loginData, updateLoginData] = useState({});
  const [loadingMode, setLoadingMode] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    updateLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const [showAlert, setShowAlert] = useState(state ? state.showAlertWL : false);
  const [alertVariant, setAlertVariant] = useState(
    state ? state.alertVariantWL : ""
  );
  const [alertMsg, setAlertMsg] = useState(state ? state.alertMsgWL : "");

  useEffect(() => {
    if (referrer === "/logout") {
      //when sign in component is loaded from logout component
      setShowAlert(true);
      setAlertMsg("Logout Sucessfull!");
      setAlertVariant("success");
    } else if (referrer === "/reset-password-redirect") {
      setShowAlert(true);
      setAlertMsg("Password Reset Successfull");
      setAlertVariant("success");
    } else if (referrer === "/" && state.msg === "not-validated") {
      //when sign in component is loaded from home component
      setShowAlert(true);
      setAlertMsg("Your ID is not validated yet!");
      setAlertVariant("danger");
      // localStorage.removeItem("access_token");
      // localStorage.removeItem("refresh_token");
      // localStorage.removeItem("user_email");
      // localStorage.removeItem("group");
      // localStorage.removeItem("subscribed_products");
    } else {
      //no need to show any message
      setAlertMsg("");
      setAlertVariant("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convert2Obj = (connectedApps, dataRes) => {
    // console.log(connectedApps);
    let tempData = {};
    for (let app in connectedApps) {
      if (connectedApps[app] === "DRS") {
        tempData[app] = {
          connect2: connectedApps[app],
          credential_available: "YES",
          product_name: app,
          api_key: dataRes.data.message.api_key,
          api_secret: dataRes.data.message.api_secret,
          base_url: dataRes.data.message.base_url,
          sid: dataRes.data.message.sid,
        };
      } else {
        tempData[app] = {
          connect2: connectedApps[app],
          credential_available: "NO",
        };
      }
    }
    // console.log(tempData);
    return tempData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Login(loginData);

    setLoadingMode(true);
    try {
      const res2erp = await LoginAPIerp(loginData);
      // console.log(res2erp);

      localStorage.setItem("api_key", res2erp.data.message.api_key);
      localStorage.setItem("api_secret", res2erp.data.message.api_secret);
      localStorage.setItem("base_url", res2erp.data.message.base_url);
      localStorage.setItem("company", res2erp.data.message.company);
      localStorage.setItem("company_logo", res2erp.data.message.company_logo);
      localStorage.setItem(
        "connected_apps_erpFormat",
        JSON.stringify(res2erp.data.message.connected_apps)
      );
      localStorage.setItem(
        "connected_apps_erpFormat2",
        JSON.stringify(
          convert2Obj(res2erp.data.message.connected_apps, res2erp)
        )
      );
      localStorage.setItem(
        "document_types",
        JSON.stringify(res2erp.data.message.document_types)
      );
      localStorage.setItem("user_email", res2erp.data.message.email);
      localStorage.setItem(
        "metadata",
        JSON.stringify(res2erp.data.message.metadata)
      );
      localStorage.setItem("mobile_no", res2erp.data.message.mobile_no);
      localStorage.setItem("roles", res2erp.data.message.roles);
      localStorage.setItem(
        "show_multiple_documents_tag",
        res2erp.data.message.show_multiple_documents_tag === 0 ? false : true
      );
      localStorage.setItem(
        "show_sync_button",
        res2erp.data.message.show_sync_button === 0 ? false : true
      );
      localStorage.setItem("sid", res2erp.data.message.sid);
      localStorage.setItem(
        "subscribed_products",
        JSON.stringify(res2erp.data.message.subscribed_products)
      );
      localStorage.setItem("username", res2erp.data.message.username);
      dispatch(
        setConnectedData({
          connected_accounts: {},
          connected_apps: convert2Obj(
            res2erp.data.message.connected_apps,
            res2erp
          ),
        })
      );
      setLoadingMode(false);
      if (res2erp.data.message.subscribed_products.length > 0) {
        navigate(URLs.ConnectedAccountSetup.path);
      } else {
        // console.log("buy plans");
        navigate(URLs.BuyPlanURL.path);
      }
    } catch (error) {
      console.log(error);
      setShowAlert(true);
      setAlertVariant("danger");
      setAlertMsg("Invalid Credentials");
      setLoadingMode(false);
    }
  };

  // website working with react + django, now starting erpDRS api integration
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={URLs.Home.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              homepage
            </Card.Link>
          </p> */}

          <Row
            className="justify-content-center form-bg-image"
            // style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                {showAlert && <Alert variant={alertVariant}>{alertMsg}</Alert>}
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign In</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        name="email"
                        autoFocus
                        required
                        placeholder="email"
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      {/* <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Remember me
                        </FormCheck.Label>
                      </Form.Check> */}
                      <Card.Link
                        className="small text-end"
                        as={Link}
                        to={URLs.GetEmailForResetPassword.path}
                      >
                        Forgot password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    {!loadingMode && <div>Sign in</div>}
                    {loadingMode && (
                      <Spinner animation="border" variant="secondary" />
                    )}
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={URLs.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <TimesheetMain /> */}
    </main>
  );
};

export default SignIn;
