import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSelectedReferenceType } from "../../../slices/calculatorSlice";

const ReferenceTypeSelect = () => {
  const [options, setOptions] = useState([]);
  const { reference_types, selected_reference_type } = useSelector(
    (state) => state.calculatorData
  );
  const [itemSelected, setItemSelected] = useState(
    selected_reference_type !== "" ? selected_reference_type : null
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (reference_types) {
      const itemNames = reference_types.map((item) => item.name);
      setOptions(itemNames);
    }
  }, [reference_types]);

  const handleChange = (e, newValue) => {
    setItemSelected(newValue);
    dispatch(setSelectedReferenceType(newValue));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Handle Enter key press here
      dispatch(setSelectedReferenceType(itemSelected));
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        style={{ background: "white", width: "100%" }}
        disablePortal
        id="combo-box-demo"
        onChange={handleChange}
        onKeyPress={handleKeyPress} // Add this line
        options={options}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Reference Type" // Add this line
          />
        )}
        value={itemSelected}
      />
    </React.Fragment>
  );
};

export default ReferenceTypeSelect;
