import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  Modal,
  Spinner,
} from "@themesberg/react-bootstrap";
import { axiosInstance } from "../../axiosSetup";
import {
  Button as ButtonMui,
  FormControl,
  InputLabel,
  LinearProgress,
  Select,
  TextField,
} from "@mui/material";
import {
  isItemInLocalStorage,
  isObjectEmpty,
} from "../../components/Utilities";
import { MenuItem } from "react-bootstrap-typeahead";
import { postSDSAPIerp } from "../../api/SdsAPI";

const DynamicForm = ({ formData, handleChange }) => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
    handleChange(e);
  };

  useEffect(() => {
    // Iterate through the fields in formData.sds and call handleChange for each
    formData.sds.forEach((field) => {
      const { name, type, options } = field;
      const value = type === "select" ? options[0] || "" : "";

      handleChange({ target: { name, value } });
    });

    // Cleanup function (optional)
    return () => {
      // Perform cleanup if needed
    };
  }, []);

  const renderFormComponents = () => {
    return formData.sds.map((field) => {
      const { name, label, type, options } = field;

      switch (type) {
        case "text":
          return (
            <TextField
              key={name}
              label={label}
              value={formValues[name] || ""}
              onChange={handleInputChange}
            />
          );

        case "select":
          return (
            <Form.Group key={name} controlId={`form-${name}`} className="mb-3">
              <Form.Label>{label}</Form.Label>
              <Form.Control
                as="select"
                name={name}
                value={formValues[name] || ""}
                onChange={handleInputChange}
              >
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          );

        default:
          return null;
      }
    });
  };

  return <div>{renderFormComponents()}</div>;
};

const AddEntry = (props) => {
  // const groups = localStorage.getItem("group").split(",");
  let tempG = JSON.parse(localStorage.getItem("group"));
  let groups = tempG?.map((element) => {
    return element.name;
  });
  const documentTypes = isItemInLocalStorage("document_types")
    ? JSON.parse(localStorage.getItem("document_types"))
    : JSON.parse(localStorage.getItem("document_types"));
  // [
  //   "Receipt",
  //   "Invoice",
  //   "VisitingCard",
  //   "DeliveryChallan",
  //   "CustomerOrder",
  //   "Shipping Label",
  // ]

  const form_metadata = isItemInLocalStorage("form_metadata")
    ? JSON.parse(localStorage.getItem("form_metadata"))
    : null;
  const { callback } = props;
  const [showModal, setshowModal] = useState(false);

  const [group, setGroup] = useState(groups ? groups[0] : null);
  const [formOutput, setFormOutput] = useState({});
  const [docType, setDocType] = useState(documentTypes[0]);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [type, setType] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const handleClose = () => setshowModal(false);

  const show_multiple_documents_tag = isItemInLocalStorage(
    "show_multiple_documents_tag"
  )
    ? localStorage.getItem("show_multiple_documents_tag") == "true"
      ? true
      : false
    : false;

  const [haveMultipleDoc, setHaveMultipleDoc] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //console.log(image);
    if (image) {
      setShowSpinner(true);
      let form_data = new FormData();
      // form_data.append("group", group);
      form_data.append("document_type", docType);
      if (preview) {
        form_data.append("image", image, image.name);
      } else {
        form_data.append("file", image, image.name);
      }
      if (show_multiple_documents_tag) {
        form_data.append("have_multiple_document", haveMultipleDoc);
      }
      if (!isObjectEmpty(formOutput)) {
        //   for (const key in formOutput) {
        //     if (formOutput.hasOwnProperty(key)) {
        //       const value = formOutput[key];
        //       form_data.append(key, value);
        //     }
        //   }
        const miscDataJson = JSON.stringify(formOutput);
        form_data.append("misc_data", miscDataJson);
      }
      try {
        const res = await postSDSAPIerp(form_data);
        // console.log(res)
        if (res.status === 201 || res.status === 200) {
          setShowSpinner(false);
          callback("success", "Uploaded!", true);
        }
      } catch (error) {
        console.log(error);
        setShowSpinner(false);
        if (error.response.status === 403) {
          callback("error", "Invalid Subscription!", false);
        } else {
          callback("error", "Upload Failed!", false);
        }
      }
      //console.log(form_data);
      // if (type !== "csv") {
      //   axiosInstance
      //     .post("sds/upload/", form_data, {
      //       headers: {
      //         "content-type": "multipart/form-data",
      //       },
      //     })
      //     .then(function (response) {
      //       //console.log("success");
      //       if (response.status === 201 || response.status === 200) {
      //         setShowSpinner(false);
      //         callback("success", "Uploaded!", true);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       setShowSpinner(false);
      //       if (error.response.status === 403) {
      //         callback("error", "Invalid Subscription!", false);
      //       } else {
      //         callback("error", "Upload Failed!", false);
      //       }
      //     });
      // } else {
      //   axiosInstance
      //     .post("sds/queue/", form_data, {
      //       headers: {
      //         "content-type": "multipart/form-data",
      //       },
      //     })
      //     .then(function (response) {
      //       //console.log("success");
      //       setShowSpinner(false);
      //       if (response.status === 201 || response.status === 200) {
      //         setShowSpinner(false);
      //         callback("success", "Uploaded!", true);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       setShowSpinner(false);
      //       if (error.response.status === 403) {
      //         callback("error", "Invalid Subscription!", false);
      //       } else {
      //         callback("error", "Upload Failed!", false);
      //       }
      //     });
      // }

      setPreview(null);
      setImage(null);
      setDocType(null);
      setHaveMultipleDoc(null);
      setTimeout(() => {
        setshowModal(false);
      }, 1000);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "group") {
      setGroup(e.target.value);
    } else if (e.target.name === "document_type") {
      setDocType(e.target.value);
    } else if (e.target.name === "have_multiple_document") {
      console.log(e.target.checked);
      setHaveMultipleDoc(e.target.checked);
    } else if (e.target.name === "file") {
      const img = e.target.files[0];
      const allowedFormats = [".jpg", ".jpeg", ".png", ".pdf", ".csv"];
      const fileFormat = img.name.slice(
        ((img.name.lastIndexOf(".") - 1) >>> 0) + 2
      );

      if (!allowedFormats.includes(`.${fileFormat.toLowerCase()}`)) {
        // Show an error alert if the format is not allowed

        callback(
          "error",
          "Invalid file format. Please choose a valid image file.",
          false
        );

        // Clear the file input
        e.target.value = null;
        // Clear the image state
        setImage(null);
        setPreview(null);
        return;
      }
      if (img) {
        // console.log(img.type);
        if (img.type.includes("image")) {
          setPreview(URL.createObjectURL(img));
        }
        if (img.type.includes("pdf")) {
          setType("pdf");
          setPreview(null);
        }
        if (img.type.includes("csv")) {
          setType("csv");
          setPreview(null);
        }
        setImage(img);
      } else {
        setPreview(null);
        setImage(null);
      }
    } else {
      setFormOutput({ ...formOutput, [e.target.name]: e.target.value });
    }
  };
  return (
    <React.Fragment>
      {/* <Button
        variant="tertiary"
        className="my-3"
        onClick={() => setshowModal(true)}
      >
        Add New Entry
      </Button> */}
      <ButtonMui
        onClick={() => setshowModal(true)}
        variant="contained"
        style={{
          marginRight: "8px",
          backgroundColor: "#050092",
          color: "#fff",
        }}
      >
        Add New Entry
      </ButtonMui>

      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="h6">Upload Picture</Modal.Title>
            <Button variant="close" aria-label="Close" onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            {/* {showSpinner && (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="grow" variant="info" size="md" />
                <Spinner animation="grow" variant="success" />
                <Spinner animation="grow" variant="warning" />
              </div>
            )} */}

            {groups?.length >= 2 && (
              <Form.Group className="mb-3">
                <Form.Label>Company Name</Form.Label>
                <Form.Select
                  name="group"
                  onChange={handleChange}
                  disabled={groups.length < 2 ? true : false}
                >
                  {groups.map((group, index) => {
                    return (
                      <option value={group} key={`group-${index}`}>
                        {group}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Document Type</Form.Label>
              <Form.Select name="document_type" onChange={handleChange}>
                {documentTypes.map((documentType, index) => {
                  return (
                    <option value={documentType} key={`documentType-${index}`}>
                      {documentType}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Choose File</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg,.jpeg,.png,.pdf,.csv"
                onChange={handleChange}
                name="file"
              />
            </Form.Group>
            {form_metadata && !isObjectEmpty(form_metadata) && (
              <DynamicForm
                formData={form_metadata}
                handleChange={handleChange}
              />
            )}
            {show_multiple_documents_tag && (
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="showComment"
                  label="Multiple Documents"
                  name="have_multiple_document"
                  checked={haveMultipleDoc}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            <React.Fragment>
              {image && preview && (
                <div>
                  <br />
                  <img src={preview} className="card-img-top" alt="preview" />
                  <br />
                </div>
              )}
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" className="ms-auto" type="submit">
              Upload
            </Button>
          </Modal.Footer>
        </Form>
        {showSpinner && <LinearProgress />}
      </Modal>
    </React.Fragment>
  );
};

export default AddEntry;
