import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add,
  ArrowRightAltOutlined,
  CalendarMonth,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
import {
  generateColumns,
  getDate4Week,
  getDateNextWeek,
  getDatePreviousWeek,
  transformTaskData,
} from "./DateTimeCalc";
import moment from "moment";
import { task_list_data } from "./TimesheetData";
import "./Timesheetstyles.css";
import Header1 from "./Header1";

const TimesheetMain = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [rowsST, setRowsST] = useState(null);
  const [colST, setColST] = useState(null);
  const [currentStartDate, setCurrentStartDate] = useState(
    moment().startOf("week")
  );

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    const dates = getDate4Week(currentStartDate);
    const columns = generateColumns(dates);
    const rows = transformTaskData(task_list_data, dates);
    setColST(columns);
    setRowsST(rows);
  };

  const handleTodayWeek = () => {
    const todayStartDate = moment().startOf("week");
    setCurrentStartDate(todayStartDate);
    const dates = getDate4Week(todayStartDate);
    const columns = generateColumns(dates);
    const rows = transformTaskData(task_list_data, dates);
    setColST(columns);
    setRowsST(rows);
  };

  const handlePrevWeek = () => {
    const previousWeekStartDate = moment(currentStartDate).subtract(1, "weeks");
    setCurrentStartDate(previousWeekStartDate);
    const previousWeekDates = getDate4Week(previousWeekStartDate);
    const columns = generateColumns(previousWeekDates);
    const rows = transformTaskData(task_list_data, previousWeekDates);
    setColST(columns);
    setRowsST(rows);
  };

  const handleNextWeek = () => {
    const nextWeekStartDate = moment(currentStartDate).add(1, "weeks");
    setCurrentStartDate(nextWeekStartDate);
    const nextWeekDates = getDate4Week(nextWeekStartDate);
    const columns = generateColumns(nextWeekDates);
    const rows = transformTaskData(task_list_data, nextWeekDates);
    setColST(columns);
    setRowsST(rows);
  };
  return (
    <div className="page-container">
      <Card
        className="shadow-sm"
        variant="outlined"
        sx={{
          border: "1px solid lightgray",
          borderRadius: "10px",
          width: "100%",
          minHeight: "90vh",
        }}
      >
        <Header1 />
        <Divider sx={{ backgroundColor: "darkgray" }} />
        <CardHeader
          title={
            <>
              <Card
                sx={{
                  // backgroundColor: "#1976d2",
                  maxWidth: "600px",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h4"
                    component="div"
                    style={{ color: "#050092" }}
                  >
                    {colST !== null && colST[2].field.slice(4, 10)} to{" "}
                    {colST !== null &&
                      colST[colST.length - 2].field.slice(4, 10)}
                  </Typography>
                </CardContent>
              </Card>
            </>
          }
          action={
            <>
              <Card
                style={{
                  width: "300px",
                  height: "60px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: isMobileOrTablet ? "5px" : "0px",
                }}
              >
                <Button
                  // onClick={handleOpenNewNote}
                  variant="outlined"
                  style={{
                    marginRight: "4px",
                    borderColor: "#050092",
                    color: "#050092",
                  }}
                >
                  <CalendarMonth />
                </Button>
               
                <Button
                  onClick={handlePrevWeek}
                  variant="outlined"
                  style={{
                    marginRight: "4px",
                    borderColor: "#050092",
                    color: "#050092",
                  }}
                >
                  <ChevronLeft />
                </Button>
                <Button
                  onClick={handleTodayWeek}
                  variant="outlined"
                  style={{
                    marginRight: "4px",
                    borderColor: "#050092",
                    color: "#050092",
                  }}
                >
                  Today
                </Button>
                <Button
                  onClick={handleNextWeek}
                  variant="outlined"
                  style={{
                    marginRight: "4px",
                    borderColor: "#050092",
                    color: "#050092",
                  }}
                >
                  <ChevronRight />
                </Button>
              </Card>
            </>
          }
          sx={{
            margin: "1px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileOrTablet ? "column" : "row",
          }}
        />{" "}
        <CardContent>
          <div style={{ height: "auto", width: "auto" }}>
            {colST && rowsST && (
              <DataGrid
                rows={rowsST}
                columns={colST}
                pageSize={rowsST.length}
                // rowsPerPageOptions={[rowsST.length]}
                autoHeight
                // pagination={false}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TimesheetMain;
