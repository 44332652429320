import axios from "axios";

export const getUsersListAPI = async (cred) => {
  const getUsersListAPI_url = `${cred.base_url}/api/resource/User`;
  try {
    const fields = ["name", "full_name"];

    const response = await axios.get(
      `${getUsersListAPI_url}?fields=${JSON.stringify(fields)}&limit=[]`,

      {
        timeout: 1 * 25000,
        headers: {
          Authorization: `token ${cred.api_key}:${cred.api_secret}`,
          // Authorization: `token ${connectingCredentials.api_key}:${connectingCredentials.api_secret}`,
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};
export const getProjectListAPI = async (cred) => {
  // `https://ops.mannlowe.com/api/method/mannlowe_internal.api.project_users_list`,
  const getProjectListAPI_url = `${cred.base_url}/api/method/project_management_system.api.project_users_list`;
  try {
    const fields = ["name", "project_name"];

    const response = await axios.get(getProjectListAPI_url, {
      timeout: 1 * 25000,
      headers: {
        // Authorization: `token ${connectingCredentials.api_key}:${connectingCredentials.api_secret}`,
        Authorization: `token ${cred.api_key}:${cred.api_secret}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};
export const updateProjectListAPI = async (data, cred) => {
  // console.log(data);
  const updateProjectListAPI_url = `${cred.base_url}/api/method/project_management_system.api.project_user`;
  const formData = new FormData();
  formData.append("projects_list", JSON.stringify(data.projects_list));
  try {
    const response = await axios.put(updateProjectListAPI_url, formData, {
      timeout: 1 * 25000,
      headers: {
        Authorization: `token ${cred.api_key}:${cred.api_secret}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};
