import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
  Alert,
} from "@themesberg/react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { URLs } from "../../routes";
import { ConfirmPasswordResetAPIerp, ResetPasswordAPI } from "../../api/auth.js";
import { Spinner } from "react-bootstrap";
const ResetPassword = () => {
  const { token } = useParams();
  // console.log(token);

  const navigate = useNavigate();
  const [resetData, setResetData] = useState({
    token: token,
  });
  const [confirmResetData, setConfirmResetData] = useState({});
  const [comparePass, setComparePass] = useState(null);
  // const [passChanged, setPassChanged] = useState(false);
  const [processingAPI, setProcessingAPI] = useState(false);
  const [snackbarD, setSnackbarD] = useState({
    flag: false,
    msg: "",
    color: "",
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSnackbarD({
        flag: false,
        msg: "",
        color: "",
      });
    }, 3000);

    return () => clearTimeout(timeout);
  }, [snackbarD]);

  const handleResetData = (e) => {
    setResetData({ ...resetData, [e.target.name]: e.target.value });
    setComparePass(true);
  };
  const handleResetData2 = (e) => {
    setConfirmResetData({
      ...confirmResetData,
      [e.target.name]: e.target.value,
    });
    setComparePass(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmResetData.confirmNewPass === resetData.password) {
      if (resetData.password.length >= 8) {
        setProcessingAPI(true);
        callResetPasswordAPI(resetData);
      }
      if (resetData.password.length < 8) {
        setSnackbarD({
          flag: true,
          msg: "Password should contain atleast 8 characters",
          color: "#dc3545",
        });
      }
    } else {
      setComparePass(false);
    }
  };
  // api call
  
  const callResetPasswordAPI = async (resetData) => {
    try {
      const response = await ConfirmPasswordResetAPIerp(resetData, token);
      // console.log(response);

      if (response.status === 200) {
        setProcessingAPI(false);
        navigate(URLs.Signin.path, {
          state: {
            from: "/reset-password-redirect",
            showAlertWL: true,
            alertVariantWL: "success",
            alertMsgWL: "Password Reset Successfull !",
          },
        });
      }
      if (response.data === null) {
        // console.log(response.error.response.data.password[0]);
        setSnackbarD({
          flag: true,
          msg: "This password is too common",
          color: "#dc3545",
        });
      }
      setProcessingAPI(false);
      // console.log(resetData);
    } catch (error) {
      console.log(error);
      setProcessingAPI(false);
    }
  };
  return (
    <main>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            {/* <p className="text-center">
              <Card.Link
                as={Link}
                to={URLs.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Go to
                sign in
              </Card.Link>
            </p> */}

            {/* {passChanged === true ? (<section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
              <Container>
                <Row className="justify-content-center">

                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Alert style={{ backgroundColor: "#8DFBB5" }}>
                      <h3 className="mb-4">Successfully Resetted Password</h3>
                    </Alert>

                  </Col>
                </Row>
              </Container>
            </section>) : (<></>)} */}
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Reset password</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        name="password"
                        onChange={handleResetData}
                        required
                        type="password"
                        placeholder="Password"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm New Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        name="confirmNewPass"
                        onChange={handleResetData2}
                        required
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                  </Form.Group>

                  {comparePass === false ? (
                    <Alert variant="danger">Password does not Match</Alert>
                  ) : (
                    <></>
                  )}
                  {snackbarD && snackbarD.flag === true && (
                    <Alert variant="danger">{snackbarD.msg}</Alert>
                  )}
                  <Button variant="primary" type="submit" className="w-100">
                    <div> Reset password</div>
                    {processingAPI === true && (
                      <div>
                        <Spinner animation="border" variant="info" />
                      </div>
                    )}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ResetPassword;
