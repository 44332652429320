import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { FormatDateTime } from "../../../components/dateformat";
import { axiosInstance } from "../../../axiosSetup";
import { postCommentsVCardAPIerp } from "../../../api/SdsAPI";

const CommentSection = (props) => {
  const { data, document } = props;
  const [comments, setComments] = useState(data ? data : []);
  const [newComment, setNewComment] = useState("");

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleAddComment = async () => {
    if (newComment.trim() !== "") {
      let formData = new FormData();
      // form_data.append("group", group);
      formData.append("doctype", "SDS Documents");
      formData.append("comment", newComment);
      formData.append("id", document);

      try {
        const res = await postCommentsVCardAPIerp(formData);
        // console.log(res);
        setComments(res.data.document);
        setNewComment("");
      } catch (error) {
        console.log(error);
      }
     
    }
  };

  return (
    <div>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Add a comment"
          variant="outlined"
          value={newComment}
          onChange={handleCommentChange}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
          onClick={handleAddComment}
        >
          Add Comment
        </Button>
      </Box>

      <List>
        {comments &&
          comments.map((comment, index) => {
            return (
              <ListItem key={index} alignItems="flex-start">
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar>
                      <AccountCircle />
                    </Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={comment.owner}
                  secondary={
                    <React.Fragment>
                      <span
                        style={{ fontSize: "0.875rem", color: "text.primary" }}
                      >
                        {comment.content}
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "0.75rem", color: "text.secondary" }}
                      >
                        {FormatDateTime(comment.creation)}
                      </span>
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};

export default CommentSection;
