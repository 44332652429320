export const task_list_data = [
  {
    id: "t1110",
    name: "Learn Trello",
    project: "Management",
    dates: [
      { date: "Tue 11-Jun-2024", time: "8hrs" },
      { date: "Sun 16-Jun-2024", time: "8hrs" },
      { date: "Wed 19-Jun-2024", time: "3hrs" },
      { date: "Fri 21-Jun-2024", time: "4hrs" },
    ],
  },
  {
    id: "t1111",
    name: "Learn Jira",
    project: "Software",
    dates: [
      { date: "Wed 12-Jun-2024", time: "5hrs" },
      { date: "Fri 21-Jun-2024", time: "5hrs" },
      { date: "Sat 22-Jun-2024", time: "6hrs" },
      { date: "Tue 18-Jun-2024", time: "7hrs" },
    ],
  },
  {
    id: "t1112",
    name: "Zulip",
    project: "Management",
    dates: [
      { date: "Thu 13-Jun-2024", time: "11hrs" },
      { date: "Fri 21-Jun-2024", time: "15hrs" },
      { date: "Thu 20-Jun-2024", time: "16hrs" },
      { date: "Wed 19-Jun-2024", time: "7hrs" },
    ],
  },
  {
    id: "t1113",
    name: "Learn Kinde",
    project: "Software",
    dates: [
      { date: "Sat 15-Jun-2024", time: "4hrs" },
      { date: "Sun 16-Jun-2024", time: "1hrs" },
      { date: "Mon 17-Jun-2024", time: "1hrs" },
      { date: "Wed 19-Jun-2024", time: "2hrs" },
    ],
  },
  {
    id: "t1114",
    name: "Parabol",
    project: "Office",
    dates: [
      { date: "Fri 14-Jun-2024", time: "15hrs" },
      { date: "Fri 21-Jun-2024", time: "15hrs" },
      { date: "Thu 20-Jun-2024", time: "16hrs" },
      { date: "Wed 19-Jun-2024", time: "7hrs" },
    ],
  },
 
];
