function getCurrentTask(partmanufacture) {
  // console.log(partmanufacture);
  let currentTask = {};
  for (let task in partmanufacture) {
    if (partmanufacture[task].status === "In Process") {
      currentTask = partmanufacture[task];
      break;
    }
  }
  return currentTask;
}

function getNextTasks(partmanufacture) {
  let nextTasks = [];
  for (let task in partmanufacture) {
    if (
      partmanufacture[task].can_begin === true &&
      partmanufacture[task].status === "Not Started"
    ) {
      nextTasks.push(partmanufacture[task]);
    }
  }
  return nextTasks;
}

export { getCurrentTask, getNextTasks };
